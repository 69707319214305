import React, { Component, Fragment } from 'react';

import Table from '../../views/TrafficTable';

import { UI, BL } from '/views';

import Provider from '../../provider';

import services from '/static/data/services';

class ReportsLostTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getLostTrafficResult(audit_id, id);
  }

  exportExcelLink() {
    const { id, audit_id, user_id } = this.props;

    return this.provider.getLostTrafficExcelLink(audit_id, id, user_id);
  }

  getTableData(select) {
    const { items, cherries } = this.state.report;

    return cherries[select].map(idx => items[idx]);
  }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderTable = idx => {
    const { report } = this.state;

    const select = ['overall', 'yandex', 'google'][idx];

    if (!report) {
      return <div className="p-3 text-center"><UI.Loader /></div>;
    }

    const toolbar = () => {
      return (
        <Fragment>
          <span className="mr-2">Экспорт:</span>
          <UI.Button.Transparent href={this.exportExcelLink()} className="btn-custom-excel" download />
        </Fragment>
      );
    };

    const data = this.getTableData(select);
    const sort = { field: 'diff_year', dir: 'asc' };

    return (
      <BL.Panel.Content className="select-default">
        <Table id={select} data={data} sort={sort} toolbar={toolbar} settings={{ select }} />
      </BL.Panel.Content>
    );
  };

  render() {
    const sets = {
      sticky: false,
      items: ['Весь поисковый трафик', 'Яндекс', 'Google'],
      child: this.renderTable
    };

    return (
      <Fragment>
        <BL.Title icon={services.lost_traffic.icon} name="Страницы, потерявшие поисковый трафик" />

        <BL.Panel>
          <BL.Tabs className="pl-3 pr-3" { ...sets } />
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ReportsLostTraffic;
