import COLORS from '../colors';

export default verdict => ({
  TRAFFIC_NEW: COLORS.STATIC,
  TRAFFIC_GROW: COLORS.SUCCESS,
  TRAFFIC_DROP: COLORS.DANGER,
  TRAFFIC_ZERO: COLORS.CRITICAL,
  TRAFFIC_SUBSIDE: COLORS.SUBSIDE,
  TRAFFIC_STABLE: COLORS.WARNING,
  TRAFFIC_UNMATCH: COLORS.UNKNOWN,
}[verdict]);
