import React from 'react';
import moment from 'moment';

const DateView = props => {
  const { timestamp } = props.items[0];

  const act = new Date(timestamp * 1000).getYear();
  const now = new Date().getYear();

  const format = act === now ? 'D MMMM' : 'D MMMM YYYY';

  return (
    <div className="chat-segment">
      <div className="time-stamp text-center mb-2 fw-400">
        {moment(timestamp * 1000).format(format)}
      </div>
    </div>
  );
};

export default DateView;
