import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import ProjectWithAuditsTable from './components/ProjectWithAuditsTable';
import ProjectWithoutAuditsTable from './components/ProjectWithoutAuditsTable';

import NoProjects from './components/NoProjects';
import NoAudits from './components/NoAudits';
import WithAudits from './components/WithAudits';
import AddAuditNotes from './components/AddAuditNotes';
import ModalRemove from './components/ModalRemove';
import ModalAuditAbort from './components/ModalAuditAbort';

import ModalAuditError from '../../views/ModalAuditError';
import ModalProjectsUpdated from '../../views/ModalProjectsUpdated';
import ModalProjectsUpdateError from './components/ModalProjectsUpdateError';

import { link } from '/services/Components/methods';
import { navigate } from '/services/Router/methods';

import { isEmpty } from '/utils/object';
import { deferred } from '/utils/common';

import { STATUS_INITIAL, STATUS_LOADING, STATUS_UPDATING, STATUS_READY } from '../../store/constants';

import Provider from '../../provider';

import './styles.scss';

class ProjectsRoot extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = { lock: false };

    if (__CLIENT__) {
      const { status } = props.projects;
      const statuses = [STATUS_INITIAL, STATUS_LOADING, STATUS_UPDATING];

      !statuses.includes(status) && props.fetchProjects();
    }
  }

  onAuditRemove = (project_id, audit_id) => {
    const { audit, projects, deleteAudit } = this.props;

    const _audit = audit.items[audit_id];
    const _project = projects.items[project_id];

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();

      const onSubmit = () => {
        const defer = deferred();

        deleteAudit(audit_id, defer);

        VIEW.MODAL.hide();
      };

      return <ModalRemove name={_project.name} date={_audit.dateAdd} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  onProjectsReload = async () => {
    const { fetchProjects, reloadProjects } = this.props;

    VIEW.VIEWPORT.lock();

    let projects = null;

    try {
      const response = await deferred((resolve, reject, defer) => reloadProjects(defer));

      await fetchProjects();

      projects = response.projects;
    } catch(e) {}

    VIEW.MODAL.show(() => {
      const Modal = projects ? ModalProjectsUpdated : ModalProjectsUpdateError;

      return <Modal title="Проекты обновлены" projects={projects} />;
    });

    VIEW.VIEWPORT.unlock();
  };

  startAudit = async id => {
    if (this.state.lock) return;

    this.setState({ lock: true, running: id });

    const { lang, projects: { items }, createAudit, fetchProjectSitemaps, blockProject } = this.props;

    const checkResult = await this.provider.checkYandexCounter(items[id].yandexCounterId);

    if (!checkResult.isAvailable) {
      VIEW.MODAL.show(() => {
        return <ModalAuditAbort accauntName={items[id].accauntName} { ...checkResult } />;
      });

      checkResult.blocked && blockProject(id, checkResult.blocked);

      this.setState({ lock: false, running: null });

      this.props.updateProjectStatus(id, checkResult.status);

      return;
    }

    const sitemaps = await deferred((res, rej, defer) => fetchProjectSitemaps(id, defer));

    const step_0 = Object.values(items[id].settings).every(v => !!v) && Object.values(items[id].mirrors).every(v => !!v);
    const step_1 = !!sitemaps.length;

    if (!step_0 || !step_1) {
      const step = (() => {
        if (!step_0) return 0;
        if (!step_1) return 1;

        return 0;
      })();

      return navigate(link('Projects.Settings', { id, lang, step, callback: 'create-audit' }));
    }

    const defer = deferred();

    defer.catch(() => VIEW.MODAL.show(() => <ModalAuditError />));
    defer.finally(() => this.setState({ lock: false, running: null }));

    createAudit(id, defer);
  };

  getSplittedProjects() {
    const { items } = this.props.projects;

    const list = Object.values(items);

    const checked = list.filter(item => !!item.audits.length);
    const unchecked = list.filter(item => !item.audits.length);

    return { checked, unchecked };
  }

  renderTable = idx => {
    const { startAudit, onAuditRemove } = this;
    const { lang, audit, projects, setCurrentAudit } = this.props;
    const { running } = this.state;

    const actions = { setCurrentAudit, startAudit, onAuditRemove };
    const settings = { lang, running };

    let list = Object.values(projects.items)

    list = list.filter(item => !item.removed && !item.audits.length === !!idx);
    list = list.map(item => ({ ...item }));

    for (let project, i = 0; project = list[i]; i++) {
      project.audits = project.audits.map(id => audit.items[id]).filter(v => v);
      project.hasRunningAudit = !!project.audits[0] && !project.audits[0].ready;
    }

    if (idx === 0) {
      list.sort((a, b) => {
        let _a = a.audits[0].addDate;
        let _b = b.audits[0].addDate;

        if (a > b) return -1;
        if (a < b) return  1;

        return 0;
      });

      return (
        <div className="p-3 select-default">
          {!list.length && <NoAudits />}
          {!!list.length && <WithAudits />}
          <ProjectWithAuditsTable data={list} actions={actions} settings={settings} />
        </div>
      );
    }

    if (idx === 1) {
      return (
        <div className="p-3 select-default">
          <AddAuditNotes />
          <ProjectWithoutAuditsTable data={list} actions={actions} settings={settings} />
        </div>
      );
    }
  };

  renderProjects() {
    const { lang, projects } = this.props;
    const { items, status } = projects;

    if (isEmpty(items) && status === STATUS_LOADING) {
      return (
        <BL.Panel>
          <div className="text-center p-3"><UI.Loader /></div>
        </BL.Panel>
      );
    }

    if (isEmpty(items) && status === STATUS_READY) {
      return <NoProjects lang={lang} />;
    }

    const list = Object.values(items);

    const { checked, unchecked } = this.getSplittedProjects();

    const toolbar = () => {
      return (
        <UI.Button.Success className="mr-3" icon="fal fa-redo" size="xs" onClick={this.onProjectsReload}>
          Обновить список проектов
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
            Нажмите на эту кнопку, если в вашем аккаунте Яндекса появились новые счетчики
          </BL.Info.Dark>
        </UI.Button.Success>
      );
    };

    const sets = {
      items: [
        'С аудитами' + (list.length ? ` (${checked.filter(item => !item.removed).length})` : ''),
        'Без аудитов' + (list.length ? ` (${unchecked.filter(item => !item.removed).length})` : ''),
      ],
      child: this.renderTable,
      active: +!checked.length,
      toolbar,
    };

    return (
      <BL.Panel>
        <BL.Tabs { ...sets } />
      </BL.Panel>
    );
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title icon="fal fa-books" name="Проекты">
          Все счетчики из подключенных вами <a href={link('Settings.Accesses', { lang })}>аккаунтов Яндекса</a>.
        </BL.Title>

        {this.renderProjects()}
      </Fragment>
    );
  }
}

export default ProjectsRoot;
