import React from 'react';

const fields = {};

fields.url = item => <a href={item.url} target="_blank">{item.url}</a>;

fields.all = item => item.stats.last3month.values.overall;
fields.yandex = item => item.stats.last3month.values.yandex;
fields.google = item => item.stats.last3month.values.google;

export default (name, item, actions, settings) => fields[name](item, actions, settings);
