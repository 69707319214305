import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="col_lastmodify" />,
    field: 'lastmod',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '200px', maxWidth: '200px', minWidth: '200px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_page" />,
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: {},
  },
];
