import React, { Fragment } from 'react';

import { BL } from '/views';

const renderMinors = list => {
  return list.map((host, i) => {
    return (
      <Fragment key={host}>
        {!!i && ', '}<b>{host}</b>
      </Fragment>
    );
  });
};

const AlertNotFinishedRedirect = props => {
  const { hosts, mirror } = props;

  const main = mirror.main.from.host;
  const minors = hosts.filter(host => host !== main);

  return (
    <BL.Alert.Danger className="mt-3">
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        Не настроен 301 редирект на главное зеркало <b>{main}</b> с неглавного зеркала {renderMinors(minors)}.
        <br />
        Рекомендуем настроить 301 редирект на главное зеркало.
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

export default AlertNotFinishedRedirect;
