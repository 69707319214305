import { connect } from 'react-redux'

import view from './view';

import { createRoute } from '/services/Router/route';

import { createConversation, readConversation, setCurrentConversation, sendConversationMessage, toggleConversationState } from '../../store/actions';

const route = createRoute({ path: '/support/chat', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { user, support } = state;
  const { conversations } = support;

  return { conversations, user };
};

const mapDispatchToProps = { createConversation, readConversation, setCurrentConversation, sendConversationMessage, toggleConversationState };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
