import { handleActions } from 'redux-actions';

import { updateSettings } from './actions';
import initial from './initial';

export default handleActions({
    [updateSettings]: _updateSettings,
}, initial());

function _updateSettings (state, { payload }) {
  return { ...state, payload };
}
