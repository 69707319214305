import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import TitleDescription from './components/TitleDescription';

import Verdict from './components/Verdict';

import GoogleMirrorsTable from './components/GoogleMirrorsTable';
import YandexMirrorsTable from './components/YandexMirrorsTable';

import AlertTransition from './components/AlertTransition';
import AlertHttpTraffic from './components/AlertHttpTraffic';
import AlertDifferentMirrors from './components/AlertDifferentMirrors';
import AlertWrongRedirectStatus from './components/AlertWrongRedirectStatus';
import AlertNotFinishedRedirect from './components/AlertNotFinishedRedirect';

import Provider from '../../provider';

import { ERROR } from './constants';

import services from '/static/data/services';

class ReportsMainMirror extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getMainMirrorResult(audit_id, id);
  }

  // exportExcelLink() {
  //   const { id, user_id } = this.props;
  //
  //   return this.provider.getAllTrafficExcelLink(id, user_id);
  // }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderMirrorTables() {
    const { stats, analytics } = this.state.report || {};

    if (!stats) return null;

    const error = analytics.errors.find(item => item.name === ERROR.DOMAIN_SWITCHED);

    return (
      <Fragment>
        {!!error && <AlertTransition domains={error.domains} />}
        {stats.hosts.map((item, i) => <GoogleMirrorsTable key={i} { ...item } />)}
      </Fragment>
    );
  }

  renderYandexTable() {
    const { stats } = this.state.report || {};

    if (!stats) return null;

    const { indexes } = [...stats.hosts].pop();

    return <YandexMirrorsTable indexes={indexes} />;
  }

  renderVerdict() {
    const { analytics } = this.state.report || {};

    if (!analytics) return null;

    return <Verdict mirror={analytics.mirror} />;
  }

  renderErrors() {
    const { analytics } = this.state.report || {};

    if (!analytics) return null;

    const { errors, mirror } = analytics;

    const find = error => errors.find(item => item.name === error);

    const result = [];
    let error = null;

    if (error = find(ERROR.HTTP_TRAFFIC)) {
      const { lang, audit_id, reports } = this.props;

      result[result.length] = <AlertHttpTraffic key={ERROR.HTTP_TRAFFIC} lang={lang} auditId={audit_id} reports={reports} />;
    }

    if (error = find(ERROR.NOT_FINISHED_REDIRECT)) {
      result[result.length] = <AlertNotFinishedRedirect key={ERROR.NOT_FINISHED_REDIRECT} hosts={error.hosts} mirror={mirror} />;
    }

    if (error = find(ERROR.WRONG_REDIRECT_STATUS)) {
      result[result.length] = <AlertWrongRedirectStatus key={ERROR.WRONG_REDIRECT_STATUS} statuses={error.statuses} />;
    }

    if (error = find(ERROR.DIFFERENT_MIRRORS)) {
      result[result.length] = <AlertDifferentMirrors key={ERROR.DIFFERENT_MIRRORS} />;
    }

    if (!result.length) return null;

    return <div>{result}</div>;
  }

  render() {
    const { report } = this.state;

    return (
      <Fragment>
        <BL.Title icon={services.main_mirror.icon} name={services.main_mirror.name}>
          <TitleDescription />
        </BL.Title>

        <BL.Panel>
          <BL.Panel.Content className="pt-1">
            {!report && <div className="text-center mt-3 pt-3 pb-3"><UI.Loader /></div>}

            {this.renderVerdict()}
            {this.renderMirrorTables()}
            {this.renderYandexTable()}
            {this.renderErrors()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ReportsMainMirror;
