import React, { Component } from 'react';
import cn from 'classnames';

import Button from '../../../Button';

const BUTTONS = {
  prev: { text: 'Назад', stl: 'primary' },
  next: { text: 'Далее', stl: 'primary' },
  submit: { text: 'Отправить', stl: 'primary' },
};

class Steps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: +props.current || 0,
    };
  }

  canGoNext() {
    const { current } = this.state;
    const { isCompleted } = this.props;

    return isCompleted ? isCompleted(current) : true;
  }

  isFirst = () => this.state.current === 0;
  isLast = () => this.state.current === this.props.steps.length - 1;

  next = async () => {
    const valid = await this.canGoNext();

    if (!valid || this.isLast()) return;

     this.setState(state => ({ ...state, current: state.current + 1 }))
  };

  prev = () => !this.isFirst() && this.setState(state => ({ ...state, current: state.current - 1 }));

  submit = async () => {
    const valid = await this.canGoNext();

    if (!valid) return;

    this.props.onSubmit && this.props.onSubmit();
  };

  renderPrevButton() {
    const { current } = this.state;
    const { buttons } = this.props;

    let values = (buttons || {}).prev || {};

    values = { ...BUTTONS.prev, ...values };

    values.disabled = !current;

    return <Button className="ui_form_steps-control_prev" icon="fal fa-angle-left" size="sm" onClick={this.prev} {...values} />
  }

  renderNextButton() {
    const { current } = this.state;
    const { steps, buttons } = this.props;

    if (current === steps.length - 1) return null;

    let values = (buttons || {}).next || {};

    values = { ...BUTTONS.next, ...values };

    return <Button className="ui_form_steps-control_next ml-2" icon2="fal fa-angle-right" size="sm" onClick={this.next} {...values} />
  }

  renderSubmitButton() {
    const { current } = this.state;
    const { steps, buttons } = this.props;

    if (current !== steps.length - 1) return null;

    let values = (buttons || {}).submit || {};

    values = { ...BUTTONS.submit, ...values };

    return <Button className="ui_form_steps-control_submit ml-2" icon2="fal fa-angle-right" size="sm" onClick={this.submit} {...values} />
  }

  renderPoints() {
    const { current } = this.state;
    const { steps, validations = [] } = this.props;

    const className = i => cn(
      'ui_form_steps-point',
      i === current && 'ui_form_steps-point_active',
      validations[i] === false && 'ui_form_steps-point_invalid',
    );

    const items = [];

    steps.forEach((v, i) => {
      if (i) items[items.length] = <div key={i + '-sep'} className="ui_form_steps-point_separator" />;

      items[items.length] = (
        <div key={i + '-point'} className={className(i)}>
          <div className="ui_form_steps-point_bubble" />
          <div className="ui_form_steps-point_num">{i + 1}</div>
        </div>
      );
    });

    return items;
  }

  render() {
    const { current } = this.state;
    const { steps, validation } = this.props;

    return (
      <div className="ui_form_steps-root">
        <div className="ui_form_steps-points">
          {this.renderPoints()}
        </div>
        <div className="ui_form_steps-separator" />
        <div className="ui_form_steps-fieldss">
          {steps[current]()}
        </div>
        <div className="ui_form_steps-controls">
          {this.renderPrevButton()}
          {this.renderNextButton()}
          {this.renderSubmitButton()}
        </div>
      </div>
    );
  }
}

export default Steps;
