import React, { Component } from 'react';

import Authorization from '/components/User/views/Authorization';

import LanguagesMenu from '/views/common/LanguagesMenu';
import ToolsMenu from '/views/common/ToolsMenu';

import { link } from '/services/Components/methods';

import UI from '/views/ui';
import VIEW from '/views/view';

import logo from '/static/images/logo/32.png';

class Header extends Component {
  onAuthShow = () => {
    VIEW.MODAL.show(() => {
      return <Authorization />;
    });
  };

  renderAuthButton = () => {
    return (
      <UI.Button.Warning size="sm" onClick={this.onAuthShow}>
        Авторизация
      </UI.Button.Warning>
    );
  };

  renderDashboardButton = () => {
    const { lang } = this.props;

    return (
      <UI.Button.Warning size="sm" key="dashboard" href={link('Projects.Root', { lang })}>
        Панель управления
      </UI.Button.Warning>
    );
  };

  renderAdminButton = () => {
    const { lang } = this.props;

    return (
      <UI.Button.Warning size="sm" key="admin" href={link('Admin.Statistics', { lang })} className="mr-3">
        Панель администратора
      </UI.Button.Warning>
    );
  };

  renderPanelButtons() {
    const { access } = this.props;

    const list = [];

    if (access.check('Admin')) {
      list[list.length] = this.renderAdminButton();
    }

    list[list.length] = this.renderDashboardButton();

    return list;
  }

  render() {
    const { lang, isAuthorized } = this.props;

    return (
      <div className="layout_default-header_wrapper">
        <div className="layout_default-header layout_default-center">
          <div className="popup-bounder d-flex justify-content-between align-items-center w-100">
            <a href={link('Home.Root', { lang })} className="page-logo-link d-flex align-items-center logo-48">
              <div className="logo-image" />
              <div className="logo-text" />
            </a>
            <div className="layout_default-menu">
              <ToolsMenu size="xs" />
              <LanguagesMenu size="xs" />
              {/* isAuthorized ? this.renderPanelButtons() : this.renderAuthButton() */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
