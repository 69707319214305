import React from 'react';

import { BL } from '/views';

const AlertDifferentMirrors = props => {
  return (
    <BL.Alert.Danger className="mt-3">
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        У сайта разные главные зеркала в Яндекс и Google. Рекомендуем изучить причины и добиться того, чтобы главное зеркало было одно.
        <br />
        Для остальных отчетов сайта мы будем использовать главное зеркало, определенное по Google.
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

export default AlertDifferentMirrors;
