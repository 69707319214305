const addFilters = () => {
  const dropshadow = `
    <filter id="dropshadow" height="200%">
      <feGaussianBlur in="SourceAlpha" stdDeviation="10"></feGaussianBlur>
      <feOffset dx="0" dy="0" result="offsetblur"></feOffset>
      <feComponentTransfer>
        <feFuncA type="linear" slope="1"></feFuncA>
        </feComponentTransfer>
        <feMerge>
        <feMergeNode></feMergeNode>
        <feMergeNode in="SourceGraphic"></feMergeNode>
      </feMerge>
    </filter>
  `;

  return [dropshadow].join('');
};

const addDefs = function addDefs() {
  return `
    <defs xmlns="http://www.w3.org/2000/svg">
      ${addFilters()}
    </defs>
  `;
};

const addStyles = function addStyles() {
  return `
    <style>
      text { font: normal 14px 'Inter'; fill: #666666; }
    </style>
  `;
};

const addCommon = function addCommon(center, radiuses) {
  let svg = '';

  svg += `<circle cx="${center.x}" cy="${center.y}" r="${radiuses.bg}" fill="rgba(0, 0, 0, 0.65)" style="filter:url(#dropshadow)"></circle>`;
  svg += `<circle cx="${center.x}" cy="${center.y}" r="${radiuses.bg}" fill="#fff"></circle>`;
  // svg += `<circle cx="${center.x}" cy="${center.y}" r="${radiuses.text}" stroke="rgb(122, 122, 122)" stroke-width="1" stroke-dasharray="4" fill="transparent"></circle>`;

  return svg;
};

const addSectors = function addSectors(items) {
  let svg = '';

  for (let item, i = 0; item = items[i]; i++) {
    if (!item.meta.value) continue;

    svg += `<path class="item-${i}" fill="${item.meta.color}" d="${item.sector}" />`;
  }

  return svg;
};

const addLines = function addLines(items) {
  let svg = '';

  for (let item, i = 0; item = items[i]; i++) {
    if (!item.meta.value || !item.label.show) continue;

    svg += `<polyline class="item-${i}" points="${item.line}" style="fill: none; stroke: rgba(0, 0, 0, 0.4); stroke-width: 1.5;" />`;
  }

  return svg;
};

const addTexts = function addTexts(items) {
  let svg = '';

  for (let item, i = 0; item = items[i]; i++) {
    if (!item.meta.value || !item.label.show) continue;

    svg += `<text x="${item.label.x}" y="${item.label.y}" text-anchor="${item.label.anchor}">${item.label.text}</text>`;
  }

  return svg;
};

const addDebug = function addDebug(debug) {
  return debug.map(item => {
    return `<circle cx="${item.x}" cy="${item.y}" r="4" fill="${item.color}"></circle>`;
  }).join('\n');
};

export default function render({ items, center, radiuses, debug }) {
  return `
    <svg style="width: 100%; height: 100%;">
      ${addDefs()}
      ${addStyles()}
      ${addCommon(center, radiuses)}
      ${addLines(items)}
      ${addSectors(items)}
      ${addTexts(items)}
      ${addDebug(debug)}
    </svg>
  `;
};
