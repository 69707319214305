import React from 'react';

import Spinner from '../Spinner';

import './styles.scss';

const LayoutLocker = props => {
  return (
    <div className="root_layout_locker-overlay">
      <Spinner />
    </div>
  );
};

export default LayoutLocker;
