import { TYPES } from '../../constants';

export default [
  { type: 'item', label: 'Запросы к платным API', value: TYPES.OUTER_API_REQUEST },
  // { type: 'item', label: '', value: TYPES.OUTER_API_EXPENSES },
  // { type: 'item', label: '', value: TYPES.OTHER_EXPENSES },

  // { type: 'item', label: '', value: TYPES.MONEY_INCOME },

  {
    type: 'group', label: 'Аудиты', items: [
      { type: 'item', label: 'Запуски аудитов', value: TYPES.AUDIT_LAUNCH },
      { type: 'item', label: 'Ошибки обработки аудитов', value: TYPES.AUDIT_ERROR },
    ],
  },


  // { type: 'item', label: '', value: TYPES.TOOLS_LAUNCH },
  {
    type: 'group', label: 'Пользователи', items: [
      { type: 'item', label: 'Уникальные пользователи', value: TYPES.USER_UNIQ },
      { type: 'item', label: 'Новые пользователи', value: TYPES.USER_NEW },
      { type: 'item', label: 'Пользовательские запросы', value: TYPES.USER_REQUEST },
    ],
  },

  // { type: 'item', label: '', value: TYPES.SUPPORT_REQUEST },
  {
    type: 'group', label: 'Инструменты', items: [
      { type: 'item', label: 'Общая статистика', value: TYPES.TOOL_LAUNCH },
      { type: 'item', label: 'Лемматизатор', value: TYPES.TOOL_LEMMER },
      { type: 'item', label: 'Проверка ответа сервера', value: TYPES.TOOL_SERVER_RESPONSE },
      { type: 'item', label: 'Проверка карты сайта', value: TYPES.TOOL_SITEMAPPER },
      { type: 'item', label: 'Анализ title, description, H1-H6', value: TYPES.TOOL_TDH },
      { type: 'item', label: 'Проверка индексации страниц', value: TYPES.TOOL_INDEX_CHECKER },
      { type: 'item', label: 'Проверка robots.txt', value: TYPES.TOOL_ROBOTS_CHECKER },
    ],
  },
];
