const self = {};

self.head_title = 'SEO сервисы и аудит сайта по данным веб-аналитики';
self.head_keywords = '';
self.head_description = 'SERP.tools подключается к сервисам веб-аналитики (Метрика, Я.Вебмастер, GA, GSC) и проводит уникальный SEO аудит по этим данным с рекомендациями';

self.hero_start_soon = 'Запуск Бета-версии в конце 2022';
self.hero_title = 'Получите план роста поискового трафика и список проблем с SEO по данным веб-аналитики';

self.hero_item_1 = 'Уникальный анализа по данным веб-аналитики.';
self.hero_item_2 = 'Старт за 60 секунд.';
self.hero_item_3 = 'Найдите проблемы с поисковым трафиком и точки роста вашего сайта.';
self.hero_item_4 = 'Вы экономите 8-10 часов на ручном анализе.';

export default self;
