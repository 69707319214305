import React from 'react';

import { UI, BL } from '/views';

const InsertBox = props => {
  const { lang, lock, insert, result, onStartTextChange, onProcess } = props;

  const Button = insert.words ? UI.Button.Primary : UI.Button.Secondary;

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title={lang('field_title')} expand={!result}>
          <div className="pt-2 pb-3 pl-3 pr-3">
            <UI.Form.TextArea value={insert.text} onChange={onStartTextChange} style={{ height: '260px' }} />
            <div className="mt-2 select-none">
              {lang('count_symbolsTotal', { count: insert.text.length }, { useParser: true })}<br />
              {lang('count_symbolsCleared', { count: insert.letters }, { useParser: true })}<br />
              {lang('count_words', { count: insert.words }, { useParser: true })}
            </div>
            <Button className="mt-2" size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} text={lang('button_check')} onClick={onProcess} />
          </div>
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default InsertBox;
