import React from 'react';

import { UI } from '/views';

const popup = name => (idx, point) => {
  return (
    <UI.Popup.Dark width={120} shown={true} style={{ padding: '7px 5px' }}>
      <div className="mb-1 fs-7p">{point.label}</div>
      <div className="d-flex align-items-center justify-content-center fs-7">
        <div className="mr-2" style={{ width: 12, height: 12, backgroundColor: point.pointColor }} />
        <div>{name}: {point.value}</div>
      </div>
    </UI.Popup.Dark>
  );
};

export default {
  labels: [
    {
      label: 'Понедельник',
      // capacity: 31,
      border: {
        left: {
          width: 5,
          color: '#4de5d5',
        },
      },
    },
    {
      label: 'Вторник',
      // capacity: 28
    },
    {
      label: 'Среда',
      // capacity: 31
    },
    {
      label: 'Четверг',
      // capacity: 30
    },
    {
      label: 'Пятница',
      // capacity: 31,
    },
    {
      label: 'Суббота',
      // capacity: 30,
      items: [
        {
          label: '1st',
        },
        {
          label: '2nd',
        },
      ],
    },
    {
      label: 'Воскресенье',
      // capacity: 31
      items: [
        {
          label: '1st',
        },
        {
          label: '2nd',
        },
        {
          label: '3rd',
        },
      ],
    },
  ],
  sections: [
    {
      name: 'yandex',
      label: 'Яндекс',
      color: '#349ffa',
      fill: 'rgba(52, 159, 250, 0.3)',
      values: [
        {
          from: { capacity: 8, offset: 20 },
          to: { capacity: 8, offset: 50 },
          description: () => 'Текст генерируется абзацами случайным образом от двух до десяти предложений в абзаце, что позволяет сделать текст более привлекательным и живым для визуально-слухового восприятия.',
        },
      ],
    },
  ],
  lines: [
    {
      name: 'yandex',
      label: 'Яндекс',
      pointColor: '#37e2d0',
      pointColorHover: '#1c978a',
      color: '#37e2d0',
      fill: 'rgba(55, 226, 208, 0.3)',
      values: [15, 18, 37, 21, 27, 15, -18, 37, 21, 27],
      // popup: popup('Яндекс'),
    },
    {
      name: 'google',
      label: 'Google',
      pointColor: '#a38cc6',
      pointColorHover: '#54436f',
      color: '#a38cc6',
      fill: 'rgba(163, 140, 198, 0.3)',
      values: [-19, -5, -15, -18, 14, 39, 4, 15, 22, -11],
      // popup: popup('Google'),
    },
  ],
  bars: [
    {
      name: 'yandex',
      label: 'Яндекс',
      color: '#a7178b',
      fill: '#e124bc',
      fillHover: '#f55dd7',
      values: [15, 18, 37, 21, 27, 15, 18, 37, 21, 27],
      popup: (point, bar) => {},
    },
  ],
}
