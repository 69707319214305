import React from 'react';
import cn from 'classnames';

const Tooltip = props => {
  const { className, children, style, side = 'top' } = props;

  const sets = { className: cn('tooltip', side, className) };

  style && (sets.style = style);

  return <div {...sets}>{children}</div>;
};

export default Tooltip;
