import React, { Component } from 'react';

import Header from './components/Header';
import Footer from '../../common/Footer';

import '../../../static/styles/home-page/main.scss';

import VIEW from '/views/view';

import './styles.scss';

class HomePageLayout extends Component {
  constructor(props) {
    super(props);

    VIEW.SIDEBAR.disable();
  }

  render() {
    const { View, api, lang, token, access } = this.props;

    return (
      <div className="layout_home-wrapper page-wrapper">
        <div className="page-inner">
          <div className="page-content-wrapper bg-transparent m-0">
            <div className="layout_home-page">
              <Header isAuthorized={!!token} lang={lang} access={access} />
              <div className="layout_home-content">
                <View api={api} lang={lang} />
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePageLayout;
