import React, { Component } from 'react';

class Error404 extends Component {
  render() {
    return (
      <div className="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center" style={{ backgroundColor: '#fff' }}>
        <h1 className="page-error color-fusion-500">
          Ошибка 404
          <small className="fw-500">
            Что-то пошло не так!
          </small>
        </h1>
        <h3 className="fw-500 mb-5">
          Данная страница не найдена, но возможно скоро найдётся!
        </h3>
      </div>
    );
  }
}

export default Error404;
