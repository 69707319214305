import { handleActions } from 'redux-actions';

import {
  setUpdates,
  removeUpdate,
  updateStatus,
} from './actions';

import initial from './initial';

import array from '/utils/array';

import { SEARCER_TYPE } from '../constants';

const sorter = array.sorter('date_start, date_finish', 'asc, desc');

const types = {
  [SEARCER_TYPE.YANDEX]: 'yandex',
  [SEARCER_TYPE.GOOGLE]: 'google',
};

export default handleActions({
    [setUpdates]: _setUpdates,
    [removeUpdate]: _removeUpdate,
    [updateStatus]: _updateStatus,
}, initial());

function _setUpdates(state, { payload }) {
  const { items } = payload;

  let { yandex, google } = state.searchers;

  const searchers = {
    yandex: yandex.slice(),
    google: google.slice(),
  };

  for (let item, i = 0; item = items[i]; i++) {
    const type = types[item.searcher];

    let target = searchers[type].find(s => s.id === item.id);

    !target && searchers[type].push(target = {});

    Object.assign(target, item);
  }

  searchers.yandex.sort(sorter);
  searchers.google.sort(sorter);

  return { ...state, searchers };
}

function _removeUpdate(state, { payload }) {
  const { id } = payload;

  let { yandex, google } = state.searchers;

  const searchers = {
    yandex: yandex.filter(item => item.id !== id),
    google: google.slice(item => item.id !== id),
  };

  return { ...state, searchers };
}

function _updateStatus(state, { payload }) {
  const { status } = payload;

  return { ...state, status };
}
