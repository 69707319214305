import itemId from './_config/itemId';
import columns from './_config/columns';
import getter from './_config/getter';
import sorter from './_config/sorter';
import searcher from './_config/searcher';

const pagination = true;
const sort = { field: 'url', dir: 'asc' };

export default {
  itemId,
  columns,
  getter,
  sorter,
  pagination,
  sort,
  searcher,
};
