import React from 'react';

import './styles.scss';

const Hero = props => {
  const { lang } = props;

  return (
    <section className="home_root-hero">
      <div className="container">
        <div className="home_root-hero_content">
          <div className="home_root-hero_comming_soon">
            {lang('hero_start_soon')}
          </div>
          <div className="home_root-hero_image">
          </div>
          <div className="home_root-hero_info">
            <h1 className="home_root-hero_title mb-5">{lang('hero_title')}</h1>
            <ul className="home_root-hero_list mb-6">
              <li className="home_root-hero_list_item">{lang('hero_item_1')}</li>
              <li className="home_root-hero_list_item">{lang('hero_item_2')}</li>
              <li className="home_root-hero_list_item">{lang('hero_item_3')}</li>
              <li className="home_root-hero_list_item">{lang('hero_item_4')}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
