import React, { Component, Fragment } from 'react';

import Yandex from './components/Yandex';

import ModalRemove from './components/ModalRemove';
import ModalErrorRefresh from './components/ModalErrorRefresh';

import ModalProjectsUpdated from '/components/Projects/views/ModalProjectsUpdated';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

class SettingsAccesses extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      yandex: [],
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  async reload() {
    const { tokens } = await this.provider.getList();

    const yandex = tokens.yandex.map(item => ({ ...item, lock: false }));

    this.setState({ yandex });
  }

  addYandex = async () => {
    const code = await this.provider.getYandexAuthCode();

    VIEW.VIEWPORT.lock();

    const { accaunt, projects } = await this.provider.addYandexAccaunt(code);

    this.props.fetchProjects();

    const yandex = [...this.state.yandex];

    const idx = yandex.findIndex(v => v.id === accaunt.id);

    if (idx === -1) {
      yandex.push({ ...accaunt, removed: 0, lock: false });
    } else {
      yandex[idx] = { ...yandex[idx], ...accaunt, removed: 0, lock: false };
    }

    this.setState({ yandex });

    VIEW.MODAL.show(() => {
      const description = <p className="mb-3">Аккаунт <b>{accaunt.accaunt_name}</b> подключен к вашему профилю.</p>;

      return <ModalProjectsUpdated title="Добавлен аккаунт" description={description} projects={projects} />;
    });

    VIEW.VIEWPORT.unlock();
  };

  refreshYandex = async (id) => {
    let yandex = this.state.yandex.map(item => item.id === id ? { ...item, lock: true } : item);

    this.setState({ yandex });

    let { date_expire, error } = await this.provider.refreshYandexAccaunt(id);

    if (error) {
      VIEW.MODAL.show(() => <ModalErrorRefresh />);

      date_expire = yandex.find(item => item.id === id).date_expire;
    }

    yandex = this.state.yandex.map(item => item.id === id ? { ...item, date_expire, lock: false } : item);

    this.setState({ yandex });
  };

  onRemove = (id, type) => {
    const item = this.state[type].find(v => v.id === id);

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();

      const onSubmit = async () => {
        VIEW.MODAL.hide();

        VIEW.VIEWPORT.lock();

        const { projects, error } = await this.provider.removeYandexAccaunt(id);

        if (error) return;

        this.props.fetchProjects();

        const list = [...this.state[type]];
        const idx = list.findIndex(v => v.id === id);

        list[idx] = { ...list[idx], removed: 1 };

        this.setState({ [type]: list });

        VIEW.VIEWPORT.unlock();

        VIEW.MODAL.show(() => {
          const description = <p className="mb-3">Аккаунт <b>{item.accaunt_name}</b> удалён из вашего профиля.</p>;

          return <ModalProjectsUpdated title="Удалён аккаунт" description={description} projects={projects} states={[ModalProjectsUpdated.STATE_REMOVED]} />;
        });
      };

      return <ModalRemove name={item.accaunt_name} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  renderChild = idx => {
    let { yandex } = this.state;

    yandex = yandex.filter(item => !item.removed);

    if (idx === 0) {
      return <Yandex provider={this.provider} items={yandex} onAdd={this.addYandex} onRefresh={this.refreshYandex} onRemove={this.onRemove} />;
    }

    return null;
  };

  render() {
    return (
      <Fragment>
        <BL.Title icon="fal fa-key" name="Настройки доступов к API">
          Добавьте доступы к счётчикам Яндекса для получения списков сайтов доступных для отчётов.
        </BL.Title>

        <BL.Panel>
          {this.renderChild(0)}
          {/*<BL.Tabs className="pl-3 pr-3" items={['Yandex', 'Google']} child={this.renderChild} />*/}
        </BL.Panel>
      </Fragment>
    );
  }
}

export default SettingsAccesses;
