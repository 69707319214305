import axios from 'axios';

import { call, put, take, takeEvery, select } from 'redux-saga/effects';
import { initUser, setUser, startSession, signin, signup, signout, restore, approveUserMail, checkRestoreToken, restoreUserAccaunt } from './actions';

import { isUndefined } from '/utils/types';
import { getPicker } from '/utils/object';

import Cookies from '/utils/cookies';
import Provider from '../provider';

function* _initUser({ payload }) {
  const { token, remember, defer } = payload;

  if (!token) return defer.resolve();

  const provider = new Provider(token);

  try {
    yield put(startSession({ token, remember }));

    const { user } = yield provider.refresh();

    const data = getPicker('id', 'name', 'mail', 'role')(user);

    yield put(setUser(data));
  } catch (e) {
    if (__CLIENT__) {
      console.log(e)
    } else {
      console.log('Can\'t login user');
    }
  }

  defer.resolve();
}

function* _startSession({ payload }) {
  if (__CLIENT__) {
    const { token, remember } = payload;

    const days = remember ? 90 : 7;

    Cookies.set('token', token, days);
    Cookies.set('remember', remember, days);
  }
}

function* _signin({ payload }) {
  const { mail, password, remember, defer } = payload;

  const provider = new Provider();

  try {
    const { user, token } = yield provider.signin(mail, password);

    const data = getPicker('id', 'name', 'mail', 'role')(user);
    const session = { token, remember };

    yield put(setUser(data));
    yield put(startSession(session));

    defer.resolve();
  } catch (e) {
    defer.reject();
  }
}

function* _signup({ payload }) {
  const { name, mail, password, remember, defer } = payload;

  const { router } = yield select();

  const provider = new Provider();

  try {
    const { lang } = router.params.url;

    const { user, token } = yield provider.signup(lang, name, mail, password);

    const data = getPicker('id', 'name', 'mail', 'role')(user);
    const session = { token, remember };

    yield put(setUser(data));
    yield put(startSession(session));

    defer.resolve();
  } catch (e) {
    defer.reject();
  }
}

function* _restore({ payload }) {
  const { mail, defer } = payload;

  const { router } = yield select();

  const provider = new Provider();

  try {
    const { lang } = router.params.url;

    const { error } = yield provider.requestRestore(lang, mail);

    if (error) throw new Error(error);

    defer.resolve();
  } catch (e) {
    defer.reject();
  }
}

function* _approveUserMail({ payload }) {
  const { defer } = payload;

  const { router } = yield select();

  const { id, token } = router.params.url;

  const provider = new Provider();

  try {
    const { ok, error } = yield provider.approve(id, token);

    if (error) throw new Error(error);

    defer.resolve();
  } catch (e) {
    defer.reject();
  }
}

function* _signout() {
  Cookies.remove('token');
  Cookies.remove('remember');
}

function* _checkRestoreToken({ payload }) {
  const { defer } = payload;

  const { id, token } = yield select(state => state.router.params.url);

  const provider = new Provider();

  try {
    const { exists, expired, error } = yield provider.checkRestoreToken(id, token);

    if (error || !exists || expired) throw new Error(error);

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

function* _restoreUserAccaunt({ payload }) {
  const { password, defer } = payload;

  const { id, token: _token } = yield select(state => state.router.params.url);

  const provider = new Provider();

  try {
    const { user, token, error } = yield provider.restore(id, _token, password);

    if (error) throw new Error(error);

    const data = getPicker('id', 'name', 'mail', 'role')(user);
    const session = { token, remember: false };

    yield put(setUser(data));
    yield put(startSession(session));

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

export default function* userSage() {
  yield takeEvery(initUser, _initUser);

  yield takeEvery(startSession, _startSession);
  yield takeEvery(signin, _signin);
  yield takeEvery(signup, _signup);
  yield takeEvery(restore, _restore);
  yield takeEvery(signout, _signout);
  yield takeEvery(approveUserMail, _approveUserMail);
  yield takeEvery(checkRestoreToken, _checkRestoreToken);
  yield takeEvery(restoreUserAccaunt, _restoreUserAccaunt);
}
