import React, { Component, Fragment } from 'react';
import cn from 'classnames';

class Item extends Component {
  state = {
    isOpened: false
  };

  onToggle = () => {
    const { childs = [] } = this.props;

    if (!childs.length) return;

    this.setState(state => ({ isOpened: !state.isOpened }));
  };

  render() {
    const { name, link, icon, childs = [], isActive, page, ...sets } = this.props;
    const { isOpened } = this.state;

    link && (sets.href = link);

    const classes = [
      (isActive || (childs.length && isOpened)) && 'active',
      childs.length && (isActive || isOpened) && 'open'
    ];

    return (
      <li className={cn(...classes)}>
        <a {...sets} onClick={this.onToggle}>
          {icon && <Icon name={icon} />}
          <span className="nav-link-text">
            {name}
          </span>
          {!!childs.length && <Chevron dir={childs.length && (isActive || isOpened) ? 'up' : 'down'} />}
        </a>
        {!!childs.length && <List childs={childs} wrap={true} />}
      </li>
    );
  }
}

const Icon = props => <i className={props.name} />;

const Chevron = props => {
  const { dir } = props;

  const className = `fal fa-angle-${dir}`;

  return (
    <b className="collapse-sign">
      <em className={className} />
    </b>
  );
};

const List = props => {
  const { childs, wrap, ...sets } = props;

  const items = childs.map((item, idx) => <Item key={idx} {...item} />);

  if (wrap) {
    return <ul>{items}</ul>;
  } else {
    return <Fragment>{items}</Fragment>;
  }
};

const MainMenu = props => <List childs={props.items} />;

export default MainMenu;
