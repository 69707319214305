import columns from './_config/columns';
import getter from './_config/getter';
import searcher from './_config/searcher';
import sorter from './_config/sorter';

const pagination = true;

export default {
  columns,
  getter,
  searcher,
  sorter,
  pagination,
};
