import React, { Component, Fragment } from 'react';

import TrafficTable from '../../../../views/TrafficTable';

import { UI, BL } from '/views';

class Traffic extends Component {
  renderToolbar = () => {
    const { exportExcelLink } = this.props;

    return (
      <Fragment>
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent href={exportExcelLink} className="btn-custom-excel" download />
      </Fragment>
    );
  };

  renderTab = idx => {
    const { data, exportExcelLink } = this.props;

    const select = ['overall', 'yandex', 'google'][idx];

    if (!data) {
      return <div className="p-3 text-center"><UI.Loader /></div>;
    }

    const sort = { field: 'year_1', dir: 'desc' };

    return (
      <div className="p-3">
        <TrafficTable id={select} data={data} sort={sort} toolbar={this.renderToolbar} settings={{ select }} />
      </div>
    );
  };

  render() {
    const sets = {
      items: ['Весь поисковый трафик', 'Яндекс', 'Google'],
      child: this.renderTab
    };

    return (
      <BL.Panel>
        <BL.Panel.Content className="p-0">
          <UI.Collapse title="Постраничный трафик" expand={true}>
            <BL.Tabs { ...sets } />
          </UI.Collapse>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }
};

export default Traffic;
