import React from 'react';

import { UI, BL } from '/views';

const ClearedText = props => {
  const { lang, result } = props;

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title={lang('cleared_title')} expand={false}>
          <div className="pt-2 pb-3 pl-3 pr-3">
            <div className="custom-scrollbar" style={{ maxHeight: '260px' }}>
              {result.text}
            </div>
          </div>
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default ClearedText;
