import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { LangConsumer } from '/contexts/Lang';

import Button from '/views/ui/Button';
import Tooltip from '/views/ui/Tooltip';

class CopyButton extends Component {
  state = {
    isCopied: false,
  };

  timer = null;

  onClick = () => {
    const { value, onCopy } = this.props;

    this.setState({ isCopied: true });

    this.clearTimer();
    this.timer = setTimeout(this.hidePopup, 1500);

    this.copy(value);
    onCopy && onCopy(value);
  };

  copy = text => {
    const el = document.createElement('textarea');

    el.value = text;
    document.body.appendChild(el);
    el.select();

    document.execCommand('copy');

    document.body.removeChild(el);
  };

  clearTimer = () => clearTimeout(this.timer);
  hidePopup = () => this.setState({ isCopied: false });

  componentWillUnmount() {
    this.clearTimer();
  }

  render() {
    const { className, size = 'sm' } = this.props;
    const { isCopied } = this.state;

    return (
      <LangConsumer context="views.blocks.CopyButton">
        {lang => (
          <Button.Default className={className} icon="fal fa-copy" size={size} onClick={this.onClick}>
            <Tooltip className={cn('py-2 px-3', isCopied && 'tooltip-visible')}>{lang('copied')}</Tooltip>
            {lang('copy')}
          </Button.Default>
        )}
      </LangConsumer>
    );
  }
}

export default Button.stylize(CopyButton);
