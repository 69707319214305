import { Popup } from '/utils/common';

import config from '/utils/config';
import link from '/utils/link';

const BASE_URL = 'https://passport.yandex.ru/auth?&noreturn=1&origin=oauth';

const getParams = () => ({
  retpath: 'https://oauth.yandex.ru/authorize?response_type=code&client_id=' + config('YANDEX_CLIENT_ID'),
  noreturn: 1,
  origin: 'oauth',
});

const proxyRequest = function proxyRequest() {
  const url = link.join(BASE_URL, getParams());

  return new Promise((resolve, reject) => {
    const popup = new Popup(url, 'YandexToken', { width: 670, height: 800 });

    const callback = search => {
      const { code } = link.parseParams(search);

      popup.destroy();

      resolve(code);
    };

    popup.addCallback('YANDEX_TOKEN_CALLBACK', callback).open();
  });
};

export default async function getAuthCode() {
  const result = await proxyRequest();

  this.update(null, result.token);

  return result;
};;
