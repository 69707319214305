import React, { Component, Fragment } from 'react';

import Provider from '../../provider';

import { VIEW, UI, BL } from '/views';

import EditForm from './components/EditForm';
import ModalError from './components/ModalError';
import ModalRemove from './components/ModalRemove';
import UpdatesTable from './components/UpdatesTable';

import findItem from './utils/findItem';

import { deferred } from '/utils/common';

import { SEARCER_TYPE } from '/components/SearcherUpdates/constants';

class SearchersUpdatesReports extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      edit: null,
    };

    if (__CLIENT__) {
      props.reloadUpdates();
    }
  }

  onAdd = () => this.setState({ edit: 0 });

  onEdit = id => this.setState({ edit: id });

  onFormSubmit = values => {
    const { edit: id } = this.state;
    const { addUpdate, updateUpdate } = this.props;

    const defer = deferred();

    defer.then(() => this.setState({ edit: null }));

    defer.catch(() => {
      VIEW.MODAL.show(() => {
        const title = `Ошибка ${id ? 'обновления' : 'добавления'} апдейта`;
        const description = `Не удалось ${id ? 'обновить' : 'добавить'} апдейт.`;

        return <ModalError title={title} description={description} />;
      });
    });

    if (id) {
      updateUpdate(id, values, defer);
    } else {
      addUpdate(values, defer);
    }
  };

  onFormCancel = () => this.setState({ edit: null });

  onDelete = id => {
    const { deleteUpdate } = this.props;
    const { searchers } = this.props.searcherUpdates;

    const item = findItem(id, searchers);

    const defer = deferred();

    defer.catch(() => {
      VIEW.MODAL.show(() => <ModalError name={item.name_ru} title="Ошибка удаления апдейта" description="Не удалось удалить апдейт." />);
    });

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();

      const onSubmit = async () => {
        deleteUpdate(id, defer);

        VIEW.MODAL.hide();
      };

      return <ModalRemove name={item.name_ru} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  renderEdit = () => {
    const { edit: id } = this.state;

    const { searchers } = this.props.searcherUpdates;

    const item = id ? findItem(id, searchers) : {};

    return (
      <BL.Panel>
        <BL.Panel.Header>
          <h5 className="color-primary-500 mb-0">{id ? 'Редактирование апдейта' : 'Добавление апдейта'}</h5>
        </BL.Panel.Header>
        <BL.Panel.Content>
          <EditForm id={id} data={item} onSubmit={this.onFormSubmit} onCancel={this.onFormCancel} />
        </BL.Panel.Content>
      </BL.Panel>
    );
  };

  renderTab = idx => {
    const type = ['yandex', 'google'][idx];

    const data = this.props.searcherUpdates.searchers[type];

    const actions = {
      onEdit: this.onEdit,
      onDelete: this.onDelete,
    };

    const toolbar = () => (
      <UI.Button.Success text="Добавить апдейт" size="sm" icon="fal fa-plus" onClick={this.onAdd} />
    );

    return (
      <div className="mt-3">
        <UpdatesTable data={data} actions={actions} toolbar={toolbar} />
      </div>
    );
  };

  renderTabs = () => {
    const sets = {
      items: ['Яндекс', 'Google'],
      child: this.renderTab,
    };

    return (
      <BL.Panel>
        <BL.Panel.Header>
          <h5 className="color-primary-500 mb-0">Спаисок апдейтов</h5>
        </BL.Panel.Header>
        <BL.Panel.Content className="pt-0">
          <BL.Tabs { ...sets } />
        </BL.Panel.Content>
      </BL.Panel>
    );
  };

  render() {
    const { edit } = this.state;

    return (
      <Fragment>
        <BL.Title icon="fal fa-arrow-alt-square-up" name="Апдейты поисковых систем" />

        {edit !== null ? this.renderEdit() : this.renderTabs()}
      </Fragment>
    );
  }
}

export default SearchersUpdatesReports;
