import React, { createContext } from 'React';

import Language from '/services/Lang';

const Context = createContext(() => '');

export const LangProvider = Context.Provider;

export const LangConsumer = props => {
  let { context = '', children } = props;

  const child = lang => {
    const _lang = context ? (lang || new Language('en')).child(context) : lang;

    return children(_lang);
  };

  return <Context.Consumer>{child}</Context.Consumer>;
};

export const Lang = props => {
  const { text, context, params, useParser = false } = props;

  const path = context ? context + '.' + text : text;
  const sets = { useContext: !context, useParser };

  return <Context.Consumer>{lang => lang(path, params, sets)}</Context.Consumer>;
};

export default { Provider: LangProvider, Consumer: LangConsumer };
