import React, { Fragment } from 'react';

import { Item as _Item, Block as _Block } from './Block';

import { BL } from '/views';

import CONST from '../../constants';

class Item extends _Item {
  hasExpand() {
    const { type, stats } = this.props;

    return !!stats.count[type];
  }

  getHeadData() {
    const { lang, type, stats } = this.props;

    const { name, infoText, infoTextActive } = {
      [CONST.SITE_TYPE.INDEX_SITEMAP]: {
        name: lang('site_type_item_index'),
        infoText: () => lang('site_type_item_index_info', {}, { useParser: true }),
        infoTextActive: true,
      },
      [CONST.SITE_TYPE.SITEMAP]: {
        name: lang('site_type_item_normal'),
        infoText: () => lang('site_type_item_normal_info', {}, { useParser: true }),
        infoTextActive: false,
      },
      [CONST.SITE_TYPE.UNKNOWN]: {
        name: lang('site_type_item_unknown'),
        infoText: () => lang('site_type_item_unknown_info', {}, { useParser: true }),
        infoTextActive: true,
      },
    }[type];

    const value = stats.count[type];

    return { name, infoText, infoTextActive, value };
  }

  renderBody() {
    const { type, items } = this.props;

    const urls = items.filter(item => item.type === type).map(item => item.url);
    const copyData = urls.join('\n');
    const isShowCopyButton = type === CONST.SITE_TYPE.INDEX_SITEMAP || type === CONST.SITE_TYPE.SITEMAP;

    return (
      <Fragment>
        {isShowCopyButton && <BL.CopyButton className="ml-4 mb-2" value={copyData}/>}
        <ol className="tools_sitemapper-stats-item-body-list">
          {urls.map(url => (
            <li key={url} className="tools_sitemapper-stats-item-body-list-item">
              <a href={url} target="_blank" className="font-weight-normal">{url}</a>
            </li>
          ))}
        </ol>
      </Fragment>
    );
  }
}

class Block extends _Block {
  constructor(props) {
    super(props);

    const { lang } = props;

    this.title = lang('site_type_title');
    this.infoText = () => lang('site_type_title_info');
    this.infoTextActive = false;
  }

  renderItems() {
    const { lang, result: { items, stats } } = this.props;

    const list = [CONST.SITE_TYPE.INDEX_SITEMAP, CONST.SITE_TYPE.SITEMAP, CONST.SITE_TYPE.UNKNOWN];

    return list.map(type => <Item key={type} lang={lang} type={type} items={items} stats={stats} />);
  }
}

export default Block;
