import React from 'react';
import cn from 'classnames';

const Badge = props => {
  const { stl = 'primary', className, children, style } = props;

  const sets = { className: cn('badge', 'badge-' + stl, className) };

  style && (sets.style = style);

  return (
    <span { ...sets }>
      {children}
    </span>
  );
};

Badge.Primary = props => <Badge stl="primary" { ...props } />;
Badge.Secondary = props => <Badge stl="secondary" { ...props } />;
Badge.Success = props => <Badge stl="success" { ...props } />;
Badge.Info = props => <Badge stl="info" { ...props } />;
Badge.Warning = props => <Badge stl="warning" { ...props } />;
Badge.Danger = props => <Badge stl="danger" { ...props } />;
Badge.Dark = props => <Badge stl="dark" { ...props } />;
Badge.Light = props => <Badge stl="light" { ...props } />;

export default Badge;
