import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL, UN } from '/views';

import { deferred } from '/utils/common';

class ModalAddTicket extends Component {
  state = {
    error: null,
    lock: false,
  };

  Form = UN.Form();

  onCancel = () => {
    const { onCancel } = this.props;

    onCancel();
  };

  onSubmit = async () => {
    const isValid = await this.Form.validate();

    if (!isValid) return;

    const { onSubmit, createConversation } = this.props;

    const { title, message } = this.Form.data;

    const defer = deferred();

    defer.then(() => onSubmit());
    defer.catch(() => this.setState({ lock: false, error: 'Не удалось добавить тикет' }));

    createConversation(title, message, defer);
  };

  renderSubmitButton = () => {
    const { lock } = this.state;

    return (
      <Fragment>
        Добавить {lock && <UI.Loader text="" />}
      </Fragment>
    );
  };

  render() {
    const { Form, onCancel, onSubmit } = this;
    const { error } = this.state;

    const sets = {
      title: 'Добавить тикет',
      icon: 'fal fa-plus-circle',
      actions: { onCancel, onSubmit },
      buttons: { cancel: 'Отмена', submit: 'Добавить' },
      closers: [BL.Modal.CLOSE_ESC, BL.Modal.CLOSE_CROSS],
    };

    return (
      <BL.Modal.Confirm.Info { ...sets }>
        <Form className="d-flex flex-column justify-content-between" onSubmit={this.onSubmit}>
          <div>
            <div className="form-group input-group-sm mb-2">
              <Form.Text className="w-100" name="title" label="Заголовок" required />
            </div>
            <div className="form-group input-group-sm mb-2">
              <Form.TextArea className="w-100" name="message" label="Сообщение" style={{ height: 120 }} />
            </div>
          </div>
          <div>
            <div className={cn('h5 mb-2', !error && 'invisible')}>
              <small className="mb-0 color-danger-600">{error}.</small>
            </div>
          </div>
        </Form>
      </BL.Modal.Confirm.Info>
    );
  }
}

export default ModalAddTicket;
