import React, { Component } from 'react';
import cn from 'classnames';

const IconLine = props => {
  const { color, fill, className } = props;

  const svg = `
    <svg style="width: 15px; height: 16px;">
      <path d="M0,12 L5,7 L9,10 L15,4 L15,16 L0,16" fill="${fill}" stroke="none" />
      <path d="M0,12 L5,7 L9,10 L15,4" fill="none" stroke="${color}" stroke-width="2" />
    </svg>
  `;

  return (
    <div className={cn('ui_line_chart-legend_line_icon', className)} dangerouslySetInnerHTML={{ __html: svg }} />
  );
};

export default IconLine;
