import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { ref } from '/utils/react';
import { isEmpty } from '/utils/object';

const sorter = getter => (a, b) => {
  a = getter(a);
  b = getter(b);

  if (a < b) return  1;
  if (a > b) return -1;

  return 0;
};

class Child extends Component {
  ref = ref;

  state = {
    expanded: false,
  };

  onExpand = event => {
    if (event.target !== this.ui.control) return;

    this.setState(state => ({ ...state, expanded: !state.expanded }));
  };

  renderValues() {
    const { name, link, settings } = this.props;
    const { count, years } = settings;

    const { expanded } = this.state;

    if (!count) {
      return (
        <div className="tools_sitemapper-tree-leaf">
          <i className="fal fa-globe tools_sitemapper-tree-icon-site mr-2" /> 
          <a href={link} className="tools_sitemapper-tree-leaf-link" target="_blank">
            {name} {!!years.length && <sup className="tools_sitemapper-tree-sup">({years[0]} г.)</sup>}
          </a>
        </div>
      );
    }

    // years.sort(sorter(v => v));

    return (
      <div className="tools_sitemapper-tree-divarication" ref={this.ref('control')} onClick={this.onExpand}>
        <i className={cn('fal', expanded ? 'fa-minus-square' : 'fa-plus-square', ' tools_sitemapper-tree-icon-control')} />
        {name} <sup className="tools_sitemapper-tree-sup">[{count}]</sup>
      </div>
    );
  }

  render() {
    const { items } = this.props;
    const { expanded } = this.state;

    return (
      <li className="tools_sitemapper-tree-list-item">
        {this.renderValues()}
        {expanded && <List tree={items} />}
      </li>
    );
  }
}

class List extends Component {
  hideLeafsFrom = 100;

  state = {
    showLeafs: false,
  };

  onShow = () => this.setState({ showLeafs: true });

  renderToggler(count) {
    return (
      <li key="toggler" className="tools_sitemapper-tree-list-item">
        <div className="tools_sitemapper-tree-leaf-toggler" onClick={this.onShow}>
          <i className="fal fal fa-ellipsis-h tools_sitemapper-tree-icon-toggler" />Показать страницы <sup className="tools_sitemapper-tree-sup">[{count}]</sup>
        </div>
      </li>
    );
  }

  renderChilds() {
    const childs = Object.values(this.props.tree);

    const total = childs.length;

    childs.sort(sorter(item => item.settings.count));

    const divarication = childs.filter(item => !!item.settings.count);
    const leafs = childs.filter(item => !item.settings.count);

    const showLeafs = this.state.showLeafs || childs.length <= this.hideLeafsFrom;

    if (showLeafs || !leafs.length) {
      return childs.map(item => <Child key={item.name} { ...item } />);
    }

    const elements = divarication.map(item => <Child key={item.name} { ...item } />);

    const toggler = this.renderToggler(leafs.length);

    return [...divarication.map(item => <Child key={item.name} { ...item } />), toggler];
  }

  render() {
    return (
      <ul className="tools_sitemapper-tree-list">
        {this.renderChilds()}
      </ul>
    );
  }
}

const Structure = props => {
  const { lang, tree } = props;

  return (
    <div className="tools_sitemapper-categories">
      {!isEmpty(tree) ? <List tree={tree} />: lang('no_data')}
    </div>
  );
};

export default Structure;
