import React from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

const STATE_ADDED = 'added';
const STATE_REMOVED = 'removed';
const STATE_INCORRECT = 'incorrect';

const STATUS_TEXT = {
  CS_NOT_FOUND: 'Не установлен',
  CS_ERR_UNKNOWN: 'Неизвестная ошибка',
  CS_ERR_TIMEOUT: 'Не удалось проверить (превышено время ожидания)',
  CS_ERR_INFECTED: 'Не удалось проверить (сайт, на котором установлен счётчик или одно из его зеркал находится в списке заражённых сайтов)',
  CS_ERR_CONNECT: 'Не удалось проверить (ошибка соединения)',
  CS_ERR_OTHER_HTML_CODE: 'Установлен другой счётчик',
};

const renderListItem = (state, item, i) => {
  const { host } = item;

  const klass = {
    [STATE_ADDED]: 'fa-plus-circle color-info-500',
    [STATE_REMOVED]: 'fa-minus-circle color-danger-500',
    [STATE_INCORRECT]: 'fa-times-circle color-fusion-300',
  }[state];

  return (
    <div key={i} className="d-flex align-items-center my-1">
      <i className={cn('fal fs-11 mr-2', klass)} />
      <div className="d-block text-truncate w-100">{host}</div>
    </div>
  );
};

const renderList = (list, state) => () => {
  return (
    <div className="text-col-2">
      {list.map((item, i) => renderListItem(state, item, i))}
    </div>
  );
};

const renderIncorrectList = (list, state) => () => {
  const statuses = {};

  for (let item, i = 0; item = list[i]; i++) {
    statuses[item.status] = statuses[item.status] || [];

    statuses[item.status].push(item);
  }

  const renderStatus = (status, items) => {
    return (
      <div key={status}>
        <div className="my-2 font-weight-bold">
          {STATUS_TEXT[status]}
        </div>
        <div className="text-col-2">
          {items.map((item, i) => renderListItem(STATE_INCORRECT, item, i))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {Object.entries(statuses).map(item => renderStatus(...item))}
    </div>
  );
};

const renderStats = (states, { added, removed, incorrect }) => {
  const items = [
    states.includes(STATE_ADDED) && { name: 'Проектов добавлено', value: added.length, body: !!added.length && renderList(added, STATE_ADDED) },
    states.includes(STATE_REMOVED) && { name: 'Проектов удалено', value: removed.length, body: !!removed.length && renderList(removed, STATE_REMOVED) },
    states.includes(STATE_INCORRECT) && { name: 'Некорректно подключенные проекты ', value: incorrect.length, body: !!incorrect.length && renderIncorrectList(incorrect, STATE_INCORRECT) },
  ];

  return <UI.DetailList items={items} />;
};

const ModalProjectsUpdated = props => {
  const { title, description, projects, states = [STATE_ADDED, STATE_REMOVED, STATE_INCORRECT] } = props;

  const sets = { title, icon: 'fal fa-check-circle' };

  const added = [...projects.add, ...projects.restore];
  const removed = [...projects.remove];
  const incorrect= [...projects.incorrect];

  return (
    <BL.Modal.Alert.Info size="lg" { ...sets }>
      {description}
      {renderStats(states, { added, removed, incorrect })}
    </BL.Modal.Alert.Info>
  );
};

ModalProjectsUpdated.STATE_ADDED = STATE_ADDED;
ModalProjectsUpdated.STATE_REMOVED = STATE_REMOVED;
ModalProjectsUpdated.STATE_INCORRECT = STATE_INCORRECT;

export default ModalProjectsUpdated;
