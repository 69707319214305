import React, { Fragment } from 'react';

import { UI, BL } from '/views';

import { link } from '/services/Components/methods';

import { STATUSES } from '../../../../constants';

const STATUS_TEXT = {
  [STATUSES.NO_ACCESS]: item => `Обновите доступы к аккаунту (${item.accauntName})`,
  [STATUSES.LOST_COUNTER]: item => `Потерян доступ к счётчику`,
  [STATUSES.ACCAUNT_REMOVED]: item => `Аккаунт (${item.accauntName}) с этим проектом был удалён, если этот проект прикреплён к другому вашему аккаунту, то нажмите кнопку "Обновить список проектов"`,
  [STATUSES.NOT_FOUND]: item => `Не установлен`,
  [STATUSES.ERR_UNKNOWN]: item => `Неизвестная ошибка`,
  [STATUSES.ERR_TIMEOUT]: item => `Не удалось проверить`,
  [STATUSES.ERR_INFECTED]: item => `Сайт, на котором установлен счётчик или одно из его зеркал находится в списке заражённых сайтов`,
  [STATUSES.ERR_CONNECT]: item => `Не удалось проверить<br />(ошибка соединения)`,
  [STATUSES.ERR_OTHER_HTML_CODE]: item => `Установлен другой счётчик`,
};

const ProjectActions = props => {
  const { item, actions, settings } = props;

  const { lang, running } = settings;

  const sets = {
    className: 'py-1 px-2',
    size: 'xs'
  };

  if (item.hasRunningAudit) {
    sets.className += ' disabled';

    sets.text = 'Аудит запущен';
    sets.icon = 'fal fa-ellipsis-h';

    return <UI.Button.Transparent { ...sets } />;
  }

  if (item.blocked) {
    sets.className = 'p-0 projects_root-error-button';

    const bait = <Fragment><i className="fal fa-exclamation-triangle mr-1" /> Израсходована квота</Fragment>;

    return (
      <UI.Button.Light { ...sets }>
        <BL.Info.Dark className="py-1 px-2 d-block" bait={bait} side={BL.Info.SIDE_TOP} width={320} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
          Для данного сайта израсходованы все запросы к апи Яндекса, попробуйте запустить аудит завтра
        </BL.Info.Dark>
      </UI.Button.Light>
    );
  }

  if ([STATUSES.NO_ACCESS, STATUSES.ACCAUNT_REMOVED].includes(item.status)) {
    sets.className = 'p-0';
    sets.href = link('Settings.Accesses', { lang });

    const bait = <Fragment><i className="fal fa-exclamation-triangle mr-1" /> Обновите доступы</Fragment>;

    return (
      <UI.Button.Danger { ...sets }>
        <BL.Info.Dark className="py-1 px-2 d-block" bait={bait} side={BL.Info.SIDE_TOP} width={320} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
          <span dangerouslySetInnerHTML={{ __html: STATUS_TEXT[item.status](item) }} />
        </BL.Info.Dark>
      </UI.Button.Danger>
    );
  }

  if (item.status !== STATUSES.OK) {
    sets.className = 'p-0 projects_root-error-button';

    const bait = (
      <Fragment>
        <i className="fal fa-exclamation-triangle mr-1" /> Ошибка счётчика
      </Fragment>
    );

    return (
      <UI.Button.Light { ...sets }>
        {!!STATUS_TEXT[item.status] && <BL.Info.Dark className="py-1 px-2 d-block" bait={bait} side={BL.Info.SIDE_TOP} width={240} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
          <span dangerouslySetInnerHTML={{ __html: STATUS_TEXT[item.status](item) }} />
        </BL.Info.Dark>}
      </UI.Button.Light>
    );
  }

  if (!item.stats) {
    sets.className = 'p-0 projects_root-error-button';

    const bait = <Fragment><i className="fal fa-exclamation-triangle mr-1" /> Недостаточно данных</Fragment>;

    return (
      <UI.Button.Light { ...sets }>
        <BL.Info.Dark className="py-1 px-2 d-block" bait={bait} side={BL.Info.SIDE_TOP} width={320} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
          Нет данных о поисковом трафике сайта. Возможно, сайт еще молодой и не получает поискового трафика
        </BL.Info.Dark>
      </UI.Button.Light>
    );
  }

  sets.text = 'Запустить аудит';
  sets.spin = item.id === running;
  sets.icon = 'fal fa-plus';
  sets.onClick = () => actions.startAudit(item.id);

  return (
    <Fragment>
        <UI.Button.Info { ...sets } />
        <UI.Button.Success className="ml-1 py-1 px-0" icon="fal fa-cogs" style={{ width: 26 }} size="xs" href={link('Projects.Settings', { id: item.id, lang, step: 0, callback: 'back-to-projects' })} />
    </Fragment>
  );
};

export default ProjectActions;
