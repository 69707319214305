import React from 'react';

import { BL } from '/views';

const AddAuditNotes = props => {
  return (
    <BL.Alert>
      <BL.Alert.Icon icon="fal fa-info-circle" />
      <BL.Alert.Content>
        <p>Все проекты, по которым есть хотя бы один готовый аудит, перемещаются на вкладку "С аудитами"</p>
        <p>В списке проектов мы не показываем сайты, у которых счетчики установлены некорректно (мы не смогли получить ответ от счетчика или там нет трафика за последний месяц), а также счетчики РСЯ и Яндекс.Дзен  (из-за некорректных или неполных данных в таких счетчиках мы не можем гарантировать достоверность аудита).</p>
        <p className="mb-0">Если вы не видите своего сайта в списке проектов, пожалуйста, проверьте счетчик по инструкции от <a href="https://yandex.ru/support/metrica/general/check-counter.html" target="_blank">Яндекс.Метрики</a>.</p>
      </BL.Alert.Content>
    </BL.Alert>
  );
};

export default AddAuditNotes;
