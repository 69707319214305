import { isEmpty } from '../object';

const self = {};

const reg_check = /^https?:\/\/((?:[a-zа-я0-9\-_]+\.)+[a-zа-я0-9\-]+)(?::\d+)?(?:\/.*)?$/i;
const reg_check_light = /^(?:https?:)?\/\/((?:[a-zа-я0-9\-_]+\.)+[a-zа-я0-9\-]+)(?::\d+)?(?:\/.*)?$/i;
const reg_root = /^(https?:\/\/(?:[a-zа-я0-9\-_]+\.)+[a-zа-я0-9\-]+(?::\d+)?)(?:\/.*)?$/i;

export const check = self.check = (link, noProto) => (noProto ? reg_check_light : reg_check).test(link);
export const getRoot = self.getRoot = link => (reg_root.exec(link) || [,null])[1];

export const joinParams = self.joinParams = params => {
  if (isEmpty(params)) return '';

  const pairs = [];

  for (let name in params) {
    if (!params.hasOwnProperty(name)) continue;

    const item = [encodeURIComponent(name), encodeURIComponent(params[name])];

    pairs.push(item);
  }

  return pairs.map(item => item.join('=')).join('&');
};

export const join = self.join = function join(url, params) {
  params = self.joinParams(params);

  if (!params) return url;

  const hasParams = url.indexOf('?') !== -1;
  const isQmLast = url[url.length - 1] === '?';

  if (!hasParams) return url + '?' + params;
  if (isQmLast) return url + params;

  return url + '&' + params;
};

export const parseParams = self.parseParams = function parseParams(search) {
  search = search.replace(/^\?/, '');

  const result = {};
  const pairs = search.split('&');

  for (let i = 0, len = pairs.length; i < len; i++) {
    const pair = pairs[i];

    if (!pair) continue;

    const [name, ...value] = pair.split('=');

    result[name] = value.join('=');
  }

  return result;
};

export const getDomain = self.getDomain = link => link.replace(/^https?:|\/\/|\/.*$/ig, '');

export const split = self.split = url => {
  const reg_protocol = /^(?:(https?):)?\/\//i;
  const reg_domain = /^((?:[a-zа-я0-9\-_]+\.)+[a-zа-я]{2,})\.?/i;
  const reg_port = /^:(\d+)/i;
  const reg_path = /^(\/[^?#]+)/i;
  const reg_query = /^\?([^#]+)/i;
  const reg_hash = /^#(.+)/i;

  let protocol, domain, port, path, query, hash;

  protocol = (reg_protocol.exec(url) || [])[1] || null;
  url = url.replace(reg_protocol, '');

  domain = (reg_domain.exec(url) || [])[1] || null;
  url = url.replace(reg_domain, '');

  port = (reg_port.exec(url) || [])[1] || null;
  url = url.replace(reg_port, '');

  path = (reg_path.exec(url) || [])[1] || null;
  url = url.replace(reg_path, '');

  query = (reg_query.exec(url) || [])[1] || null;
  url = url.replace(reg_query, '');

  hash = (reg_hash.exec(url) || [])[1] || null;
  url = url.replace(reg_hash, '');

  return { protocol, domain, port, path, query, hash };
};

export default self;
