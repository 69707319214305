import { trimLeft } from '/utils/array';

export const pad = (t, apply = 1) => apply && t < 10 ? '0' + t : t;

export const sec2dur = (time, ms = false) => {
  const sign = time < 0 ? '-' : '';

  time = Math.abs(time);

  const list = [
    Math.floor(time / 3600),
    Math.floor(time / 60) % 60,
    Math[ms ? 'floor' : 'round'](time % 60)
  ].map(pad);

  let result = sign + trimLeft(list).join(':');

  if (ms) {
    result += '.' + time.toFixed(3).split('.')[1] || '000';
  }

  return result;
};
