import columnFinder from '../../utils/columnFinder';
import columnPath from '../../utils/columnPath';
import matchLabel from '../../utils/matchLabel';

import { MARKER } from '../../constants';

const match = (range, columns, items, exclude, settings) => {
  const lines = [];

  const getColumn = i => columnFinder(i, columns);

  const match_x = (i, value, displace = 0.5) => {
    const { area } = getColumn(i);

    const width = (area.x[1] - area.x[0]) / area.capacity.size;

    return area.x[0] + Math.round(width * (i - area.capacity.from + displace));
  };

  const match_y = (i, value) => {
    const column = getColumn(i);

    const percent = (range.max - value) / (range.max - range.min);
    const height = column.area.y[1] - column.area.y[0];

    return column.area.y[0] + Math.round(height * percent);
  };

  for (let item, i = 0; item = items[i]; i++) {
    const { name, color, fill, values, ...other } = item;

    if (exclude.includes(name)) continue;

    const points = [];

    const first = 0;
    const last = values.length - 1;

    points[points.length] = {
      bezier: null,
      point: [getColumn(first).boundary.left, getColumn(first).center.y],
    };

    for (let j = 0, len = values.length; j < len; j++) {
      if (j === first) {
        points[points.length] = {
          bezier: null,
          point: [getColumn(first).boundary.left, match_y(j, values[j])],
        };
      }

      points[points.length] = {
        name: item.label,
        label: settings.popup.label(matchLabel(j, columns), columnPath(j, columns)),
        marker: settings.marker.type === MARKER.SINGLE,
        value: values[j],
        bezier: [
          j
          ? [match_x(j - 1, values[j - 1], 1), match_y(j - 1, values[j - 1])]
          : [match_x(0, values[0], 0), match_y(0, values[0])]
          ,
          [match_x(j, values[j], 0), match_y(j, values[j])],
        ],
        point: [match_x(j, values[j]), match_y(j, values[j])],
      };

      if (j === last) {
        points[points.length] = {
          bezier: null,
          point: [getColumn(last).boundary.right, match_y(j, values[j])],
        };
      }
    }

    points[points.length] = {
      bezier: null,
      point: [getColumn(last).boundary.right, getColumn(last).center.y],
    };

    lines[lines.length] = { color, fill, points, ...other };
  }

  return lines;
};

export default function matchLines(range, columns, data, exclude, settings) {
  return match(range, columns, data, exclude, settings);
};
