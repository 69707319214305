import { DEFAULT, SUPER_ADMIN, SUPPORT, USER, SUPER_USER } from '/static/data/roles';

const self = {};

self[DEFAULT] = {
  available: false,
  Lemmer: {
    available: true,
  },
  ServerResponse: {
    available: true,
  },
  Sitemapper: {
    available: true,
  },
  TitleDescriptionH1: {
    available: true,
  },
  UrlIndexing: {
    available: false,
  },
  RobotsChecker: {
    available: true,
  },
};

self[SUPER_ADMIN] = {
  available: true,
  Lemmer: {
    available: true,
  },
  ServerResponse: {
    available: true,
  },
  Sitemapper: {
    available: true,
  },
  TitleDescriptionH1: {
    available: true,
  },
  UrlIndexing: {
    available: true,
  },
  RobotsChecker: {
    available: true,
  },
};

self[SUPPORT] = self[USER] = self[SUPER_USER] = {
  available: true,
  Lemmer: {
    available: true,
  },
  ServerResponse: {
    available: true,
  },
  Sitemapper: {
    available: true,
  },
  TitleDescriptionH1: {
    available: true,
  },
  RobotsChecker: {
    available: true,
  },
};

export default self;
