import React, { Component, Fragment } from 'react';
import moment from 'moment';

import InsertBox from './components/InsertBox';
import Result from './components/Result';

import { UI, BL } from '/views';

import Provider from '../../provider';

import services from '/static/data/services';

class ReportsZeroTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
      },
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  async reload() {
    const { id, audit_id } = this.props;

    let report = this.provider.getZeroTrafficResult(audit_id, id);
    let settings = this.provider.getReportSettings(audit_id, id);

    [report, settings] = await Promise.all([report, settings]);

    const insert = {
      text: (settings.links || []).map(item => item.url).join('\n'),
    };

    this.setState({ insert, report });
  }

  onInsertChange = (name, value, values) => this.setState({ insert: values });

  onProcess = async links => {
    const { id, audit_id } = this.props;

    await this.provider.updateReportSettings(audit_id, id, { links: links.uniq });

    // TODO: DO SOMETHING AWESOME!
  };

  renderContent() {
    const { lock, insert, report } = this.state;
    const { id, audit_id, user_id } = this.props;

    const sets = { lock, insert, main: report.main };

    sets.onChange = this.onInsertChange;
    sets.onSubmit = this.onProcess;

    const exportExcelLink = this.provider.getZeroTrafficExcelLink(audit_id, id, user_id);

    return (
      <Fragment>
        {!report.error && <InsertBox { ...sets } />}
        <Result error={report.error} items={report.items} exportExcelLink={exportExcelLink} />
      </Fragment>
    );
  }

  renderLoader() {
    return (
      <BL.Panel>
        <BL.Panel.Content className="py-3 text-center">
          <UI.Loader />
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { report } = this.state;

    const { dates } = report || {};

    return (
      <Fragment>
        <BL.Title name="Страницы без поискового трафика">
          <p>В этом отчете находится список страниц сайта, которые не получали поисковый трафик за последний год{dates ? ': ' + moment(dates.year.from).format('MMM YYYY') + ' - ' + moment(dates.year.to).format('MMM YYYY') : ''}. А также список таких страниц отдельно для Яндекс и Гугл.</p>
          <p>Как источник списка страниц сайта на текущий момент мы используем XML-карту сайта, если она была указана при запуске аудита. Также вы можете загрузить свой список страниц в отчет и проверить по нему наличие трафика.</p>
        </BL.Title>

        {report ? this.renderContent() : this.renderLoader()}
      </Fragment>
    );
  }
}

export default ReportsZeroTraffic;
