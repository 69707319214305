import React from 'react';

import { BL } from '/views';

const ModalRemove = props => {
  const { name, onCancel, onSubmit } = props;

  const sets = {
    title: 'Удалить аккаунт',
    icon: 'fal fa-exclamation-circle',
    actions: { onCancel, onSubmit },
    buttons: { cancel: 'Отмена', submit: 'Удалить' },
  };

  return (
    <BL.Modal.Confirm.Danger { ...sets }>
      <p>Вы действительно хотите удалить аккаунт <b>{name}</b>?</p>
      <p className="mt-3">В ходе удаления будет удалён только доступ к аккаунту. Вы можете восстановить его позже вновь авторизовавшись в нём.</p>
      <p className="mt-3">Проекты к которым он был подключен, а также уже готовые аудиты удалены не будут.</p>
      <p className="mt-3"><b>Внимание!</b> При удалении доступа к аккаунту Яндекса, все аудиты сайтов, а также сервисы, не смогут получать данные из этого аккаунта.</p>
      <p className="mt-3">Для полного удаления доступа, зайдите в аккаунт <b>{name}</b>, перейдите на <a href="https://oauth.yandex.ru/access" target="_blank">страницу приложений связанных с аккаунтом</a> и удалите там доступ для SERP tools.</p>
    </BL.Modal.Confirm.Danger>
  );
};

export default ModalRemove;
