const self = {};

self.getList = function getList() {
  const url = '/api/v1/project-types/list';
  const method = 'get';

  return this.axios({ url, method });
};

export default self;
