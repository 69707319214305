import { getInitial } from '/services/Store';

import { STATUS_INITIAL } from './constants';

const defaults = {
  items: {},
  types: [],
  status: STATUS_INITIAL,
};

export default () => getInitial('projects', defaults || {});
