import React, { PureComponent, Fragment } from 'react';

import { UI, VIEW } from '/views';

import { LangConsumer } from '/contexts/Lang';

import ModalTransfer from '../../ModalTransfer';

class Toolbar extends PureComponent {
  transfer = () => {
    const { lemms, onSelect } = this.props;

    onSelect(...lemms);
  };

  onTransfer = () => {
    const { lang, lemms } = this.props;

    if (!lemms.length) return;

    VIEW.MODAL.show(() => {
      const onCancel = VIEW.MODAL.hide;

      const onSubmit = () => {
        this.transfer();
        VIEW.MODAL.hide();
      };

      return <ModalTransfer lang={lang} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  render() {
    const { lang, lemms } = this.props;

    const Button = lemms.length ? UI.Button : UI.Button.Secondary;

    return (
      <Fragment>
        <Button text={lang('mylist_button_add_all')} icon2="fal fa-angle-double-right" onClick={this.onTransfer} />
      </Fragment>
    );
  }
}

const toolbar = (data, actions, settings) => {
  const lemms = data.filtered.map(item => item.lemma);

  const { onSelect } = actions;

  return (
    <LangConsumer>
      {lang => <Toolbar lang={lang} lemms={lemms} onSelect={onSelect} />}
    </LangConsumer>
  );
};

export default toolbar;
