import React, { Fragment } from 'react';

import { BL } from '/views';

const renderStatuses = list => {
  return list.map((item, i) => {
    const { host, code } = item;

    return (
      <Fragment key={host}>
        {!!i && <br />}
        С неглавного зеркала <b>{host}</b> настроен <b>{code}</b>, а не <b>301</b> редирект на главное зеркало.
      </Fragment>
    );
  });
};

const AlertWrongRedirectStatus = props => {
  const { statuses } = props;

  return (
    <BL.Alert.Danger className="mt-3">
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        {renderStatuses(statuses)}
        <br />
        Рекомендуем настроить именно 301 редирект.
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

export default AlertWrongRedirectStatus;
