import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cn from 'classnames';

import ModalRemove from '../ModalRemove';

import { VIEW } from '/views';

import { deferred } from '/utils/common';

import {
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  PURPOSE_MESSAGE_PLACEHOLDER_REMOVE,
} from '../../../../constants';

const isPlaceholder = purpose => [
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  PURPOSE_MESSAGE_PLACEHOLDER_REMOVE,
].includes(purpose);

class Message extends Component {
  onEdit = () => {
    const { id, onEdit } = this.props;

    onEdit(id);
  };

  onRemove = () => {
    const { id, value, conversation_id, sendMessage } = this.props;

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();

      const onSubmit = () => {
        sendMessage(conversation_id, id, PURPOSE_MESSAGE_PLACEHOLDER_REMOVE);

        VIEW.MODAL.hide();
      };

      const message = (
        <div className="chat-segment chat-segment-sent" style={{ textAlign: 'left', margin: '16px 0 0' }}>
          <div className="conversation_messanger-history_message chat-message">
            <p>{value}</p>
          </div>
        </div>
      );

      return <ModalRemove message={message} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  renderMessage() {
    const { id, side, value, editing, purpose, updated, removed } = this.props;

    const plh = isPlaceholder(purpose);
    const edit = editing && editing.id === id;

    const style = {};

    plh && (style.opacity = 0.5);

    if (removed) {
      return (
        <span className="conversation_messanger-history_message_deleted">deleted</span>
      );
    }

    const renderActions = () => (
      <ul className="conversation_messanger-history_message_controls">
        <li className="conversation_messanger-history_message_controls_item">
          <span className="conversation_messanger-history_message_controls_button" onClick={this.onEdit}>
            <i className="fal fa-pen-nib" />
          </span>
        </li>
        <li className="conversation_messanger-history_message_controls_item">
          <span className="conversation_messanger-history_message_controls_button" onClick={this.onRemove}>
            <i className="fal fa-trash-alt" />
          </span>
        </li>
      </ul>
    );

    return (
      <div className={cn('conversation_messanger-history_message chat-message', edit && 'conversation_messanger-history_message_editing')} style={style}>
        {updated && <span className="conversation_messanger-history_message_edited">edited</span>}
        {side && !plh && !edit && renderActions()}
        <p>
          {value}
        </p>
      </div>
    );
  }

  renderTime() {
    const { showTime, timestamp, side } = this.props;

    if (!showTime) return null;

    return (
      <div className={cn(side ? 'text-right' : 'text-left', 'fw-300 text-muted mt-1 fs-xs')}>
        {moment(timestamp * 1000).format('HH:mm')}
      </div>
    );
  }

  render() {
    const { isFirst, isMiddle, isLast, side } = this.props;

    return (
      <div className={cn('chat-segment', 'chat-segment-' + (side ? 'sent' : 'get'), isFirst && 'chat-start', isMiddle && 'chat-middle', isLast && 'chat-end')}>
        {this.renderMessage()}
        {this.renderTime()}
      </div>
    );
  }
}

export default Message;
