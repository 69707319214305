import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

const route = createRoute({ path: '/audit/:auditId/reports/tech/mirror/:reportId', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { audit, router, user } = state;
  const { lang, auditId, reportId } = router.params.url;

  const { reports } = audit.items[auditId] || {};

  return { lang, id: +reportId, audit_id: +auditId, user_id: user.id, reports };
};

export default { route, view: connect(mapStateToProps)(view), settings };
