import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import Provider from '../../provider';

import TableQueries from './components/TableQueries';
import TableWords from './components/TableWords';
import TableLemms from './components/TableLemms';

import services from '/static/data/services';

import './style.scss';

class ReportsLostTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  async reload() {
    const { id, audit_id } = this.props;

    const report = await this.provider.getYandexQueriesResult(audit_id, id);

    this.setState({ report });
  }

  renderToolbar = () => {
    const { id, audit_id, user_id } = this.props;

    return (
      <Fragment>
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent href={this.provider.getYandexQueriesExcelLink(audit_id, id, user_id)} className="btn-custom-excel" download />
      </Fragment>
    );
  };

  renderQueriesTable = () => {
    const { report } = this.state;
    const { list, average } = report.queries;

    return (
      <BL.Panel.Content className="select-default">
        <TableQueries data={list} toolbar={this.renderToolbar} settings={{ average }} />
      </BL.Panel.Content>
    );
  };

  renderWordsTable = () => {
    const { words } = this.state.report;

    return (
      <BL.Panel.Content className="select-default">
        <TableWords data={words} toolbar={this.renderToolbar} />
      </BL.Panel.Content>
    );
  };

  renderLemmsTable = () => {
    const { lemms } = this.state.report;

    return (
      <BL.Panel.Content className="select-default">
        <TableLemms data={lemms} toolbar={this.renderToolbar} />
      </BL.Panel.Content>
    );
  };

  renderTab = idx => {
    if (!this.state.report) {
      return <div className="p-3 text-center"><UI.Loader /></div>;
    }

    const tab = [this.renderQueriesTable, this.renderWordsTable, this.renderLemmsTable][idx];

    return tab();
  };

  render() {
    const sets = {
      items: ['Запросы', 'Группы запросов', 'Группы запросов (лемм)'],
      child: this.renderTab
    };

    return (
      <Fragment>
        <BL.Title icon={services.yandex_metrika_query.icon} name="Запросы, потерявшие поисковый трафик в Яндекс" />

        <BL.Panel>
          <BL.Tabs className="pl-3 pr-3" { ...sets } />
        </BL.Panel>
      </Fragment>
    );
  }
}


export default ReportsLostTraffic;
