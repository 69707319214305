import React, { Fragment, useState } from 'react';
import cn from 'classnames';

import NoContent from '../NoContent';
import LemmsBox from '../LemmsBox';
import ContentValue from '../ContentValue';

import { UI, BL } from '/views';

const Item = props => {
  const { lang, item, index } = props;
  const { content } = item || {};

  const descValue = content ? content.description.value : undefined;

  return (
    <tr>
      <td>{index + 1}</td>
      <td className="url">
        <BL.StatusBadge className="ml-0" code={item.statusCode} error={item.error} /> <a href={item.url} target="_blank">{item.croppedUrl}</a>
      </td>
      <td>
        <ContentValue lang={lang} value={descValue} />
      </td>
    </tr>
  );
};

const Table = props => {
  const { lang, list, lemms, showLemms } = props;

  return (
    <Fragment>
      <table className="table table-responsive-md select-default tools_tdh-table-results">
        <thead>
          <tr>
            <th className="tools_tdh-table-number">#</th>
            <th>{lang('col_url')}</th>
            <th>{lang('col_description')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, key) => <Item key={key} lang={lang} item={item} index={key} />)}
        </tbody>
        <tfoot>
          <tr>
            <th>#</th>
            <th>{lang('col_url')}</th>
            <th>{lang('col_description')}</th>
          </tr>
        </tfoot>
      </table>

      <LemmsBox className="mt-5" lang={lang} lemms={lemms} showLemms={showLemms} />
    </Fragment>
  );
};

export default Table;
