import React from 'react';

const Title = props => {
  const { icon, name, text, toolbar, children = [] } = props;

  return (
    <div className="subheader select-default">
      <h1 className="subheader-title">
        {icon && <i className={icon} />} {name}
        {(text || !!children) && <small className="mt-3">{text}{children}</small>}
      </h1>
      {toolbar && <div>{toolbar()}</div>}
    </div>
  );
};

export default Title;
