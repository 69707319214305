import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import Result from './components/Result';

import matchLinksDiff from './utils/matchLinksDiff';
import matchDomainsDiff from './utils/matchDomainsDiff';
import getClearedDomains from './utils/getClearedDomains';
import mergeResults from './utils/mergeResults';

import { isEmpty } from '/utils/object';
import { check as checkLink } from '/utils/link';

import './styles.scss';

const PACKAGE_SIZE = 10;

class ToolsServerResponse extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
        items: [],
        links: [],
      },
      result: {
        domains: {},
        links: [],
      },
    };
  }

  onStartTextChange = (name, text) => {
    const items = text.split('\n').map(v => v.trim()).filter(v => !!v);
    const links = items.filter(link => checkLink(link));

    const insert = { ...this.state.insert, text, items, links };

    this.setState({ insert });
  };

  onProcess = () => {
    const { lock, insert } = this.state;

    if (lock || !insert.links.length) return;

    this.process();
  };

  async process() {
    this.setState({ lock: true });

    const { insert, result } = this.state;

    const linksDiff = matchLinksDiff(result.links, insert.links);
    const domainsDiff = matchDomainsDiff(result.links, insert.links);

    const domains = getClearedDomains(result.domains, domainsDiff.removed, linksDiff.removed);

    const { robots } = await this.provider.robotsChecker(linksDiff.added);

    mergeResults(domains, robots);

    this.setState({ lock: false, result: { ...result, domains, links: insert.links } });
  }

  renderInsertBox() {
    const { lang } = this.props;
    const { lock, insert, result } = this.state;

    const sets = { lang, lock, insert, result };

    sets.onStartTextChange = this.onStartTextChange;
    sets.onProcess = this.onProcess;

    return <InsertBox { ...sets } />;
  }

  // renderToolbar = () => {
  //   const { id } = this.props;
  //   const { result, lock } = this.state;
  //
  //   return (
  //     <Fragment>
  //       <span className="mr-2">Экспорт:</span>
  //       <UI.Button.Transparent disabled={lock} onClick={() => !lock && this.provider.serverResponseExport(result.links)} className="btn-custom-excel" download />
  //     </Fragment>
  //   );
  // };

  renderResult() {
    const { lang } = this.props;
    const { result } = this.state;

    if (!result.links.length) return null;

    return Object.values(result.domains).map(item => <Result key={item.domain} lang={lang} { ...item } />);
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title icon="fal fa-robot" name={lang('title')} text={lang('description')} />

        {this.renderInsertBox()}
        {this.renderResult()}
      </Fragment>
    );
  }
}


export default ToolsServerResponse;
