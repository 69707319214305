import React, { Component, Fragment } from 'react';

import { BL } from '/views';

import { DATA_ERRORS } from '../../constants';

const Good = props => {
  const { lang } = props;

  return (
    <BL.Alert.Success>
      <BL.Alert.Icon icon="fal fa-check-circle" />
      <BL.Alert.Content>
        <p className="mb-0">{lang('robots_status_sitemapOk')}</p>
      </BL.Alert.Content>
    </BL.Alert.Success>
  );
};

const Empty = props => {
  const { lang } = props;

  return (
    <BL.Alert.Warning>
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        <p className="mb-0">{lang('robots_status_sitemapMissing', {}, { useParser: true })}</p>
      </BL.Alert.Content>
    </BL.Alert.Warning>
  );
};

const WrongMirror = props => {
  const { lang } = props;

  return (
    <BL.Alert.Danger>
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        <p className="mb-0">{lang('robots_status_sitemapMirror')}</p>
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

const NoRobots = props => {
  const { lang } = props;

  return (
    <BL.Alert.Danger>
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        <p className="mb-0">{lang('robots_status_fetchError')}</p>
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

const alerts = (lang, robots, sitemaps, errors) => {
  if (robots.status >= 300) return <NoRobots lang={lang} />;

  if (errors.includes(DATA_ERRORS.ROBOTS_EMPTY_MAPS)) return <Empty lang={lang} />;
  if (errors.includes(DATA_ERRORS.ROBOTS_WRONG_MIRROR)) return <WrongMirror lang={lang} />;

  return <Good lang={lang} list={sitemaps} />;
};

const list = (lang, robots, sitemaps) => {
  if (robots.status >= 300) return null;

  if (!sitemaps.length) return null;

  return (
    <Fragment>
      <p>{lang('sitemap_list')}</p>
      <ul className="pb-2 mt-0 mb-2">
        {sitemaps.map((link, i) => <li key={i}><a href={link} target="_blank">{link}</a></li>)}
      </ul>
    </Fragment>
  );
};

const Robots = props => {
  const { lang } = props;
  const { robots, sitemaps, errors } = props.data;

  return (
    <div className="pt-3 px-3">
      <h5 className="pb-2 color-primary-500">{lang('robots_title')}</h5>
      {list(lang, robots, sitemaps, errors)}
      {alerts(lang, robots, sitemaps, errors)}
    </div>
  );
};

export default Robots;
