import { connect } from 'react-redux'
import View from './view';

const mapStateToProps = (state) => {
  const { component, action } = state.router;

  return { component, action };
};

export default connect(mapStateToProps)(View);
