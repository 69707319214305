import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { BL, UN } from '/views';

class UserSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      lock: false
    };
  }

  Form = UN.Form();

  onInvalid = errors => {
    console.warn('Invalid!', errors);
  };

  onSubmit = data => {
    console.log('Submit!', data);
  };

  render() {
    const { Form } = this;
    const { error, lock } = this.state;

    return (
      <Fragment>
        <BL.Title icon="fal fa-user-cog" name="Настройки аккаунта" />

        <BL.Panel>
          <div className="row">
            <div className="col-lg-8 col-md-12 pt-3 pb-3">
              <Form className="d-flex flex-column justify-content-between col-12" onSubmit={this.onSubmit} onInvalid={this.onInvalid}>
                <div className="form-group input-group-sm">
                  <Form.Text className="col-md-9 col-12" label="Логин" name="name" required />
                </div>
                <div className="form-group input-group-sm">
                  <Form.Email className="col-md-9 col-12" label="Адрес электронной почта" name="mail" required />
                </div>
                <div className="form-group input-group-sm">
                  <Form.Password className="col-md-9 col-12" label="Пароль" name="password" equalTo="password-repeat" required />
                  <Form.Password className="col-md-9 col-12" name="password-repeat" equalTo="password" required />
                </div>
                <div className={cn('h5 mb-2', !error && 'invisible')}>
                  <small className="mb-0 color-danger-600">{error}.</small>
                </div>
                <div className="col-12">
                  <Form.Submit size="sm" icon2="fal fa-angle-double-right" spin2={lock} text="Сохранить" />
                </div>
              </Form>
            </div>
          </div>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default UserSettings;
