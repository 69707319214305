import React from 'react';

import TableForms from '../TableForms';

import { BL } from '/views';

const ResultForms = props => {
  const { className, result, tableHeight } = props;

  return (
    <TableForms className={className} data={result.words} tableHeight={tableHeight} />
  );
};

export default ResultForms;
