import { DEFAULT, SUPER_ADMIN, SUPPORT, USER, SUPER_USER } from '/static/data/roles';

const self = {};

self[DEFAULT] = {
  Approve: {
    available: true,
  },
  Login: {
    available: true,
  },
  Restore: {
    available: true,
  },
  Settings: {
    available: false,
  },
};

self[SUPER_ADMIN] = self[SUPPORT] = self[USER] = self[SUPER_USER] = {
  Settings: {
    available: true,
  },
};

export default self;
