import columnFinder from '../../utils/columnFinder';
import columnPath from '../../utils/columnPath';
import matchLabel from '../../utils/matchLabel';

import { MARKER } from '../../constants';

const matchTotalWidth = (items, settings) => {
  const count = items.length;

  const { width, offset } = settings.bars;

  return offset.left + offset.right + (count - 1) * offset.between + count * width;
};

const match = (range, columns, items, exclude, settings) => {
  items = items.filter(item => !exclude.includes(item.name));

  const bars = items.map(item => ({ ...item, boxes: [] }));

  const count = items.length;

  const getColumn = i => columnFinder(i, columns);

  const longest = items.reduce((result, item) => item.values.length > result.length ? item.values : result, []);

  const totalWidth = matchTotalWidth(items, settings);

  const match_box = (k, i, j, value, item, range, column) => {
    const { label: name, color, fill, fillHover } = item;

    const { width, offset } = settings.bars;

    const _x1 = column.center.x + Math.round(k * (offset.between * (i - (items.length - 1) / 2) + width * (i - items.length / 2)));
    const _x2 = _x1 + Math.round(width * k);

    const percent = (range.max - value) / (range.max - range.min);
    const height = column.area.y[1] - column.area.y[0];

    const _y1 = column.area.y[0] + Math.round(height * percent);
    const _y2 = column.center.y - (value / Math.abs(value) || 0);

    const x1 = Math.min(_x1, _x2);
    const x2 = Math.max(_x1, _x2);

    const y1 = Math.min(_y1, _y2);
    const y2 = Math.max(_y1, _y2);

    const box = { x: [x1, x2], y: [y1, y2], width: x2 - x1, height: y2 - y1 };

    const marker = settings.marker.type === MARKER.SINGLE;

    return { name, color, fill, fillHover, marker, value, box, label: settings.popup.label(matchLabel(j, columns), columnPath(j, columns)) };
  };

  for (let j = 0, len = longest.length; j < len; j++) {
    const column = getColumn(j);

    let k = Math.min(1, column.area.width / totalWidth);

    for (let item, i = 0; item = items[i]; i++) {
      const { boxes } = bars[i];

      boxes[boxes.length] = match_box(k, i, j, item.values[j] || 0, item, range, column);
    }
  }

  return bars;
};

export default function matchBars(range, columns, data, exclude, settings) {
  return match(range, columns, data, exclude, settings);
};
