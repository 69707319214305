export default function mergeResults(target, update) {
  for (let domain in update) {
    if (!update.hasOwnProperty(domain)) continue;

    if (domain in target) {
      target[domain].links.push(...update[domain].links);
    } else {
      target[domain] = update[domain];
    }
  }
};
