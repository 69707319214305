import React, { Fragment } from 'react';
import cn from 'classnames';

import { Lang } from '/contexts/Lang';

import { isUndefined } from '/utils/types';

const Loader = props => {
  const text = isUndefined(props.text) ? <Lang text="loading" context="views.ui.Loader" /> : props.text;

  return (
    <Fragment>
      <span className={cn('spinner-border spinner-border-sm', props.className)} />
      {text && <span className="ml-2 select-none">{text}</span>}
    </Fragment>
  );
};

export default Loader;
