import { packComponent } from '/services/Components/methods';

import actions from './actions';
import access from './access';

const settings = {
  name: __FOLDER__,
  icon: 'home',

  menu: 'Home',
  priority: 1,

  rootAction: 'Root',

  title: () => 'Home',
  breadcrumb: () => 'Home',
};

export default packComponent({ settings, actions, access });
