import React from 'react';

import Text from '../Text';

import { VALIDATION } from '../../constants';

class Email extends Text {
  FIELD_TYPE = 'email';

  DEFAULT_CHECK = [VALIDATION.INVALID];
  DATA_CHECKER = /^[a-z0-9-_.]+@([a-z0-9-_]+\.)+[a-z]{2,}$|^$/i;
}

export default Email;
