import * as Methods from '../constants/methods';

class Link {
    constructor (path, method = Methods.GET) {
        this.path = path;
        this.method = method;
    }

    toString () {
        return this.path;
    }
}

export default Link;
