import React, { Component, Fragment } from 'react';

import Popup from '../../../Popup';

class Point extends Component {
  state = {
    hover: false,
  };

  showPopup = () => this.setState({ hover: true });
  hidePopup = () => this.setState({ hover: false });

  renderPopup = () => {
    const { hover } = this.state;
    const { idx, label, name, value, color, fill, popup, pointColor } = this.props;

    if (!hover) return null;

    if (popup) return popup(idx, this.props);

    const type = 'lines';

    const values = [
      { type, name, value, color, fill },
    ];

    return <Popup label={label} values={values} style={{ zIndex: 4 }} />
  };

  render() {
    const { hover } = this.state;
    const { idx, point, pointColor, pointColorHover, popup } = this.props;

    const position = { left: point[0], top: point[1] };

    return (
      <Fragment>
        <div style={{ position: 'absolute', ...position, zIndex: 4 }}>
          {this.renderPopup()}
        </div>
        <div
          className="ui_line_chart-chart_point"
          style={{ ...position, zIndex: hover ? 5 : 3 }}
          onMouseEnter={this.showPopup}
          onMouseLeave={this.hidePopup}
        >

          <div className="ui_line_chart-chart_point_outer" style={{ backgroundColor: pointColor }} />
          <div className="ui_line_chart-chart_point_inner" style={{ backgroundColor: pointColorHover || pointColor }} />
        </div>
      </Fragment>
    );
  }
}

export default (lines, settings) => {
  const items = [];

  const render = (item, idx, i) => {
    if (!item.marker) return null;

    const sets = { ...lines[i], ...item, idx, settings };

    return <Point key={i + ':' + idx} { ...sets } />;
  };

  for (let i = 0, len = lines.length; i < len; i++) {
    const points = lines[i].points.slice(2, -2).map((item, idx) => render(item, idx, i));

    items.push(...points);
  }

  return items;
};
