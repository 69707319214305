import React from 'react';

import { BL } from '/views';

const ModalRemove = props => {
  const { name, onCancel, onSubmit } = props;

  const sets = {
    title: 'Удалить аккаунт',
    icon: 'fal fa-exclamation-circle',
    actions: { onCancel, onSubmit },
    buttons: { cancel: 'Отмена', submit: 'Удалить' },
  };

  return (
    <BL.Modal.Confirm.Danger { ...sets }>
      <p>Вы действительно хотите удалить апдейт "{name}"?</p>
    </BL.Modal.Confirm.Danger>
  );
};

export default ModalRemove;
