import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import Chart from './components/Chart';
import Legends from './components/Legends';
import Sections from './components/Sections';

import VIEW from '/views/view';

import matchGraph from './graph';

import { ref } from '/utils/react';

import SETTINGS from './settings';

import './styles.scss';

class LineChart extends Component {
  state = {
    width: null,
    exclude: {
      sections: [],
      lines: [],
      bars: [],
    },
  };

  ref = ref;

  timer = null;

  componentDidMount() {
    this.updateWidth();

    VIEW.on('viewport:change-stop', this.updateWidth);
    VIEW.on('sidebar:toggle', this.updateWidth);
  }

  componentDidUpdate() {
    this.updateWidth();
  }

  componentWillUnmount() {
    VIEW.off('viewport:change-stop', this.updateWidth);
    VIEW.off('sidebar:toggle', this.updateWidth);
  }

  updateWidth = () => {
    const { width } = this.state;
    const { clientWidth } = this.ui.root;

    width !== clientWidth && this.setState({ width: clientWidth });
  };

  onLegendClick = (name, type) => {
    const { onLegendClick } = this.props;

    this.setState(state => {
      const exclude = { ...state.exclude };

      const flag = exclude[type].includes(name);

      if (flag) {
        exclude[type] = exclude[type].filter(n => n !== name);
      } else {
        exclude[type] = [...exclude[type], name];
      }

      return { exclude };
    });

    onLegendClick && onLegendClick(name);
  };

  renderData = () => {
    const { width, exclude } = this.state;
    const { height = 280, offset = {}, data, events, settings = {} } = this.props;

    if (!width) return null;

    const box = { width, height };

    const graph = matchGraph(box, offset, data, exclude, settings);

    return (
      <Fragment>
        <Legends data={data} exclude={exclude} settings={graph.settings} onLegendClick={this.onLegendClick} />
        <Sections data={data} sections={graph.sections} exclude={exclude} settings={graph.settings} onLegendClick={this.onLegendClick} />
        <Chart className="mt-2" data={graph} events={events} />
      </Fragment>
    );
  };

  render() {
    const { className } = this.props;

    return (
      <div className={cn('ui_line_chart-root', className)} ref={this.ref('root')}>
        {this.renderData()}
      </div>
    );
  }
}

export default LineChart;
