import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI } from '/views';

import { ERROR_FETCH, ERROR_INCORRECT_URL, ERROR_DIFFERENT_PROTOCOL } from '../../../constants';

class Child extends Component {
  renderErrorItem = (code, type) => {
    return {
      [ERROR_DIFFERENT_PROTOCOL]: this.renderProtocolError,
    }[code](type);
  };

  renderProtocolError = type => {
    const { item } = this.props;

    const { initial } = item.url;
    const { link } = item.result[type];

    const reg = /^(https?)(:\/\/.+)$/i;

    const _initial = reg.exec(initial);
    const _link = reg.exec(link);

    return (
      <div key={ERROR_DIFFERENT_PROTOCOL}>
        <div className="d-flex align-items-center">
          <UI.Icon.Warning className="fs-12 mr-1" /> Исходная ссылка и ссылка в индексе различаются протоколами
        </div>
        <div className="mt-2">
          Исходная ссылка: <a className="color-info-500 text-decoration-none" href={initial} target="_blank">
            <span className="color-danger-500">{_initial[1]}</span>{_initial[2]}
          </a>
          <br />
          Ссылка в индексе: <a className="color-info-500 text-decoration-none" href={link} target="_blank">
            <span className="color-danger-500">{_link[1]}</span>{_link[2]}
          </a>
        </div>
      </div>
    );

  };

  renderResult(type) {
    const { item } = this.props;

    const result = item.result[type];

    if (!result || !result.errors.length) return null;

    const { initial } = item.url;
    const { link } = result;

    const name = { yandex: 'Яндекс', google: 'Google' }[type];

    return (
      <div className="my-3">
        <h6 className="font-weight-bold">Индексация {name}</h6>
        <div>
          {result.errors.map(code => this.renderErrorItem(code, type))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderResult('yandex')}
        {this.renderResult('google')}
      </Fragment>
    );
  }
}

const child = function child(item) {
  return <Child item={item} />;
}

child.check = function check(item) {
  const { yandex, google } = item.result;

  if (yandex && yandex.errors.length) return true;
  if (google && google.errors.length) return true;

  return false;
};

export default child;
