const self  = {};

self.head_title = 'Bulk Title, Meta Description, H1 Checker &amp; Extraction Tool';
self.head_keywords = '';
self.head_description = 'Extract Page Title, Description, H1 &amp; other H-tags from URLs in bulk online for free. Analyze H-tags structure, word frequency for title tags or H1';

self.name = 'Title, Meta Description, H1 Checker';

self.title = 'Title, Meta Description, H1 Checker & Extraction Tool';
self.description = 'Bulk Extraction Tool extracts Title Tag, Meta Description, H1-H6 heading tags from URLs and lets you quickly view data of multiple web pages. Easily analyze header ags stucture, word frequency for title tags or H1 and export your data.';

self.field_title = 'URLs (one per line)';
self.field_matchWords = 'Count word frequency';
self.field_linksCount = 'URLs count: <b class="<%= styleCount %>"><%= linksCount %></b> of <b><%= linksLimit %></b>';

self.button_check = 'Extract Title, Description & H-tags';

self.notice_tooMuchPages = 'Pages exceeding the limit will not be checked';

self.error_incorrectUrl = 'URL list contains invalid URL. Please make sure that your URLs begin with \'https://\' or \'http://\' and include your domain name.';
self.error_missmatchUrl = 'All URLs must have the same protocol (https or http) and domain name.';

self.title_result = 'Results';

self.tab_allRestuls = 'All data';
self.tab_title = 'Title';
self.tab_description = 'Description';
self.tab_h1h6 = 'H1-H6';
self.tab_h1 = 'H1';

self.export = 'Export';

self.col_url = 'URL';
self.col_title = 'Title';
self.col_description = 'Description';
self.col_h1h6 = 'H1-H6 Tags';

self.button_toggleAll = 'Show all / Hide all';
self.button_toggleTags = 'Show tags / Collapse tags';

self.state_notFound = 'Not found';
self.state_cantCheck = 'Failed to check';
self.state_noH1H6Tags = 'H-tags are not found';
self.state_empty = 'Empty';

self.button_countWords = 'Count word frequency';

self.title_lemms = 'Frequent words';

self.col_lems = 'Word (lemma)';
self.col_count = 'Frequency';
self.col_forms = 'Word forms from text';

export default self;
