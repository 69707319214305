import React, { Fragment } from 'react';

import { BL } from '/views';

const fields = {};

fields.visits = item => {
  return (
    <Fragment>
      {item.value}<br />({item.percent}%)
    </Fragment>
  );
};

fields.status = item => {
  const sets = { error: item.response.error, code: item.response.statusCode };

  return <BL.StatusBadge {...sets} />;
};

const labelGood = () => {
  return (
    <div className="d-flex align-center mt-2 text-success">
      <i className="fal fa-check-circle fs-12 mr-2" /> Всё хорошо
    </div>
  );
};

const labelWarning = () => {
  return (
    <div className="d-flex align-center mt-2 text-warning">
      <i className="fal fa-exclamation-circle fs-12 mr-2" />Возможно проблема
    </div>
  );
};

const labelProblem = () => {
  return (
    <div className="d-flex align-center mt-2 text-danger">
      <i className="fal fa-exclamation-circle fs-12 mr-2" />Есть проблема
    </div>
  );
};

const VERDICTS = {
  VERDICT_MAIN: 'Главное зеркало',
  VERDICT_MIRROR: 'Неглавное зеркало',
  VERDICT_OLD_MIRROR: 'Зеркало на старом домене',
  VERDICT_DEV: 'Поддомен (возможно тестовая версия)',
  VERDICT_SUBDOMAIN: 'Поддомен',
  VERDICT_DANGER: 'Проверьте сайт, возможно это копия, "зеркало" или тестовая версия',
};

fields.description = item => {
  const { redirect } = item.response;

  let html = VERDICTS[item.verdict];

  if (redirect) html += '<br />Переадресания на <a href="' + redirect + '" target="_blank">' + redirect + '</a>';

  let label = null;

  ['VERDICT_MAIN', 'VERDICT_MIRROR', 'VERDICT_OLD_MIRROR', 'VERDICT_SUBDOMAIN'].includes(item.verdict) && (label = labelGood());
  ['VERDICT_DEV'].includes(item.verdict) && (label = labelWarning());
  ['VERDICT_DANGER'].includes(item.verdict) && (label = labelProblem());

  return (
    <Fragment>
      <a href={item.url} target="_blank">{item.url}</a>
      {!!item.title && <p>Title: {item.title}</p>}
      {label}
      <p className="mt-2" dangerouslySetInnerHTML={{ __html: html }} />
    </Fragment>
  );
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
