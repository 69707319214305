import moment from 'moment';

import { createArray } from '/utils/array';

const DATE_FORMAT = 'YYYY-MM-DD';

import { GROUPS } from '../../constants';

const pick = (weeks, date) => {
  const { from, to } = date;

  const main = [];

  const days = createArray(7, 0);
  const hours = createArray(24, 0);

  for (let week, j = 0; week = weeks[j]; j++) {
    if (week.date.to < from || week.date.from > to) continue;

    week.days.forEach((v, i) => {
      const date = moment(week.date.from).add(i, 'day').format(DATE_FORMAT);

      if (date < from || to < date) return;

      main[main.length] = { name: date, value: week.days[i] };
    });

    if (from <= week.date.from || week.date.to <= to) {
      week.days.forEach((v, i) => days[i] += v);
      week.hours.forEach((v, i) => hours[i] += week.hours[i]);
    }
  }

  return { date, main, days, hours };
};

const methods = {};

methods[GROUPS.BY_WEEK] = function groupByWeek(weeks) {
  const result = [];

  for (let week, i = 0; week = weeks[i]; i++) {
    const { date, days, hours } = week;

    const group = result[result.length] = {};

    group.date = { ...date };

    group.main = createArray(7, i => {
      const name = moment(date.from).add(i, 'day').format(DATE_FORMAT);
      const value = days[i];

      return { name, value };
    });

    group.days = createArray(7, i => days[i]);
    group.hours = createArray(24, i => hours[i]);
  }

  return result;
};

methods[GROUPS.BY_MONTH] = function groupByMonth(weeks) {
  const result = [];

  for (let i = -12; i; i++) {
    const date = {
      from: moment().add(i, 'month').add(1, 'day').format(DATE_FORMAT),
      to: moment().add(i + 1, 'month').format(DATE_FORMAT),
    };

    result[result.length] = pick(weeks, date);
  }

  return result;
};

methods[GROUPS.BY_3_MONTH] = function groupByMonth(weeks) {
  const result = [];

  for (let i = -12; i; i += 3) {
    const date = {
      from: moment().add(i, 'month').add(1, 'day').format(DATE_FORMAT),
      to: moment().add(i + 3, 'month').format(DATE_FORMAT),
    };

    result[result.length] = pick(weeks, date);
  }

  return result;
};

export default function groupData(weeks, groupBy) {
  return methods[groupBy](weeks);
};
