import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Визитов из поиска за месяц',
    field: 'visits',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '120px', maxWidth: '120px', minWidth: '120px', textAlign: 'center' },
  },
  {
    name: 'Код ответа',
    field: 'status',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '50px', maxWidth: '50px', minWidth: '50px', textAlign: 'center' },
  },
  {
    name: 'Адрес страницы и статус',
    field: 'description',
    sortable: true,
    sortFirst: 'asc',
    style: {},
  },
];
