import React, { Component } from 'react';
import cn from 'classnames';

import { isFunction } from '/utils/types';

import matchHeaderHeight from '../../utils/matchHeaderHeight';
import pickDeepChilds from '../../utils/pickDeepChilds';
import unpackDeepChilds from '../../utils/unpackDeepChilds';

const complete = params => Object.assign({
  name: '',
  field: '',
  sortable: false,
  sortFirst: 'asc',
  transform: (field, row, data) => null,
}, params);

const reverse = dir => ({ asc: 'desc', desc: 'asc' }[dir]);

class TableHead extends Component {
  onSort = event => {
    event.preventDefault();

    const field = event.target.closest('th').getAttribute('field');

    const { sort, columns, onSort } = this.props;

    const { sortFirst } = unpackDeepChilds(columns).find(c => c.field === field);

    const dir = sort.field === field ? reverse(sort.dir) : sortFirst;

    onSort(field, dir);
  };

  renderItem = (params, key, row) => {
    const { settings, sort } = this.props;

    params = complete(params);

    const sets = { key };
    const classes = ['select-none'];

    if (params.sortable) {
      sets.field = params.field;
      sets.tabIndex = 0;
      sets.onClick = this.onSort;

      classes.push('sorting');
    }

    if (params.field === sort.field) classes.push('sorting_' + sort.dir);

    sets.className = cn(...classes);

    if (params.style) sets.style = params.style;

    if (row.total > 1) {
      const colSpan = params.childs && params.childs.length;
      const rowSpan = params.childs ? 1 : row.total - row.idx;

      colSpan > 1 && (sets.colSpan = colSpan);
      rowSpan > 1 && (sets.rowSpan = rowSpan);
    }

    return <th { ...sets }>{isFunction(params.name) ? params.name(settings) : params.name}</th>;
  };

  renderRow = (idx, total) => {
    const columns = pickDeepChilds(this.props.columns, idx);

    const items = columns.map((item, key) => this.renderItem(item, key, { idx, total }));

    return <tr key={idx}>{items}</tr>;
  };

  renderRows = () => {
    const { columns } = this.props;

    const size = matchHeaderHeight(columns);

    const rows = [];

    for (let i = 0; i < size; i++) {
      rows[rows.length] = this.renderRow(i, size);
    }

    return rows;
  };

  render() {
    return <thead>{this.renderRows()}</thead>;
  }
}

export default TableHead;
