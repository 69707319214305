import React, { Component } from 'react';
import Modal from '../Base';

import cn from 'classnames';

import KeyControl from '/services/KeyControl';

import UI from '/views/ui';
import VIEW from '/views/view';

class Alert extends Component {
  componentDidMount() {
    KeyControl.on('keyup:enter', VIEW.MODAL.hide);
  }

  componentWillUnmount() {
    KeyControl.off('keyup:enter', VIEW.MODAL.hide);
  }

  render() {
    const { icon, title, subtitle, children, buttons = {}, ...sets } = this.props;

    return (
      <Modal { ...sets } position="top">
        <div className="modal-header pt-3 pb-3">
          <h4 className="modal-title d-flex align-items-center">
            {icon && <i className={cn(icon, 'mr-3')} style={{ fontSize: '1.9rem' }} />}
            <span>
              {title}
              {subtitle && <small className="m-0 text-muted">{subtitle}</small>}
            </span>
          </h4>
        </div>
        <div className="modal-devider" />
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer pt-2 pb-2">
          <UI.Button stl={sets.stl} onClick={VIEW.MODAL.hide}>{buttons.close || 'Закрыть'}</UI.Button>
        </div>
      </Modal>
    );
  }
};

Alert.Danger = props => <Alert stl="danger" { ...props } />;
Alert.Warning = props => <Alert stl="warning" { ...props } />;
Alert.Success = props => <Alert stl="success" { ...props } />;
Alert.Info = props => <Alert stl="info" { ...props } />;
Alert.Primary = props => <Alert stl="primary" { ...props } />;

export default Alert;
