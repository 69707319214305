import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="statusCode" />,
    field: 'status',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '190px', maxWidth: '190px', minWidth: '190px', textAlign: 'center' },
  },
  {
    name:  () => <Lang text="count_redirects" />,
    field: 'redirects',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '90px', maxWidth: '90px', minWidth: '90px', textAlign: 'center' },
  },
  {
    name:  () => <Lang text="url" />,
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: { textAlign: 'left' },
  },
];
