import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'XML-карта сайта',
    field: 'url',
    sortable: false,
    sortFirst: 'asc',
    style: { width: '30%', minWidth: '240px', maxWidth: '240px', textAlign: 'left' },
  },
  {
    name: () => {
      return (
        <Fragment>
          Источник
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={360} boundingBox=".panel" passive={true}>
            В этом столбце указано, откуда мы получили информацию о карте сайта
          </BL.Info.Dark>
        </Fragment>
      );
    },
    field: 'source',
    sortable: false,
    sortFirst: 'asc',
    style: { width: '10%', minWidth: '90px', maxWidth: '220px', textAlign: 'center' },
  },
  {
    name: () => {
      return (
        <Fragment>
          Статус
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={240} boundingBox=".panel" passive={true}>
            Код ответа сервера
          </BL.Info.Dark>
        </Fragment>
      );
    },
    field: 'response_code',
    sortable: false,
    sortFirst: 'asc',
    style: { width: '10%', minWidth: '90px', maxWidth: '90px', textAlign: 'center' },
  },
  {
    name: () => {
      return (
        <Fragment>
          Корректная
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={420} boundingBox=".panel" passive={true}>
            Мы автоматически проверяем отсутствие ошибок у карты сайта и используем в аудите только корректные, со статусом "Да". Карты со статусом "Нет" в аудите использоваться не будут. При проверке карт сайта мы проверяем: доступность, наличие страниц сайта в карте, ведут ли ссылки в карте на главное зеркало сайта, не содержит ли формат карты сайта ошибок
          </BL.Info.Dark>
        </Fragment>
      );
    },
    field: 'valid',
    sortable: false,
    sortFirst: 'asc',
    style: { width: '10%', minWidth: '80px', textAlign: 'center' },
  },
  {
    name: () =>  (
      <Fragment>
        Тип
        <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={400} boundingBox=".panel" passive={false}>
          Файлы индекса Sitemap - в них содержатся ссылки на другие XML-карты сайта, а не на страницы сайта (<a className="text-decoration-none color-danger-500" href="https://www.sitemaps.org/ru/protocol.html#index" target="_blank">описание формата</a>).
          <br />
          Из таких файлов сервис извлекает ссылки на обычные XML-карты сайта и использует уже их для дальнейшего анализа.
        </BL.Info.Dark>
      </Fragment>
    ),
    field: 'type',
    sortable: false,
    sortFirst: 'asc',
    style: { width: '10%', minWidth: '80px', textAlign: 'center' },
  },
  {
    name: () =>  (
      <Fragment>
        Действия
        <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={400} boundingBox=".panel" passive={false}>
          По кнопке "Удалить" вы можете удалить карту сайта из списка и она не будет использоваться при аудите сайта.
        </BL.Info.Dark>
      </Fragment>
    ),
    field: 'actions',
    sortable: false,
    style: { width: '90px', minWidth: '90px', textAlign: 'center' },
  },
];
