import {
  TYPE_META,
  TYPE_MESSAGE,
  PURPOSE_MESSAGE_ADD,
  PURPOSE_MESSAGE_UPDATE,
  PURPOSE_MESSAGE_REMOVE,
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  PURPOSE_MESSAGE_PLACEHOLDER_REMOVE,
} from '../../../../constants';

const processHistory = function processHistory(list) {
  const result = [];
  const map = {};

  const meta = (item, idx) => result.push(item);

  const message = (item, idx) => {
    if (item.purpose === PURPOSE_MESSAGE_ADD || item.purpose === PURPOSE_MESSAGE_PLACEHOLDER_ADD) {
      result[idx] = item;
      map[item.id] = idx;
    } else {
      const index = map[item.id];
      const { timestamp, ...data } = item;

      if (item.purpose === PURPOSE_MESSAGE_UPDATE) {
        if (result[map[item.id]].user_id === item.user_id) {
          result[index] = { ...result[index], ...data, updated: true, update_timestamp: timestamp };
        }
      }

      if (item.purpose === PURPOSE_MESSAGE_REMOVE) {
        if (result[map[item.id]].user_id === item.user_id) {
          result[index] = { ...result[index], ...data, removed: true, remove_timestamp: timestamp };
        }
      }

      if (item.purpose === PURPOSE_MESSAGE_PLACEHOLDER_UPDATE) {
        result[index] = { ...result[index], ...data, updated: true, update_timestamp: timestamp };
      }

      if (item.purpose === PURPOSE_MESSAGE_PLACEHOLDER_REMOVE) {
        result[index] = { ...result[index], ...data, removed: true, remove_timestamp: timestamp };
      }
    }
  };

  for (let item, i = 0; item = list[i]; i++) {
    const idx = result.length;

    item.type === TYPE_META && meta(item, idx);
    item.type === TYPE_MESSAGE && message(item, idx);
  }

  return result.filter(v => !!v);
};

export default processHistory;
