import axios from 'axios';

import { select, call, put, take, takeEvery } from 'redux-saga/effects';
import { startSession, signout } from '../../User/store/actions';
import {
  dropConversations,
  createConversation,
  readConversation,
  fetchConversations,
  fetchUnhandledConversations,
  watchConversations,
  appendConversations,
  updateConversations,
  setCurrentConversation,
  toggleConversationState,
  sendConversationMessage,
  appendConversationMessages,
  finalizeConversationMessage,
 } from './actions';

import Provider from '../provider';

import {
  TYPE_MESSAGE,
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  PURPOSE_MESSAGE_PLACEHOLDER_REMOVE,
} from '../constants';

import { CONVERSATION_FETCH_TIMEOUTS as FETCH_TIMEOUTS } from '../settings';

import { tokenGenerator, setAsyncTimeout, deferred } from '/utils/common';

function* _startSession({ payload }) {
  if (__CLIENT__) {
    yield put(fetchConversations());
  }
}

function* _signout() {
 yield put(dropConversations());
}

function* _fetchConversations() {
  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { conversations, error } = yield provider.fetch();

    if (error) throw new Error(`Can't fetch conversations`);

    yield put(appendConversations(conversations));
    yield put(watchConversations());
  } catch (e) {
    console.error(e);
  }
};

function* _fetchUnhandledConversations() {
  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { conversations, error } = yield provider.fetchUndandled();

    if (error) throw new Error(`Can't fetch conversations`);

    yield put(appendConversations(conversations));
    yield put(watchConversations());
  } catch (e) {
    console.error(e);
  }
};

function* _createConversation({ payload }) {
  const { title, message, defer } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { conversation, error } = yield provider.create(title, message);

    if (error) throw new Error(`Can't create conversation`);

    yield put(appendConversations([conversation]));

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

function* _readConversation({ payload }) {
  const { id } = payload;

  const { user, conversations } = yield select();

  const provider = new Provider(user.token);

  try {
    const { last_id, last_timestamp } = conversations[id] || {};

    const { messages, error } = yield provider.getHistory(id, last_id, last_timestamp);

    if (error) throw new Error(`Can't load conversation history`);

    if (!messages.length) return;

    yield put(appendConversationMessages(id, messages, true));
  } catch (e) {
    console.error(e);
  }
}

const createPlaceholder = (user_id, message_id, purpose, value) => {
  const id = message_id;
  const type = TYPE_MESSAGE;
  const timestamp = Math.round(+new Date() / 1000);
  const waiting = false;
  const failed = true;

  const result = { id, timestamp, type, purpose, user_id, waiting, failed };

  value && (result.value = value);

  return result;
};

function* _sendConversationMessage({ payload }) {
  let { id, message_id, purpose, msg } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  const method = {
    [PURPOSE_MESSAGE_PLACEHOLDER_ADD]: 'sendMessage',
    [PURPOSE_MESSAGE_PLACEHOLDER_UPDATE]: 'updateMessage',
    [PURPOSE_MESSAGE_PLACEHOLDER_REMOVE]: 'removeMessage',
  }[purpose];

  message_id = message_id || tokenGenerator(5);

  const placeholder = createPlaceholder(user.id, message_id, purpose, msg);

  try {
    yield put(appendConversationMessages(id, [placeholder], false));

    const { message, error } = yield provider[method](id, message_id, msg);

    if (error) throw new Error(error);

    yield put(finalizeConversationMessage(id, message, purpose));
  } catch (e) {
    const message = { ...placeholder, waiting: false, failed: true };

    yield put(finalizeConversationMessage(id, message, purpose));
  }

  yield put(watchConversations());
}

function* _setCurrentConversation({ payload }) {
  const { id } = payload;

  yield put(readConversation(id));
}

let _I_ = 0, WATCHING = false, WAITING = null;

function* _watchConversations() {
  _I_ = 0;

  if (WATCHING) return WAITING && WAITING.resolve();

  WATCHING = true;

  const max = FETCH_TIMEOUTS.length - 1;

  const { user } = yield select();

  const provider = new Provider(user.token);

  while (true) {
    WAITING = deferred(resolve => setTimeout(resolve, 1000 * FETCH_TIMEOUTS[_I_]));

    yield WAITING;

    const { items, current } = yield select(state => state.conversations);

    const active = Object.values(items).filter(item => !item.closed);

    if (!active.length) return WATCHING = false;

    const ids = active.map(item => item.id);

    try {
      let { updates, error } = yield provider.getUpdates(ids);

      if (error) throw new Error(error);

      updates = updates.filter(item => (item.updates !== items[item.id].updates) || item.closed);

      if (updates.length) {
        yield put(updateConversations(updates));

        const isActive = !!updates.find(item => item.id === currentConversation);

        if (isActive) {
          yield put(readConversation(currentConversation));
        }

        _I_ = 0;
      } else {
        _I_ = Math.min(_I_ + 1, max);
      }
    } catch (e) {
      _I_ = Math.min(_I_ + 1, max);
    }
  }
}

function* _toggleConversationState({ payload }) {
  const { id, closed } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { conversation, error } = yield provider.toggleState(id, closed);

    if (error) throw new Error(`Can't change conversation state`);

    yield put(updateConversations([conversation]));
  } catch (e) {
    console.error(e);
  }
}

export default function* settingsSage() {
  yield takeEvery(startSession, _startSession);
  yield takeEvery(signout, _signout);

  yield takeEvery(fetchConversations, _fetchConversations);
  yield takeEvery(fetchUnhandledConversations, _fetchUnhandledConversations);
  yield takeEvery(createConversation, _createConversation);
  yield takeEvery(readConversation, _readConversation);
  yield takeEvery(sendConversationMessage, _sendConversationMessage);

  yield takeEvery(setCurrentConversation, _setCurrentConversation);
  yield takeEvery(watchConversations, _watchConversations);

  yield takeEvery(toggleConversationState, _toggleConversationState);
}
