import React, { Component } from 'react';
import cn from 'classnames';

import { deferred } from '/utils/common';

import { UN } from '/views';

class Signup extends Component {
  state = {
    error: null,
    lock: false,
    restored: false,
  };

  Form = UN.Form();

  onSubmit = async values => {
    if (this.state.lock) return;

    this.setState({ lock: true });

    const { restore } = this.props;
    const { mail } = values;

    const defer = deferred();

    defer.then(() => this.setState({ restored: true }));
    defer.catch(() => this.setState({ lock: false, error: 'Не удалось восстановить аккаунт' }));

    restore(mail, defer);
  };

  renderForm() {
    const { error, lock } = this.state;

    const { Form } = this;

    return (
      <Form className="d-flex flex-column justify-content-between col-12" onSubmit={this.onSubmit}>
        <div>
          <div className="form-group input-group-sm mb-2">
            <Form.Email className="w-100" name="mail" label="Адрес электронной почты" required />
          </div>
        </div>
        <div>
          <div className={cn('h5 mb-2', !error && 'invisible')}>
            <small className="mb-0 color-danger-600">{error}.</small>
          </div>
          <div className="d-flex justify-content-center">
            <Form.Submit style={{ minWidth: 220 }} icon2="fal fa-angle-double-right ml-1" spin2={lock} text="Выслать письмо" />
          </div>
        </div>
      </Form>
    );
  }

  renderResult() {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="text-center">
          <i className="fal fa-check-circle text-success fs-35" />
        </div>
        <div className="p-3 mt-3 text-center">
          Письмо с инструкцией выслано на указанный почтовый адрес.
        </div>
      </div>
    );
  }

  render() {
    const { restored } = this.state;

    if (restored) {
      return this.renderResult();
    } else {
      return this.renderForm();
    }
  }
};

export default Signup;
