import React, { Component, Fragment } from 'react';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';
import { waitForRender } from '/utils/common';

const LABELS = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'].map(label => ({ label }));

const collectChartData = items => {
  const labels = LABELS;

  const data = {
    name: 'queries',
    label: 'Запросы',
    color: color.primary._500,
    fill: color.primary._300,
    values: items,
  };

  const bars = [data];

  return { labels, bars };
};

class ChartDays extends Component {
  renderGraph = () => {
    const { items } = this.props;

    const data = collectChartData(items);

    return <UI.LineChart data={data} settings={{}} />;
  };

  render() {
    return (
      <Fragment>
        <h4 className="mt-3">Распределение по дням недели</h4>

        {this.renderGraph()}
      </Fragment>
    );
  }
}

export default ChartDays;
