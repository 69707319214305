import React from 'react';
import cn from 'classnames';

import Field from '../Field';

import Loader from '/views/UI/Loader';

class Text extends Field {
  FIELD_TYPE = 'text';

  render () {
    const { errors, waiting } = this.state;
    const { label, name, prepend, append, value, className } = this.props;

    const fieldSets = {
      name: name,
      type: this.FIELD_TYPE,
      defaultValue: value,
      className: 'form-control',
      onChange: this.onChange,
    };

    if (errors.length && !waiting) {
      fieldSets.className += ' is-invalid';
    }

    return (
      <label className={cn("d-block", className)}>
        {label && <span className="form-label select-none">{label}</span>}
        <div className={cn('position-relative mt-2', (prepend || append) && 'input-group')}>
          {prepend && <Field.Before>{prepend}</Field.Before>}
          <input { ...fieldSets } />{this.renderState()}
          {append && <Field.After>{append}</Field.After>}
          {waiting && <Loader text="" className="field-loader" />}
        </div>
      </label>
    );
  }
}

export default Text;
