import React from 'react';

import { BL } from '/views';

const ModalAuditError = () => {
  const sets = {
    title: 'Запуск аудита',
    icon: 'fal fa-exclamation-circle',
  };

  return (
    <BL.Modal.Alert.Danger { ...sets }>
      <p>Не получилось создать аудит.</p>
      <p className="mt-3">Попробуйте запустить его позже...</p>
    </BL.Modal.Alert.Danger>
  );
};

export default ModalAuditError;
