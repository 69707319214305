import React from 'react';

import BL from '/views/blocks';

import config from './config';

const sort = { field: 'stats', dir: 'desc' };

const Table = props => {
  return <BL.Table className="darken" sort={sort} { ...props } { ...config } />;
};

export default Table;
