export function restructurizeTagsTree(tree) {
  let firstH1 = null;

  const filterTree = (data) => data.filter(item => {
    if (!firstH1) {
      if (item.type === 'h1') {
        firstH1 = item;
        return false;
      } else {
        item.items = filterTree(item.items)
        item.settings.count = item.items.length
      }
    }

    return true;
  })

  const newTree = filterTree(tree);
  const items = [
    ...firstH1.items,
    ...newTree
  ];

  return [
    {
      ...firstH1,
      items,
      settings: {
        level: 1,
        count: items.length
      }
    }
  ]
}

export function getTagCount(tree, tagName) {
  return tree.reduce((total, item) => {
    return (item.type === tagName ? total + 1 : total) + getTagCount(item.items)
  }, 0);
}

export function wrapInH1(tree) {
  return [{
    type: 'h1',
    value: null,
    items: tree,
    settings: {
      level: 1,
      count: tree.length
    }
  }];
}
