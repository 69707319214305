import React, { Component, Fragment } from 'react';
import moment from 'moment';

import TrafficGraph from './components/TrafficGraph';
import Stats from './components/Stats';
import Message from './components/Message';
import PagesTable from './components/PagesTable';

import { UI, BL } from '/views';

import Provider from '../../provider';

import { capitalize } from '/utils/string';

import services from '/static/data/services';

class ReportsHttpsTransition extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getHttpsTransitionResult(audit_id, id);
  }

  // exportExcelLink() {
  //   const { id, user_id } = this.props;
  //
  //   return this.provider.getAllTrafficExcelLink(id, user_id);
  // }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderGraph() {
    const { stats } = this.state.report || {};

    if (!stats) return null;

    return (
      <Fragment>
        <h4 className="ui-detail_list-title">Изменение поискового трафика на http и https-версию за 2 года</h4>
        <TrafficGraph stats={stats} />
      </Fragment>
    );
  }

  renderStats() {
    const { stats, analytics } = this.state.report || {};

    if (!analytics) return null;

    return <Stats className="pt-3" stats={stats} analytics={analytics} />;
  }

  renderSSL() {
    const { stats } = this.state.report || {};

    if (!stats || !stats.ssl) return null;

    const labelGood = () => <i className="fal fa-check-circle text-success fs-12 mr-2" />;
    const labelWarning = () => <i className="fal fa-exclamation-circle text-warning fs-12 mr-2" />;
    const labelProblem = () => <i className="fal fa-exclamation-circle text-danger fs-12 mr-2" />;

    const title = 'SSL-сертификат';

    const from = moment(stats.ssl.date.from);
    const to = moment(stats.ssl.date.to);

    const remain = Math.floor((to.unix() - moment().unix()) / (24 * 60 * 60));

    const label = remain <= 0 ? labelProblem() : (remain <= 30 ? labelWarning() : labelGood());

    const items = [
      { name: 'Дата начала действия', value: from.format('D MMMM YYYY HH:mm:ss') },
      {
        name: 'Дата окончания действия',
        value: () => (
          <Fragment>{label}{to.format('D MMMM YYYY HH:mm:ss')} (Осталось дней: {remain})</Fragment>
        ),
      },
      { name: 'Сертификат валидный', value: ['Нет', 'Да'][+stats.ssl.isValidCert] || '-' },
      { name: 'Сертификат для этого домена', value: ['Нет', 'Да'][+stats.ssl.isValidDomain] || '-' },
    ];

    const message = () => {
      if (remain > 30) return null;

      return (
        <BL.Alert.Warning className="mt-2">
          <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
          <BL.Alert.Content>
            <p className="mb-0">Пожалуйсте, не забудьте продлить SSL-сертификат. В случае непродления сайт не будет доступен по защищенному протоколу, браузер будет выдавать пользователям ошибку, а сайт может начать терять позиции в поиске</p>
          </BL.Alert.Content>
        </BL.Alert.Warning>
      );
    };

    return (
      <Fragment>
        <UI.DetailList className="pt-3" title={title} items={items} />
        {message()}
      </Fragment>
    );
  }

  renderVerdict() {
    const { stats, analytics } = this.state.report || {};

    if (!analytics) return null;

    return <Message className="mt-3" stats={stats} analytics={analytics} />;
  }

  renderPagesTable() {
    const { stats } = this.state.report || {};

    if (!stats) return null;

    const http = stats.http.year[1][11].value;
    const https = stats.https.year[1][11].value;

    if ((http && !https) || (!http && https)) return null;

    const { date, pages } = stats.http.year[1][11];

    if (!pages.length) return null;

    return (
      <Fragment>
        <h3>http-страницы</h3>
        <div className="mb-3">Список страниц http-версии сайта с поисковым трафиком за <b>{moment(date.from).format('MMMM YYYY')}</b>.</div>
        <PagesTable data={pages} />
      </Fragment>
    );
  }

  render() {
    const { stats } = this.state.report || {};

    return (
      <Fragment>
        <BL.Title icon={services.https_transition.icon} name={services.https_transition.name}>
          <p>В данном отчете мы проверяем, подключен ли для сайта SSL-сертификат, использует ли сайт защищенный https-протокол, весь ли поисковый трафик попадает на версию сайта, доступную по https. Также отчет позволяет легко определить, упал или вырос поисковый трафик после перехода на защищенный протокол.</p>
          <p>Https-протокол влияет на ранжирование в Яндекс и Google. Изучите <a href="https://developers.google.com/search/docs/advanced/security/https" target="_blank">рекомендации Google</a> и <a href="https://yandex.ru/support/webmaster/yandex-indexing/https-migration.html" target="_blank">рекомендации Яндекс</a> по использованию https.</p>
          <p>На текущий момент <a href="https://radar.yandex.ru/https" target="_blank">по данным сервиса Яндекс.Радар</a> более 95% трафика в Рунете попадает на https-версию сайтов.</p>
        </BL.Title>

        <BL.Panel>
          <BL.Panel.Content className="pt-1">
            {!stats && <div className="text-center mt-3 pt-3 pb-3"><UI.Loader /></div>}

            {this.renderGraph()}
            {this.renderStats()}
            {this.renderSSL()}
            {this.renderVerdict()}
            {this.renderPagesTable()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ReportsHttpsTransition;
