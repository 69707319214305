import checkers from '../../utils/checkers';

import { VALIDATION } from '../../constants';

export default function getCheckers() {
  const validation = [].concat(this.props.checker || [], this.DEFAULT_CHECK || []);

  const required = 'required' in this.props;
  const equal = 'equalTo' in this.props;

  const custom = this.props.checker;

  const result = [];

  if (required || validation.includes(VALIDATION.REQUIRED)) {
    const type = VALIDATION.REQUIRED;
    const checker = checkers[type];

    result.push({ type, checker });
  }

  if (equal || validation.includes(VALIDATION.EQUAL)) {
    const type = VALIDATION.EQUAL;
    const checker = checkers[type];

    result.push({ type, checker });
  }

  if (validation.includes(VALIDATION.INVALID) && this.DATA_CHECKER) {
    const type = VALIDATION.INVALID;
    const checker = checkers[type](this.DATA_CHECKER);

    result.push({ type, checker });
  }

  if (custom) {
    const type = VALIDATION.CUSTOM;
    const checker = custom;

    result.push({ type, checker });
  }

  return result;
};
