import moment from 'moment';

import { capitalize } from '/utils/string';
import { createArray, deduplicate } from '/utils/array';

export default months => {
  let labels = months.map(month => {
    const date = moment(month.date.from);

    const number = +date.format('M');
    const year = +date.format('YYYY');

    return { number, year, date };
  });

  return labels.reduce((result, item, i) => {
    const capacity = item.date.daysInMonth();

    const date = capitalize(item.date.format('MMMM YYYY'));

    const box = result[result.length] = {
      label: date,
      items: createArray(3, i => {
        const from = i * 10 + 1;
        const to = i === 2 ? capacity : (i + 1) * 10;

        const label = date;

        const items = createArray(to - from + 1, i => ({
          label: (from + i).toString(),
          popupLabel: moment(item.date).add(from + i - 1, 'day').format('D MMMM YYYY (dd)'),
        }));

        return { label, items };
      }),
    };

    if (item.number === 1) {
      box.border = {
        left: { width: 5, color: '#4de5d5', dasharray: '0' },
      };
    }

    return result;
  }, []);
};
