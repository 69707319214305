import React, { Component } from 'react';
import cn from 'classnames';

import IconLine from '../IconLine';
import IconBar from '../IconBar';

class Legend extends Component {
  onClick = event => {
    event.preventDefault();

    const { name, type, onClick } = this.props;

    onClick && onClick(name, type);
  };

  renderIcon = () => {
    const { type, color, fill } = this.props;

    if (type === 'bars') return <IconBar className="mr-2" color={color} />;
    if (type === 'lines') return <IconLine className="mr-2" color={color} fill={fill} />;

    return null;
  };

  render() {
    const { name, label, color, type, exclude, onClick } = this.props;

    const state = !exclude.includes(name);

    return (
      <div className={cn('ui_line_chart-legend_item', !state && 'ui_line_chart-legend_item_disabled', !!onClick && 'cursor-pointer')} onClick={this.onClick}>
        {this.renderIcon()}
        <div className="ui_line_chart-legend_name">{label}</div>
      </div>
    );
  }
}

const Legends = props => {
  const { data, exclude, settings, onLegendClick } = props;

  if (settings.legend.hidden) {
    return null;
  }

  let onClick = {};

  if (settings.legend.clickable) {
    onClick.onClick = onLegendClick;
  }

  const { multiline } = settings.legend;

  const lines = (data.lines || []).map((item, idx) => <Legend key={'lines' + idx} idx={idx} { ...item } exclude={exclude.lines} type="lines" { ...onClick } />);
  const bars = (data.bars || []).map((item, idx) => <Legend key={'bars' + idx} idx={idx} { ...item } exclude={exclude.bars} type="bars" { ...onClick } />);

  return (
    <div className="ui_line_chart-legend">
      {multiline && <div className="ui_line_chart-legend_list">{lines}</div>}
      {multiline && <div className="ui_line_chart-legend_list">{bars}</div>}

      {!multiline && <div className="ui_line_chart-legend_list">{[...lines, ...bars]}</div>}
    </div>
  );
};

export default Legends;
