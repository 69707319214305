import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import ClearedText from './components/ClearedText';

import ResultWords from './components/ResultWords';
import ResultNGrams from './components/ResultNGrams';
import ResultForms from './components/ResultForms';

import Selections from './components/Selections';

import { ref } from '/utils/react';

import './styles.scss';

class ToolsLemmer extends Component {
  ref = ref;

  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
        words: 0,
        letters: 0
      },
      result: null,
      selections: [],
      nSize: null,
    };
  }

  onSelect = (...lemms) => {
    this.setState(({ selections }) => {
      const box = selections.slice();

      lemms.forEach(lemm => !box.includes(lemm) && box.push(lemm));

      if (box.length === selections.length) return;

      return { selections: box };
    });
  }

  onDeselect = (...lemms) => {
    this.setState(({ selections }) => {
      const box = selections.filter(item => !lemms.includes(item));

      if (box.length === selections.length) return;

      return { selections: box };
    });
  };

  onChangeSize = nSize => {
    this.setState({ nSize });
  };

  onStartTextChange = (name, text) => {
    const letters = text.replace(/\s/g, '').length;
    const words = text.split(' ').filter(v => !!v).length;

    const insert = { text, words, letters };

    this.setState({ insert });
  };

  onProcess = () => {
    const { lock, insert } = this.state;

    if (lock || !insert.words) return;

    this.setState({ lock: true });

    this.process();
  };

  async process() {
    const result = await this.provider.lemmatize(this.state.insert.text);

    this.setState({ result, lock: false });

    VIEW.VIEWPORT.scrollTo(this.ui.anchor);
    // window.scrollTo(0, document.body.clientHeight - VIEW.VIEWPORT.HEIGHT);
  }

  onViewportChange = () => this.forceUpdate();

  componentDidMount() {
    VIEW.on('viewport:change', this.onViewportChange);
  }

  componentWillUnmount() {
    VIEW.off('viewport:change');
  }

  renderInsertBox() {
    const { lang } = this.props;
    const { lock, insert, result } = this.state;

    const sets = { lang, lock, insert, result };

    sets.onStartTextChange = this.onStartTextChange;
    sets.onProcess = this.onProcess;

    return <InsertBox { ...sets } />;
  }

  renderClearedText() {
    const { lang } = this.props;
    const { result } = this.state;

    if (!result) return null;

    return <ClearedText lang={lang} result={result} />;
  }

  renderChildResult = idx => {
    const { lang } = this.props;
    const { result, selections, nSize } = this.state;
    const { onSelect, onDeselect, onChangeSize } = this;

    const tableHeight = VIEW.VIEWPORT.HEIGHT - 165;

    const sets = { className: "select-default mt-3", tableHeight, onSelect, onDeselect, onChangeSize, selections };

    if (idx === 0) return <ResultWords lang={lang} result={result} { ...sets } />;
    if (idx === 1) return <ResultNGrams lang={lang} result={result.ngrams} nSize={nSize} { ...sets } />
    if (idx === 2) return <ResultForms lang={lang} result={result} { ...sets } />

    return null;
  }

  renderToolbar = () => {
    const { lang, id } = this.props;
    const { result } = this.state;

    return (
      <Fragment>
        <span className="mr-2">{lang('export')}:</span>
        <UI.Button.Transparent onClick={() => this.provider.lemmerExport(result, lang)} className="btn-custom-excel" download />
      </Fragment>
    );
  };

  renderResult() {
    if (!this.state.result) return null;

    const { lang } = this.props;
    const { selections } = this.state;

    const tabs = [lang('tab_words'), lang('tab_phrases'), lang('tab_forms')];

    return (
      <BL.Panel className="mb-0">
        <BL.Panel.Content className="pt-0 pb-0">
          <div className="row">
            <div className="tools_lemmer-result col-8 pb-3">
              <BL.Tabs className="pl-3 pr-3 w-100" items={tabs} child={this.renderChildResult} toolbar={this.renderToolbar} />
            </div>
            <div className="col-4 pb-3 d-flex flex-column">
              <Selections lang={lang} list={selections} onRemove={this.onDeselect} />
            </div>
          </div>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title icon="fal fa-poll-h" name={lang('title')} text={lang('description')} />

        {this.renderInsertBox()}
        {this.renderClearedText()}

        <div ref={this.ref('anchor')} style={{ marginTop: '-10px', paddingBottom: '10px' }} />

        {this.renderResult()}
      </Fragment>
    );
  }
}


export default ToolsLemmer;
