import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

import { sorter } from '/utils/array';

const SiteType = props => {
  const { Form, type, types, project } = props;

  let values = (types || []).slice();

  values = values.sort(sorter('order', 'asc'));
  values = values.map(item => ({ ...item, value: item.id }));

  const sets = {
    name: 'site_type_id',
    value: Form.data.site_type_id || (project ? project.settings.siteTypeId : null),
    values: values,
    placeholder: 'Выберите тип сайта',
  };

  sets.label = (
    <Fragment>
      Тип сайта
      <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={420} boundingBox=".panel" passive={true}>
        Правильно указанный тип сайта поможем нам сформировать более точные рекомендации именно для сайта вашего типа.
      </BL.Info.Dark>
    </Fragment>
  );

  return <div className="mt-2"><Form.Select maxWidth={420} {...sets} required /></div>;
};

const Mirrors = props => {
  const { project, lock } = props;

  const sets = { className: 'ml-1', side: BL.Info.SIDE_TOP, width: 320, boundingBox: '.panel', passive: true };

  const items = [
    {
      name: (
        <Fragment>
          Главное зеркало
          <BL.Info.Dark { ...sets }>
            Зеркало сайта, которое будет использоваться как главное в остальных отчетах аудита. Однозначно определяется, если в Яндекс и Google одинаковое главное зеркало.
          </BL.Info.Dark>
        </Fragment>
      ),
      value: lock ? <UI.Loader /> : project.mirrors.main
    },
    {
      name: (
        <Fragment>
          Главное зеркало Google
          <BL.Info.Dark { ...sets }>
            Главное зеркало в поисковой системе Google, определенное нашим алгоритмом.
          </BL.Info.Dark>
        </Fragment>
      ),
      value: lock ? <UI.Loader /> : project.mirrors.google
    },
    {
      name: (
        <Fragment>
          Главное зеркало Yandex
          <BL.Info.Dark { ...sets }>
            Главное зеркало в поисковой системе Яндекс, определенное нашим алгоритмом.
          </BL.Info.Dark>
        </Fragment>
      ),
      value: lock ? <UI.Loader /> : project.mirrors.yandex
    },
  ];

  const title = (
    <div className="font-style-default" style={{ fontWeight: 500, color: '#3e3e3e' }}>
      <span>Зеркала</span>
      <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={420} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
        В SERP.tools мы автоматически определяем главное зеркало (основную версию сайта) для 4-х возможных версий вашего сайта: http и https, www и без www - для поисковых систем это разные сайты. Найти главное зеркало нужно для корректной работы многих отчетов в аудите. Если его у вас определило некорректно - напишите в техподдержку.
      </BL.Info.Dark>
    </div>
  );

  return <div className="mt-2"><UI.DetailList title={title} items={items} /></div>;
};

const Step_1 = props => {
  const { loading, project } = props;

  if (loading) return <div className="py-3 text-center"><UI.Loader /></div>;

  return (
    <Fragment>
      <h4 className="py-2 font-weight-semibold">Шаг 1: Настройки проекта</h4>

      <SiteType { ...props } />
      <Mirrors { ...props } />
    </Fragment>
  );
};

export default Step_1;
