import React, { Component, Fragment } from 'react';

import Title from '../Title';
import History from '../History';
import Input from '../Input';
import Closed from '../Closed';

import { UI } from '/views';

import findLastMessage from '../../utils/findLastMessage';

import {
  TYPE_META,
  PURPOSE_META_TITLE,
  SET_LAST_MESSAGE,
} from '../../../../constants';

class ChatMessanger extends Component {
  state = {
    editing: null,
  };

  onEdit = id => {
    if (id === SET_LAST_MESSAGE) {
      const { conversation, user } = this.props;

      const last = findLastMessage(conversation.history, user.id);

      if (!last) return;

      id = last.id;
    }

    this.setState({ editing: id });
  };

  shouldComponentUpdate(_props, _state) {
    const { props, state } = this;

    return props.conversation !== _props.conversation || state.editing !== _state.editing;
  }

  getEditingMessage() {
    const { editing } = this.state;
    const { conversation } = this.props;

    return editing && conversation.history.filter(item => item.id === editing).slice(-1)[0] || null;
  }

  renderInitialText() {
    const { selected, conversation } = this.props;

    if (selected) return null;

    return (
      <div className="support_chat-history_select_ticket">
        <i className="support_chat-history_select_ticket_icon fal fa-info-circle" /> Выберите тикет
      </div>
    );
  }

  renderLoader() {
    const { selected, conversation } = this.props;

    if (!selected || conversation.history) return null;

    return (
      <div className="support_chat-history_loader">
        <UI.Loader />
      </div>
    );
  }

  renderInput() {
    const { selected, conversation, sendMessage, toggleConversationState } = this.props;

    const editing = this.getEditingMessage();

    if (conversation.closed) {
      return <Closed id={selected} toggleConversationState={toggleConversationState} />;
    } else {
      return <Input id={selected} editing={editing} sendMessage={sendMessage} toggleConversationState={toggleConversationState} onEdit={this.onEdit} />;
    }
  }

  renderMessages() {
    const { selected, conversation, user, sendMessage, toggleConversationState } = this.props;

    if (!selected || !conversation.history) return null;

    const title = conversation.history.find(item => item.type === TYPE_META && item.purpose === PURPOSE_META_TITLE);

    const editing = this.getEditingMessage();

    return (
      <Fragment>
        <Title { ...title } />
        <div className="flex-wrap align-items-center flex-grow-1 position-relative bg-gray-50">
          <div className="position-absolute pos-top pos-bottom w-100 overflow-hidden">
            <div className="d-flex h-100 flex-column">
              <div className="msgr d-flex h-100 flex-column bg-white">
                <History id={selected} editing={editing} items={conversation.history} user={user} sendMessage={sendMessage} onEdit={this.onEdit} />
                {this.renderInput()}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="support_chat-reader">
        {this.renderInitialText()}
        {this.renderLoader()}
        {this.renderMessages()}
      </div>
    );
  }
}

export default ChatMessanger;
