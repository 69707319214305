const self = {};

self.signin = function signin(mail, password) {
  const url = '/api/v1/users/signin';
  const method = 'post';

  const data = { mail, password };

  return this.axios({ url, method, data });
};

self.signup = function signup(lang, name, mail, password) {
  const url = '/api/v1/users/';
  const method = 'post';

  const data = { lang, name, mail, password };

  return this.axios({ url, method, data });
};

self.refresh = function refresh() {
  const url = '/api/v1/users/refresh';
  const method = 'get';

  return this.axios({ url, method });
};

self.checkToken = function checkToken(id, type, token) {
  const url = `/api/v1/users/tokens/check?id=${id}&type=${type}&token=${token}`;
  const method = 'get';

  return this.axios({ url, method });
};

self.requestRestore = function requestRestore(lang, mail) {
  const url = '/api/v1/users/restore/';
  const method = 'put';

  const data = { lang, mail };

  return this.axios({ url, method, data });
};

self.restore = function approve(id, token, password) {
  const url = '/api/v1/users/restore/';
  const method = 'post';

  const data = { id, token, password };

  return this.axios({ url, method, data });
};

self.approve = function approve(id, token) {
  const url = '/api/v1/users/approve/';
  const method = 'post';

  const data = { id, token };

  return this.axios({ url, method, data });
};

export default self;
