/* eslint-disable */
import { isFunction } from '../types';

let mixin = {};

mixin.on = function(event, callback) {
  this._events = this._events || {};

  if (typeof event === 'string') {
    return (this._events[event] = this._events[event] || []).push(callback);
  }

  for (let name in event) {
    event.hasOwnProperty(name) && this.on(name, event[name]);
  }
};

mixin.off = function(name, callback) {
  if (!arguments.length) return delete this._events;

  if (!this._events || !this._events[name]) return;

  if (arguments.length === 1) return delete this._events[name];

  const idx = this._events[name].indexOf(callback);

  idx !== -1 && this._events[name].splice(idx, 1);
};

mixin.trigger = function(name) {
  if (!this._events || !this._events[name]) return;

  const params = Array.prototype.slice.call(arguments, 1);

  const events = this._events[name].slice();

  for (let i = 0, len = events.length; i < len; i++) {
    events[i].apply(this, params);
  }
};

export default function emitter(income, onIncome = false) {
  const target = !onIncome && isFunction(income) ? income.prototype : income;

  for (let name in mixin) mixin.hasOwnProperty(name) && (target[name] = mixin[name]);

  return income;
};
