import React, { Component } from 'react';

import UI from '/views/ui';

class Accordion extends Component {
  state = {
    active: null
  };

  onToggle = idx => {
    this.setState(state => {
      const active = state.active !== idx ? idx : null;

      return { active };
    });
  };

  renderItem = (item, key) => {
    const sets = { key, idx: key, ...item };

    sets.isOuterControl = true;
    sets.onToggle = this.onToggle;

    sets.expand = this.state.active === key;

    return <UI.Collapse { ...sets } />;
  };

  render() {
    const { items } = this.props;

    return (
      <div className="accordion accordion-clean">
        {items.map(this.renderItem)}
      </div>
    );
  }
}

export default Accordion;
