const self = {};

self.get = function get(audit_id, id) {
  const url = '/api/v1/audits/' + audit_id + '/reports/' + id;
  const method = 'get';

  return this.axios({ url, method });
};

self.getUpdates = function getUpdates() {
  const url = '/api/v1/searcher-updates/list';
  const method = 'get';

  return this.axios({ url, method });
};

self.addUpdate = function addUpdate(data) {
  const url = '/api/v1/searcher-updates/';
  const method = 'post';

  return this.axios({ url, method, data });
};

self.updateUpdate = function updateUpdate(id, data) {
  const url = '/api/v1/searcher-updates/' + id;
  const method = 'put';

  return this.axios({ url, method, data });
};

self.deleteUpdate = function deleteUpdate(id) {
  const url = '/api/v1/searcher-updates/' + id;
  const method = 'delete';

  return this.axios({ url, method });
};

export default self;
