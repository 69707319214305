import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { isFunction } from '/utils/types';

class TableRow extends Component {
  state = {
    expand: false,
  };

  onExpand = event => {
    const { tagName, classList } = event.target;

    if (['A'].includes(tagName) || classList.contains('with-action')) return;

    const { child } = this.props;

    if (!child) return;

    event.preventDefault();

    this.setState(state => ({ expand: !state.expand }));
  };

  renderCell = (cell, key) => {
    const { item, data, getter, actions, decorator, settings } = this.props;

    const sets = { key, style: cell.style };

    const className = decorator && decorator(cell.field, item, settings, data);

    if (className) sets.className = className;

    return <td { ...sets }>{getter(cell.field, item, actions, settings, data)}</td>;
  };

  renderRow = () => {
    const { expand } = this.state;
    const { columns, className, child, item, data, settings } = this.props;

    const sets = { className: cn(className, expand && 'parent') };

    if (child) {
      sets.role = 'row';

      if (!child.check || child.check(item, data, settings)) {
        sets.onClick = this.onExpand;
      } else {
        sets.className = cn(sets.className, 'childless');
      }
    }

    return (
      <tr { ...sets }>
        {columns.map(this.renderCell)}
      </tr>
    );
  };

  renderChild = () => {
    const { child, item, actions, settings, columns, data } = this.props;

    if (isFunction(child.check) && !child.check(item, data, settings)) {
      return null;
    }

    return (
      <tr className="child">
        <td className="child" colSpan={columns.length}>
          <div className="dtr-details col-12">
            {child(item, actions, settings)}
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { expand } = this.state;

    return (
      <Fragment>
        {this.renderRow()}
        {expand && this.renderChild()}
      </Fragment>
    );
  }
}

export default TableRow;
