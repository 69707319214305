import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import global from '/utils/global';

import createInitial, { getInitial } from './initials';
import createReducer, { addReducer } from './reducers';
import createSaga, { addSaga } from './sagas';

export { getInitial as getInitial};
export { addReducer as addReducer};
export { addSaga as addSaga};

const devtool = global.__REDUX_DEVTOOLS_EXTENSION__;
const reduxDevTools = (devtool && devtool()) || compose;

export default function () {
  const initialState = createInitial();
  const rootReducer = createReducer();
  const rootSaga = createSaga();

  const sagaMiddleware = createSagaMiddleware();

  const middlewares = compose(applyMiddleware(sagaMiddleware), reduxDevTools);

  const store = createStore(rootReducer, middlewares);

  sagaMiddleware.run(rootSaga);

  return store;
};
