import { combineReducers } from 'redux';

import { isObject } from '/utils/types';

let MAIN_REDUCER = null;
const LIST = {};

export const addReducer = function addReducer(name, value) {
  if (isObject(name)) {
    Object.assign(LIST, name);
  }
  else {
    LIST[name] = value;
  }
};

export default function createReducer() {
  return MAIN_REDUCER = MAIN_REDUCER || combineReducers(LIST);
};
