import { DEFAULT, SUPER_ADMIN, SUPPORT, USER, SUPER_USER } from '/static/data/roles';

const self = {};

self[DEFAULT] = {
  available: false,
  AllTrafficResult: {
    available: false,
  },
  HttpsTransition: {
    available: false,
  },
  LostTrafficResult: {
    available: false,
  },
  MainMirror: {
    available: false,
  },
  MetrikaDomains: {
    available: false,
  },
  MissingInSitemap: {
    available: false,
  },
  SearchAlgoUpdate: {
    available: false,
  },
  SearchKeywords: {
    available: false,
  },
  Sitemaps: {
    available: false,
  },
  YandexMetrikaQueriesResult: {
    available: false,
  },
  ZeroTraffic: {
    available: false,
  },
};

self[SUPER_ADMIN] = self[USER] = self[SUPER_USER] = {
  available: true,
  AllTrafficResult: {
    available: true,
  },
  HttpsTransition: {
    available: true,
  },
  LostTrafficResult: {
    available: true,
  },
  MainMirror: {
    available: true,
  },
  MetrikaDomains: {
    available: true,
  },
  MissingInSitemap: {
    available: true,
  },
  SearchAlgoUpdate: {
    available: false,
  },
  SearchKeywords: {
    available: false,
  },
  Sitemaps: {
    available: true,
  },
  YandexMetrikaQueriesResult: {
    available: true,
  },
  ZeroTraffic: {
    available: true,
  },
  SearchAlgoUpdate: {
    available: true,
  },
};

export default self;
