import React, { Fragment } from 'react';

import { Item as _Item, Block as _Block } from './Block';

import CONST from '../../constants';

const TYPE_CORRECT = 'correct';
const TYPE_INCORRECT = 'incorrect';

class Item extends _Item {
  getHeadData() {
    const { lang, item } = this.props;

    const name = item.year === 'unknown' ? lang('years_year_unknown') : item.year;
    const value = item.count;

    return { name, value, infoTextActive: false };
  }
}

class Block extends _Block {
  renderItems() {
    const { lang } = this.props;
    const { years } = this.props.result.stats;

    return years.map(item => <Item key={item.year} lang={lang} item={item} />);
  }
}

export default Block;
