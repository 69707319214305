import React from 'react';

import { BL } from '/views';

const ModalProjectsUpdateError = props => {
  const sets = {
    title: 'Ошибка обновления проектов',
    icon: 'fal fa-times-circle',
  };

  return (
    <BL.Modal.Alert.Danger { ...sets }>
      <p>Не удалось обновить список проектов.</p>
      <p>Проверьте подключение к интернету и повторите ещё раз.</p>
    </BL.Modal.Alert.Danger>
  );
};

export default ModalProjectsUpdateError;
