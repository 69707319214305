export const TYPE_META = 'meta';
export const TYPE_MESSAGE = 'msg';
export const TYPE_DATE = 'date';

export const PURPOSE_META_TITLE = 'title';

export const PURPOSE_MESSAGE_ADD = 'add';
export const PURPOSE_MESSAGE_UPDATE = 'update';
export const PURPOSE_MESSAGE_REMOVE = 'remove';

export const PURPOSE_MESSAGE_PLACEHOLDER_ADD = 'placeholder_add';
export const PURPOSE_MESSAGE_PLACEHOLDER_UPDATE = 'placeholder_update';
export const PURPOSE_MESSAGE_PLACEHOLDER_REMOVE = 'placeholder_remove';

export const PURPOSE_DATE_CHANGE = 'change';

export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE';
