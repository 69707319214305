import { createActions } from 'redux-actions';

export const {
  addAudit,
  addAudits,
  setCurrentAudit,
  createAudit,
  deleteAudit,
  removeAudit,
  clearAudits,
  setAuditReports,
  updateRunningAuditStates,
} = createActions(
  {
    ADD_AUDIT: (id, projectId, percent, ready, dateAdd, dateStart, dateFinish, reports) => ({ id, projectId, percent, ready, dateAdd, dateStart, dateFinish, reports }),
    ADD_AUDITS: list => ({ list }),
    SET_CURRENT_AUDIT: id => ({ id: +id }),
    CREATE_AUDIT: (projectId, defer) => ({ projectId, defer }),
    DELETE_AUDIT: (id, defer) => ({ id, defer }),
    REMOVE_AUDIT: id => ({ id }),
    CLEAR_AUDITS: () => ({}),
    SET_AUDIT_REPORTS: (id, reports) => ({ id, reports }),
    UPDATE_RUNNING_AUDIT_STATES: items => ({ items }),
  }
);
