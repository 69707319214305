import { packComponent } from '/services/Components/methods';

import actions from './actions';
import access from './access';

const settings = {
  name: __FOLDER__,
  icon: 'home',

  menu: 'Admin',
  priority: 1,

  rootAction: 'Reports',

  title: () => 'Admin',
  breadcrumb: () => 'Admin',
};

export default packComponent({ settings, actions, access });
