import React, { Component } from 'react';
import cn from 'classnames';

import { getPicker } from '/utils/object';
import { deferred } from '/utils/common';

import { UN, VIEW } from '/views';

class Signup extends Component {
  state = {
    error: null,
    lock: false
  };

  Form = UN.Form();

  onSubmit = async values => {
    if (this.state.lock) return;

    this.setState({ lock: true });

    const { signup } = this.props;
    const { name, mail, password, remember } = values;

    const defer = deferred();

    defer.then(() => VIEW.MODAL.hide());
    defer.catch(() => this.setState({ lock: false, error: 'Не удалось создать аккаунт' }));

    signup(name, mail, password, remember, defer);
  };

  render() {
    const { error, lock } = this.state;

    const { Form } = this;

    return (
      <Form className="d-flex flex-column justify-content-between col-12" onSubmit={this.onSubmit}>
        <div>
          <div className="form-group input-group-sm mb-2">
            <Form.Text className="w-65" label="Имя" name="name" required />
          </div>
          <div className="form-group input-group-sm mb-2">
            <Form.Email className="w-65" label="Адрес электронной почта" name="mail" required />
          </div>
          <div className="form-group input-group-sm mb-3">
            <Form.Password className="w-65" label="Пароль" name="password" equalTo="password-repeat" required />
            <Form.Password className="w-65" name="password-repeat" equalTo="password" required />
          </div>
          <div className="custom-control custom-checkbox">
            <Form.Checkbox name="remember" label="Запомнить на этом устройстве" />
          </div>
        </div>
        <div>
          <div className={cn('h5 mb-2 select-none', !error && 'invisible')}>
            <small className="mb-0 color-danger-600">{error}.</small>
          </div>
          <div>
            <Form.Submit size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} text="Создать аккаунт" />
          </div>
        </div>
      </Form>
    );
  }
};

export default Signup;
