import React, { Component, Fragment } from 'react';

import Sidebar from './components/Sidebar';
import Messanger from './components/Messanger';

import ModalAddTicket from './components/ModalAddTicket';

import { UI, BL, VIEW } from '/views';

import './styles.scss';

class Chat extends Component {
  state = {
    selected: null,
  };

  onTicketAdd = () => {
    const { createConversation } = this.props;

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();
      const onSubmit = () => VIEW.MODAL.hide();

      return <ModalAddTicket onCancel={onCancel} onSubmit={onSubmit} createConversation={createConversation} />;
    });
  };

  onTicketSelect = id => {
    const { selected } = this.state;
    const { conversations, setCurrentConversation } = this.props;

    if (selected === id) return;

    this.setState({ selected: id });

    setCurrentConversation(id);
  };

  render() {
    const { conversations, user, sendConversationMessage, toggleConversationState } = this.props;
    const { selected } = this.state;

    return (
      <Fragment>
        <BL.Title icon="fal fa-user-headset" name="Служба поддержки" />

        <div className="support_chat-root border-faded shadow-4">
          <Sidebar selected={selected} conversations={conversations} onTicketAdd={this.onTicketAdd} onTicketSelect={this.onTicketSelect} />
          <Messanger selected={selected} conversation={conversations[selected]} user={user} sendMessage={sendConversationMessage} toggleConversationState={toggleConversationState} />
        </div>
      </Fragment>
    );
  }
}

export default Chat;
