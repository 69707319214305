import Provider from '/services/Provider';

class ReportsProvider extends Provider {
  getReportSettings(audit_id, id) {
    return this.api.reports.getSettings(audit_id, id);
  }

  updateReportSettings(audit_id, id, data) {
    return this.api.reports.updateSettings(audit_id, id, data);
  }

  getAllTrafficResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'all.json.gzip');
  }

  getLostTrafficResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'bad.json.gzip');
  }

  getZeroTrafficResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'zero.json.gzip');
  }

  getMissingInSitemapResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'missing.json.gzip');
  }

  getYandexQueriesResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getSearchAlgoUpdateResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getSearchKeywordsResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getHttpsTransitionResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getMainMirrorResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getMetrikaDomainsResult(audit_id, id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getSitemapsResult(audit_id, id, user_id) {
    return this.api.reports.getResult(audit_id, id, 'report.json.gzip');
  }

  getZeroTrafficExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'zero.xlsx', 'zero_traffic_pages');
  }

  getMissingInSitemapExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'missing.xlsx', 'not_xmlsitemap_pages');
  }

  getLostTrafficExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'bad.xlsx', 'lost_traffic');
  }

  getAllTrafficExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'all.xlsx', 'all_traffic');
  }

  getSitemapExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'export.xlsx', 'sitemap');
  }

  getYandexQueriesExcelLink(audit_id, id, user_id) {
    return this.api.reports.downloadLink(audit_id, id, user_id, 'export.xlsx', 'yandex_queries');
  }
}

export default ReportsProvider;
