export default [
  {
    name: 'Аккаунт',
    field: 'accaunt',
    sortable: true,
    sortFirst: 'asc',
  },
  {
    name: 'Дата добавления',
    field: 'date_add',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '17%', minWidth: '160px', textAlign: 'center' },
  },
  {
    name: 'Дата окончания',
    field: 'date_expire',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '17%', minWidth: '160px', textAlign: 'center' },
  },
  {
    name: 'Статус',
    field: 'state',
    sortable: false,
    style: { width: '9%', minWidth: '160px', textAlign: 'center' },
  },
  {
    name: 'Действия',
    field: 'actions',
    sortable: false,
    style: { width: '23%', minWidth: '230px', textAlign: 'center' },
  },
];
