import { select, call, put, take, takeEvery, throttle, delay } from 'redux-saga/effects';

import { STATUS_LOADING, STATUS_READY, STATUS_ERROR_FETCH } from './constants';

import {
  getUpdates,
  reloadUpdates,
  setUpdates,
  addUpdate,
  updateUpdate,
  deleteUpdate,
  removeUpdate,
  updateStatus,
} from './actions';

import { link } from '/services/Components/methods';

import Provider from '../provider';

function* _reloadUpdates({ payload }) {
  const { searcherUpdates } = yield select();

  if (searcherUpdates.state !== STATUS_READY) {
    yield put(getUpdates());
  }
}

function* _getUpdates({ payload }) {
  const { user } = yield select();

  const provider = new Provider(user.token);

  yield put(updateStatus(STATUS_LOADING));

  try {
    const { list } = yield provider.getUpdates();

    yield put(setUpdates(list));
    yield put(updateStatus(STATUS_READY));
  } catch(e) {
    yield put(updateStatus(STATUS_ERROR_FETCH));
  }
}

function* _addUpdate({ payload }) {
  const { data, defer } = payload;
  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { id } = yield provider.addUpdate(data);

    const list = [{ id, ...data }];

    yield put(setUpdates(list));

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

function* _updateUpdate({ payload }) {
  const { id, data, defer } = payload;
  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    yield provider.updateUpdate(id, data);

    const list = [{ id, ...data }];

    yield put(setUpdates(list));

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

function* _deleteUpdate({ payload }) {
  const { id, defer } = payload;
  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    yield provider.deleteUpdate(id);

    yield put(removeUpdate(id));

    defer.resolve();
  } catch (e) {
    defer.reject(e);
  }
}

export default function* auditSage() {
  yield takeEvery(getUpdates, _getUpdates);
  yield takeEvery(reloadUpdates, _reloadUpdates);
  yield takeEvery(addUpdate, _addUpdate);
  yield takeEvery(updateUpdate, _updateUpdate);
  yield takeEvery(deleteUpdate, _deleteUpdate);
}
