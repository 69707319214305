const self  = {};

self.head_title = 'Bulk URL HTTP Status Code &amp; Redirect Checker Tool | SERP.tools';
self.head_keywords = '';
self.head_description = 'Easily check status codes HTTP response headers, redirect chains and loops in bulk online. Try our free HTTPS status code checker!';

self.name = 'Bulk URL HTTP Status Code';

self.title = 'Bulk URL HTTP Status Code & Redirect Checker Tool';
self.description = 'Check status codes, response headers and redirect chains. Click Export to download results.';

self.field_links = 'URLs (one per line)';
self.field_userAgent_currentBrowser = 'Your Browser';
self.field_userAgent_empty = 'Blank / Empty';
self.field_userAgent_searchers = 'Search Engine Bots';
self.field_userAgent_desktopBrowsers = 'Desktop Browsers';
self.field_userAgent_mobileBrowsers = 'Mobile Browsers';

self.count_links = 'URLs count';

self.button_check = 'Check Status';

self.title_result = 'Result';
self.filter_code = 'Status code filter';

self.count_redirects = 'Redirects';

self.url = 'URL';
self.link = 'URL';
self.statusCode = 'Status code';
self.redirectsChain = 'Redirect chain';
self.result = 'Results';
self.export = 'Export';

export default self;
