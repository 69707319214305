import { PADDING } from '../../constants';

const matchRow = (idx, from, to, _area, offset, settings) => {
  const boundary = {};

  boundary.top = _area.y[0];
  boundary.left = _area.x[0];
  boundary.right = _area.x[1];
  boundary.bottom = _area.y[1];

  const area = {};

  area.x = [boundary.left, boundary.right];
  area.y = [boundary.top, boundary.bottom];

  area.width = area.x[1] - area.x[0];
  area.height = area.y[1] - area.y[0];

  const y_from = Math.round(boundary.bottom - area.height / _area.capacity * idx);
  const y_to = Math.round(boundary.bottom - area.height / _area.capacity * (idx + 1));

  const border = {
    top: {
      line: [
        { x: boundary.left, y: y_from },
        { x: boundary.right, y: y_from },
      ],
      label: {
        name: from,
        top: y_from,
        left: 0,
        width: offset.left,
      },
      isHighlight: !from,
      color: settings.axis[from ? 'y' : 'y0'].color,
      width: settings.axis[from ? 'y' : 'y0'].width,
      dasharray: settings.axis[from ? 'y' : 'y0'].dasharray,
    },
    bottom: {
      line: [
        { x: boundary.left, y: y_to },
        { x: boundary.right, y: y_to },
      ],
      label: {
        name: to,
        top: y_to,
        left: 0,
        width: offset.left,
      },
      isHighlight: !to,
      color: settings.axis[to ? 'y' : 'y0'].color,
      width: settings.axis[to ? 'y' : 'y0'].width,
      dasharray: settings.axis[to ? 'y' : 'y0'].dasharray,
    },
  };

  return { boundary, area, border };
};

const matchRows = (area, offset, range, settings) => {
  const { min, max, step } = range;

  const rows = [];

  for (let i = 0, len = (max - min) / step; i < len; i += 1) {
    const from = min + i * step;
    const to = min + (i + 1) * step;

    rows[rows.length] = matchRow(i, from, to, area, offset, settings);
  }

  return rows;
};

export default function match(canvas, offset, range, settings) {
  const area = {
    x: [offset.left + PADDING.LEFT, canvas.width - offset.right - PADDING.RIGHT],
    y: [offset.top + PADDING.TOP, canvas.height - offset.bottom - PADDING.BOTTOM],
    width: canvas.width - (offset.left + offset.right) - (PADDING.LEFT + PADDING.RIGHT),
    height: canvas.height - (offset.top + offset.bottom) - (PADDING.TOP + PADDING.BOTTOM),
    capacity: (range.max - range.min) / range.step,
  };

  return matchRows(area, offset, range, settings);
};
