import moment from 'moment';

import { createArray } from '/utils/array';

const DAY = 24 * 60 * 60 * 1000;
const UNIX_WEEK_OFFSET = 4;

const getDates = week => {
  const date = (week * 7 + UNIX_WEEK_OFFSET) * DAY;

  const from = moment(date).utc(0).format('YYYY-MM-DD');
  const to = moment(date).endOf('week').utc(0).format('YYYY-MM-DD');

  return { from, to };
};

const createWeekData = week => {
  const date = getDates(week);

  const days = createArray(7, 0);
  const hours = createArray(24, 0);

  return { date, days, hours };
};

export default function normalizeData(income) {
  const offset = Math.round(new Date().getTimezoneOffset() / 60);

  const { date, weeks } = income;

  const result = [];

  for (let week = date.from.week; week <= date.to.week; week++) {
    const item = weeks[week] || createWeekData(week);

    item.hours = [...item.hours.slice(offset), ...item.hours.slice(0, offset)];

    result[result.length] = item;
  }

  return result;
};
