import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="forms_col_words" />,
    field: 'lemma',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '30%', maxWidth: '30%', minWidth: '30%', textAlign: 'left' },
  },
  {
    name: () => <Lang text="forms_col_count" />,
    field: 'count',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '60px', maxWidth: '60px', minWidth: '60px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="forms_col_forms" />,
    field: 'forms',
    sortable: false,
    style: { textAlign: 'left' },
  },
];
