import Footer from './Footer';
import LanguagesMenu from './LanguagesMenu';
import Logo from './Logo';
import ToolsMenu from './ToolsMenu';

export default {
  Footer,
  LanguagesMenu,
  Logo,
  ToolsMenu,
};
