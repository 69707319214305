import React, { Component, Fragment } from 'react';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';
import { waitForRender } from '/utils/common';

const collectChartData = items => {
  const labels = items.map(item => item.name).map(label => ({ label }));

  const data = {
    name: 'queries',
    label: 'Запросы',
    color: color.primary._500,
    fill: color.primary._300,
    values: items.map(item => item.value),
  };

  const bars = [data];

  return { labels, bars };
};

class ChartMain extends Component {
  renderGraph = () => {
    const { items } = this.props;

    const data = collectChartData(items);

    return <UI.LineChart data={data} settings={{}} />;
  };

  render() {
    return (
      <Fragment>
        <h4 className="mt-3">Посуточные данные</h4>

        {this.renderGraph()}
      </Fragment>
    );
  }
}

export default ChartMain;
