import { handleActions } from 'redux-actions';

import { deduplicate, sorter } from '/utils/array';

import {
  dropConversations,
  appendConversations,
  updateConversations,
  setCurrentConversation,
  appendConversationMessages,
  finalizeConversationMessage,
 } from './actions';

import initial from './initial';

export default handleActions({
    [dropConversations]: _dropConversations,
    [appendConversations]: _appendConversations,
    [updateConversations]: _updateConversations,
    [setCurrentConversation]: _setCurrentConversation,
    [appendConversationMessages]: _appendConversationMessages,
    [finalizeConversationMessage]: _finalizeConversationMessage,
}, initial());

function _dropConversations() {
  return initial();
}

function _appendConversations(state, { payload }) {
  const { items: newest } = payload;

  const items = { ...state.items };

  for (let item, i = 0; item = newest[i]; i++) {
    items[item.id] = {
      ...item,
      history: null,
      last_id: null,
      last_timestamp: null,
    };
  }

  return { ...state, items };
}

function _updateConversations(state, { payload }) {
  const { updates } = payload;

  const items = { ...state.items };

  for (let item, i = 0; item = updates[i]; i++) {
    items[item.id] = { ...items[item.id], ...item };
  }

  return { ...state, items };
}

function _setCurrentConversation(state, { payload }) {
  const { id } = payload;

  console.log('SET CURRENT ' + id);

  return { ...state, current: id };
}

const historySorter = sorter('timestamp', 'asc');

function _appendConversationMessages(state, { payload }) {
  const { id, messages, remember } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id], updates: 0, unreaded: 0 };
  items[id].history = [].concat(items[id].history || [], messages);

  items[id].history = deduplicate(items[id].history, (a, b) => a.id === b.id && a.timestamp === b.timestamp);

  items[id].history.sort(historySorter);

  const last = messages[messages.length - 1];

  if (last && remember) {
    items[id].last_id = last.id;
    items[id].last_timestamp = last.timestamp;
  }

  return { ...state, items };
}

function _finalizeConversationMessage(state, { payload }) {
  const { id, message, purpose } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id] };
  items[id].history = [].concat(items[id].history || []);

  const idx = items[id].history.findIndex(item => item.id === message.id && item.purpose === purpose);

  if (idx !== -1) {
    items[id].history[idx] = message;
  }

  return { ...state, items };
}
