import React from 'react';
import cn from 'classnames';

const Medal = props => {
  const {
    stl = 'primary',
    icon,
    iconStl = 'white',
    bg = 'base base-7',
    bg2 = 'base base-7',
    size = 'lg'
  } = props;

  return (
    <div className={cn('icon-stack select-none', 'icon-stack-' + size, props.className)}>
      <i className={cn(bg, 'icon-stack-3x opacity-100', 'color-' + stl + '-500')} />
      <i className={cn(bg2, 'icon-stack-2x opacity-100', 'color-' + stl + '-300')} />
      <i className={cn(icon, 'icon-stack-1x opacity-100', 'color-' + iconStl)} />
    </div>
  );
};

export default Medal;
