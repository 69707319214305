import React, { Fragment } from 'react';
import moment from 'moment';

import { SEARCER_TYPE, UPDATE_TYPE, UPDATE_NAME } from '/components/SearcherUpdates/constants';

const fields = {};

fields.description = item => {
  return (
    <Fragment>

      <p><b>{item.name_ru}</b></p>
      {item.searcher === SEARCER_TYPE.GOOGLE && <p><b>Тип:</b> {UPDATE_NAME[item.update_type]}</p>}
      <p className="mb-0">{item.comment_ru}</p>
    </Fragment>
  );
};

fields.date_start = item => moment(item.date_start).format('YYYY-MM-DD');

fields.date_finish = item => moment(item.date_finish).format('YYYY-MM-DD');

export default (name, item, actions, settings) => fields[name](item, actions, settings);
