import React, { Component } from 'react';
import cn from 'classnames';

import UI from '/views/ui';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0
    };
  }

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._active !== _props.active) {
      result._active = _props.active;
      result.active = _props.active;
    }

    return result;
  }

  onChange = active => this.setState({ active });

  renderContent() {
    const { child } = this.props;
    const { active } = this.state;

    return <div className="col-12">{child(active)}</div>;
  }

  renderTabs() {
    const { items, toolbar, sticky, style, className } = this.props;
    const { active } = this.state;

    if (items.length < 2) return null;

    const sets = { items, active, style, onChange: this.onChange };

    let styles = sticky ? { top: 0, zIndex: 1 } : {};

    return (
      <div className={cn('col-12', className, sticky && 'position-sticky')} style={styles}>
        <div className="nav-tabs-wrapper">
          <UI.Tabs { ...sets } />
          {toolbar && <div className="nav-toolbar">{toolbar()}</div>}
        </div>
      </div>
    );
  }

  render() {
    const { sticky } = this.props;

    return (
      <div className={cn('row select-none', sticky && 'position-relative')}>
        {this.renderTabs()}
        {this.renderContent()}
      </div>
    );
  }
}

export default Tabs;
