import React, { Component } from 'react';

import Hero from './components/Hero';
import Subscribe from './components/Subscribe';

class HomeRoot extends Component {
  render() {
    const { lang } = this.props;

    return (
      <div className="home-page">
        <Hero lang={lang} />
        <Subscribe lang={lang} />
      </div>
    );
  }
}

export default HomeRoot;
