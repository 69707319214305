import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import DarkPrompt from '../DarkPrompt';
import BlockYears from '../StatsBlocks/BlockYears';

import color from '/utils/color';
import { capitalize } from '/utils/string';
import { waitForRender } from '/utils/common';

const collectChartData = (lang, years) => {
  years = years.slice().reverse();

  const labels = years.map(item => item.year).map(label => ({ label }));

  const data = {
    name: 'pages',
    label: lang('years_chart_pages'),
    color: color.info._500,
    fill: color.info._300,
    values: years.map(item => item.count),
  };

  const bars = [data];

  return { labels, bars };
};

class YearStats extends Component {
  renderGraph = () => {
    const { lang } = this.props;
    const { years } = this.props.result.stats;

    if (years.length <= 1) return null;

    const data = collectChartData(lang, years);

    return (
      <div className="d-flex justify-content-center mt-3">
        <div style={{ width: '100%', maxWidth: years.length * 80, minWidth: 240 }}>
          <UI.LineChart data={data} height={150} settings={{}} />
        </div>
      </div>
    );
  };

  renderAlert() {
    const { lang } = this.props;
    const { years } = this.props.result.stats;

    const now = new Date().getFullYear();

    const outdated = years.filter(item => +item.year && +item.year < now - 2);

    if (!outdated.length) return null;

    const pages = outdated.reduce((result, item) => result + item.count, 0);
    const total = years.reduce((result, item) => result + item.count, 0);
    const percent = (100 * pages / total).toFixed(1);

    return (
      <BL.Alert.Warning className="mt-3">
        <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
        <BL.Alert.Content>
          {lang('years_alert', { pages, percent }, { useParser: true })}
        </BL.Alert.Content>
      </BL.Alert.Warning>
    );
  }

  render() {
    const { lang, result } = this.props;

    const { years } = result.stats;

    return (
      <Fragment>
        <h5 className="tools_sitemapper-stats-title">
          {lang('years_title')}
          <DarkPrompt>
            {lang('years_title', {}, { useParser: true })}
          </DarkPrompt>
        </h5>

        <BlockYears lang={lang} result={result} />

        {this.renderGraph()}
        {this.renderAlert()}
      </Fragment>
    );
  }
}

export default YearStats;
