export { default as gradient } from './gradient';

const getColor = selector => {
  if (__SERVER__) return null;

  if (__CLIENT__) {
    const query = '#js-color-profile ' + selector;

    const element = document.querySelector(query);
    const styles = window.getComputedStyle(element);

    return styles.getPropertyValue('color');
  }
};

const color = {};

const REG_RGB = /^rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)$/i;
const REG_HEX = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;

color.rgba = (color, alpha) => {
  color = color.trim();

  if (REG_RGB.test(color)) {
    return color.replace(')', ',' + alpha + ')');
  }

  if (REG_HEX.test(color)) {
    color = color.slice(1);

    let r, g, b;

    if (color.length === 3) {
      r = color[0] + color[0];
      g = color[1] + color[1];
      b = color[2] + color[2];
    }

    if (color.length === 6) {
      r = color.slice(0, 2);
      g = color.slice(2, 4);
      b = color.slice(4);
    }

    return `rgba(${[r, g, b, alpha].join(',')})`;
  }

  return color;
};

color.primary = {};

color.primary._50 = getColor('.color-primary-50');
color.primary._100 = getColor('.color-primary-100');
color.primary._200 = getColor('.color-primary-200');
color.primary._300 = getColor('.color-primary-300');
color.primary._400 = getColor('.color-primary-400');
color.primary._500 = getColor('.color-primary-500');
color.primary._600 = getColor('.color-primary-600');
color.primary._700 = getColor('.color-primary-700');
color.primary._800 = getColor('.color-primary-800');
color.primary._900 = getColor('.color-primary-900');

color.info = {};

color.info._50 = getColor('.color-info-50');
color.info._100 = getColor('.color-info-100');
color.info._200 = getColor('.color-info-200');
color.info._300 = getColor('.color-info-300');
color.info._400 = getColor('.color-info-400');
color.info._500 = getColor('.color-info-500');
color.info._600 = getColor('.color-info-600');
color.info._700 = getColor('.color-info-700');
color.info._800 = getColor('.color-info-800');
color.info._900 = getColor('.color-info-900');

color.danger = {};

color.danger._50 = getColor('.color-danger-50');
color.danger._100 = getColor('.color-danger-100');
color.danger._200 = getColor('.color-danger-200');
color.danger._300 = getColor('.color-danger-300');
color.danger._400 = getColor('.color-danger-400');
color.danger._500 = getColor('.color-danger-500');
color.danger._600 = getColor('.color-danger-600');
color.danger._700 = getColor('.color-danger-700');
color.danger._800 = getColor('.color-danger-800');
color.danger._900 = getColor('.color-danger-900');

color.warning = {};

color.warning._50 = getColor('.color-warning-50');
color.warning._100 = getColor('.color-warning-100');
color.warning._200 = getColor('.color-warning-200');
color.warning._300 = getColor('.color-warning-300');
color.warning._400 = getColor('.color-warning-400');
color.warning._500 = getColor('.color-warning-500');
color.warning._600 = getColor('.color-warning-600');
color.warning._700 = getColor('.color-warning-700');
color.warning._800 = getColor('.color-warning-800');
color.warning._900 = getColor('.color-warning-900');

color.success = {};

color.success._50 = getColor('.color-success-50');
color.success._100 = getColor('.color-success-100');
color.success._200 = getColor('.color-success-200');
color.success._300 = getColor('.color-success-300');
color.success._400 = getColor('.color-success-400');
color.success._500 = getColor('.color-success-500');
color.success._600 = getColor('.color-success-600');
color.success._700 = getColor('.color-success-700');
color.success._800 = getColor('.color-success-800');
color.success._900 = getColor('.color-success-900');

color.fusion = {};

color.fusion._50 = getColor('.color-fusion-50');
color.fusion._100 = getColor('.color-fusion-100');
color.fusion._200 = getColor('.color-fusion-200');
color.fusion._300 = getColor('.color-fusion-300');
color.fusion._400 = getColor('.color-fusion-400');
color.fusion._500 = getColor('.color-fusion-500');
color.fusion._600 = getColor('.color-fusion-600');
color.fusion._700 = getColor('.color-fusion-700');
color.fusion._800 = getColor('.color-fusion-800');
color.fusion._900 = getColor('.color-fusion-900');

export default color;
