import axios from 'axios';
import api from './api';

const Yandex = function Yandex(counter, token) {
  this.counter = counter;
  this.token = token;
};

Yandex.prototype.update = function update(counter, token) {
  this.counter = counter || this.counter;
  this.token = token || this.token;
}

Yandex.prototype.getHeaders = function getHeaders() {
  return { Authorization: 'OAuth ' + this.token};
};

Yandex.prototype.fetch = async function fetch(url) {
  try {
    const headers = this.getHeaders();

    const { data } = await axios.get(url, { headers });

    return data;
  } catch (e) {
    console.error(`Can't fetch ${url}: ${e.response ? e.response.status + ' - ' : ''}${e.message}`);
  }

  return null;
};

export default api(Yandex);
