export const getNearest = function getNearest(tag, target) {
  tag = tag.toUpperCase();

  do {
    if (!target || target.tagName === 'BODY') break;
    if (target.tagName !== tag) continue;

    return target;
  }
  while (target = target.parentNode);

  return null;
};

export const getStack = function getStack(target) {
  const list = [];

  do {
    list.push(target);

    if (!target || target.tagName === 'BODY') break;
  }
  while (target = target.parentNode);

  return list;
};
