import { MARKER } from './constants';

export default {
  axis: {
    x0: { width: 1, color: '#000', dasharray: null },
    x: { width: 1, color: '#aaa', dasharray: null },
    x2: { width: 1, color: '#aaa', dasharray: '4' },
    x3: { width: 0, color: 'none', dasharray: null },
    y0: { width: 1, color: '#000', dasharray: null },
    y: { width: 1, color: '#aaa', dasharray: null },
  },
  labels: {
    x: { font: '12px', color: '#444', rotate: null },
    y: { font: '12px', color: '#444', rotate: null },
  },
  selection: {
    enabled: false,
    deep: 0,
    color: 'rgba(255, 39, 139, 0.3)',
    border: 'rgba(255, 39, 139, 0.8)',
  },
  legend: {
    hidden: false,
    clickable: true,
    multiline: false,
  },
  marker: {
    type: MARKER.SINGLE,
    column: {
      color: 'rgba(255, 39, 139, 0.3)',
    },
  },
  popup: {
    label: items => items.join(': '),
  },
  sections: {
    line: {
      minWidth: 10,
      minDistance: 5,
    },
  },
  bars: {
    width: 40,
    offset: {
      left: 10,
      right: 10,
      between: 5,
    },
  },
  rows: {
    count: [3, 5],
    steps: [
      { max: 4, step: 2 },
      { max: 8, step: 4 },
      { max: Infinity, step: 5 },
    ],
  },
};
