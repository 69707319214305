import { setter } from '/utils/object';

export const getList = function getList(exports, exclude) {
  return exports.keys().reduce((result, key) => {
    if (exclude && exclude(key)) return result;

    result.push(exports(key).default);

    return result;
  }, []);
};

const regFile = /\/([^/]+)$/;
const regFolder = /\/([^/]+)\/[^/]+$/;

export const getHash = function getHash(exports, useFolderName = true, exclude) {
  return exports.keys().reduce((result, key) => {
    if (exclude && exclude(key)) return result;

    const match = (useFolderName ? regFolder : regFile).exec(key);
    const name = (match || [])[1];

    result[name] = exports(key).default;

    return result;
  }, {});
};

export const getFullHash = function getFullHash(exports, exclude) {
  return exports.keys().reduce((result, key) => {
    if (exclude && exclude(key)) return result;

    result[key] = exports(key).default;

    return result;
  }, {});
};

const clearPath = path => path.replace(/^\.\/|\.js$/g, '');

export const getTree = function getTree(exports, exclude) {
  return exports.keys().reduce((result, key) => {
    if (exclude && exclude(key)) return result;

    const path = clearPath(key).split('/').join('.');

    setter(result, path, exports(key).default);

    return result;
  }, {});
};
