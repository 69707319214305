import React, { Component, Fragment } from 'React';

import { BL } from '/views'

class ErrorBoundary extends Component {
  state = {
    broken: false
  };

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._path !== _props.path) {
      result.path = result._path = _props.path;
      result.broken = false;
    }

    return result;
  };

  componentDidCatch(error, errorInfo) {
    console.log(error);
    console.log(errorInfo);

    this.setState({ broken: true });
  }

  render() {
    const { broken } = this.state;

    if (!broken) return this.props.children;

    return (
      <Fragment>
        <BL.Title icon="fal fa-thunderstorm" name="Ошибка сценария" />

        <div className="p-3 rounded bg-warning-100">
          <span className="h5">К сожалению на данной странице возникла ошибка</span>
          <br />
          <br />
          Мы постараемся исправить случившееся в ближайшее время, а пока, увы, данный функционал является не стабильным.
          Вы можете переоткрыть страницу и избегать использования поломанной фунциональности.
        </div>
      </Fragment>
    );
  }
}

export default ErrorBoundary;
