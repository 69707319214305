import React from 'react';
import moment from 'moment';

const ChatTitle = props => {
  const { value, date_add } = props;

  if (!value) return null;

  return (
    <div className="conversation_messanger-history_title_wrapper border-faded">
      <div className="conversation_messanger-history_title color-primary-500">
        <div className="d-flex flex-column align-items-center pl-3 py-2">
          <div className="conversation_messanger-history_title_text">{value}</div>
          <div className="conversation_messanger-history_title_date">Дата добавления: {moment(date_add).format('D MMMM YYYY')}</div>
        </div>
      </div>
    </div>
  );
}

export default ChatTitle;
