const self = {};

self.lemmer = function lemmer(text) {
  const url = '/api/v1/tools/lemmer/';
  const method = 'post';

  const data = { text };

  return this.axios({ url, method, data });
};

self.lemmerExport = function lemmerExport(words, ngrams, lang) {
  const url = '/api/v1/tools/lemmer/export?lang=' + lang;
  const method = 'post';

  const data = { words, ngrams };

  return this.download({ url, method, data });
};

self.serverResponse = function serverResponse(links, ua) {
  const url = '/api/v1/tools/server-response/';
  const method = 'post';

  const data = { links, ua };

  return this.axios({ url, method, data });
};

self.serverResponseExport = function serverResponseExport(links, lang) {
  const url = '/api/v1/tools/server-response/export?lang=' + lang;
  const method = 'post';

  const data = { links };

  return this.download({ url, method, data });
};

self.sitemapper = function sitemapper(links) {
  const url = '/api/v1/tools/sitemapper/';
  const method = 'post';

  const data = { links };

  return this.axios({ url, method, data });
};

self.sitemapperExport = function sitemapperExport(main, items, lang) {
  const url = '/api/v1/tools/sitemapper/export?lang=' + lang;
  const method = 'post';

  const data = { main, items };

  return this.download({ url, method, data });
};

self.titleDescriptionH1 = function titleDescriptionH1(links) {
  const url = '/api/v1/tools/title-description-h1/';
  const method = 'post';

  const data = { links };

  return this.axios({ url, method, data });
};

self.titleDescriptionH1Export = function titleDescriptionH1Export(items, lang) {
  const url = '/api/v1/tools/title-description-h1/export?lang=' + lang;
  const method = 'post';

  const data = { items };

  return this.download({ url, method, data });
};

self.indexChecker = function indexChecker(links, searchers) {
  const url = '/api/v1/tools/index-checker/';
  const method = 'post';

  const data = { links, searchers };

  return this.axios({ url, method, data });
};

self.indexCheckerExport = function indexCheckerExport(items, searchers, kanl) {
  const url = '/api/v1/tools/index-checker/export?lang=' + lang;
  const method = 'post';

  const data = { items, searchers };

  return this.download({ url, method, data });
};

self.robotsChecker = function robotsChecker(links) {
  const url = '/api/v1/tools/robots-checker/';
  const method = 'post';

  const data = { links };

  return this.axios({ url, method, data });
};

export default self;
