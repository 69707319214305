import axios from 'axios';

import { isRegExp, isFunction } from '/utils/types';

import { VALIDATION, ERROR } from '../../constants';

const self = {};

self[VALIDATION.REQUIRED] = value => ['', null, undefined].includes(value) ? ERROR.REQUIRED : null;

self[VALIDATION.INVALID] = checker => {
  if (isRegExp(checker)) return value => checker.test(value) ? null : VALIDATION.INVALID;
  if (isFunction(checker)) return value => checker(value) ? null : VALIDATION.INVALID;

  throw new Error('Type of checker should be Funciton or RegExp');
};

self[VALIDATION.EQUAL] = (value, field) => {
  const { name, equalTo, union } = field.props;
  const { data, errors } = union;

  const field_0 = name;
  const field_1 = equalTo;

  const value_0 = data[field_0];
  const value_1 = data[field_1];

  const isEqual = value_0 === value_1;

  if (!errors[equalTo].length !== isEqual) {
    setTimeout(() => union.fields[equalTo].validate(), 0);
  }

  return isEqual ? null : ERROR.NOT_EQUAL;
};

export default self;
