import React, { Component } from 'react';
import cn from 'classnames';

import Popup from '../Popup';

import './styles.scss';

class Item extends Component {
  onEnter = () => {
    const { idx, onEnter } = this.props;

    onEnter(idx);
  };

  onLeave = () => {
    const { idx, onLeave } = this.props;

    onLeave(idx);
  };

  render() {
    const { width, height, margin } = this.props;

    const style = {};

    width && (style.width = width);
    !width && (style.flexGrow = 1);

    margin && (style.marginLeft = margin);

    return (
      <div className="ui-sparkline-spacer" style={style} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
        <div className="ui-sparkline-item" style={{ height: height + '%' }} />
      </div>
    );
  }
};

class Sparkline extends Component {
  state = {
    active: null,
  };

  onEnter = idx => this.setActive(idx);
  onLeave = idx => this.setActive(null);

  setActive = active => {
    clearTimeout(this.updateTimer);

    this.updateTimer = setTimeout(() => this.setState({ active }), 0);
  };

  componentWillUnmount() {
    clearTimeout(this.updateTimer);
  }

  renderLabel() {
    const { active } = this.state;
    const { items, label, popupWidth } = this.props;

    if (active === null) return;

    const { name, value } = items[active];

    const sets = {
      width: popupWidth,
      side: Popup.SIDE_BOTTOM,
      text: label ? label(name, value, active) : name + ' - ' + value,
      shown: true,
    };

    return <Popup.Dark className="ui-sparkline-popup" { ...sets } />;
  }

  renderItem = (height, idx) => {
    const { items, width: wrapperWidth, itemWidth: width } = this.props;

    const sets = { idx, width, height };

    sets.margin = idx && (wrapperWidth - width * items.length) / (items.length - 1);

    sets.onEnter = this.onEnter;
    sets.onLeave = this.onLeave;

    return <Item key={idx} { ...sets } />;
  };

  render() {
    const { items, width, height, itemWidth, className } = this.props;

    const max = Math.max(...items.map(item => item.value));
    const sizes = items.map(item => item.value / max * 100);

    const style = { ...(this.props.style || {}) };

    width && (style.width = style.minWidth = width);
    height && (style.height = height);

    return (
      <div className={cn('ui-sparkline', className, !itemWidth && 'ui-sparkline-flex')} style={style}>
        {sizes.map(this.renderItem)}

        {this.renderLabel()}
      </div>
    );
  }
}

export default Sparkline;
