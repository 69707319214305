import React, { Component } from 'react';

import { LangConsumer } from '/contexts/Lang';

class Search extends Component {
  onChange = event => {
    const { value } = event.target;
    const { onSearch } = this.props;

    onSearch(value.trim());
  };

  render() {
    return (
      <LangConsumer context="views.ui.Table">
        {lang => (
          <div className="dataTables_filter">
            <label className="mb-0">
              <div className="input-group-text d-inline-flex width-3 align-items-center justify-content-center border-bottom-right-radius-0 border-top-right-radius-0 border-right-0">
                <i className="fal fa-search" />
              </div>
              <input
                type="search"
                className="form-control border-top-left-radius-0 border-bottom-left-radius-0 ml-0 width-lg shadow-inset-1"
                placeholder={lang('search')}
                onChange={this.onChange} />
            </label>
          </div>
        )}
      </LangConsumer>
    );
  }
}

export default Search;
