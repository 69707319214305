import { setter } from '/utils/object';
import { getFullHash } from '/utils/webpack';

const components = require.context('./', true, /^\.\/.+\/\w{2}\.js$/);
const hash = getFullHash(components);

const langs = {};

const reg = /\.\/(?:(.+)\/)?(\w+)\.js$/;

for (let path in hash) {
  if (!hash.hasOwnProperty(path)) continue;

  let [, context, lang] = reg.exec(path) || [];

  context = context.split(/\/|\\/).join('.');

  setter(langs, lang + '.' + context, hash[path]);
}

console.log('LANGUAGES:', langs);

export default langs;
