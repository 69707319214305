import { link as createLink } from '/services/Components/methods';

import checkActive from '../../../../utils/checkActive';

import services from '/static/data/services';

const addItem = (serviceName, access, lang, auditId, reports, current) => {
  const report = reports.find(item => item.serviceName === serviceName);

  if (!report) return null;

  const data = services[serviceName];

  if (!access.check(data.component + '.' + data.action)) return null;

  const page = [data.component, data.action];
  const name = data.menu;
  const link = createLink(page, { lang, auditId, reportId: report.id });

  const isActive = checkActive(page, current);

  return { page, name, link, isActive };
};

const getTrafficAnalize = (access, lang, auditId, reports, current) => {
  const page = ['Report'];
  const name = 'Анализ трафика';
  const icon = 'fal fa-analytics';
  const link = '#';
  const isActive = checkActive(page, current);

  const childs = [
    addItem('all_traffic', access, lang, auditId, reports, current),
    addItem('lost_traffic', access, lang, auditId, reports, current),
    addItem('zero_traffic', access, lang, auditId, reports, current),
    addItem('missing_in_sitemap', access, lang, auditId, reports, current),
    addItem('yandex_metrika_query', access, lang, auditId, reports, current),
    addItem('search_algo_update', access, lang, auditId, reports, current),
  ].filter(v => v);

  if (!childs.length) return null;

  return { page, name, icon, link, isActive, childs };
};

const getTechnicalAnalize = (access, lang, auditId, reports, current) => {
  const page = ['Report'];
  const name = 'Технический аудит';
  const icon = 'fal fa-code';
  const link = '#';
  const isActive = checkActive(page, current);

  const childs = [
    addItem('main_mirror', access, lang, auditId, reports, current),
    addItem('https_transition', access, lang, auditId, reports, current),
    addItem('metrika_domains', access, lang, auditId, reports, current),
    addItem('sitemaps', access, lang, auditId, reports, current),
  ].filter(v => v);

  if (!childs.length) return null;

  return { page, name, icon, link, isActive, childs };
};

const getSearchCore = (access, lang, auditId, reports, current) => {
  const page = ['Report'];
  const name = 'Поисковое ядро';
  const icon = 'fal fa-bullseye-arrow';
  const link = '#';
  const isActive = checkActive(page, current);

  const childs = [
    addItem('search_keywords', access, lang, auditId, reports, current),
  ].filter(v => v);

  if (!childs.length) return null;

  return { page, name, icon, link, isActive, childs };
};

const getItems = (access, lang, auditId, reports, current) => [
  getTrafficAnalize(access, lang, auditId, reports, current),
  getTechnicalAnalize(access, lang, auditId, reports, current),
  getSearchCore(access, lang, auditId, reports, current),
].filter(v => v);

export default getItems;
