import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { reloadUpdates, addUpdate, updateUpdate, deleteUpdate } from '/components/SearcherUpdates/store/actions';

const route = createRoute({ path: '/admin/searcher-updates', layout: 'Admin' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = ({ searcherUpdates }) => ({ lang: settings.language, searcherUpdates });

const mapDispatchToProps = { reloadUpdates, addUpdate, updateUpdate, deleteUpdate };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
