import React from 'react';

import emitter from '/utils/emitter';
import array from '/utils/array';

const wrapClass = className => state => {
  if (__CLIENT__) {
    document.body.classList.toggle(className, state);
  }
};

const CLASSES = {
  MODAL_OPEN: wrapClass('modal-open'),
  FIX_SIDEBAR: wrapClass('nav-function-fixed'),
  HIDE_SIDEBAR: wrapClass('nav-function-hidden'),
  LOCK_LAYOUT: wrapClass('locked-layout'),
};

const VIEW = emitter({});

VIEW.VIEWPORT = (() => {
  const VIEWPORT = {
    WIDTH: 0,
    HEIGHT: 0,
  };

  if (__CLIENT__) {
    let timeout = null;

    const update = function update() {
      const width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

      const isDiffWidth = width !== VIEWPORT.WIDTH;
      const isDiffHeight = height !== VIEWPORT.HEIGHT;

      VIEWPORT.WIDTH = width;
      VIEWPORT.HEIGHT = height;

      VIEW.trigger('viewport:change', width, height, isDiffWidth, isDiffHeight);

      timeout && clearTimeout(timeout);

      timeout = setTimeout(() => VIEW.trigger('viewport:change-stop', width, height, isDiffWidth, isDiffHeight), 50);
    };

    update() & window.addEventListener('resize', update, false);

    VIEWPORT.lock = () => CLASSES.LOCK_LAYOUT(true);
    VIEWPORT.unlock = () => CLASSES.LOCK_LAYOUT(false);

    VIEWPORT.scrollTo = element => {
      const offset = element.getBoundingClientRect().top + window.scrollY;

      window.scrollTo(0, offset);
    };
  }

  return VIEWPORT;
})();

VIEW.MODAL = (() => {
  const MODAL = {};

  const STACK = [];
  const ACTIVE = [];

  MODAL.show = (modal, force) => {
    STACK.push(modal);

    MODAL.showNext(force);
  };

  MODAL.showNext = (force = false) => {
    if (ACTIVE.length && !force) return;

    const view = STACK.pop();

    if (!view) return;

    CLASSES.MODAL_OPEN(true);

    const modal = view();

    ACTIVE.push(modal);

    VIEW.trigger('modal:show', modal);
  };

  MODAL.hide = modal => {
    ACTIVE.pop();

    CLASSES.MODAL_OPEN(false);

    VIEW.trigger('modal:hide');

    MODAL.showNext();
  };

  MODAL.hideAll = () => {
    ACTIVE.splice(0, ACTIVE.length);

    VIEW.trigger('modal:hide');

    CLASSES.MODAL_OPEN(false);
  };

  return MODAL;
})();

VIEW.SIDEBAR = (() => {
  const SIDEBAR = {};

  const STATE = { hidden: false };

  SIDEBAR.notify = () => VIEW.trigger('sidebar:toggle', !STATE.hidden);

  SIDEBAR.toggle = () => {
    CLASSES.HIDE_SIDEBAR(STATE.hidden = !STATE.hidden);

    SIDEBAR.notify();
  };

  SIDEBAR.show = () => {
    CLASSES.HIDE_SIDEBAR(STATE.hidden = false);

    SIDEBAR.notify();
  };

  SIDEBAR.hide = () => {
    CLASSES.HIDE_SIDEBAR(STATE.hidden = true);

    SIDEBAR.notify();
  };

  SIDEBAR.enable = () => {
    STATE.hidden && CLASSES.HIDE_SIDEBAR(true);

    CLASSES.FIX_SIDEBAR(true);
  };

  SIDEBAR.disable = () => {
    CLASSES.HIDE_SIDEBAR(false);
    CLASSES.FIX_SIDEBAR(false);
  };

  return SIDEBAR;
})();

export default VIEW;
