import React from 'react';

import Logo from '../Logo';

import Lang from '/services/Lang';

import './styles.scss';

const Footer = props => {
  const lang = (props.lang || new Lang('en')).child('views.common.footer');

  return (
    <footer className="common_footer-wrapper">
      <div className="common_footer-container">
        <div className="common_footer-top">
          <div className="common_footer-top_block">
            <Logo className="footer_logo"/>
          </div>
          <div className="common_footer-top_block fs-8">
            <div className="mb-1 text-right">
              {lang('contacts')}
            </div>
            <div>
              <a href="mailto:support@serp.tools">support@serp.tools</a>
            </div>
          </div>
        </div>
        <div className="text-center text-muted fs-6 mt-3">
          &copy; {new Date().getFullYear()} serp.tools
        </div>
      </div>
    </footer>
  );
};

export default Footer;
