import { connect } from 'react-redux'
import View from './view';

const mapStateToProps = state => {
  const { user } = state;

  return { token: user.token };
};

export default connect(mapStateToProps)(View);
