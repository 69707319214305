import { createActions } from 'redux-actions';

export const {
  routerUpdatePath,
  routerUpdateState
} = createActions(
  {
    ROUTER_UPDATE_PATH: (path, method, params, updateHistory, defer) => ({ path, method, params, updateHistory, defer }),
    ROUTER_UPDATE_STATE: (path, method, component, action, layout, params) => ({ path, method, component, action, layout, params })
  }
);
