import axios from 'axios';

import getApp from '.';

if (module.hot) {
  const FILE_NAME = 'styles.css';

  let src = document.querySelector(`link[href*="${FILE_NAME}"]`);

  const { href } = src;

  const style = document.createElement('style');

  style.type = 'text/css';
  style.rel = 'stylesheet';

  document.head.appendChild(style);

  const update = function update(url) {
    axios.get(url).then(responce => {
      style.textContent = responce.data.replace('sourceMappingURL=', 'sourceMappingURL=/');

      src.disabled = true;
    });
  };

  module.hot.accept();

  module.hot.addStatusHandler(() => {
    update(`${href}?${+new Date}`);
  });

  update(href);
}

export default getApp;
