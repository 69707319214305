import React from 'react';
import cn from 'classnames';

import IconLine from '../IconLine';
import IconBar from '../IconBar';

import UI_Popup from '/views/ui/Popup';

const Item = props => {
  const { type, name, value, color, fill } = props;

  let icon = null;

  if (type === 'bars') icon = <IconBar className="mr-2 pb-1" color={color} />;
  if (type === 'lines') icon = <IconLine className="mr-2 pb-1" color={color} fill={fill} />;

  return <div className="d-flex pt-1">{icon}<div>{name}: {value}</div></div>;
};

const Popup = props => {
  const { label, values, style = {} } = props;

  return (
    <UI_Popup.Dark shown={true} style={{ width: 'auto', whiteSpace: 'nowrap', padding: '7px 12px', pointerEvents: 'none', ...style }} boundingBox=".panel">
      <div className="mb-1 fs-7p">{label}</div>
      <div className={cn('d-flex flex-column justify-content-center fs-7', values.length === 1 && 'align-items-center')}>
        {values.map((item, i) => <Item key={i} { ...item } />)}
      </div>
    </UI_Popup.Dark>
  );
};

export default Popup;
