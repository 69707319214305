import { select, put, takeEvery, throttle, delay } from 'redux-saga/effects';

import Provider from '../provider';

import { addAudit, addAudits, deleteAudit } from '../../Audit/store/actions';
import { startSession, signout } from '../../User/store/actions';

import {
  fetchProjects,
  reloadProjects,
  addProjects,
  dropProjects,
  changeProjectsStatus,
  addAuditToProject,
  fetchProjectTypes,
  setProjectTypes,
  saveProjectSettings,
  removeProjectAudit,
  fetchProjectMirrors,
  updateProjectMirrors,
  fetchProjectSitemaps,
  updateProjectSitemaps,
} from './actions';

import { setAsyncTimeout } from '/utils/common';

import { STATUS_INITIAL, STATUS_LOADING, STATUS_UPDATING, STATUS_READY, STATUS_ERROR_FETCH } from './constants';

function* _startSession({ payload }) {
  if (__CLIENT__) {
    yield put(fetchProjects());
  }
}

function* _signout() {
  yield put(dropProjects());
}

function* _fetchProjects({ payload }) {
  const { attempts } = payload;

  const { user, projects: _projects } = yield select();

  if (!user.token) return;

  if (attempts) {
    const newest = _projects.status === STATUS_READY ? STATUS_UPDATING : STATUS_LOADING;

    yield put(changeProjectsStatus(newest));
  }

  const provider = new Provider(user.token);

  let projects, audits = null;

  try {
    const data = yield provider.getList();

    projects = data.projects;
    audits = projects.map(item => item.audits).flat();
  } catch (e) {
    if (attempts) {
      yield put(changeProjectsStatus(STATUS_ERROR_FETCH));
    } else {
      yield setAsyncTimeout(3000);
      yield put(fetchProjects(1));
    }
  }

  yield put(addAudits(audits));
  yield put(addProjects(projects));

  yield put(changeProjectsStatus(STATUS_READY));
}

function* _reloadProjects({ payload }) {
  const { defer } = payload;

  const { user } = yield select();

  if (!user.token) return;

  const provider = new Provider(user.token);

  try {
    const projects = yield provider.reloadProjects();

    defer.resolve(projects);
  } catch(e) {
    defer.reject();
  }
}

function* _fetchProjectMirrors({ payload }) {
  const { id, defer } = payload;

  const { user } = yield select();

  if (!user.token) return;

  const provider = new Provider(user.token);

  try {
    const { mirrors } = yield provider.getProjectMirrors(id);

    yield put(updateProjectMirrors(id, mirrors));

    defer.resolve(mirrors);
  } catch(e) {
    defer.reject(e);
  }
}

function* _addAudit({ payload }) {
  const { id, projectId } = payload;

  yield put(addAuditToProject(projectId, id));
}

function* _fetchProjectTypes({ payload }) {
  const { attempt } = payload;

  const { projects } = yield select();

  if (projects.types.length) return;

  const provider = new Provider();

  const { types, error } = yield provider.getProjectTypes();

  if (error) {
    if (attempt >= 1) return;

    attempt += 1;

    yield delay(2000);
    yield put(fetchProjectTypes(attempt));
  } else {
    yield put(setProjectTypes(types));
  }
}

function* _fetchProjectSitemaps({ payload }) {
  const { id, defer } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { items } = yield provider.getProjectSitemaps(id);

    const add = items.map(v => ({ ...v, valid: true }));

    yield put(updateProjectSitemaps(id, { add }));

    defer.resolve(add);
  } catch (e) {
    console.log(e);
    defer.reject(e);
  }
}

function* _saveProjectSettings({ payload }) {
  const { id, settings, defer } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  try {
    const { sitemaps } = yield provider.updateSettings(id, settings.site_type_id, settings.sitemaps);

    yield put(updateProjectSitemaps(id, sitemaps));

    defer.resolve();
  } catch (e) {
    console.log(e);
    defer.reject(e);
  }
}

function* _deleteAudit({ payload }) {
  const { id } = payload;

  const { audit } = yield select();

  const { projectId } = audit.items[id];

  yield put(removeProjectAudit(projectId, id));
}

export default function* projectsSage() {
  yield takeEvery(startSession, _startSession);
  yield takeEvery(signout, _signout);

  yield takeEvery(fetchProjects, _fetchProjects);
  yield takeEvery(fetchProjectTypes, _fetchProjectTypes);
  yield takeEvery(fetchProjectMirrors, _fetchProjectMirrors);
  yield takeEvery(fetchProjectSitemaps, _fetchProjectSitemaps);

  yield takeEvery(reloadProjects, _reloadProjects);

  yield takeEvery(saveProjectSettings, _saveProjectSettings);

  yield takeEvery(deleteAudit, _deleteAudit);

  yield throttle(0, addAudit, _addAudit);
}
