import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { setCurrentConversation, toggleConversationState, sendConversationMessage } from '../../store/actions';

const mapStateToProps = ({ user, conversations }) => {
  const { current, items } = conversations;

  const conversation = items[current];

  return { user, conversation };
};

const mapDispatchToProps = {
  setCurrent: setCurrentConversation,
  toggleState: toggleConversationState,
  sendMessage: sendConversationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(view);
