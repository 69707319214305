import { createActions } from 'redux-actions';

export const {
  getUpdates,
  reloadUpdates,
  setUpdates,
  addUpdate,
  updateUpdate,
  deleteUpdate,
  removeUpdate,
  updateStatus,
} = createActions(
  {
    GET_UPDATES: () => ({}),
    RELOAD_UPDATES: () => ({}),
    SET_UPDATES: items => ({ items }),
    ADD_UPDATE: (data, defer) => ({ data, defer }),
    UPDATE_UPDATE: (id, data, defer) => ({ id, data, defer }),
    DISABLE_UPDATE: (id, disabled, defer) => ({ id, disabled, defer }),
    DELETE_UPDATE: (id, defer) => ({ id, defer }),
    REMOVE_UPDATE: id => ({ id }),
    UPDATE_STATUS: status => ({ status }),
  }
);
