import React, { Fragment } from 'react';
import cn from 'classnames';

import { LangConsumer } from '/contexts/Lang';

import { UI } from '/views';

import { AGENTS } from '../../constants';

const renderDisallow = (name, item, robots) => {
  if (!item || item.allowed) return null;

  let lines = robots.data.split("\n");

  const from = Math.max(item.line - 2, 0);
  const to = Math.min(item.line + 3, lines.length - 1);

  lines = lines.slice(from, to).map((value, i) => {
    const result = { num: from + i + 1, value };

    if (from + i === item.line) result.className = 'bg-danger-50';

    return result;
  });

  return (
    <Fragment>
      <div className="font-weight-bold">{name}</div>
      <div className="mt-2"><UI.Code lines={lines} /></div>
    </Fragment>
  );
};

const RobotsDisallowRules = props => {
  return (
    <LangConsumer context="components.Tools.RobotsChecker">
      {lang => {
        const { items, robots } = props;

        const list = [
          renderDisallow(lang('result_subtitle_all'), items[AGENTS.ALL], robots),
          renderDisallow(lang('result_subtitle_yandex'), items[AGENTS.YANDEX], robots),
          renderDisallow(lang('result_subtitle_google'), items[AGENTS.GOOGLE], robots),
        ].filter(v => !!v);

        return list.map((item, i) => <div key={i} className={cn(!!i && 'mt-3')} style={{ maxWidth: 600 }}>{item}</div>);
      }}
    </LangConsumer>
  );
};

export default RobotsDisallowRules;
