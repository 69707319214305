import React, { Fragment } from 'react';

import { Item as _Item, Block as _Block } from './Block';

import { deduplicate } from '/utils/array';

import CONST from '../../constants';

const TYPE_TOTAL = 'TOTAL';
const TYPE_UNIQ = 'UNIQ';
const TYPE_REPEAT = 'REPEAT';

class Item extends _Item {
  hasExpand() {
    const { type, stats } = this.props;

    return type === TYPE_REPEAT && stats.pages.total !== stats.pages.uniq;
  }

  getHeadData() {
    const { lang, type, stats } = this.props;

    const { name, infoText, infoTextActive } = {
      [TYPE_TOTAL]: {
        name: lang('count_item_total'),
        infoText: lang('count_item_total_info'),
        infoTextActive: false,
      },
      [TYPE_UNIQ]: {
        name: lang('count_item_uniq'),
        infoText: lang('count_item_uniq_info'),
        infoTextActive: false,
      },
      [TYPE_REPEAT]: {
        name: lang('count_item_duplicates'),
        infoText: lang('count_item_duplicates_info'),
        infoTextActive: false,
      },
    }[type];

    const value = {
      [TYPE_TOTAL]: stats.pages.total,
      [TYPE_UNIQ]: stats.pages.uniq,
      [TYPE_REPEAT]: stats.pages.total - stats.pages.uniq,
    }[type];

    return { name, infoText, infoTextActive, value };
  }

  matchDuples() {
    const { items } = this.props;

    const maps = items.filter(item => item.type === CONST.SITE_TYPE.SITEMAP);

    const duples = [];
    const dictionary = {};

    for (let map, i = 0; map = maps[i]; i++) {
      for (let link, j = 0; link = map.links[j]; j++) {
        if (link.url in dictionary) {
          duples.push(link.url);
        } else {
          dictionary[link.url] = [];
        }

        dictionary[link.url].push(map);
      }
    }

    return duples.reduce((result, url) => {
      let item = result.find(v => v.url === url);

      if (!item) {
        const maps = dictionary[url].map(map => map.url);

        result.push({ url, maps });
      }

      return result;
    }, []);
  }

  renderBody() {
    const duples = this.matchDuples();

    const renderItem = item => {
      return (
        <li key={item.url} className="tools_sitemapper-stats-item-body-list-item">
          <div>{item.url}</div>
          <ul className="tools_sitemapper-stats-item-body-error-list">
            {item.maps.map((url, i) => <li key={i} className="tools_sitemapper-stats-item-body-error-item">{url}</li>)}
          </ul>
        </li>
      );
    };

    return (
      <ol className="tools_sitemapper-stats-item-body-list">
        {duples.map(renderItem)}
      </ol>
    );
  }
}

class Block extends _Block {
  constructor(props) {
    super(props);

    const { lang } = props;

    this.title = lang('count_title');
    this.infoText = () => lang('count_title_info');
    this.infoTextActive = false;
  }

  renderItems() {
    const { lang, result: { items, stats } } = this.props;

    const list = [TYPE_TOTAL, TYPE_UNIQ, TYPE_REPEAT];

    return list.map(type => <Item key={type} lang={lang} type={type} items={items} stats={stats} />);
  }
}

export default Block;
