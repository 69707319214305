import React, { Component } from 'react';
import cn from 'classnames';

import VIEW from '/views/view';

import { ref } from '/utils/react';

class Popup extends Component {
  ref = ref;

  displace = 0;

  componentDidMount() {
    this.rematchDisplace();

    VIEW.on('viewport:change-stop', this.rerender);
  }

  componentWillUnmount() {
    VIEW.off('viewport:change-stop', this.rerender);
  }

  componentDidUpdate() {
    this.rematchDisplace();
  }

  rerender = () => this.forceUpdate();

  rematchDisplace = () => {
    if (!this.ui) return 0;

    const { boundingBox, side = Popup.SIDE_TOP } = this.props;

    const { container, triangle } = this.ui;

    if (boundingBox && [Popup.SIDE_TOP, Popup.SIDE_BOTTOM].includes(side)) {
      const bounder = boundingBox && container.closest(boundingBox) || container.closest('body');

      container.style.marginLeft = 0;

      const b_rect = bounder.getBoundingClientRect();
      const c_rect = container.getBoundingClientRect();

      const b_cx = Math.round(b_rect.x + b_rect.width / 2);
      const c_cx = Math.round(c_rect.x + c_rect.width / 2);

      const out = Math.round(Math.abs(b_cx - c_cx) + (c_rect.width - b_rect.width) / 2);
      const displace = out > 0 ? out * (b_rect.x > c_rect.x ? 1 : -1) : 0;

      container.style.marginLeft = displace + 'px'; // prevent blinking
      triangle.style.marginLeft = -1 * displace + 'px'; // prevent blinking

      return this.displace = displace;
    }

    return this.displace = 0;
  };

  render() {
    const { className, children, title, text, shown, style = {}, width, side = Popup.SIDE_TOP, stl = 'primary' } = this.props;

    const _style = { ...style, marginLeft: this.displace }; // sometimes style object is read-only

    width && (_style.width = width + 'px');

    return (
      <div className="popup-container">
        <div className={cn('popup', 'side-' + side, 'popup-' + stl, shown && 'popup-show', className)} style={_style} ref={this.ref('container')}>
          <div className="popup-triangle" style={{ marginLeft: -1 * this.displace }} ref={this.ref('triangle')} />
          {title && <h3 className="popup-header">{title}</h3>}
          {(text || children) && <div className="popup-body">{text || children}</div>}
        </div>
      </div>
    );
  }
}

Popup.SIDE_TOP = 'top';
Popup.SIDE_BOTTOM = 'bottom';

Popup.SIDE_LEFT_MIDDLE = 'left-middle';
Popup.SIDE_RIGHT_MIDDLE = 'right-middle';

Popup.Primary = props => <Popup stl="primary" { ...props } />;
Popup.Secondary = props => <Popup stl="secondary" { ...props } />;
Popup.Success = props => <Popup stl="success" { ...props } />;
Popup.Info = props => <Popup stl="info" { ...props } />;
Popup.Warning = props => <Popup stl="warning" { ...props } />;
Popup.Danger = props => <Popup stl="danger" { ...props } />;
Popup.Dark = props => <Popup stl="fusion" { ...props } />;
Popup.Light = props => <Popup stl="light" { ...props } />;

export default Popup;
