import React, { Component, Fragment } from 'react';

import CONST from '../../constants';

import { BL } from '/views';

import YearStats from '../YearStats';

import { BlockCount, BlockValidity, BlockPagesCount, BlockUncorrectRoots } from '../StatsBlocks';

import DarkPrompt from '../DarkPrompt'

const renderRoot = (lang, root) => (
  <div className="tools_sitemapper-stats-root p-0 select-text">
    <b>{lang('root_domain')}</b>: {root}
    <DarkPrompt className="ml-2">{lang('root_domain_info')}</DarkPrompt>
  </div>
);

const renderMainMirror = (lang, root) => (
  <div className="tools_sitemapper-stats-root p-0 select-text">
    <b>{lang('main_mirror')}</b>: {root}
    <DarkPrompt className="ml-2">{lang('main_mirror_info')}</DarkPrompt>
  </div>
);

const Stats = props => {
  const { lang, result, isAudit } = props;

  const { root } = result.stats;

  return (
    <div className="p-3">
      {isAudit ? renderMainMirror(lang, root) : renderRoot(lang, root)}
      <hr className="mt-3 mb-3" />
      <BlockCount lang={lang} result={result} />
      <hr className="mt-3 mb-3" />
      <BlockValidity lang={lang} result={result} />
      <hr className="mt-3 mb-3" />
      <BlockPagesCount lang={lang} result={result} />
      <hr className="mt-3 mb-3" />
      <BlockUncorrectRoots lang={lang} result={result} />
      <hr className="mt-3 mb-3" />
      <YearStats lang={lang} result={result} />
    </div>
  );
};

export default Stats;
