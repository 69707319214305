import React from 'react';

import Text from '../Text';

import { VALIDATION } from '../../constants';

class Password extends Text {
  FIELD_TYPE = 'password';
}

export default Password;
