import { deduplicate } from '/utils/array';

export default function getNewLinks(insert, result) {
  if (result.userAgent && insert.userAgent !== result.userAgent) return insert.links.slice();

  const finished = result.links.map(item => item.url);

  const items = insert.links.filter(url => !finished.includes(url));

  return deduplicate(items);
};
