import React from 'react';

import { UI, BL } from '/views';

import { indexYandexLink } from '/utils/searchers';

const matchMirrors = indexes => {
  const result = [];

  for (let index, i = 0; index = indexes[i]; i++) {
    const { url, yandex } = index;

    const isHttp = url.indexOf('http://') === 0;
    const isHttps = url.indexOf('https://') === 0;

    const cleared = url.replace(/^https?:\/\/|\/$/ig, '');

    let box = result.find(v => v.url === cleared);

    if (!box) {
      box = { url: cleared, total: yandex.total };

      result.push(box);
    }

    isHttp && (box.http = yandex.percent);
    isHttps && (box.https = yandex.percent);
  }

  return result;
};

const TableRow = props => {
  const { url, total, http, https } = props;

  return (
    <tr>
      <td>{url}</td>
      <td className="text-center">
        <a href={indexYandexLink(url)} target="_blank">{total}</a>
      </td>
      <td className="text-center">{http}%</td>
      <td className="text-center">{https}%</td>
    </tr>
  );
};

const YandexMirrorsTable = props => {
  const { indexes } = props;

  const mirrors = matchMirrors(indexes);

  return (
    <div className="mt-3">
      <h4 className="ui-detail_list-title">Таблица проверки индексации в Яндексе</h4>
      <table className="table table-bordered table-hover table-layout-fixed w-100 valign-middle dtr-inline darken">
        <thead>
          <tr>
            <th rowSpan="2">
              Зеркало
              <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Проверяемое зеркало сайта. В Яндекс мы проверяем только количество страниц с www и без, но не протокол https/http.
              </BL.Info.Dark>
            </th>
            <th rowSpan="2" className="text-center" style={{ width: 130 }}>
              Индекс Яндекса<br />
              <BL.Info.Dark side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Количество страниц проверяемого зеркала в индексе Яндекс. Может отличаться от реального, точные данные вы можете узнать в Яндекс.Вебмастер.
              </BL.Info.Dark>
            </th>
            <th colSpan="2" className="text-center">
              Протокол страниц в индексе
              <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                При проверке количества страниц в индексе мы получаем 50 страниц сайта, которые находятся в индексе. Ниже считаем, какой процент из этих страниц с протоколом https, а какой с http.
              </BL.Info.Dark>
            </th>
          </tr>
          <tr>
            <th className="text-center">http</th>
            <th className="text-center">https</th>
          </tr>
        </thead>
        <tbody>
          {mirrors.map(item => <TableRow key={item.url} { ...item } />)}
        </tbody>
      </table>
    </div>
  );
};

export default YandexMirrorsTable;
