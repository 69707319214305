const renderPoint = (point, idx) => {
  const type = idx ? 'L': 'M';

  return type + point.join(',');
};

const renderPath = item => {
  let points = [
    [item.box.x[0], item.box.y[0]],
    [item.box.x[0], item.box.y[1]],
    [item.box.x[1], item.box.y[1]],
    [item.box.x[1], item.box.y[0]],
    [item.box.x[0], item.box.y[0]],
  ];

  points = points.map(renderPoint).join(' ');

  return `<path d="${points}" fill="${item.fill}" stroke="${item.color || 'none'}" />`;
};

const renderBars = bars => {
  const items = [];

  for (let i = 0, len = bars.length; i < len; i++) {
    items[items.length] = bars[i].boxes.map(item => renderPath(item)).join('');
  }

  return items.join('');
};

export default function svg_bars(bars) {
  let svg = '';

  svg += '<g>' + renderBars(bars) + '</g>';

  return svg;
};
