import React, { Fragment } from 'react';
import moment from 'moment';

import { UI, BL } from '/views';

const fields = {};

fields.accaunt = item => item.accaunt_name;

fields.date_add = item => {
  const date = moment(item.date_add);

  return <span className="text-nowrap">{date.format('D MMMM YYYY')}</span>;
};

fields.date_expire = item => {
  const date = moment(item.date_expire).startOf('day');

  return <span className="text-nowrap">{date.format('D MMMM YYYY')}</span>;
};

fields.state = item => {
  const { date_expire } = item;

  const now = moment().unix();
  const expire = moment(date_expire).unix();

  if (now > expire) {
    return <UI.Badge stl="danger">Не активный</UI.Badge>;
  }

  if (expire - now < 7 * 24 * 60 * 60) {
    return <UI.Badge stl="warning">Активный</UI.Badge>;
  }

  return <UI.Badge stl="success">Активный</UI.Badge>;
};

fields.actions = (item, actions) => {
  const classes = 'pt-1 pb-1 pl-2 pr-2 m-1';

  let refreshButton = null;

  if (item.has_refresh) {
    refreshButton = <UI.Button.Info className={classes} size="xs" icon="fal fa-redo mr-1" spin={item.lock} text="Обновить" onClick={() => !item.lock && actions.onRefresh(item.id, 'yandex')} />;
  } else {
    const bait = <UI.Button.Info className={classes} size="xs" icon="fal fa-redo mr-1" text="Обновить" disabled={true} />;

    refreshButton = (
      <BL.Info.Dark className="d-inline-block" bait={bait} width={360} boundingBox=".panel" passive={true} >
        К сожалению для данного аккаунта отсутствует рефреш токен. Для его обновления подключите данный аккаунт ещё раз.
      </BL.Info.Dark>
    );
  }

  return (
    <Fragment>
      {refreshButton}
      <UI.Button.Danger className={classes} size="xs" icon="fal fa-times mr-1" text="Удалить" onClick={() => !item.lock && actions.onRemove(item.id, 'yandex')} />
    </Fragment>
  );
};

export default (name, item, actions) => fields[name](item, actions);
