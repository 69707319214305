import React from 'react';
import cn from 'classnames';

import './styles.scss';

const State = props => {
  const { state, className, ...sets } = props;

  switch (state) {
    case State.WAITING:
      sets.className = cn('ui-form-field spinner-border spinner-border-sm', className);

      return <span { ...sets } />;
    case State.CORRECT:
      sets.className = cn('ui-form-field fal fa-exclamation-circle color-danger-500', className);

      return <i { ...sets } />;
    case State.ERROR:
      sets.className = cn('ui-form-field fal fa-check-circle color-info-500', className);

      return <i { ...sets } />;
  }

  return null;
};

State.WAITING = 'waiting';
State.CORRECT = 'correct';
State.ERROR = 'error';

export default State;
