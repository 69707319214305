import { sorter } from '/utils/array';

export default function mergeResults(src_1, src_2) {
  const links = [...src_1, ...src_2].reduce((result, item) => {
    const { link } = item;

    const isNew = !(link in result);
    const isActual = !!item.id;
    const isUpdate = item.isAdded || item.isRemoved;

    if (isNew || isActual || isUpdate) {
      result[link] = item;
    }

    return result;
  }, {});

  const comporator = sorter([item => +!item.id, 'response_code', 'link'], 'asc');

  return Object.values(links).sort(comporator);
};
