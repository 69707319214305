import React from 'react';

import Button from '../../../Button';

const Submit = function Submit(props) {
  const { onCreate, onChange, onValidate, ...sets } = props;

  return <Button type="submit" { ...sets } />;
};

export default Button.stylize(Submit);
