import React from 'react';
import cn from 'classnames';

const ListItem = props => {
  const { icon, name, href, onClick, children } = props;

  const sets = { href: href || '#' };

  onClick && (sets.onClick = onClick);

  return (
    <div className="list-item">
      <a className={cn('link', (href || onClick) && 'active')} { ...sets }>
        <div>
          <i className={cn(icon, "width-2 fs-xl")} />
        </div>
        <div className="ml-2">
          {name}
          {children}
        </div>
      </a>
    </div>
  );
};

const List = function List(props) {
  const { className, items = [], children } = props;

  return (
    <div className={cn("list border-top-left-radius-0 border-top-right-radius-0", className)}>
      {items.map((data, idx) => <ListItem key={idx} { ...data } />)}
      {children}
    </div>
  );
};

List.Item = ListItem;

export default List;
