const worker = columns => {
  let max = 0;

  if (!columns) return 0;

  for (let i = 0, len = columns.length; i < len; i++) {
    const deep = 1 + worker(columns[i].childs);

    max < deep && (max = deep);
  }

  return max;
};

export default worker;
