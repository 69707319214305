import React, { Component } from 'react';
import cn from 'classnames';

import Authorization from '/components/User/views/Authorization';
import Logo from '../../../../common/Logo';

import { link } from '/services/Components/methods';

import UI from '/views/ui';
import CMN from '/views/common';
import VIEW from '/views/view';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShow: false
    };
  }

  toggleMenu = () => {
    this.setState({
      isShow: !this.state.isShow
    });
  };

  anchorClick = (sectionId) => {
    this.setState({
      isShow: false
    }, () => {
      document.querySelector(sectionId).scrollIntoView({
        behavior: 'smooth'
      });
    });
  }

  onAuthShow = () => {
    VIEW.MODAL.show(() => {
      return <Authorization />;
    });
  };

  renderLoginButton = () => {
    return (
      <UI.Button className="btn-accent-light box-shadow header__login" onClick={this.onAuthShow}>
        Войти
      </UI.Button>
    );
  };

  renderAuthButton = () => {
    return (
      <UI.Button.Warning className="header__register" onClick={this.onAuthShow}>
        Регистрация
      </UI.Button.Warning>
    );
  };

  renderDashboardButton = () => {
    const { lang } = this.props;

    return (
      <UI.Button.Warning key="dashboard" href={link('Projects.Root', { lang })}>
        Панель управления
      </UI.Button.Warning>
    );
  };

  renderAdminButton = () => {
    const { lang } = this.props;

    return (
      <UI.Button.Warning key="admin" href={link('Admin.Statistics', { lang })} className="mr-3">
        Панель администратора
      </UI.Button.Warning>
    );
  };

  renderAuthButtons() {
    const list = [];


    list[list.length] = this.renderLoginButton();
    list[list.length] = this.renderAuthButton();

    return list;
  }

  renderPanelButtons() {
    const { access } = this.props;

    const list = [];

    if (access.check('Admin')) {
      list[list.length] = this.renderAdminButton();
    }

    list[list.length] = this.renderDashboardButton();

    return list;
  }

  render() {
    const { lang, isAuthorized } = this.props;
    const { isShow } = this.state;

    return (
      <header className="header py-2 py-lg-4">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <div className="popup-bounder d-flex justify-content-between align-items-center w-100" style={{ paddingBottom: '1px' }}>
              <a href={link('Home.Root', { lang })}>
                <Logo className="header__logo mr-2"/>
              </a>
              <ul className="list d-flex m-0">
                <li className="nav-item">
                  <CMN.ToolsMenu />
                </li>
                <li className="nav-item ml-2">
                  <CMN.LanguagesMenu />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
