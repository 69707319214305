import React from 'react';

import { UI, BL } from '/views';

import { indexGoogleLink } from '/utils/searchers';

const TableRow = props => {
  const { idx, mirror, traffic, indexes } = props;

  let visits = null;

  if (idx % 2 === 0) {
    const { protocol } = mirror.from.parts;

    visits = <td className="text-center" rowSpan="2">{traffic[protocol].visits}</td>;
  }

  const index = (indexes || []).find(index => index.url === mirror.from.url);
  const indexGoogle = index ? index.google.total : '-';

  return (
    <tr>
      <td>{mirror.from.url}</td>
      <td className="text-center"><BL.StatusBadge code={mirror.from.statusCode} error={mirror.from.error} /></td>
      <td>{mirror.to && mirror.to.url}</td>
      <td className="text-center">{mirror.to && <BL.StatusBadge code={mirror.to.statusCode} error={mirror.to.error} />}</td>
      <td className="text-center">
        <a href={indexGoogleLink(mirror.from.url)} target="_blank">{indexGoogle}</a>
      </td>
      {visits}
    </tr>
  );
};

const GoogleMirrorsTable = props => {
  const { domain, mirrors, ...other } = props;

  return (
    <div className="mt-3">
      <h5 className="ui-detail_list-title">Таблица проверки домена <b>{domain}</b></h5>
      <table className="table table-bordered table-hover table-layout-fixed w-100 valign-middle dtr-inline darken">
        <thead>
          <tr>
            <th>
              URL
              <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Проверяемое зеркало сайта
              </BL.Info.Dark>
            </th>
            <th className="text-center" style={{ width: 130 }}>
              Ответ сервера<br />
              <BL.Info.Dark side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Ответ сервера для проверяемого зеркала сайта.
              </BL.Info.Dark>
            </th>
            <th>
              Итоговый URL
              <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Страница, на которую перенаправляет проверяемое зеркало сайта.
              </BL.Info.Dark>
            </th>
            <th className="text-center" style={{ width: 130 }}>
              Итоговый ответ сервера<br />
              <BL.Info.Dark side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Ответ сервера страницы, на которую перенаправляет проверяемое зеркало сайта.
              </BL.Info.Dark>
            </th>
            <th className="text-center" style={{ width: 100 }}>
              Индекс Google<br />
              <BL.Info.Dark side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Количество страниц проверяемого зеркала в индексе Google. Может отличаться от реального, точные данные вы можете узнать в Google Search Console.
              </BL.Info.Dark>
            </th>
            <th className="text-center" style={{ width: 140 }}>
              Поисковый трафик за месяц<br />
              <BL.Info.Dark side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Поисковый трафик за месяц на https-версию и http-версию сайта.
              </BL.Info.Dark>
            </th>
          </tr>
        </thead>
        <tbody>
          {mirrors.map((mirror, i) => <TableRow key={i} idx={i} mirror={mirror} { ...other } />)}
        </tbody>
      </table>
    </div>
  );
};

export default GoogleMirrorsTable;
