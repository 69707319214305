const self = {};

self.add = function add(project_id) {
  const url = '/api/v1/audits/';
  const method = 'post';

  const data = { project_id };

  return this.axios({ url, method, data });
};

self.delete = function _delete(id) {
  const url = '/api/v1/audits/' + id;
  const method = 'delete';

  return this.axios({ url, method });
};

self.getReports = function getReports(id) {
  const url = '/api/v1/audits/' + id + '/reports';
  const method = 'get';

  return this.axios({ url, method });
};

self.getListByIds = function getListByIds(ids) {
  const url = `/api/v1/audits/list?ids=[${ids.join(',')}]`;
  const method = 'get';

  return this.axios({ url, method });
};

export default self;
