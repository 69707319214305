import React, { Component } from 'react';

import Table from '/views/ui/Table';

import matchStateUpdate from './utils/matchStateUpdate';

const getSort = (sort = {}) => ({
  field: sort.field || null,
  dir: sort.dir || null,
});

class TableSync extends Component {
  constructor(props) {
    super(props);

    const sort = getSort(props.sort);

    const search = { value: null };
    const paging = { limit: props.pagination ? 25 : Infinity, page: 0 };

    this.state = matchStateUpdate({ search, sort, paging }, props);
  }

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._data !== _props.data || result._id !== _props.id) {
      result._id = _props.id;
      result._data = _props.data;

      const { search, paging, data, _state } = state;

      const sort = getSort(_props.sort);

      const params = { search, sort, paging };

      const ext = matchStateUpdate(params, _props, _state);

      Object.assign(result, ext);
    }

    return result;
  }

  onSort = (field, dir) => {
    const state = matchStateUpdate({ sort: { field, dir } }, this.props, this.state);

    this.setState(state);
  };

  onSearch = value => {
    if (!value.length) value = null;

    const state = matchStateUpdate({ search: { value } }, this.props, this.state);

    this.setState(state);
  };

  onPaginate = (page, limit) => {
    const { paging: settings } = this.state;

    page = page !== null ? page : settings.page;
    limit = limit !== null ? limit : settings.limit;

    const paging = { page, limit };

    const state = matchStateUpdate({ paging }, this.props, this.state);

    this.setState(state);
  };

  render() {
    const {
      loading, emptyText, itemId, columns, getter, actions, pagination, toolbar,
      searcher, decorator, settings, child, className, wrapperStyle, useScrollbar,
    } = this.props;

    const { data, search, sort, paging } = this.state;

    const sets = {
      loading, emptyText, itemId, columns, getter, actions, pagination, toolbar,
      searcher, decorator, settings, child, className, wrapperStyle, useScrollbar,
      search, sort, paging,
    };

    sets.data = data;

    sets.count = {
      filtered: data.filtered.length,
      total: (this.props.data || []).length,
    };

    sets.onSearch = this.onSearch;
    sets.onPaginate = this.onPaginate;
    sets.onSort = this.onSort;

    return <Table { ...sets } />;
  }
}

export default TableSync;
