import React from 'react';

import { UI } from '/views';

const settings = {
  label: (item, total) => `${item.value} <tspan dy="-6" font-size="10">${(item.value / total * 100).toFixed(1)}%</tspan>`,
};

const Chart = props => {
  const { items } = props;

  const params = { items, settings, height: 250 };

  return <UI.PieChart { ...params } />;
};

export default Chart;
