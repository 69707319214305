import { connect } from 'react-redux'
import { createRoute } from '/services/Router/route';

import View from './view';

import { requestYandexAccaunt } from '../../store/actions';
import { fetchProjects } from '../../../Projects/store/actions';

const route = createRoute({ path: '/settings/accesses', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = ({ user }) => ({ token: (user || {}).token });
const mapDispatchToProps = { requestYandexAccaunt, fetchProjects };

const view = connect(null, mapDispatchToProps)(View);

export default { route, view, settings };
