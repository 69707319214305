import React, { Component } from 'react';
import cn from 'classnames';

import constants from './constants';
import fields from './fields';
import views from './views';

import './styles.scss';

class Form extends Component {
  onSubmit = event => {
    const { onSubmit } = this.props;

    onSubmit && onSubmit(event);
  };

  render() {
    const { className, ...sets } = this.props;

    sets.className = cn('d-flex flex-column', className);
    sets.onSubmit = this.onSubmit;

    return <form { ...sets } noValidate />;
  }
}

export default Object.assign(Form, constants, fields, views);
