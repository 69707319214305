import React, { Component } from 'react';

import KeyControl from '/services/KeyControl';

import { UI } from '/views';

import { ref } from '/utils/react';

import {
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  SET_LAST_MESSAGE,
} from '../../../../constants';

class ChatInput extends Component {
  ref = ref;

  state = {
    value: '',
  };

  id = null;

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    const id = _props.editing && _props.editing.id;

    if (result._id !== id) {
      result._id = id;

      result.value = id ? _props.editing.value : '';
    }

    return result;
  }

  onKeyDown = event => {
    const { editing, onEdit } = this.props;
    const { input } = this.ui;

    switch(event.keyCode) {
      case KeyControl.KEYS.up:
        !input.value && onEdit(SET_LAST_MESSAGE);
      break;
      case KeyControl.KEYS.enter:
        if (!KeyControl.states.ctrl && !KeyControl.states.shift) {
          event.preventDefault();

          this.onSend();
        }
      break;
      case KeyControl.KEYS.escape:
        editing && onEdit(null);
      break;
    }
  };

  onChange = () => {
    const { editing, onEdit } = this.props;

    const { value } = this.ui.input;

    const message = value.trim();

    if (editing && !message) onEdit(null);

    this.setState({ value });
  };

  onClose = () => {
    const { id, toggleConversationState } = this.props;

    toggleConversationState(id, true);
  };

  onSend = () => {
    const message = this.ui.input.value.trim();

    if (!message) return;

    const { id, editing, sendMessage, onEdit } = this.props;

    const message_id = editing ? editing.id : null;
    const purpose = editing ? PURPOSE_MESSAGE_PLACEHOLDER_UPDATE : PURPOSE_MESSAGE_PLACEHOLDER_ADD;

    sendMessage(id, message_id, purpose, message);

    editing && onEdit(null);

    this.setState({ value: '' });
  };

  setFocus = () => {
    if (this.id === this.state._id || !this.state._id) return;

    const { input } = this.ui;

    input.focus();

    const { length } = input.value;

    input.selectionStart = length;
    input.selectionEnd = length;
  };

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  render() {
    const { editing } = this.props;
    const { value } = this.state;

    const fieldSets = {
       onKeyDown: this.onKeyDown,
       onChange: this.onChange,
       ref: this.ref('input'),
       value,
    };

    this.focusTimeout = setTimeout(this.setFocus, 0);

    return (
      <div className="support_chat-input_wrapper d-flex flex-column">
        <div className="px-3 pt-2">
          <textarea className="support_chat-input_field form-control custom-scrollbar" { ...fieldSets } />
        </div>
        <div className="support_chat-input_controls px-3 py-2">
          <span className="btn btn-icon fs-xl mr-1">
            <i className="fal fa-camera color-fusion-300"></i>
          </span>
          <div className="ml-auto">
            <UI.Button.Light onClick={this.onClose} className="mr-2" text="Закрыть тикет" />
            <UI.Button.Info onClick={this.onSend} text={editing ? 'Обновить' : 'Отправить'} />
          </div>
        </div>
      </div>
    );
  }
}

export default ChatInput;
