import SETTINGS from '../settings';

import getLabelWidth from '../utils/getLabelWidth';
import matchRange from './matchRange';

import matchColumns from './matchColumns';
import matchRows from './matchRows';

import matchSections from './matchSections';
import matchLines from './matchLines';
import matchBars from './matchBars';

import { merge } from '/utils/object';

const updateSettings = settings => merge({}, SETTINGS, settings);

const updateOffset = (offset, range, sections) => {
  offset = {
    top: 0,
    left: 0,
    right: 1,
    bottom: 0,
    ...offset,
  };

  const value = (range.min + '').length > (range.max + '').length ? range.min : range.max;
  const name = sections.reduce((res, item) => item.label.length > res.length ? item.label : res, '');

  const text = [value, name][+(value.length < name.length)];

  offset.left = offset.left || getLabelWidth(text);

  return offset;
};

const getRange = (data, exclude, settings) => {
  let { lines = [], bars = [] } = data;

  lines = lines.filter(line => !exclude.lines.includes(line.name));
  bars = bars.filter(bar => !exclude.bars.includes(bar.name));

  const items = [...lines, ...bars];
  const values = [].concat(...items.map(item => item.values));

  return matchRange(values, settings);
};

export default function matchGraph(area, offset, data, exclude, settings) {
  settings = updateSettings(settings);

  const range = getRange(data, exclude, settings);

  offset = updateOffset(offset, range, data.sections || []);

  const columns = matchColumns(area, offset, range, data.labels, settings);
  const rows = matchRows(area, offset, range, settings);

  const sections = matchSections(range, columns, offset, data.sections || [], exclude.sections, settings);
  const lines = matchLines(range, columns, data.lines || [], exclude.lines, settings);
  const bars = matchBars(range, columns, data.bars || [], exclude.bars, settings);

  return { area, offset, columns, rows, sections, lines, bars, range, settings };
};
