import React, { Fragment, Component, PureComponent } from 'react';
import cn from 'classnames';

import ModalClear from '../ModalClear';

import { UI, VIEW } from '/views';

class Item extends Component {
  onRemove = () => {
    const { onRemove, lemma } = this.props;

    onRemove(lemma);
  };

  render() {
    const { lemma, isFirst } = this.props;

    return (
      <li className={cn(!isFirst && 'mt-2')}>
        <div className="d-flex align-items-center">
          <div className="pr-2">
            <UI.Button.Danger icon="fal fa-times" size="xs" className="pl-2 pr-2 pt-1 pb-1" onClick={this.onRemove} />
          </div>
          <div>
            {lemma}
          </div>
        </div>
      </li>
    );
  }
}

class Selections extends Component {
  clear = () => {
    const { list, onRemove } = this.props;

    onRemove(...list);
  };

  onClear = () => {
    const { lang, list } = this.props;

    if (!list.length) return;

    VIEW.MODAL.show(() => {
      const onCancel = VIEW.MODAL.hide;

      const onSubmit = () => {
        this.clear();
        VIEW.MODAL.hide();
      };

      return <ModalClear lang={lang} onCancel={onCancel} onSubmit={onSubmit} />;
    });
  };

  onCopy = () => {
    const { list } = this.props;

    if (!list.length) return;

    navigator.clipboard.writeText(list.join('\n'))
      .then(() => console.log('Получилось'))
      .catch(err => console.log('Не получилось', err));
  };

  renderControls() {
    const { lang, list } = this.props;

    const ButtonClear = list.length ? UI.Button.Danger : UI.Button.Secondary;
    const ButtonCopy = list.length ? UI.Button : UI.Button.Secondary;

    return (
      <div className="d-flex justify-content-between mt-3 mb-3">
        <ButtonClear text={lang('mylist_button_clear')} onClick={this.onClear} icon2="fal fa-times" />
        <ButtonCopy text={lang('mylist_button_copy')} onClick={this.onCopy} />
      </div>
    );
  }

  renderItems() {
    const { lang, list } = this.props;

    const renderList = () => list.map((lemma, i) => <Item key={lemma} isFirst={!i} lemma={lemma} onRemove={this.props.onRemove} />);

    return (
      <div className={cn(this.props.className, 'tools_lemmer-spacer')}>
        <div className="tools_lemmer-box p-2 custom-scrollbar">
          <ul className="list-clear">
            {list.length ? renderList() : lang('mylist_empty')}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    const { lang, className, list } = this.props;

    return (
      <Fragment>
        <div className="tools_lemmer-list_title select-none">
          {lang('mylist_title')}
        </div>
        {this.renderControls()}
        {this.renderItems()}
      </Fragment>
    );
  }
}

export default Selections;
