import { remove } from '/utils/array';
import { getDomain } from '/utils/link';

export default function getClearedDomains(current, domains, links) {
  const result = { ...current };

  for (let domain in result) {
    if (!result.hasOwnProperty(domain)) continue;

    if (domains.includes(domain)) {
      delete result[domain];
    } else {
      result[domain] = { ...result[domain] };
      result[domain].links = result[domain].links.slice();
    }
  }

  for (let link, i = 0; link = links[i]; i++) {
    const domain = getDomain(link);

    if (!result[domain]) continue;

    remove(result[domain].links, item => item.url === link);
  }

  return result;
};
