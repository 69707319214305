const fields = {};

fields.id = item => item.id;
fields.host = item => item.host;
fields.stats = item => (item.stats || {}).traffic || 0;
fields.audit = item => item.audits[0].date_add;

export default (field, dir) => {
  const getter = fields[field];
  const fallback = fields.id;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a);
    _b = getter(b);

    if (_a === _b) {
      _a = fallback(a);
      _b = fallback(b);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
