import React from 'react';

import { UI, BL } from '/views';

const AlertTransition = props => {
  const { domains } = props;

  return (
    <BL.Alert.Danger className="mt-3">
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        У проекта был изменен домен с <b>{domains.from}</b> на <b>{domains.to}</b> и в аудите будет использоваться именно домен <b>{domains.to}</b>.
        <br />
        Пожалуйста, не забудьте сменить домен в настройках счетчика Яндекс.Метрики
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

export default AlertTransition;
