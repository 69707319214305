const fields = {};

fields.verdict = item => {
  const states = {
    TRAFFIC_GROW: 1,
    TRAFFIC_SUBSIDE: 2,
    TRAFFIC_DROP: 3,
    TRAFFIC_ZERO: 4,
    TRAFFIC_STABLE: 5,
    TRAFFIC_NEW: 6,
    TRAFFIC_UNMATCH: 7,
  };

  return states[item.verdict];
};

fields.count_value = item => item.count.overall.value;
fields.count_percent = item => item.count.overall.percent;

fields.year_0 = item => item.stats.total[0].values.overall;
fields.year_1 = item => item.stats.total[1].values.overall;

fields.diff_year = item => item.analytics.total.values.overall.variance;

fields.diff_year_per_page = item => item.analytics.total.values.overall.variance;

fields.diff_3_month = item => item.analytics.last3month.values.overall.variance;
fields.diff_1_month = item => item.analytics.year[11].values.overall.variance;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.verdict;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
