import React, { Component } from 'react';
import cn from 'classnames';

import { link } from '/services/Components/methods';

import { LangConsumer } from '/contexts/Lang';

import UI from '/views/ui';

import './styles.scss';

const items = [
  { worker: 'Tools.Lemmer', name: 'Lemmer.name' },
  { worker: 'Tools.RobotsChecker', name: 'RobotsChecker.name' },
  { worker: 'Tools.ServerResponse', name: 'ServerResponse.name' },
  { worker: 'Tools.Sitemapper', name: 'Sitemapper.name' },
  { worker: 'Tools.TitleDescriptionH1', name: 'TitleDescriptionH1.name' },
];

class ToolsMenu extends Component {
  state = {
    show: false,
  };

  show = () => this.setState({ show: true });
  hide = () => this.setState({ show: false });

  onClick = event => {
    if (this.state.show) return;

    this.show();

    window.addEventListener('click', this.onWindowClick, true);
  };

  onWindowClick = event => {
    this.hide();
  };

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  renderPopup(lang) {
    const { component, action } = this.props;

    const language = lang.child('components.Tools');

    const active = [component, action].join('.');

    return (
      <UI.Popup.Light
        className="common_tools_menu-popup"
        width={280}
        side={UI.Popup.SIDE_BOTTOM}
        shown={this.state.show}
        boundingBox=".popup-bounder"
      >
        <ul className="common_tools_menu-list">
          {items.map(item => (
            <li key={item.worker} className={cn('common_tools_menu-item', item.worker === active && 'common_tools_menu-item_active')}>
              <a href={link(item.worker, { lang })}>
                {language(item.name)}
              </a>
            </li>
          ))}
        </ul>
      </UI.Popup.Light>
    );
  }

  render() {
    const { size = 'sm' } = this.props;

    return (
      <LangConsumer context="global">
        {lang => (
          <div className="common_tools_menu">
            <UI.Button.Warning className="fs-8" size={size} text={lang('tools')} onClick={this.onClick} />
            {this.renderPopup(lang)}
          </div>
        )}
      </LangConsumer>
    );
  }
}

export default ToolsMenu;
