import Badge from './Badge';
import Button from './Button';
import Medal from './Medal';
import Description from './Description';
import Tabs from './Tabs';
import Notice from './Notice';
import List from './List';
import Loader from './Loader';
import Collapse from './Collapse';
import Form from './Form';
import Execute from './Execute';
import Tooltip from './Tooltip';
import Popup from './Popup';
import PieChart from './PieChart';
import LineChart from './LineChart';
import DetailList from './DetailList';
import Table from './Table';
import Tag from './Tag';
import Favicon from './Favicon';
import Sparkline from './Sparkline';
import Icon from './Icon';
import Code from './Code';

export default {
  Badge,
  Button,
  Medal,
  Description,
  Tabs,
  Notice,
  List,
  Loader,
  Collapse,
  Form,
  Execute,
  Tooltip,
  Popup,
  PieChart,
  LineChart,
  DetailList,
  Table,
  Tag,
  Favicon,
  Sparkline,
  Icon,
  Code,
};
