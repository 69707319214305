import React, { Fragment } from 'react';

import { UI, BL } from '/views';

const fields = {};

fields.yandex = item => {
  const result = item.result.yandex;

  if (!result) return null;

  if (!result.exists) {
    return <UI.Icon.Danger className="fs-12" />;
  }

  if (result.errors.length) {
    return <UI.Icon.Warning className="fs-12" />;
  }

  return <UI.Icon.Good className="fs-12" />;
};

fields.google = item => {
  const result = item.result.google;

  if (!result) return null;

  if (!result.exists) {
    return <UI.Icon.Danger className="fs-12" />;
  }

  let icon = null;
  const cache = <UI.Button.Transparent size="xs" className="ml-1" href={result.cachelink} target="_blank">Кэш <i className="fal fa-external-link-square" /></UI.Button.Transparent>;

  if (result.errors.length) {
    icon = <UI.Icon.Warning className="fs-12" />;
  } else {
    icon = <UI.Icon.Good className="fs-12" />;
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      {icon} {result.cachelink && cache}
    </div>
  );
};

fields.url = item => {
  return <a href={item.url.initial} target="_blank">{item.url.initial}</a>;
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
