import React, { Component } from 'react';
import cn from 'classnames';

import { LangConsumer } from '/contexts/Lang';

const SIDE_SIZE = 2;

class PaginationItem extends Component {
  onClick = () => {
    const { page, active, disabled, onPaginate } = this.props;

    if (active || disabled) return;

    onPaginate(page, null);
  };

  icon(side) {
    return <i className={cn('fal', 'fa-chevron-' + side)} />;
  }

  render() {
    let { label, page, active, disabled } = this.props;

    label = ['left', 'right'].includes(label) ? this.icon(label) : label;

    const style = { width: '30px', height: '30px'};

    if (page === null) style.background = 'none';

    return (
      <li className={cn('paginate_button page-item', disabled && 'disabled', active && 'active')}>
        <a href="#" tabIndex="0" className="page-link p-0 text-center" style={style} onClick={this.onClick}>
          {label}
        </a>
      </li>
    );
  }
}

class Pagination extends Component {
  match() {
    const { count, page, limit } = this.props;

    const total = Math.ceil(count.filtered / limit) - 1;

    const items = [];

    if (total < 1) return items;

    let from = Math.max(page - SIDE_SIZE, 0);
    let to = Math.min(page + SIDE_SIZE, total);

    let breakLeft = page > 2 + SIDE_SIZE;
    let breakRight = page < total - (2 + SIDE_SIZE);

    if (from === 2) from = 0;
    if (to === total - 2) to = total;

    if (from !== 0) {
      items[items.length] = { label: 1, page: 0, disabled: page === 0 };
    }

    if (breakLeft) {
      items[items.length] = { label: '...', page: null, disabled: true };
    }

    for (let i = from; i < page; i++) {
      items[items.length] = { label: i + 1, page: i };
    }

    items[items.length] = { label: page + 1, page: page, active: true };

    for (let i = page + 1; i <= to; i++) {
      items[items.length] = { label: i + 1, page: i };
    }

    if (breakRight) {
      items[items.length] = { label: '...', page: null, disabled: true };
    }

    if (to !== total) {
      items[items.length] = { label: total + 1, page: total };
    }

    items[items.length] = { label: 'left', page: Math.max(0, page - 1), disabled: page === 0 };
    items[items.length] = { label: 'right', page:  Math.min(total, page + 1), disabled: page === total };

    return items;
  }

  renderItem = (item, key) => <PaginationItem key={key} onPaginate={this.props.onPaginate} { ...item } />;

  render() {
    return (
      <div className="dataTables_paginate paging_simple_numbers select-none">
        <ul className="pagination">
          {this.match().map(this.renderItem)}
        </ul>
      </div>
    );
  }
}

class Limiter extends Component {
  onChange = event => {
    const value = +event.target.value;
    const { onPaginate } = this.props;

    onPaginate(null, value === -1 ? Infinity : value);
  };

  render() {
    let { limit } = this.props.paging;

    if (limit === Infinity) limit = -1;

    return (
      <LangConsumer context="views.ui.Table">
        {lang => (
          <div className="dataTables_length select-none">
            <label className="mb-0">{lang('show_by')}
              <select className="form-control custom-select ml-1 mr-0" defaultValue={limit} onChange={this.onChange}>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="-1">{lang('show_by_all')}</option>
              </select>
            </label>
          </div>
        )}
      </LangConsumer>
    );
  }
}

Pagination.Limiter = Limiter;

const Statistics = props => {
  const { count, pagination, page, limit } = props;

  if (!count.total) return <span />;

  const from = (page * limit || 0) + 1;
  const to = Math.min((page + 1) * limit, count.filtered);

  return (
    <LangConsumer context="views.ui.Table">
      {lang => {
        let main;

        if (!pagination) {
          main = lang('paging_total', { total: count.total });
        } else if (count.filtered) {
          main = lang('paging_showed', { from, to, filtered: count.filtered });
        } else {
          main = lang('paging_empty');
        }

        const additional = count.total !== count.filtered && ' ' + lang('paging_picked_from', { total: count.total });

        return <span>{main}{additional}</span>;
      }}
    </LangConsumer>
  );
};

Pagination.Statistics = Statistics;

export default Pagination;
