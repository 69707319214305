import React, { PureComponent } from 'react';

import TableNGrams from '../TableNGrams';

import { BL } from '/views';

const sorter = (a, b) => {
  let [_a, _b] = [a.count, b.count];

  if (_a === _b) {
    [_a, _b] = [a.lemma.split(' ').length, b.lemma.split(' ').length];
  }

  if (_a < _b) return  1;
  if (_a > _b) return -1;

  return 0;
};

class ResultNGrams extends PureComponent {
  render() {
    const { lang, className, result, tableHeight, selections, nSize, onSelect, onDeselect, onChangeSize } = this.props;

    const list = nSize === null ? [].concat(...Object.values(result)) : result[nSize];
    const sizes = Object.keys(result).sort((a, b) => a - b).map(value => ({ name: value, value }));

    sizes.unshift({ name: lang('phrases_filter_all'), value: 'null' });

    list.sort(sorter);

    return (
      <TableNGrams
        className={className}
        data={list}
        tableHeight={tableHeight}
        actions={{ onSelect, onDeselect, onChangeSize }}
        settings={{ nSize, sizes, selections }} />
    );
  }
};

export default ResultNGrams;
