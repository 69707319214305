import { isNill } from '/utils/types';

export default (value, data) => {
  const filter = item => {
    const { host, accaunt_name, yandex_counter_id } = item;

    if (!isNill(host) && host.indexOf(value) !== -1) {
      return true;
    }

    if (!isNill(accaunt_name) && accaunt_name.toString().indexOf(value) !== -1) {
      return true;
    }

    if (!isNill(yandex_counter_id) && yandex_counter_id.toString().indexOf(value) !== -1) {
      return true;
    }

    return false;
  };

  return data.filter(filter);
};
