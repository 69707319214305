export const TYPES = {};

TYPES.OUTER_API_REQUEST = 'OUTER_API_REQUEST';
TYPES.OUTER_API_EXPENSES = 'OUTER_API_EXPENSES';
TYPES.OTHER_EXPENSES = 'OTHER_EXPENSES';

TYPES.MONEY_INCOME = 'MONEY_INCOME';

TYPES.AUDIT_LAUNCH = 'AUDIT_LAUNCH';
TYPES.AUDIT_ERROR = 'AUDIT_ERROR';

TYPES.REPORT_LAUNCH = 'REPORT_LAUNCH';

TYPES.USER_UNIQ = 'USER_UNIQ';
TYPES.USER_NEW = 'USER_NEW';
TYPES.USER_REQUEST = 'USER_REQUEST';

TYPES.SUPPORT_REQUEST = 'SUPPORT_REQUEST';

TYPES.TOOL_LAUNCH = 'TOOL_LAUNCH';

TYPES.TOOL_LEMMER = 'TOOL_LEMMER';
TYPES.TOOL_SERVER_RESPONSE = 'TOOL_SERVER_RESPONSE';
TYPES.TOOL_SITEMAPPER = 'TOOL_SITEMAPPER';
TYPES.TOOL_TDH = 'TOOL_TDH';
TYPES.TOOL_INDEX_CHECKER = 'TOOL_INDEX_CHECKER';
TYPES.TOOL_ROBOTS_CHECKER = 'TOOL_ROBOTS_CHECKER';

export const PERIODS = {};

PERIODS.LAST_WEEK = 'LAST_WEEK';
PERIODS.LAST_MONTH = 'LAST_MONTH';
PERIODS.LAST_3_MONTH = 'LAST_3_MONTH';
PERIODS.LAST_6_MONTH = 'LAST_6_MONTH';
PERIODS.LAST_12_MONTH = 'LAST_12_MONTH';

export const GROUPS = {};

GROUPS.BY_WEEK = 'BY_WEEK';
GROUPS.BY_MONTH = 'BY_MONTH';
GROUPS.BY_3_MONTH = 'BY_3_MONTH';

export default { TYPES, PERIODS, GROUPS };
