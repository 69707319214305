import React, { Component } from 'react';
import cn from 'classnames';

import Signin from './components/Signin';
import Signup from './components/Signup';
import Restore from './components/Restore';

import { BL } from '/views';

import './styles.scss';

import image_logo from '/static/images/logo/32.png';
import image_bg from '/static/images/bg/home.svg';

const styles_bg = {
  backgroundImage: `url(${image_bg})`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `left bottom`,
};

class Authorization extends Component {
  state = {
    view: 0
  };

  onFormChange = view => this.setState({ view });

  onClickSignin = () => this.onFormChange(0);
  onClickSignUp = () => this.onFormChange(1);
  onClickRestore = () => this.onFormChange(2);

  renderForm = () => {
    const { view } = this.state;

    const Form = [Signin, Signup, Restore][view];

    return <Form close={this.props.onClose} />;
  };

  render() {
    const { view } = this.state;

    return (
      <BL.Modal visible={true} position="centered" size="lg" { ...this.props }>
        <div className="row m-0" style={{ height: '430px' }}>
          <div className="col-5 bg-brand-gradient d-flex flex-column justify-content-between select-none" style={styles_bg}>
            <div>
              <div className="h1 pt-4 pl-4 pr-4 color-white font-weight-light">
                Авторизация
              </div>
              <ul className="p-4 color-white font-weight-light" style={{ listStyle: 'none'}}>
                <li className="mt-3 mb-3">
                  <a href="#" className={cn('modal-auth-switch', view === 0 && 'modal-auth-active')} onClick={this.onClickSignin}>Вход</a>
                </li>
                { /*
                <li className="mt-3 mb-3">
                  <a href="#" className={cn('modal-auth-switch', view === 1 && 'modal-auth-active')} onClick={this.onClickSignUp}>Создать аккаунт</a>
                </li>
                */ }
                <li className="mt-3 mb-3">
                  <a href="#" className={cn('modal-auth-switch', view === 2 && 'modal-auth-active')} onClick={this.onClickRestore}>Забыл пароль</a>
                </li>
              </ul>
            </div>
            <div className="h3 p-4 mb-0 color-white font-weight-light d-flex align-items-center logo-32">
              <div className="logo-image" />
              <div className="logo-text" />
            </div>
          </div>
          <div className="col-7 p-4 pt-6 d-flex flex-row align-items-stretch">
            {this.renderForm()}
          </div>
        </div>
      </BL.Modal>
    );
  }
}

export default Authorization;
