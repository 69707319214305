import React from 'react';

import { BL } from '/views';

const NoAudits = props => {
  return (
    <BL.Alert>
      <BL.Alert.Icon icon="fal fa-info-circle" />
      <BL.Alert.Content>
        <p>Вы можете запустить новый аудит по кнопке "Запустить аудит", но так как аудит проводится по данным за полный календарный месяц, рекомендуем запускать новый аудит не раньше 1-го числа следующего месяца.</p>
        <p className="mb-0">Посмотреть список всех проведенных аудитов и перейти в нужный вы можете, нажав + слева от адреса сайта.</p>
      </BL.Alert.Content>
    </BL.Alert>
  );
};

export default NoAudits;
