import React, { Component, Fragment, useEffect, useState } from 'react';
import cn from 'classnames';

import NoContent from '../NoContent';
import ContentValue from '../ContentValue';

import { UI } from '/views';

import { ref } from '/utils/react';

import { getTagCount, wrapInH1 } from '../../utils/tagsUtils'

class Child extends Component {
  ref = ref;

  state = {
    expanded: this.props.expanded
  }

  onExpand = event => {
    this.setState(state => ({ ...state, expanded: !state.expanded }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.expanded !== prevProps.expanded) {
      this.setState(state => ({ ...state, expanded: this.props.expanded }));
    }
  }

  renderValues() {
    const { lang, type, value, settings } = this.props;
    const { count } = settings;

    const getTagBgColor = () => `bg-primary-${7 - settings.level}00`;

    if (!count) {
      return (
        <div className="tools_tdh-tree-leaf">
          <UI.Badge className={cn('tools_tdh-tree-tag', type, getTagBgColor())}>{type}</UI.Badge>
          {value && value.trim()
            ? <div className="tools_tdh-tree-tag-text">{value}</div>
            : <ContentValue lang={lang} value={value}/>
          }
        </div>
      );
    }

    return (
      <div className="tools_tdh-tree-divarication" onClick={this.onExpand}>
        <UI.Badge className={cn('tools_tdh-tree-tag', type, getTagBgColor())}>{type}</UI.Badge>
        {!!count && <span className="text-muted">[{count}]</span>}&nbsp;
        {value && value.trim()
          ? <div className="tools_tdh-tree-tag-text">{value}</div>
          : <ContentValue lang={lang} value={value}/>
        }
      </div>
    );
  }

  getIndentClass = () => {
    const { level } = this.props.settings;

    if (this.props.parentLevel) {
      return level - this.props.parentLevel > 1 ? 'tools_tdh-tree-list-item-indent' : '';
    } else if (level > 1) {
      return 'tools_tdh-tree-list-item-start-indent' + (level > 2 ? ' indent-level-2' : '');
    }

    return 0;
  }

  render() {
    const { items, settings } = this.props;
    const { expanded } = this.state;

    return (
      <li className={cn('tools_tdh-tree-list-item', this.getIndentClass())}>
        <div className="tools_tdh-tree-list-item-inner">
          {this.renderValues()}
          {(expanded && items.length)
            ? <List tree={items} expanded={this.props.expanded} parentLevel={settings.level} />
            : ''
          }
        </div>
      </li>
    );
  }
}

const List = props => {
  const { lang, expanded, parentLevel } = props;
  let { tree } = props;

  const h1Count = getTagCount(tree, 'h1');

  if (!parentLevel && h1Count === 0) {
    tree = wrapInH1(tree);
  }

  const classNames = cn('tools_tdh-tree-list', { 'tools_tdh-tree': !parentLevel });

  return (
    <ul className={classNames}>
      {tree.map((item, key) => <Child key={key + item.type} lang={lang} { ...item } expanded={expanded} parentLevel={parentLevel} />)}
    </ul>
  );
}

const Headers = props => {
  const { lang, tree, className } = props;
  const [expanded, setExpanded] = useState(props.expanded);

  const toggleExpand = () => setExpanded(!expanded);

  useEffect(() => {
    setExpanded(props.expanded);
  }, [props.expanded]);

  if (tree.length > 0) {
    return (
      <div className={className}>
        <UI.Button.Link className="p-0 mb-2" onClick={toggleExpand}>{lang('button_toggleTags')}</UI.Button.Link>
        <List lang={lang} tree={tree} expanded={expanded} />
      </div>
    );
  }

  return <NoContent text={lang('state_noH1H6Tags')} />;
};

export default Headers;
