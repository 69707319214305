import { CHECK_YANDEX, CHECK_GOOGLE } from '../../../constants';

export default searchers => {
  const result = [];

  if (searchers.includes(CHECK_YANDEX)) {
    result[result.length] = {
      name: 'Яндекс',
      field: 'yandex',
      sortable: true,
      sortFirst: 'asc',
      style: { width: '90px', maxWidth: '90px', minWidth: '90px', textAlign: 'center' },
    };
  }

  if (searchers.includes(CHECK_GOOGLE)) {
    result[result.length] = {
      name: 'Google',
      field: 'google',
      sortable: true,
      sortFirst: 'asc',
      style: { width: '90px', maxWidth: '90px', minWidth: '90px', textAlign: 'center' },
    };
  }

  result[result.length] = {
    name: 'Адрес',
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: { textAlign: 'left' },
  };

  return result;
};
