import React, { Component, Fragment } from 'react';

import { UI } from '/views';

import ChartMain from '../ChartMain';
import ChartDays from '../ChartDays';
import ChartHours from '../ChartHours';

class Statistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };
  }

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._groups !== _props.groups) {
      result._groups = _props.groups;
      result.index = _props.groups.length - 1;
    }

    return result;
  }

  prev = () => this.setState(state => ({ ...state, index: state.index - 1 }));
  next = () => this.setState(state => ({ ...state, index: state.index + 1 }));

  renderControls() {
    const { groups } = this.props;
    const { index } = this.state;

    const { date } = groups[index];

    const title = [date.from, date.to].join(' - ');

    const prev = index !== 0 && <UI.Button text="Предыдущий" onClick={this.prev} style={{ width: 130 }} />;
    const next = index !== groups.length - 1 && <UI.Button text="Следующей" onClick={this.next} style={{ width: 130 }} />;

    return (
      <div className="d-flex justify-content-between">
        <div style={{ width: 130 }}>{prev}</div>
        <div style={{ lineHeight: '36px' }}>{title}</div>
        <div style={{ width: 130 }}>{next}</div>
      </div>
    );
  }

  render() {
    const { groups } = this.props;
    const { index } = this.state;

    if (!groups) return null

    return (
      <Fragment>
        {this.renderControls()}
        <div>
          <ChartMain items={groups[index].main} />
          <ChartDays items={groups[index].days} />
          <ChartHours items={groups[index].hours} />
        </div>
      </Fragment>
    );
  }
}

export default Statistics;
