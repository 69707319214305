import {
  PURPOSE_MESSAGE_ADD,
  PURPOSE_MESSAGE_UPDATE,
  PURPOSE_MESSAGE_REMOVE,
  PURPOSE_MESSAGE_PLACEHOLDER_ADD,
  PURPOSE_MESSAGE_PLACEHOLDER_UPDATE,
  PURPOSE_MESSAGE_PLACEHOLDER_REMOVE,
} from '../../../../constants';

const getLastMessage = (history, user_id) => {
  const purpose_add = [PURPOSE_MESSAGE_ADD, PURPOSE_MESSAGE_PLACEHOLDER_ADD];
  const purpose_update = [PURPOSE_MESSAGE_UPDATE, PURPOSE_MESSAGE_PLACEHOLDER_UPDATE];
  const purpose_remove = [PURPOSE_MESSAGE_REMOVE, PURPOSE_MESSAGE_PLACEHOLDER_REMOVE];

  const messages = history.filter(item => item.user_id === user_id);

  let added = messages.filter(item => purpose_add.includes(item.purpose));
  let updated = messages.filter(item => purpose_update.includes(item.purpose));
  let removed = messages.filter(item => purpose_remove.includes(item.purpose));

  removed = removed.map(item => item.id);

  let actual = added.filter(item => !removed.includes(item.id));

  if (!actual.length) return null;

  actual = actual[actual.length - 1];

  return purpose_update.reverse().find(item => item.id === actual.id) || actual;
};

export default getLastMessage;
