import React from 'react';

import { BL } from '/views';

import config from './config';

const ResultTable = props => {
  const sort = { field: 'all', dir: 'desc' };

  return <BL.Table className="darken" pagination={true} sort={sort} { ...props } { ...config } />;
};

export default ResultTable;
