import React from 'react';
import cn from 'classnames';

const IconBox = props => {
  const { stl, item } = props;

  const { icon, name, ...sets } = item;

  return (
    <div className="col-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 d-flex justify-content-center align-items-center mb-g">
      <a { ...sets } className="rounded bg-white p-0 m-0 d-flex flex-column w-100 h-100 js-showcase-icon shadow-hover-2">
        <div className={cn('rounded-top color-fusion-300 w-100', 'bg-' + stl + '-300')}>
          <div className="rounded-top d-flex align-items-center justify-content-center w-100 pt-3 pb-3 pr-2 pl-2 fa-3x hover-bg">
            <i className={icon} />
          </div>
        </div>
        <div className="rounded-bottom p-1 w-100 d-flex justify-content-center align-items-center text-center">
          <span className="d-block text-truncate text-muted">
            {name}
          </span>
        </div>
      </a>
    </div>
  );
};

const IconList = function IconList(props) {
  const { stl = "primary", items } = props;

  return (
    <div className="card pt-3 pr-3 pb-0 pl-3">
      <div className="row">
          {items.map((item, key) => <IconBox key={key} stl={stl} item={item} />)}
      </div>
    </div>
  );
};

IconList.Primary = props => <IconList stl="primary" { ...props } />;
IconList.Secondary = props => <IconList stl="secondary" { ...props } />;
IconList.Success = props => <IconList stl="success" { ...props } />;
IconList.Info = props => <IconList stl="info" { ...props } />;
IconList.Warning = props => <IconList stl="warning" { ...props } />;
IconList.Danger = props => <IconList stl="danger" { ...props } />;
IconList.Dark = props => <IconList stl="dark" { ...props } />;
IconList.Light = props => <IconList stl="light" { ...props } />;

export default IconList;
