import { createActions } from 'redux-actions';

export const {
  addYandexAccaunt,
  requestYandexAccaunt,
} = createActions(
  {
    ADD_YANDEX_ACCAUNT: () => ({}),
    REQUEST_YANDEX_ACCAUNT: () => ({}),
  }
);
