import itemId from './_config/itemId';
import columns from './_config/columns';
import getter from './_config/getter';
import searcher from './_config/searcher';
import sorter from './_config/sorter';
import child from './_config/child';

const pagination = true;

export default searchers => ({
  itemId,
  columns: columns(searchers),
  getter,
  searcher,
  sorter,
  pagination,
  child,
});
