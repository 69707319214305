import React, { Fragment } from 'react';

import TableLinks from '../TableLinks';

import { UI } from '/views';

import CONST from '../../constants';

const renderItem = (lang, item, idx) => {
  return (
    <UI.Collapse key={idx} title={item.url + ' (' + lang('total_pages', { count: item.links.length }) + ')'} expand={false}>
      <div className="pt-3">
        <TableLinks data={item.links} />
      </div>
    </UI.Collapse>
  );
};

const Links = props => {
  const { lang, result } = props;

  const sitemaps = result.items.filter(item => item.type === CONST.SITE_TYPE.SITEMAP);

  if (!sitemaps.length) {
    return <div className="px-5 py-3">{lang('no_data')}</div>;
  }

  return (
    <div className="p-3">
      {sitemaps.map((item, i) => renderItem(lang, item, i))}
    </div>
  );
};

export default Links;
