import columnFinder from '../../utils/columnFinder';
import matchLabel from '../../utils/matchLabel';

import { isObject } from '/utils/types';

import { SECTION_LINE_TYPE } from '../../constants';

const match = (range, columns, offset, items, exclude, settings) => {
  items = items.filter(item => !exclude.includes(item.name));

  const sections = items.map(item => {
    const labelWidth = offset.left;

    const x1 = columns[0].area.x[0];
    const x2 = columns[columns.length - 1].area.x[1];

    const area = {
      x: [x1, x2],
      width: x2 - x1,
    };

    return { ...item, labelWidth, area, boxes: [], lines: [] };
  });

  const match_box = (value, item) => {
    const { description } = value;

    const from = isObject(value.from) ? value.from : { capacity: value.from, offset: 0 };
    const to = isObject(value.to) ? value.to : { capacity: value.to, offset: 100 };

    const min = columns[0].area.capacity.from;
    const max = columns[columns.length - 1].area.capacity.to;

    const column_from = columnFinder(Math.max(min, from.capacity), columns);
    const column_to = columnFinder(Math.min(max, to.capacity), columns);

    const x1 = Math.round(column_from.area.x[0] + column_from.area.width * from.offset / 100);
    const x2 = Math.round(column_to.area.x[0] + column_to.area.width * to.offset / 100);

    const y1 = column_from.boundary.top;
    const y2 = column_from.area.y[1];

    const box = {
      x: [x1, x2],
      y: [y1, y2],
      width: x2 - x1,
      height: y2 - y1,
    };

    const { minWidth } = settings.sections.line;

    const isShort = Math.abs(x1 - x2) < minWidth;

    const displace = Math.round((minWidth - Math.abs(x1 - x2)) / 2);

    let _x1 = isShort ? x1 - displace : x1;
    let _x2 = isShort ? _x1 + minWidth : x2;

    [_x1, _x2] = [_x1 - columns[0].area.x[0], _x2 - columns[0].area.x[0]];

    const line = {
      x: [_x1, _x2],
      width: _x2 - _x1,
      type: isShort ? SECTION_LINE_TYPE.POINT : SECTION_LINE_TYPE.LINE,
    };

    return { ...item, description, box, line };
  };

  for (let j = 0, len = items.length; j < len; j++) {
    for (let value, i = 0; value = items[j].values[i]; i++) {
      const { boxes, lines } = sections[j];

      const { box, line, ...other } = match_box(value, items[j]);

      let target = lines.find(item => item[item.length - 1].line.x[1] + settings.sections.line.minDistance <= line.x[0]);

      !target && (lines[lines.length] = target = []);

      target[target.length] = { line, ...other };
      boxes[boxes.length] = { box, ...other };
    }
  }

  return sections;
};

export default function matchSections(range, columns, offset, data, exclude, settings) {
  return match(range, columns, offset, data, exclude, settings);
};
