import React, { Fragment } from 'react';
import cn from 'classnames';

import Field from '../Field';

class Checkbox extends Field {
  static DEFAULT_VALUE = false;

  setDefaultValue() {
    const { name, value, onChange, onValidate } = this.props;

    this.value = !!value || this.constructor.DEFAULT_VALUE;

    onChange && onChange(name, this.value);
    onValidate && onValidate(name, []);
  }

  onChange = event => {
    const { name, checked } = event.target;
    const { onChange } = this.props;

    this.value = checked;

    onChange && onChange(name, checked);

    this.runValidator();
  };

  render () {
    const { errors } = this.state;
    const { label, name, className } = this.props;

    return (
      <label className={cn("d-block", className)}>
        <input className="custom-control-input" name={name} type="checkbox" checked={this.value} onChange={this.onChange} />
        <span className={cn("custom-control-label select-none", errors.length && 'is-invalid')}>{label}</span>
      </label>
    );
  }
}

export default Checkbox;
