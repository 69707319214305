import React from 'react';

import './styles.scss';

const Spinner = props => {
  return (
    <div className="root_spinner-bookshelf_wrapper">
      <ul className="root_spinner-books_list">
        <li className="root_spinner-book_item root_spinner-first" />
        <li className="root_spinner-book_item root_spinner-second" />
        <li className="root_spinner-book_item root_spinner-third" />
        <li className="root_spinner-book_item root_spinner-fourth" />
        <li className="root_spinner-book_item root_spinner-fifth" />
        <li className="root_spinner-book_item root_spinner-sixth" />
      </ul>
      <div className="root_spinner-shelf" />
    </div>
  );
};

export default Spinner;
