import columns from './_config/columns';
import getter from './_config/getter';
import itemId from './_config/itemId';
import sorter from './_config/sorter';

const pagination = false;

export default {
  columns,
  getter,
  itemId,
  sorter,
  pagination,
};
