import moment from 'moment';

import { capitalize } from '/utils/string';
import { createArray, deduplicate } from '/utils/array';

export default months => {
  const [month] = months;

  const date = moment(month.date.from);
  const number = +date.format('M');
  const year = +date.format('YYYY');

  const capacity = date.daysInMonth();

  const result = createArray(capacity, i => {
    const label = i + 1;

    const border = {};

    if (i % 5) {
      border.left = { width: 1, color: '#fff', dasharray: '4' };
    }

    const popupLabel = moment(date).add(i, 'days').format('D MMMM YYYY (dd)');

    return { label, border, popupLabel };
  });

  if (number === 1) {
    result[0].border = {
      left: { width: 5, color: '#4de5d5', dasharray: '0' },
    };
  }

  return result;
};
