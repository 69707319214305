const picker = (column, deep) => {
  const cols = [];

  if (!column || !deep) return column || cols;

  for (let i = 0, len = column.length; i < len; i++) {
    const items = picker(column[i].childs, deep - 1);

    cols.push(...items);
  }

  return cols;
};

export default picker;
