import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { reloadUpdates } from '/components/SearcherUpdates/store/actions';
import { STATUS_READY } from '/components/SearcherUpdates/store/constants';

const route = createRoute({ path: '/audit/:auditId/reports/traffic/search-algo-update/:reportId', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { router, user, searcherUpdates } = state;

  const { auditId, reportId } = router.params.url;

  const updates = searcherUpdates.searchers;
  const updatesReady = searcherUpdates.status === STATUS_READY;

  return { id: +reportId, audit_id: +auditId, user_id: user.id, updates, updatesReady };
};

const mapDispatchToProps = { reloadUpdates };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
