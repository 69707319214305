import React from 'react';

import UI from '/views/ui';

import codes from './codes';

import './styles.scss';

const badges = [
  UI.Badge.Secondary,
  UI.Badge.Success,
  UI.Badge.Warning,
  UI.Badge.Danger,
  UI.Badge.Danger,
];

const Error = props => {
  return (
    <i className="fal fa-ban font-weight-bold text-center color-danger-900 tooltip-parent bl-status_badge-error">
      <UI.Tooltip style={{ width: 220 }}>
        {props.error}
      </UI.Tooltip>
    </i>
  );
};

const StatusBadge = props => {
  const { code, error, loader, style } = props;

  if (loader) return <UI.Loader text={null} className="bl-status_badge-loader" />;
  if (error) return <Error error={error} />;
  if (!code) return <Error error="Incorrect URL" />;

  const Badge = badges[Math.floor(code / 100) - 1];

  return (
    <Badge className="tooltip-parent bl-status_badge bl-status_badge-badge" style={style}>
      {code}
      <UI.Tooltip>
        {codes[code]}
      </UI.Tooltip>
    </Badge>
  );
};

export default StatusBadge;
