import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import Signin from '../../views/Authorization/components/Signin';
import Signup from '../../views/Authorization/components/Signup';
import Restore from '../../views/Authorization/components/Restore';

import { link } from '/services/Components/methods';

import { UI, BL, UN } from '/views';

import './styles.scss';

class UserLogin extends Component {
  renderTab = (idx) => {
    let component = null;

    if (idx === 0) component = <Signin />;
    if (idx === 1) component = <Restore />;

    return (
      <div className="user-login_form-wrapper">
        <div className="user-login_form">
          <div className="user-login_logo">
            <img src="/images/f31b5e61a5c8.svg" className="img-fluid" alt="SERP.tools" />
          </div>
          {component}
        </div>
      </div>
    );
  };

  renderUserLogin = () => {
    const sets = {
      items: [
        'Авторизация',
        'Восстановление доступа',
      ],
      child: this.renderTab,
    };

    return <BL.Tabs className="user-login_tabs-static" { ...sets } />;
  };

  renderUserPage = () => {
    const { lang } = this.props;

    return (
      <div className="user-login_authorized">
        <div className="user-login_authorized-title">Вы успешно авторизовались</div>
        <div className="user-login_authorized-button">
          <UI.Button.Warning href={link('Dashboard.Root', { lang })} style={{ minWidth: 220 }} text="Перейти в Dashboard" />
        </div>
      </div>
    );
  };

  render() {
    const { user } = this.props;

    const sets = {
      items: [
        'Авторизация',
        'Восстановление доступа',
      ],
      child: this.renderTab,
    };

    return (
      <div className="user-login_page">
        <div className="user-login_page-wrapper">
          {user.id !== null ? this.renderUserPage() : this.renderUserLogin()}
        </div>
      </div>
    );
  }
}

export default UserLogin;
