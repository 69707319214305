export default (params, props, state = {}) => {
  const { searcher, sorter, pagination, settings } = props;

  const visible = (props.data || []).slice();
  const filtered = (state.data && state.data.visible.length && state.data.filtered) || visible.slice();

  const data = { visible, filtered };

  let search = params.search || state.search;
  let sort = params.sort || state.sort;
  let paging = params.paging || state.paging;

  if (params.search || params.sort) {
    if (searcher && search.value) {
      data.visible = searcher(search.value, data.visible, settings);
    }

    if (sorter && sort.field) {
      data.visible = data.visible.sort(sorter(sort.field, sort.dir, settings));
    }

    data.filtered = data.visible.slice();
  } else {
    data.visible = data.filtered.slice();
  }

  if (pagination && paging.limit) {
    const limit = paging.limit;
    const page = paging.page * limit < data.visible.length
      ? paging.page
      : Math.floor((data.visible.length) / limit);

    data.visible = data.visible.slice(page * limit, (page + 1) * limit);

    paging = { page, limit };
  }

  return { data, search, sort, paging };
};
