import React, { Component, Fragment } from 'react';

class Description extends Component {
  state = {
    expand: false,
  };

  isExp = () => this.state.expand;

  onToggle = event => {
    event.preventDefault();

    this.setState(state => ({ ...state, expand: !state.expand }));
  };

  render() {
    const { short, full, expand = 'Подробнее', collapse = 'Скрыть описание' } = this.props;

    let toggler = <a href="#" className="fw-500" onClick={this.onToggle}><u>{this.isExp() ? collapse : expand}</u></a>;

    if (this.isExp()) {
      toggler = <div className="mt-1">{toggler}</div>;
    }

    return (
      <Fragment>
        {this.isExp() ? full : short}
        {!this.isExp() && ' '}
        {toggler}
      </Fragment>
    );
  }
}

export default Description;
