import React, { Fragment, useState } from 'react';

import { Lang } from '/contexts/Lang';

import Headers from '../Headers';
import NoContent from '../NoContent';
import ContentValue from '../ContentValue';

import { UI, BL } from '/views';
import { restructurizeTagsTree, getTagCount } from '../../utils/tagsUtils';

const Item = props => {
  const { lang, item, index, expanded } = props;
  const { content } = item || {};

  const titleValue = content ? content.title.value : undefined;
  const descValue = content ? content.description.value : undefined;
  let tags_h = [];

  if (content) {
    const h1Count = getTagCount(content.structurized_tags_h, 'h1');

    if (h1Count > 1 || (h1Count > 0 && content.structurized_tags_h[0].type !== 'h1')) {
      tags_h = restructurizeTagsTree(content.structurized_tags_h);
    } else {
      tags_h = content.structurized_tags_h;
    }
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td className="url">
        <div className="tools_url-box">
          <BL.StatusBadge code={item.statusCode} error={item.error} style={{ marginLeft: 0 }} /> <a href={item.url} target="_blank">{item.croppedUrl}</a>
        </div>
      </td>
      <td key="title">
        <ContentValue lang={lang} value={titleValue} />
      </td>
      <td key="description">
        <ContentValue lang={lang} value={descValue} />
      </td>
      <td key="tags_h">
        {content
          ? <Headers lang={lang} tree={tags_h} expanded={expanded} />
          : <NoContent text={lang('state_cantCheck')} />
        }
      </td>
    </tr>
  );
};

const Table = props => {
  const { lang, list } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <Fragment>
      <table className="table table-responsive-md select-default tools_tdh-table-results">
        <thead>
          <tr>
            <th className="tools_tdh-table-number">#</th>
            <th className="tools_tdh-table-results-col">{lang('col_url')}</th>
            <th className="tools_tdh-table-results-col">{lang('col_title')}</th>
            <th className="tools_tdh-table-results-col">{lang('col_description')}</th>
            <th>{lang('col_h1h6')} <UI.Button.Link className="p-0 ml-2" onClick={toggleExpand}>{lang('button_toggleAll')}</UI.Button.Link></th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, key) => <Item key={key} lang={lang} item={item} index={key} expanded={expanded}/>)}
        </tbody>
        <tfoot>
          <tr>
            <th>#</th>
            <th>{lang('col_url')}</th>
            <th>{lang('col_title')}</th>
            <th>{lang('col_description')}</th>
            <th>{lang('col_h1h6')} <UI.Button.Link className="p-0 ml-2" onClick={toggleExpand}>{lang('button_toggleAll')}</UI.Button.Link></th>
          </tr>
        </tfoot>
      </table>
    </Fragment>
  );
};

export default Table;
