import React, { Fragment } from 'react';

const TitleDescription = props => {
  return (
    <Fragment>
      <p><b>Зеркала сайта</b> - это разные сайты, доступные по разным адресам, но с идентичным контентом (<a href="https://yandex.ru/support/webmaster/yandex-indexing/site-mirrors.html" target="_blank">почитать в справке Яндекса</a>). При обнаружении копий сайта, поисковая система будет пытаться определить один главный сайт, чтобы показывать в поиске именно его. Такой сайт называется главным зеркалом. Чтобы в поиске показывался нужный вам сайт, необходимо настроить 301 редирект на главное зеркало.</p>
      <p>Вот примеры возможных зеркал сайта:</p>
      <ul>
        <li>Версия сайта с протоколом http и https (<b className="text-danger">http</b>://site.ru и <b className="text-danger">https</b>://site.ru)</li>
        <li>Версия сайта с префиксом www и без (<b className="text-danger">www.</b>site.ru и site.ru)</li>
        <li>Разные доменные зоны с одинаковым контентом (site.<b className="text-danger">ru</b> и site.<b className="text-danger">com</b>)</li>
        <li>Разные домены с одинаковым контентом (<b className="text-danger">site</b>.ru и <b className="text-danger">newsite</b>.ru)</li>
      </ul>
      <p>В SERP.tools мы автоматически определяем главное зеркало для 4-х возможных версий вашего сайта: <b className="text-danger">http</b> и <b className="text-danger">https</b>, <b className="text-danger">www</b> и <b className="text-danger">без www</b> - для поисковых систем это разные сайты (<a href="https://yandex.ru/support/webmaster/yandex-indexing/www-migration.html" target="_blank">почитать в справке Яндекса</a>). Найти главное зеркало нужно для корректной работы многих отчетов в аудите.</p>
      <p>Если у вас есть копии сайта на субдоменах или в других доменных зонах, пожалуйста, убедитесь, что они не мешают вашему главному зеркалу и не ищутся вместо него в поиске.</p>
    </Fragment>
  );
};

export default TitleDescription;
