export default {
  100: 'Continue',
  101: 'Switching\u00A0Protocol',
  103: 'Early\u00A0Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No\u00A0Content',
  205: 'Reset\u00A0Content',
  206: 'Partial\u00A0Content',
  300: 'Multiple\u00A0Choices',
  301: 'Moved\u00A0Permanently',
  302: 'Found',
  303: 'See\u00A0Other',
  304: 'Not\u00A0Modified',
  307: 'Temporary\u00A0Redirect',
  308: 'Permanent\u00A0Redirect',
  400: 'Bad\u00A0Request',
  401: 'Unauthorized',
  402: 'Payment\u00A0Required',
  403: 'Forbidden',
  404: 'Not\u00A0Found',
  405: 'Method\u00A0Not\u00A0Allowed',
  406: 'Not\u00A0Acceptable',
  407: 'Proxy\u00A0Authentication Required',
  408: 'Request\u00A0Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length\u00A0Required',
  412: 'Precondition\u00A0Failed',
  413: 'Payload\u00A0Too\u00A0Large',
  414: 'URI\u00A0Too\u00A0Long',
  415: 'Unsupported Media\u00A0Type',
  416: 'Range\u00A0Not\u00A0Satisfiable',
  417: 'Expectation\u00A0Failed',
  418: 'I\'m\u00A0a\u00A0teapot',
  422: 'Unprocessable\u00A0Entity',
  425: 'Too\u00A0Early',
  426: 'Upgrade\u00A0Required',
  428: 'Precondition\u00A0Required',
  429: 'Too\u00A0Many\u00A0Requests',
  431: 'Request\u00A0Header Fields\u00A0Too\u00A0Large',
  451: 'Unavailable\u00A0For Legal\u00A0Reasons',
  500: 'Internal\u00A0Server\u00A0Error',
  501: 'Not\u00A0Implemented',
  502: 'Bad\u00A0Gateway',
  503: 'Service\u00A0Unavailable',
  504: 'Gateway\u00A0Timeout',
  505: 'HTTP\u00A0Version Not\u00A0Supported',
  506: 'Variant\u00A0Also Negotiates',
  507: 'Insufficient\u00A0Storage',
  508: 'Loop\u00A0Detected',
  510: 'Not\u00A0Extended',
  511: 'Network\u00A0Authentication Required',
};
