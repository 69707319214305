import React, { Component } from 'react';
import cn from 'classnames';

import { UI } from '/views';

import { SEARCER_TYPE, UPDATE_TYPE, UPDATE_NAME } from '/components/SearcherUpdates/constants';

class Toggler extends Component {
  onClick = () => {
    const { type, state, onToggle } = this.props;

    onToggle(type, !state);
  };

  render() {
    const { name, type, state } = this.props;

    const Button = state ? UI.Button.Success : UI.Button.Light;

    return (
      <Button text={name} className="mx-1" size="xs" onClick={this.onClick} />
    );
  }
}

const UpdatesToggler = props => {
  const { className, searcher, states, onToggle } = props;

  if (searcher !== SEARCER_TYPE.GOOGLE) return null;

  const items = Object.values(UPDATE_TYPE).map(type => {
    const name = UPDATE_NAME[type];
    const state = !states.includes(type);

    return { name, type, state, onToggle };
  });

  return (
    <div className={cn('d-flex justify-content-center', className)}>
      {items.map(item => <Toggler key={item.type} { ...item } />)}
    </div>
  );
};

export default UpdatesToggler;
