export default [
  {
    name: 'Запрос',
    field: 'query',
    sortable: true,
    sortFirst: 'asc',
  },
  {
    name: 'Ссылки',
    field: 'links',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '10%', maxWidth: '90px', minWidth: '74px', textAlign: 'center' },
  },
  {
    name: 'Статус',
    field: 'state',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '10%', maxWidth: '90px', minWidth: '74px', textAlign: 'center' },
  },
  {
    name: 'Общий трафик',
    style: { width: '160px', maxWidth: '160px', minWidth: '160px', textAlign: 'center' },
    childs: [
      {
        name: '1 Год',
        field: 'year_0',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '80px', maxWidth: '80px', minWidth: '80px', textAlign: 'center' },
      },
      {
        name: '2 Год',
        field: 'year_1',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '80px', maxWidth: '80px', minWidth: '79px', textAlign: 'center' },
      },
    ],
  },
  {
    name: 'Разница год к году',
    style: { width: '34%', maxWidth: '400px', minWidth: '300px', textAlign: 'center' },
    childs: [
      {
        name: 'Год',
        field: 'diff_year',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '13%', minWidth: '100px', textAlign: 'center' },
      },
      {
        name: '3 месяца',
        field: 'diff_3_month',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '13%', minWidth: '100px', textAlign: 'center' },
      },
      {
        name: '1 месяц',
        field: 'diff_1_month',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '13%', minWidth: '100px', textAlign: 'center' },
      },
    ],
  },
];
