import React from 'react';

import BL from '/views/blocks';

import config from './config';

const sorter = (a, b) => {
  let _a = a.audits[0].dateAdd;
  let _b = b.audits[0].dateAdd;

  if (_a === _b) {
    _a = a.name;
    _b = b.name;
  }

  if (_a < _b) return  1;
  if (_a > _b) return -1;

  return 0;
};

const Table = props => {
  const { data, ...sets } = props;

  const list = data.sort(sorter);

  return <BL.Table className="darken" data={list} { ...sets } { ...config } />;
};

export default Table;
