import { getInitial } from '../../Store';

const defaults = {
  path: '',
  method: '',
  component: '',
  action: '',
  layout: '',
  params: {
    get: {},
    post: {},
    url: {}
  }
};

export default () => getInitial('router', defaults);
