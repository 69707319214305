import { accesses } from './methods';

import { isArray, isUndefined } from '/utils/types';

import { DEFAULT } from '/static/data/roles';

class Access {
  constructor(role) {
    role && this.setRole(role);
  }

  setRole = role => this.role = role;
  getRole = () => this.role;

  _get(role, path) {
    const [component, ...parts] = isArray(path) ? path : path.split('.');

    let name, context = accesses[component][role] || {};

    // console.log('==================');
    // console.log('## 0 ->', accesses);
    // console.log('## 1 ->', path);
    // console.log('## 2 ->', component, role);
    // console.log('## 3 ->', accesses[component], context);

    while (name = parts.shift()) {
      // console.log('## 4 ->', context, name);

      context = context[name];

      if (!context) {
        context = {};

        break;
      }
    }

    // console.log('==================');

    return context.available;
  }

  check(path) {
    let available;

    if (this.role) {
      available = this._get(this.role, path);
    }

    if (isUndefined(available)) {
      available = this._get(DEFAULT, path);
    }

    return !!available;
  }
}

Access.check = (role, path) => new Access(role).check(path);

export default Access;
