import React, { Component, Fragment } from 'react';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import Statistics from './components/Statistics';

import { UI, BL } from '/views';

import { link } from '/services/Components/methods';

import normalizeData from './utils/normalizeData';
import groupData from './utils/groupData';

import { PERIODS } from './constants';

class AuditReports extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        type: '',
        group: null,
      },
      data: {},
    };
  }

  onProcess = async values => {
    const { type, group } = values;

    const update = {};

    update.insert = { type, group };

    if (!this.state.data[type]) {
      const stats = await this.fetch(type);

      update.data = { ...this.state.data, [type]: stats };
    }

    this.setState(update);
  };

  async fetch(type) {
    const data = await this.provider.getStatistics(type, PERIODS.LAST_12_MONTH);

    return normalizeData(data);
  }

  render() {
    const { insert, data } = this.state;

    const { type, group } = insert;

    const groups = type in data && groupData(data[insert.type], insert.group);

    return (
      <Fragment>
        <BL.Title icon="fal fa-ballot-check" name="Статистика" />

        <BL.Panel>
          <BL.Panel.Header>
            <div className="title ml-1 mr-1 fs-9">
              <b>Статистика использования сисиемы</b>
            </div>
          </BL.Panel.Header>

          <BL.Panel.Content>
            <InsertBox onProcess={this.onProcess} />
            <Statistics groups={groups} />
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default AuditReports;
