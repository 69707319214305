const self = {};

self.get = function get(audit_id, id) {
  const url = '/api/v1/audits/' + audit_id + '/reports/' + id;
  const method = 'get';

  return this.axios({ url, method });
};

self.getSettings = function getSettings(audit_id, id) {
  const url = '/api/v1/audits/' + audit_id + '/reports/' + id + '/settings';
  const method = 'get';

  return this.file({ url, method });
};

self.updateSettings = function updpateSettings(audit_id, id, update) {
  const url = '/api/v1/audits/' + audit_id + '/reports/' + id + '/settings';
  const method = 'post';

  const data = { update };

  return this.axios({ url, method, data });
};

self.getResult = function getReport(audit_id, id, file) {
  const url = '/api/v1/audits/' + audit_id + '/reports/' + id + '/result/' + file;
  const method = 'get';

  return this.file({ url, method });
};

self.downloadLink = function downloadLink(audit_id, id, user_id, file, middleName) {
  return this.HOST + '/api/v1/audits/' + audit_id + '/reports/' + id + '/' + user_id + '/export?file=' + file + '&middle_name=' + middleName;
};

export default self;
