import React from 'react';
import cn from 'classnames';

import RobotsDisallowRules from '../../../../../views/RobotsDisallowRules';

import { UI, BL } from '/views';

import { AGENTS } from '../../../constants';

const Child = props => {
  const { item, robots } = props;

  return <RobotsDisallowRules items={item.result} robots={robots} />;
};

const child = function child(item, actions, settings) {
  return <Child item={item} robots={settings.robots} />;
}

child.check = function check(item) {
  const { result } = item;

  return !result[AGENTS.ALL].allowed || !result[AGENTS.GOOGLE].allowed || !result[AGENTS.YANDEX].allowed;
};

export default child;
