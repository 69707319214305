const self  = {};

self.head_title = 'Robots.txt Validator and Tester - Free Online Tool for SEO';
self.head_keywords = '';
self.head_description = 'Bulk test a list of URLs against a robots.txt file, check if URLs are blocked and how. Make sure if search engine crawlers allowed to crawl your URLs';

self.name = 'Robots.txt checker';

self.title = 'Robots.txt Testing Tool and Validator';
self.description = 'The tool attempts to mimic the behaviour for Googlebot and Yandex and allows you to check whether URLs crawling is allowed or disallowed for search engine robots in the robots.txt file. Please, enter URLs (one per line) to test if they are allowed, or blocked and if so, by what rule in robots.txt. URLs should start with http / https protocol, for URLs without a protocol, the https protocol will be used.  You can use different hosts\' urls and they will be grouped by host in the results of the check.';

self.field_title = 'URLs to test (one per line)';
self.field_links_count = 'URLs count: <b><%= count %></b>';

self.error_incorrectUrl = 'All URLs should be full and start with http / https, one per line';
self.error_duplicateUrl = 'There are duplicate URLs in the list';

self.error_missingRobots = 'The file at the address <b><%= url %></b> returns the server response code <b><%= status %></b>, so it cannot be downloaded. Please check if you entered the website URL correctly. Probably robots.txt file is missing.';

self.button_check = 'Test URLs';

self.result_title = 'The result of URLs test for <%= domain %>';
self.result_links_count = 'URLs count';

self.tab_links = 'URLs';
self.tab_robots = 'Robots.txt';

self.col_yandex = 'Yandex';
self.col_google = 'Google';
self.col_url = 'URLs';

self.result_subtitle_all = 'Restrictive rule for all search engines';
self.result_subtitle_yandex = 'Restrictive rule for Yandex';
self.result_subtitle_google = 'Restrictive rule for Google';

export default self;
