import { getListArg } from '/utils/array';

export const accesses = {};

export const addComponent = function addComponent(items) {
  const list = getListArg(items, arguments);

  for (let component, i = 0; component = list[i]; i++) {
    const { settings, access } = component;

    accesses[settings.name] = access;
  }
};
