import React, { Component } from 'react';
import cn from 'classnames';

import { isFunction } from '/utils/types';

class Collapse extends Component {
  state = {
    expand: false
  };

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._expand !== _props.expand) {
      result.expand = result._expand = _props.expand;
    }

    return result;
  };

  onClick = () => {
    const { idx, onToggle } = this.props;

    this.setState({ expand: !this.state.expand });

    onToggle && onToggle(idx);
  };

  renderTitle = () => {
    const { expand } = this.state;
    const { title } = this.props;

    const icon = expand ? 'fa-minus' : 'fa-plus';

    return (
      <div className="card-header">
        <a href="#" className={cn('card-title select-none d-block', !expand && 'collapsed')} onClick={this.onClick}>
          <span className="mr-2">
            <span className="collapsed-reveal">
              <i className={cn('fal fs-xl', icon)} />
            </span>
          </span>
          {isFunction(title) ? title() : title}
        </a>
      </div>
    );
  };

  renderBody = () => {
    const { expand } = this.state;
    const { body, children } = this.props;

    if (!expand) return null;

    const content = body || children;

    return (
      <div className="collapse show">
        <div className="card-body">
          {isFunction(content) ? content() : content}
        </div>
      </div>
    );
  };

  wrap = content => {
    const { isOuterControl } = this.props;

    if (isOuterControl) return content;

    return (
      <div class="accordion accordion-clean">
        {content}
      </div>
    );
  };

  render() {
    return (
      <div className="card">
        {this.renderTitle()}
        {this.renderBody()}
      </div>
    );
  }
}

export default Collapse;
