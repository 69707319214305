import React, { PureComponent, Fragment } from 'react';
import cn from 'classnames';

import { Lang } from '/contexts/Lang';

import { UI } from '/views';

class FilterButton extends PureComponent {
  onToggle = () => {
    const { status, disabled, onToggle } = this.props;

    onToggle(status, !disabled);
  };

  getButtonView() {
    const { status, disabled } = this.props;

    if (disabled) return UI.Button.Light;

    if (!status) return UI.Button.Default;

    return [
      UI.Button.Secondary,
      UI.Button.Success,
      UI.Button.Warning,
      UI.Button.Danger,
      UI.Button.Danger,
    ][Math.floor(status / 100) - 1];
  }

  render() {
    const { children } = this.props;

    const Button = this.getButtonView();

    return <Button size="xs" className="pt-1 pb-1 tools_server_response-filter_badge" onClick={this.onToggle}>{children}</Button>;
  }
}

class Toolbar extends PureComponent {
  returnButton = item => {
    const { actions, settings } = this.props;

    const items = settings.items.filter(v => {
      return !!v.result && v.result[0].statusCode === item.status;
    });

    return (
      <li key={item.status} className="d-block ml-3">
        <FilterButton {...item} {...actions}>
          {item.status || <i className={cn('fal fa-ban font-weight-bold tooltip-parent', !item.disabled && 'color-danger-900')} />}
          <span className="tools_server_response-status_badge">
            {items.length}
          </span>
        </FilterButton>
      </li>
    );
  }

  renderFilters() {
    const { statuses } = this.props.settings;

    return (
      <ul className="d-flex flex-wrap m-0 p-0">
        {statuses.map(this.returnButton)}
      </ul>
    );
  }

  render() {
    return (
      <Fragment>
        <span className="select-none"><Lang text="filter_code" />:</span>
        {this.renderFilters()}
      </Fragment>
    );
  }
}

const toolbar = (data, actions, settings) => {
  return <Toolbar data={data} actions={actions} settings={settings} />;
};

export default toolbar;
