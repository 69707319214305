import React, { Fragment } from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

import { SOURCE, TYPE } from '../../../constants';

import { capitalize } from '/utils/string';

const fields = {};

fields.url = item => <a href={item.link} target="_blank">{item.link}</a>;

fields.source = item => {
  return {
    [SOURCE.AUTOMATICALY]: 'Типовой адрес',
    [SOURCE.ROBOTS]: 'Файл robots.txt',
    [SOURCE.MANUAL]: 'Добавлена пользователем',
  }[item.source];
};

fields.response_code = item => <BL.StatusBadge code={item.response_code} />;

fields.valid = item => {
  const icon = item.valid ? <UI.Icon.Good className="fs-11" /> : <UI.Icon.Danger className="fs-11" />;
  const text = <span className={cn('ml-1', item.valid ? 'text-success' : 'text-danger')}>{item.valid ? 'Да' : 'Нет'}</span>;

  return <div className="d-flex align-items-center justify-content-center">{icon} {text}</div>;
};

fields.type = item => {
  return {
    [TYPE.INDEX_SITEMAP]: 'Индексная карта',
    [TYPE.SITEMAP]: 'Обычная карта',
    [TYPE.UNKNOWN]: 'Не опознана',
  }[item.type] || '-';
};

fields.actions = (item, actions, settings) => {
  const sets = {
    className: 'py-1 px-2',
    size: 'xs'
  };

  if (!item.valid) {
    sets.className += ' color-danger-500';

    return <UI.Button.Light { ...sets } icon="fal fa-exclamation-triangle">Не доступно</UI.Button.Light>;
  }

  if ((!item.id && !item.isAdded) || (item.id && item.isRemoved)) {
    return <UI.Button.Success { ...sets } icon="fal fa-plus" onClick={() => actions.onSitemapAdd(item.link)}>Добавить</UI.Button.Success>;
  }

  if ((!item.id && item.isAdded) || (item.id && !item.isRemoved)) {
    return <UI.Button.Danger { ...sets } icon="fal fa-times" onClick={() => actions.onSitemapRemove(item.link)}>Удалить</UI.Button.Danger>;
  }
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
