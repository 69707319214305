import { getInitial } from '/services/Store';

import { STATUS_INITIAL } from './constants';

const defaults = {
  status: STATUS_INITIAL,
  searchers: {
    yandex: [],
    google: [],
  },
};

export default () => getInitial('searcherUpdates', defaults || {});
