import view from './view';

import { createRoute } from '/services/Router/route';

const route = createRoute({ path: '/', layout: 'HomePage' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

export default { route, view, settings };
