import axios from 'axios';

import { select, call, put, take, takeEvery } from 'redux-saga/effects';
import { requestYandexAccaunt, addYandexAccaunt } from './actions';

import Yandex from '/services/Yandex';

function* _requestYandexAccaunt() {
}

export default function* settingsSage() {
  yield takeEvery(requestYandexAccaunt, _requestYandexAccaunt);
}
