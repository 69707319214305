export { default as markdown } from './markdown';

export const replace = (string, params = {}) => {
  const regExp = /<%=\s*(\w+)\s*%>/g;

  return string.replace(regExp, (m, $1) => $1 in params ? params[$1] : m);
};

export const matchAll = (string, regExp) => {
  if (!regExp.global) throw new Error('RegExp must have global modifier');

  const result = [];
  let match;

  while (match = regExp.exec(string)) {
    result.push(match);
  }

  return result;
};

export const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

export const capitalizeAll = (string) => string.split(' ').map(capitalize).join(' ');
