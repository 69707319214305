import { connect } from 'react-redux'
import View from './view';

import { collect } from '/utils/object';

const mapStateToProps = (state) => {
  const { user, router } = state;
  const { path, component, action, layout, params } = router;

  const { lang } = params.url;

  return { path, component, action, layout, user, lang };
};

export default connect(mapStateToProps)(View);
