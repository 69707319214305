import React, { Component } from 'react';

import { UN } from '/views';

import types from './types';
import groups from './groups';

class InsertBox extends Component {
  constructor(props) {
    super(props);

    this.Form = UN.Form();
  }

  onSubmit = values => {
    const { onProcess } = this.props;

    onProcess(values);
  };

  render() {
    const { Form } = this;

    const { lock, onProcess } = this.props;

    return (
      <Form className="py-2" onSubmit={this.onSubmit}>
        <div className="d-flex flex-row">
          <div>
            Тип данных:<br />
            <Form.Select name="type" values={types} placeholder="Выберите тип" required />
          </div>
          <div className="ml-2">
            Группировать по:<br />
            <Form.Select name="group" values={groups} placeholder="Выберите способ группировки" required />
          </div>
          <div className="ml-2" style={{ paddingTop: 30 }}>
            <Form.Submit size="sm" icon2="fal fa-angle-double-right" spin2={lock} text="Показать" style={{ whiteSpace: 'nowrap' }} />
          </div>
        </div>
      </Form>
    );
  };
}

export default InsertBox;
