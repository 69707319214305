const fields = {};

const color_success = 'report-result-good';
const color_static = 'report-result-static';
const color_subside = 'report-result-subside';
const color_warning = 'report-result-warning';
const color_danger = 'report-result-danger';
const color_critical = 'report-result-critical';
const color_unknown = 'report-result-unknown';

fields.verdict = item => {
  const states = {
    TRAFFIC_GROW: color_success,
    TRAFFIC_DROP: color_danger,
    TRAFFIC_ZERO: color_critical,
    TRAFFIC_SUBSIDE: color_subside,
    TRAFFIC_NEW: color_static,
  };

  return states[item.verdict];
};

const getStatisticColor = statistic => {
  const list = {
    STATISTIC_GOOD: color_success,
    STATISTIC_STATIC: color_static,
    STATISTIC_SUBSIDE: color_subside,
    STATISTIC_WARNING: color_warning,
    STATISTIC_DANGER: color_danger,
    STATISTIC_UNKNOWN: color_unknown,
    STATISTIC_CRITICAL: color_critical,
  };
};

fields.diff_year = item => {
  const { statistic } = item.analytics.total.values;

  return getStatisticColor(statistic);
};

fields.diff_3_month = (item, actions, settings) => {
  const { statistic } = item.analytics.total.values;

  return getStatisticColor(statistic);
};

fields.diff_1_month = (item, actions, settings) => {
  const { statistic } = item.analytics.total.values;

  return getStatisticColor(statistic);
};


export default (name, item, settings) => !!fields[name] && fields[name](item, settings);
