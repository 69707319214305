import React, { Fragment } from 'react';
import cn from 'classnames';

const Icon = props => {
  const { icon, className, style } = props;

  const sets = {};

  sets.className = cn(icon, className);

  style && (sets.style = style);

  return <i { ...sets } />;
};

Icon.Good = ({ icon, className, ...props }) => <Icon icon={icon || 'fal fa-check-circle'} className={cn('text-success', className)} { ...props } />;
Icon.Warning = ({ icon, className, ...props }) => <Icon icon={icon || 'fal fa-exclamation-circle'} className={cn('text-warning', className)} { ...props } />;
Icon.Danger = ({ icon, className, ...props }) => <Icon icon={icon || 'fal fa-times-circle'} className={cn('text-danger', className)} { ...props } />;

export default Icon;
