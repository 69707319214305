import React, { Fragment } from 'react';
import moment from 'moment';

import { UI, BL } from '/views';

import { capitalize } from '/utils/string';

import { UP_TO_NOW, LONG_AGO, NOT_DONE } from '../../constants';

const STYLE = { width: 360, textAlign: 'center', fontSize: '0.8rem', pointerEvents: 'none' };

const labelGood = () => <i className="fal fa-check-circle text-success fs-12 mr-2" />;
const labelProblem = () => <i className="fal fa-exclamation-circle text-danger fs-12 mr-2" />;

const httpsDateFrom = (stats, analytics) => {
  const { enableDate } = analytics.https;

  const text = {
    [LONG_AGO]: 'Подключен более 2 лет назад',
    [NOT_DONE]: 'Не подключен',
  }[enableDate] || moment(enableDate.from).format('MMMM YYYY');

  return {
    name: () => (
      <Fragment>
        Месяц подключения https{'\u00A0'}
        <BL.Info.Dark side={BL.Info.SIDE_CT} style={STYLE} boundingBox=".panel">
          Месяц, в котором появился трафик из поиска на https-версию сайта
        </BL.Info.Dark>
      </Fragment>
    ),
    value: capitalize(text),
  };
};

const httpsDateSwitch = (stats, analytics) => {
  const { isNewSite, switchDate } = analytics.overall;
  const { value } = stats.https.total;

  const label = switchDate !== NOT_DONE ? labelGood() : labelProblem();

  const text = {
    [LONG_AGO]: 'Переход выполнен более 2 лет назад',
    [NOT_DONE]: 'Переход не выполнен',
  }[switchDate] || (isNewSite ? 'Переход выполнен с момента запуска сайта' : moment(switchDate.from).format('MMMM YYYY'));

  return {
    name: () => (
      <Fragment>
        Месяц перехода на https{'\u00A0'}
        <BL.Info.Dark side={BL.Info.SIDE_CT} style={STYLE} boundingBox=".panel">
          Месяц, в котором поисковый трафик на https-версию превысил поисковый трафик на http-версию
        </BL.Info.Dark>
      </Fragment>
    ),
    value: () => (
      <Fragment>
        {label}{capitalize(text)}
      </Fragment>
    ),
  };
};

const httpZeroDateFrom = (stats, analytics) => {
  const { isNewSite } = analytics.overall;
  const { disableDate } = analytics.http;

  const label = disableDate !== UP_TO_NOW ? labelGood() : labelProblem();

  const text = {
    [LONG_AGO]: isNewSite ? 'Трафика нет с момента запуска сайта' : 'Трафика нет более 2 лет',
    [UP_TO_NOW]: 'Трафик есть до сих пор',
  }[disableDate] || moment(disableDate.from).format('MMMM YYYY');

  return {
    name: () => (
      <Fragment>
        Месяц, с которого нет http-трафика{'\u00A0'}
        <BL.Info.Dark side={BL.Info.SIDE_CT} style={STYLE} boundingBox=".panel">
          Месяц, с которого 100% поискового трафика идет на https-версию сайта. В данном случае мы не учитываем месяцы, когда на http-версию попало 1-3 посетителя - это скорее погрешность
        </BL.Info.Dark>
      </Fragment>
    ),
    value: () => (
      <Fragment>
        {label}{capitalize(text)}
      </Fragment>
    ),
  };
};

const Stats = props => {
  const { stats, analytics, className } = props;

  const title = 'Статистика';

  const items = [
    httpsDateFrom(stats, analytics),
    httpsDateSwitch(stats, analytics),
    httpZeroDateFrom(stats, analytics),
  ];

  return <UI.DetailList className={className} title={title} items={items} />;
};

export default Stats;
