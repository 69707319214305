export default (id, searchers) => {
  for (let name in searchers) {
    if (!searchers.hasOwnProperty(name)) continue;

    for (let item, i = 0; item = searchers[name][i]; i++) {
      if (item.id === id) {
        return item;
      }
    }
  }

  return null;
};
