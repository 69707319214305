import React, { Component, Fragment } from 'react';

import Popup from '../../../Popup';

import columnFinder from '../../../../utils/columnFinder';
import columnPath from '../../../../utils/columnPath';
import matchLabel from '../../../../utils/matchLabel';

class Bar extends Component {
  state = {
    hover: false,
  };

  showPopup = () => this.setState({ hover: true });
  hidePopup = () => this.setState({ hover: false });

  renderPopup = () => {
    const { hover } = this.state;
    const { idx, label, name, value, color, popup } = this.props;

    if (!hover) return null;

    if (popup) return popup(idx, this.props);

    const type = 'bars';

    const values = [
      { type, name, value, color },
    ];

    return <Popup label={label} values={values} style={{ zIndex: 4 }} />
  };

  render() {
    const { hover } = this.state;
    const { idx, box, fill, fillHover, pointColor, pointColorHover, popup } = this.props;

    const position = { left: box.x[0], top: box.y[0] };

    const style = {
      ...position,
      width: box.width,
      height: box.height,
      opacity: 1,
      zIndex: 3,
    };

    return (
      <Fragment>
        <div style={{ position: 'absolute', ...position, width: box.width, zIndex: 4 }}>
          {this.renderPopup()}
        </div>
        <div
          className="ui_line_chart-chart_bar_overlay"
          style={style}
          onMouseEnter={this.showPopup}
          onMouseLeave={this.hidePopup}
        />
      </Fragment>
    );
  }
}

export default bars => {
  const items = [];

  for (let i = 0, len = bars.length; i < len; i++) {
    const item = bars[i].boxes.map((item, idx) => item.marker && <Bar key={i + ':' + idx} idx={idx} { ...item } />);

    items.push(...item);
  }

  return items;
};
