import ReactDOM from 'react-dom';

import '/static/images/favicon.png';

import '/utils/config/setup.client';

import { setRender } from '/App';

const render = function (getComponent) {
  const root = document.getElementById('root');
  const method = root.childNodes.length ? 'hydrate' : 'render';

  return ReactDOM[method](getComponent(), root);
};

setRender(render);
