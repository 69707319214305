export default [
  {
    name: 'Описание',
    field: 'description',
    sortable: true,
    sortFirst: 'asc',
  },
  {
    name: 'Дата начала',
    field: 'date_start',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '20%', maxWidth: '20%', minWidth: '20%', textAlign: 'center' },
  },
  {
    name: 'Дата окончания',
    field: 'date_finish',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '20%', maxWidth: '20%', minWidth: '20%', textAlign: 'center' },
  },
];
