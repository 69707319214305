import React, { Fragment } from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';
import { createArray, deduplicate } from '/utils/array';

import time2day from '../../utils/time2day';
import getLabelsByDays from '../../utils/getLabelsByDays';
import getLabelsByMonths from '../../utils/getLabelsByMonths';
import getLabelsBySeasons from '../../utils/getLabelsBySeasons';

const displayDate = date => moment(date).format('D MMM YYYY');

const names = {
  yandex: 'Яндекс',
  google: 'Google',
};

const types = {
  yandex: 'info',
  google: 'primary',
};

const updates = {
  yandex: 'warning',
  google: 'info',
};

const collectChartData = (items, upates) => {
  const { months } = items[0];

  const labels = (() => {
    if (months.length <= 1) return getLabelsByDays(months);
    if (months.length <= 12) return getLabelsByMonths(months);

    return getLabelsBySeasons(months);
  })();

  const lines = items.map(item => {
    const type = types[item.searcher];

    return {
      name: item.searcher,
      label: 'Дневной трафик ' + capitalize(item.searcher),
      pointColor: color[type]._300,
      pointColorHover: color[type]._500,
      color: color[type]._300,
      fill: color.rgba(color[type]._50, 0.4),
      values: [].concat(...item.months.map(month => month.values)),
    };
  });

  const from = time2day(items[0].date.from);

  const sections = upates.map(update => {
    const _color = updates[update.name];

    return {
      name: update.name,
      label: names[update.name],
      color: color[_color]._300,
      fill: color.rgba(color[_color]._50, 0.4),
      values: update.items.map(upd => {
        const _from = Math.max(0, time2day(upd.date_start) - from);
        const _to = Math.max(0, time2day(upd.date_finish) - from);

        const period = deduplicate([upd.date_start, upd.date_finish]).map(displayDate).join(' - ');

        return {
          from: _from,
          to: _to,
          description: () => (
            <Fragment>
              {upd.name_ru}<br />{period}
            </Fragment>
          ),
        };
      }),
    };
  });

  return { labels, lines, sections };
};

const settings = {
  legend: {
    clickable: false,
  },
  marker: {
    type: 'overall',
    column: {
      color: 'rgba(255, 39, 139, 0.4)',
    },
  },
  popup: {
    label: (labels, items) => items[items.length - 1].popupLabel,
    // label: (labels, items) => labels[2] + ' ' + labels[1] + ' (' + items[items.length - 1].day + ')',
  },
};

const trimData = (data, selection) => {
  if (!selection) return data;

  const caps = data[0].months.reduce((result, month) => {
    const prev = result[result.length - 1];

    result.push(prev + month.values.length);

    return result;
  }, [0]);

  const bound = [caps.indexOf(selection[0]), caps.indexOf(selection[1] + 1)];

  const items = data.map(item => {
    item = { ...item };

    item.date = {
      from: moment(item.date.from).add(selection[0], 'days').format('YYYY-MM-DD'),
      to: moment(item.date.from).add(selection[1], 'days').format('YYYY-MM-DD'),
    };

    item.months = item.months.slice(...bound);

    return item;
  });

  return items;
};

const TrafficGraph = props => {
  const { data, updates, selection } = props;

  const items = trimData(data, selection);

  const chartData = collectChartData(items, updates);

  return <UI.LineChart className="pt-3" data={chartData} settings={settings} />;
};

export default TrafficGraph;
