import { isEqual } from '/utils/object';

import {
  TYPE_DATE,
  PURPOSE_DATE_CHANGE,
} from '../../../../constants';

const createDateItem = timestamp => {
  const id = timestamp;
  const user_id = null;
  const type = TYPE_DATE;
  const purpose = PURPOSE_DATE_CHANGE;

  return { id, timestamp, type, user_id, purpose };
};

const splitHistory = function splitHistory(list) {
  const groups = [];

  let group = [], last = {}, date = null;

  for (let item, i = 0; item = list[i]; i++) {
    const { type, user_id, timestamp } = item;
    const message_date = new Date(timestamp * 1000).getDate();

    if (date !== message_date) {
      group.length && groups.push(group);

      const item = createDateItem(timestamp);

      groups.push([item]);

      (group = []) && (date = message_date);
    }

    const cur = { type, user_id, date, timestamp };

    if (last.type !== cur.type || last.user_id !== cur.user_id || last.date !== cur.date || cur.timestamp - last.timestamp > 300) {
      group.length && groups.push(group);

      (group = []) && (last = cur);
    }

    group.push(item);
  }

  group.length && groups.push(group);

  return groups.map(items => {
    const { type, user_id } = items[0];

    return { type, user_id, items };
  });
};

export default splitHistory;
