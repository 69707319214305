import React, { Component } from 'react';

import { UI, BL } from '/views';

import { deduplicate } from '/utils/array';
import { check as checkLink, getRoot } from '/utils/link';
import cn from 'classnames';

const ERROR_INVALID_ITEMS = 'INVALID_ITEMS';
const ERROR_DIFFERENT_ROOTS = 'DIFFERENT_ROOTS';

class InsertBox extends Component {
  state = {
    errors: [],
  };

  onSubmit = () => {
    const { insert, onProcess } = this.props;
    const { items, links } = insert;

    const errors = [];

    let roots = links.map(link => getRoot(link));

    deduplicate(roots).length > 1 && errors.push(ERROR_DIFFERENT_ROOTS);
    items.length !== links.length && errors.push(ERROR_INVALID_ITEMS);

    this.setState({ errors });

    !errors.length && onProcess();
  }

  renderError() {
    const { lang } = this.props;
    const { errors } = this.state;

    if (!errors.length) return null;

    const text = [];

    if (errors.includes(ERROR_INVALID_ITEMS)) {
      text[text.length] = lang('error_incorrectUrl');
    }

    if (errors.includes(ERROR_DIFFERENT_ROOTS)) {
      text[text.length] = lang('error_missmatchDomain');
    }

    return (
      <div className="w-65 color-danger-500">
        {text.map((text, i) => <div key={i}>{text}</div>)}
      </div>
    );
  }

  render() {
    const { lang, lock, insert, result, onLinksUpdate } = this.props;

    const Button = insert.links.length ? UI.Button.Primary : UI.Button.Secondary;

    return (
      <BL.Panel>
        <UI.Collapse title={lang('field_title')} expand={!result}>
          <div className="pt-2 pb-3 pl-3 pr-3">
            <UI.Form.TextArea value={insert.text} onChange={onLinksUpdate} style={{ height: '120px' }} />
            {this.renderError()}
            <div className="mt-2 select-none">
              {lang('count_links', { count: deduplicate(insert.links).length }, { useParser: true })}
            </div>
            <Button className="mt-4" spin2={lock} icon2={cn({ 'ml-2': lock })} text={lang('button_check')} onClick={this.onSubmit} />
          </div>
        </UI.Collapse>
      </BL.Panel>
    );
  }
}

export default InsertBox;
