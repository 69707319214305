import Provider from '/services/Provider';

class ConversationsProvider extends Provider {
  create = (title, message) => this.api.conversations.create(title, message);
  fetch = () => this.api.conversations.fetch();

  getHistory = (id, message_id, timestamp) => this.api.conversations.getHistory(id, message_id, timestamp);
  getUpdates = (ids) => this.api.conversations.getUpdates(ids);

  toggleState = (id, closed) => this.api.conversations.toggleState(id, closed);

  sendMessage = (id, message_id, message) => this.api.conversations.sendMessage(id, message_id, message);
  updateMessage = (id, message_id, message) => this.api.conversations.updateMessage(id, message_id, message);
  removeMessage = (id, message_id) => this.api.conversations.removeMessage(id, message_id);
}

export default ConversationsProvider;
