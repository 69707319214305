const self = {};

self.getList = function getList() {
  const url = '/api/v1/projects/list';
  const method = 'get';

  return this.axios({ url, method });
};

self.getProject = function getProject(project_id) {
  const url = `/api/v1/projects/${project_id}`;
  const method = 'get';

  return this.axios({ url, method });
};

self.getProjectMirrors = function getProjectMirrors(project_id) {
  const url = `/api/v1/projects/${project_id}/mirrors`;
  const method = 'get';

  return this.axios({ url, method });
};

self.getProjectSitemaps = function getProjectSitemaps(project_id) {
  const url = `/api/v1/projects/${project_id}/sitemaps`;
  const method = 'get';

  return this.axios({ url, method });
};

self.getSettings = function getSettings(project_id) {
  const url = `/api/v1/projects/${project_id}/settings`;
  const method = 'get';

  return this.axios({ url, method });
};

self.updateSettings = function updateSettings(project_id, data) {
  const url = `/api/v1/projects/${project_id}/settings`;
  const method = 'put';

  return this.axios({ url, method, data });
};

self.reloadProjects = function reloadProjects() {
  const url = `/api/v1/projects/reload`;
  const method = 'put';

  return this.axios({ url, method });
};

export default self;
