import React from 'react';

import { BL } from '/views';

const Message = props => {
  const { stats, analytics, className } = props;

  const https = stats.https.year[1][11].value;
  const http = stats.http.year[1][11].value;

  const total = https + http;

  const percent_https = Math.round(100 * https / total * 10) / 10;
  const percent_http = Math.round(100 * http / total * 10) / 10;

  if (!https) {
    return (
      <BL.Alert.Danger className={className}>
        <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
        <BL.Alert.Content>
          <p>Рекомендуем купить SSL-сертификат и перевести сайт на защищенный протокол https.</p>
          <p className="mb-0">Прочтите <a href="https://developers.google.com/search/docs/advanced/security/https?hl=ru" target="_blank">рекомендации Google</a> и <a href="https://yandex.ru/support/webmaster/yandex-indexing/https-migration.html" target="_blank">рекомендации Яндекс</a>.</p>
        </BL.Alert.Content>
      </BL.Alert.Danger>
    );
  }

  if (https <= http) {
    return (
      <BL.Alert.Danger className={className}>
        <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
        <BL.Alert.Content>
          <p>За последний месяц ваш сайт получил <b>{percent_http}%</b> поискового трафика на http-версию сайта и <b>{percent_https}%</b> на https-версию сайта. Рекомендуем завершить переход на https. Проверьте, настроен ли 301 редирект (перенаправление) для всех страниц с http-версии на https-версию.</p>
          <p>Прочтите <a href="https://developers.google.com/search/docs/advanced/security/https?hl=ru" target="_blank">рекомендации Google</a> и <a href="https://yandex.ru/support/webmaster/yandex-indexing/https-migration.html" target="_blank">рекомендации Яндекс</a>.</p>
          <p className="mb-0">Изучите примеры страниц ниже, которые получают http-трафик.</p>
        </BL.Alert.Content>
      </BL.Alert.Danger>
    );
  }

  if (https > http && http) {
    return (
      <BL.Alert.Danger className={className}>
        <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
        <BL.Alert.Content>
        <p>За последний месяц ваш сайт получил <b>{percent_http}%</b> поискового трафика на http-версию сайта и <b>{percent_https}%</b> на https-версию сайта. Рекомендуем завершить переход на https. Проверьте, настроен ли 301 редирект (перенаправление) для всех страниц с http-версии на https-версию.</p>
        <p>Прочтите <a href="https://developers.google.com/search/docs/advanced/security/https?hl=ru" target="_blank">рекомендации Google</a> и <a href="https://yandex.ru/support/webmaster/yandex-indexing/https-migration.html" target="_blank">рекомендации Яндекс</a>.</p>
        <p className="mb-0">Изучите страницы ниже, которые получают http-трафик.</p>
        </BL.Alert.Content>
      </BL.Alert.Danger>
    );
  }

  if (!http) {
    return (
      <BL.Alert.Success className={className}>
        <BL.Alert.Icon icon="fal fa-check-circle" />
        <BL.Alert.Content>
        <p className="mb-0">Все в порядке! 100% поискового трафика на ваш сайт попадает на защищенную https-версию сайта. Пожалуйста, не забывайте продлевать SSL-сертификат и следить, чтобы был настроен 301 редирект (перенаправление) с http-версии на https-версию. Если вы будете выполнять аудит ежемесячно, вы сможете обнаружить, если на сайт появится http-трафик и на каких страницах он появится.</p>
        </BL.Alert.Content>
      </BL.Alert.Success>
    );
  }
};

export default Message;
