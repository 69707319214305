import React, { Fragment } from 'react';

import { link } from '/services/Components/methods';

import Menu from '../Menu';

import getItems from './items';

import { UI } from '/views';

import './styles.scss';

const AuditMenu = props => {
  const { access, lang, audit, projects, current } = props;

  if (!audit.current) return null;

  const { projectId, reports = [] } = audit.items[audit.current] || {};

  const project = projects.items[projectId];

  if (!project) return null;

  const items = getItems(access, lang, audit.current, reports, current);

  return (
    <Fragment>
      <li className="nav-title sidebar_menu-audit_title">
        <div className="d-flex align-items-center">
          <div className="sidebar_menu-audit_project_name">
            {project.host}
          </div>
          <div className="sidebar_menu-audit_project_settings">
            <UI.Button.Transparent className="p-1" icon="fal fa-cogs fs-9" style={{ width: 28, height: 28, float: 'right' }} size="xs" href={link('Projects.Settings', { id: project.id, lang, step: 0, callback: 'back-to-projects' })} />
          </div>
        </div>
      </li>
      <Menu items={items} />
      <li style={{ height: 20 }} />
    </Fragment>
  );
};

export default AuditMenu;
