import Link from './classes/Link';

import { getListArg } from '/utils/array';
import { isFunction } from '/utils/types';

import { testRoute, matchRoute } from './route';

import { routerUpdatePath } from './store/actions';

import Lang from '/services/Lang';
import Access from '/services/Access';

import share from '/share';

const LANG_PREFIXES = Lang.AVAILABLE.filter(name => name !== Lang.DEFAULT);

const routes = [];

let fallback = null;

export const addRoute = function addRoute(items) {
  const list = getListArg(items, arguments);

  routes.push(...list);
};

export const addFallbackRoute = route => fallback = route;

const getMatchResult = (route, values = {}, data = {}) => {
  const { component, action, layout } = route;

  return { route, component, action, layout, values };
};

const extractLang = path => {
  let lang = LANG_PREFIXES.find(name => path.startsWith('/' + name + '/'));

  if (lang) {
    path = path.slice(lang.length + 1);
  } else {
    lang = Lang.DEFAULT;
  }

  return { lang, path };
};

export const match = async function match(rawPath, method, state) {
  const { lang, path } = extractLang(rawPath);

  let data = {};

  for (let i = 0, len = routes.length; i < len; i++) {
    let route = routes[i];

    if (!testRoute(path, method, route)) continue;

    let values = matchRoute(path, method, route);

    const available = Access.check(state.user.role, route.component + '.' + route.action);

    if (!available) break;

    if (route.dataChecker) {
      const checked = await route.dataChecker(state, values);

      data = { ...data, ...checked.data };

      if (checked.skip) continue;
      if (checked.force_404) break;
    }

    if (isFunction(route.layout)) {
      route = { ...route, layout: route.layout(state.user) };
    }

    values = { ...values, lang };

    return getMatchResult(route, values, data);
  }

  console.warn(path, method, data);

  return getMatchResult(fallback, { lang }, data);
};

export const navigate = function navigate(path, method = 'GET', params = {}, updateHistory = true) {
  if (path instanceof Link) path = path.path;

  const action = routerUpdatePath(path, method, params, updateHistory);

  share.app.store.dispatch(action);
};
