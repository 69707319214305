export const idGenerator = function idGenerator(prefix = '') {
  let id = 0;

  return () => prefix + '' + ++id;
};

const letters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const tokenGenerator = function tokenGenerator(len) {
  let result = '';

  while (len--) {
    result += letters[~~(Math.random() * letters.length)];
  }

  return result;
};

export const setAsyncTimeout = function setAsyncTimeout(delay) {
  return new Promise(resolve => setTimeout(resolve, delay));
};

export const parse = function parse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const safety = function safety(runner, fallback) {
  try {
    return runner();
  } catch (e) {
    return fallback;
  }
};

export const waitForRender = function waitForRender(callback = () => {}) {
  document.body.clientHeight;

  return new Promise(resolve => {
    setTimeout(() => callback() & resolve(), 0);
  });
};

export const deferred = function deferred(callback) {
  let resolve, reject;

  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });

  promise.resolve = resolve;
  promise.reject = reject;

  callback && callback(resolve, reject, promise);

  return promise;
};

export { default as Popup } from './popup';
