import React from 'react';
import moment from 'moment';

import { BL } from '/views';

const ModalRemove = props => {
  const { message, onCancel, onSubmit } = props;

  const sets = {
    title: 'Удалить сообщение',
    icon: 'fal fa-exclamation-circle',
    actions: { onCancel, onSubmit },
    buttons: { cancel: 'Отмена', submit: 'Удалить' },
  };

  return (
    <BL.Modal.Confirm.Warning { ...sets }>
      <p>Вы действительно хотите удалить это сообщение:</p>
      {message}
    </BL.Modal.Confirm.Warning>
  );
};

export default ModalRemove;
