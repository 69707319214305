import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import Provider from '../../provider';

import PagesTable from './components/PagesTable';

import services from '/static/data/services';

class ReportsMainMirror extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getMetrikaDomainsResult(audit_id, id);
  }

  // exportExcelLink() {
  //   const { id, user_id } = this.props;
  //
  //   return this.provider.getAllTrafficExcelLink(id, user_id);
  // }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderDomainsTable() {
    const { analytics } = this.state.report || {};

    if (!analytics) return null;

    return (
      <Fragment>
        <h4 className="ui-detail_list-title">Домены с метрикой</h4>
        <PagesTable data={analytics.domains} />
      </Fragment>
    );
  }

  render() {
    const { report } = this.state;

    return (
      <Fragment>
        <BL.Title icon={services.metrika_domains.icon} name={services.metrika_domains.name}>
          <p>В этом отчете вы увидите все сайты, по которым собираются данные для выбранного счетчика, то есть все сайты, на которых установлен этот же счетчик Яндекс.Метрики что и на {this.props.project.host}. Отчет полезен тем, что в нем вы можете найти:</p>
          <ul>
            <li>Тестовые версии сайта (они должны быть закрыты от индексации поисковыми системами).</li>
            <li>Копии и зеркала сайта (<a href="https://yandex.ru/support/webmaster/yandex-indexing/site-mirrors.html" target="_blank">что такое зеркала</a>).</li>
            <li>Поддомены сайта.</li>
          </ul>
          <p>Ниже список всех сайтов, на которых стоит этот же счетчик Яндекс.Метрики, и которые получали трафик из поисковых систем за последние 3 месяца. Мы автоматически исключаем сайты, которые получили 3 и меньше перехода, а также безвредные сайты (кэш поиска Яндекс и Гугл, например). Изучите остальные сайты и в случае обнаружения тестовых версий или незнакомых вам поддоменов проверьте, должны ли они искаться в поиске.</p>
        </BL.Title>

        <BL.Panel>
          <BL.Panel.Content className="pt-1">
            {!report && <div className="text-center mt-3 pt-3 pb-3"><UI.Loader /></div>}

            {this.renderDomainsTable()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ReportsMainMirror;
