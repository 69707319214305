import { connect } from 'react-redux'
import View from './view';

const mapStateToProps = (state) => {
  const { component, action, params } = state.router;

  return { component, action, params };
};

export default connect(mapStateToProps)(View);
