const fields = {};

fields.yandex = item => {
  const result = item.result.yandex;

  if (!result) return -1;

  if (!result.exists) return 2;
  if (result.errors.length) return 1;

  return 0;
};

fields.google = item => {
  const result = item.result.google;

  if (!result) return -1;

  if (!result.exists) return 2;
  if (result.errors.length) return 1;

  if (!result.cachelink) return 0.5;

  return 0;
};

fields.url = item => item.url.initial;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.url;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
