const splitColor = color => {
  color = color.replace(/^#/, '');

  return [
    parseInt(color.slice(0, 2), 16),
    parseInt(color.slice(2, 4), 16),
    parseInt(color.slice(4, 6), 16),
  ];
};

const gradient = function gradient(color_1, color_2, count) {
  const [start, end] = [color_1, color_2].map(splitColor);

  const delta = [...new Array(3)].map((u, i) => {
    return (end[i] - start[i]) / (count - 1);
  });

  return [...new Array(count)].map((u, i) => {
    const color = [...new Array(3)].map((u, j) => {
      return Math.round(start[j] + delta[j] * i).toString(16);
    });

    return '#' + color.join('');
  });
};

export default gradient;
