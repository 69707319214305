import React, { Fragment } from 'react';

import Robots from '../Robots';
import TableMaps from '../TableMaps';

const Maps = props => {
  const { lang, result } = props;

  return (
    <Fragment>
      <Robots lang={lang} data={result.robots} />
      <TableMaps lang={lang} data={result.items} settings={result} />
    </Fragment>
  );
};

export default Maps;
