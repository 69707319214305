import { select, call, put, take, takeEvery } from 'redux-saga/effects';
import { eventChannel  } from 'redux-saga';

import { routerUpdatePath, routerUpdateState } from './actions';
import { createClickHandler, createPopStateHandler } from './events';

import { match } from '../methods';

import { DEFAULT } from '/static/data/roles';

function* createNavigateChannel() {
  return eventChannel(emit => {
    const callback = (...args) => emit(args);

    const onClick = createClickHandler(callback);
    const onPopState = createPopStateHandler(callback);

    if (__CLIENT__) {
      document.body.addEventListener('click', onClick, false);
      window.addEventListener('popstate', onPopState, false);
    }

    return function destroyNavigateChannel() {
      if (__CLIENT__) {
        document.body.removeEventListener('click', onClick, false);
        window.removeEventListener('popstate', onPopState, false);
      }
    };
  });
}

function* _routerUpdatePath({ payload }) {
  const { path, method, params, updateHistory, defer } = payload;

  if (__CLIENT__) {
    updateHistory && window.history.pushState({}, document.title, path);
  }

  const state = yield select();

  const { component, action, layout, values } = yield match(path, method, state);

  yield put(routerUpdateState(path, method, component, action, layout, { ...params, url: values }));

  defer && defer.resolve();
}

export default function* routerSage() {
  const navigateChannel = yield call(createNavigateChannel);

  yield takeEvery(routerUpdatePath, _routerUpdatePath);

  while (true) {
    const params = yield take(navigateChannel);

    yield put(routerUpdatePath(...params));
  }
}
