import React, { Component, Fragment } from 'react';
import moment from 'moment';

import ModalAddTicket from './components/ModalAddTicket';

import { link } from '/services/Components/methods';

import { UI, BL, VIEW } from '/views';

import './styles.scss';

class UserTickets extends Component {
  constructor(props) {
    super(props);

    if (__CLIENT__) {
      props.fetchConversations();
    }
  }

  onTicketAdd = () => {
    const { createConversation } = this.props;

    VIEW.MODAL.show(() => {
      const onCancel = () => VIEW.MODAL.hide();
      const onSubmit = () => VIEW.MODAL.hide();

      return <ModalAddTicket onCancel={onCancel} onSubmit={onSubmit} createConversation={createConversation} />;
    });
  };

  onTicketSelect = id => {
    const { selected } = this.state;
    const { conversations, setCurrentConversation } = this.props;

    if (selected === id) return;

    this.setState({ selected: id });

    setCurrentConversation(id);
  };

  renderTicketsTable() {
    const { lang } = this.props;
    let { items } = this.props.conversations;

    items = Object.values(items);

    const renderItem = item => {
      const { id } = item;

      const updates = item.unreaded
      ? <UI.Badge.Warning className="px-2">{item.unreaded}</UI.Badge.Warning>
      : <UI.Badge.Secondary className="px-2">{item.unreaded}</UI.Badge.Secondary>;

      const status = item.closed
        ? <UI.Badge.Secondary>Закрыт</UI.Badge.Secondary>
        : <UI.Badge.Warning>Активный</UI.Badge.Warning>;

      return (
        <tr key={id}>
          <td className="text-center">#{id}</td>
          <td className="text-center">{updates}</td>
          <td>
            <div className="fs-7 font-weight-bold">{item.title}</div>
            {!!item.last_message && <div className="mt-2">{item.last_message}</div>}
          </td>
          <td className="text-center">{moment(item.date_update || item.date_create).format('D MMM YYYY HH:mm')}</td>
          <td className="text-center">{status}</td>
          <td className="text-center">
            <UI.Button.Info href={link('Support.UserMessanger', { lang, id })} className="py-1 px-2" size="xs" text="Перейти" />
          </td>
        </tr>
      );
    };

    return (
      <table className="table table-bordered table-hover table-layout-fixed w-100 valign-middle dtr-inline darken">
        <thead>
          <tr>
            <th className="text-center" style={{ width: 90 }}>ID тикета</th>
            <th className="text-center" style={{ width: 90 }}>Апдейтов</th>
            <th>Последнее сообщение</th>
            <th className="text-center" style={{ width: 160 }}>Дата</th>
            <th className="text-center" style={{ width: 100 }}>Статус</th>
            <th className="text-center" style={{ width: 100 }}>Действия</th>
          </tr>
        </thead>
        <tbody>
          {items.map(renderItem)}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <Fragment>
        <BL.Title icon="fal fa-user-headset" name="Служба поддержки" />

        <div className="border-faded shadow-4 p-3">
          <div className="mb-3">
            <UI.Button.Primary icon="fal fa-plus" text="Добавить тикет" onClick={this.onTicketAdd} style={{ width: 220 }} />
          </div>
          <div>
            {this.renderTicketsTable()}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserTickets;
