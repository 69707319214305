import React, { Fragment } from 'react';

import { UI } from '/views';

const fields = {};

fields.url = (item, actions, settings) => {
  return (
    <div className="position-relative popup-control">
      <a href={item.url} className="text-decoration-none" target="_blank">
        {item.url} <i className="fal fa-external-link-square font-weight-bold" />
      </a>
    </div>
  );
};

fields.state = (item, actions, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return 'Новая';

  const { verdict } = values[settings.select];

  const states = {
    TRAFFIC_GROW: 'Вырос',
    TRAFFIC_DROP: 'Упал',
    TRAFFIC_ZERO: 'Упал в 0',
    TRAFFIC_SUBSIDE: 'Падает',
    TRAFFIC_STABLE: 'На месте',
    TRAFFIC_UNMATCH: 'Отсутствует'
  };

  return <span className="d-block text-truncate w-100">{states[verdict]}</span>;
};

fields.year_0 = (item, actions, settings) => item.stats.total[0].values[settings.select];
fields.year_1 = (item, actions, settings) => item.stats.total[1].values[settings.select];

fields.diff_year = (item, actions, settings) => {
  const { state, values } = item.analytics.total;

  if (state !== 'STATE_OK') return '-';

  const { variance, percent, tone } = values[settings.select];

  return variance + ' (' + percent + '%)';
};

fields.diff_3_month = (item, actions, settings) => {
  const { state, values } = item.analytics.last3month;

  if (state !== 'STATE_OK') return '-';

  const { variance, percent, tone } = values[settings.select];

  return variance + ' (' + percent + '%)';
};

fields.diff_1_month = (item, actions, settings) => {
  const { state, values } = item.analytics.year[11];

  if (state !== 'STATE_OK') return '-';

  const { variance, percent, tone } = values[settings.select];

  return variance + ' (' + percent + '%)';
};

fields.downfall = (item, actions, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return '-';

  const { overall, yandex, google } = values;

  const items = settings.select === 'overall' ? [overall, yandex, google] : [values[settings.select]];

  return items.map(v => v.downfall.variance).join(' / ');
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
