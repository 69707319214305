import React from 'react';

const fields = {};

fields.verdict = item => {
  const states = {
    TRAFFIC_GROW: 'Вырос',
    TRAFFIC_DROP: 'Упал',
    TRAFFIC_ZERO: 'Упал в 0',
    TRAFFIC_SUBSIDE: 'Падает',
    TRAFFIC_STABLE: 'На месте',
    TRAFFIC_NEW: 'Новая',
    TRAFFIC_UNMATCH: 'Отсутствует'
  };

  return <span className="d-block text-truncate w-100">{states[item.verdict]}</span>;
};

fields.count_value = (item, actions, settings) => item.count[settings.select].value;
fields.count_percent = (item, actions, settings) => item.count[settings.select].percent + '%';

fields.year_0 = (item, actions, settings) => item.stats.total[0].values[settings.select];
fields.year_1 = (item, actions, settings) => item.stats.total[1].values[settings.select];

fields.diff_year = (item, actions, settings) => {
  const { variance, percent } = item.analytics.total.values[settings.select];

  return variance + ' (' + (percent !== null ? percent + '%' : 'n/a')+ ')';
};

fields.diff_year_per_page = (item, actions, settings) => item.analytics.total.values[settings.select].variance_per_page;

fields.diff_3_month = (item, actions, settings) => {
  const { variance, percent } = item.analytics.last3month.values[settings.select];

  return variance + ' (' + (percent !== null ? percent + '%' : 'n/a')+ ')';
};

fields.diff_1_month = (item, actions, settings) => {
  const { variance, percent } = item.analytics.year[11].values[settings.select];

  return variance + ' (' + (percent !== null ? percent + '%' : 'n/a')+ ')';
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
