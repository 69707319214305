import React, { Component } from 'React';
import cn from 'classnames';

import { isFunction } from '/utils/types';

import './styles.scss';

class DetailItem extends Component {
  state = {
    expand: false,
  };

  onToggle = () => {
    const { body } = this.props;

    if (!body) return;

    this.setState(state => ({ ...state, expand: !state.expand }));
  };

  renderTitle() {
    const { expand } = this.state;
    const { name, value, body, noValue } = this.props;

    return (
      <div className={cn('fs-8p ui-detail_list-item_title', body && 'ui-detail_list-item_title_active', noValue && 'ui-detail_list-item_title_no_value')} onClick={this.onToggle}>
        <div className="ui-detail_list-item_title_toggler">
          {body && <i className={cn('ui-detail_list-item_title_toggler-icon', `fal fa-chevron-${expand ? 'up' : 'down'}`)} />}
        </div>
        <div className="ui-detail_list-item_title_name">
          {isFunction(name) ? name() : name}
        </div>
        {
          !noValue && <div className="ui-detail_list-item_title_value">
            {isFunction(value) ? value() : value}
          </div>
        }
      </div>
    );
  }

  renderBody() {
    const { expand } = this.state;
    const { body } = this.props;

    if (!expand) return null;

    const content = isFunction(body) ? body() : body;

    return (
      <div className="ui-detail_list-item_details">
        {content}
      </div>
    );
  }

  render() {
    return (
      <li className={cn('ui-detail_list-item')}>
        {this.renderTitle()}
        {this.renderBody()}
      </li>
    );
  }
}

class DetailList extends Component {
  isEmpty() {
    const { items } = this.props;

    return !items || !items.length;
  }

  renderItem = (item, key) => {
    const { noValue = false } = this.props;

    item.id && (key = isFunction(item.id) ? item.id() : item.id);

    return <DetailItem key={key} noValue={noValue} { ...item } />;
  };

  renderListItems() {
    if (this.isEmpty()) return null;

    const { items } = this.props;

    return (
      <ul className="ui-detail_list-list">
        {items.map(this.renderItem)}
      </ul>
    );
  }

  renderEmptyText() {
    if (!this.isEmpty()) return null;

    const { empty } = this.props;

    return (
      <div className="ui-detail_list-empty">
        {empty()}
      </div>
    );
  }

  render() {
    const { title, className } = this.props;

    return (
      <div className={cn('ui-detail_list-wrapper', className)}>
        {title && <h5 className="ui-detail_list-title">{title}</h5>}

        {this.renderListItems()}
        {this.renderEmptyText()}
      </div>
    );
  }
}

export default DetailList;
