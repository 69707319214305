import { parse } from '../common';

export const setCookie = function setCookie(name, value, days) {
  if (__CLIENT__) {
    var expires = "";

    if (days) {
      var date = new Date();

      date.setTime(+date + days * 24 * 60 * 60 * 1000);

      expires = "; expires=" + date.toUTCString();
    }

    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
};

export const getCookie = function getCookie(name) {
  if (__CLIENT__) {
    const [, value] = document.cookie.match(new RegExp('(?:^| )' + name + '=([^;]+)')) || [];

    return parse(value);
  }
}

export const removeCookie = function removeCookie(name) {
  if (__CLIENT__) {
    // document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    setCookie(name, '', -1);
  }
}

export default {
  set: setCookie,
  get: getCookie,
  remove: removeCookie,
};
