const tags = { '**': 'b', '__': 'u', '^^': 'i' };

const repalcePairs = text => {
  const flags = { '**': false, '__': false, '^^': false };

  return text.replace(/(\*{2}|__|\^\^)/g, (m, $1) => {
    const tag = tags[$1];
    const state = flags[$1];

    flags[$1] = !flags[$1];

    return state ? `</${tag}>` : `<${tag}>`;
  });
};

const replaceSingles = text => {
  return text.replace(/(\[br\])/g, (m, $1) => {
    if ('[br]') return '<br />';

    return '';
  });
};

export default text => {
  text = repalcePairs(text);
  text = replaceSingles(text);

  return text;
};
