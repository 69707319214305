import React from 'react';

import Menu from '../Menu';

import getItems from './items';

const MainMenu = props => {
  const items = getItems(props.lang, props.current, props.user);

  return <Menu items={items} />;
};

export default MainMenu;
