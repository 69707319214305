import { parse } from '../common';

import { isString, isObject, isNill } from '../types';

let data = {};

export const setup = function setup(params) {
  for (let name in params) {
    if (!params.hasOwnProperty(name)) continue;

    data[name] = parse(params[name]);
  }
};

export const get = function get(name) {
  return data[name];
};

export const fill = function fill(pattern) {
  let result = {};

  for (let name in pattern) {
    if (!pattern.hasOwnProperty(name)) continue;

    let prop = pattern[name];

    if (!isString(prop)) {
      result[name] = prop;
    }
    else {
      let value = get(pattern[name]);

      result[name] = !isNill(value) ? value : prop;
    }
  }

  return result;
};

export default function (name) {
  if (isString(name)) return get(name);
  if (isObject(name)) return fill(name);
};
