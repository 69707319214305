import Access from '/services/Access';

import { link as createLink } from '/services/Components/methods';

import checkActive from '../../../../utils/checkActive';

const getStatistics = (lang, current) => {
  const page = ['Admin', 'Statistics'];
  const name = 'Статистика';
  const icon = 'fal fa-chart-pie';
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getSearcherUpdates = (lang, current, user) => {
  const page = ['Admin', 'SearcherUpdates'];
  const name = 'Апдейты поисковиков';
  const icon = 'fal fa-arrow-alt-square-up';

  const available = Access.check(user.role, page);

  if (!available) return null;

  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getItems = (lang, current, user) => [
  getStatistics(lang, current, user),
  getSearcherUpdates(lang, current, user),
];

export default getItems;
