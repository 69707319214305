import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="col_type" />,
    field: 'type',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '140px', maxWidth: '140px', minWidth: '140px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_state" />,
    field: 'status',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '120px', maxWidth: '120px', minWidth: '120px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_link" />,
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: {},
  },
];
