import React, { Component } from 'react';

import './styles.scss';

import Table from './components/Table';
import TopLine from './components/TopLine';
import BottomLine from './components/BottomLine';

import { getPicker } from '/utils/object';

class TableWrapper extends Component {
  onSearch = value => {
    const { onSearch } = this.props;

    onSearch && onSearch(value);
  };

  onPaginate = (page, limit) => {
    const { paging, onPaginate } = this.props;

    if (!onPaginate) return;

    page = page != null ? page : paging.page;
    limit = limit != null ? limit : paging.limit;

    onPaginate(page, limit);
  };

  onSort = (field, dir) => {
    const { onSort } = this.props;

    onSort && onSort(field, dir);
  };

  renderTopLine() {
    const picker = getPicker('data', 'searcher', 'pagination', 'paging', 'actions', 'settings', 'toolbar');

    const sets = picker(this.props);

    sets.onSearch = this.onSearch;
    sets.onPaginate = this.onPaginate;

    return <TopLine { ...sets } />;
  }

  renderTable() {
    const picker = getPicker(
      'className', 'wrapperStyle', 'useScrollbar',
      'loading', 'emptyText',
      'itemId', 'data', 'sort', 'child',
      'columns', 'getter', 'actions', 'decorator', 'settings'
    );

    const sets = picker(this.props);

    sets.onSort = this.onSort;

    return <Table { ...sets } />;
  }

  renderBottomLine() {
    const { pagination, count, paging } = this.props;

    if (!pagination) return null;

    const sets = { count, pagination, ...paging };

    sets.onPaginate = this.onPaginate;

    return <BottomLine { ...sets } />;
  }

  render() {
    return (
      <div className="dataTables_wrapper">
        {this.renderTopLine()}
        {this.renderTable()}
        {this.renderBottomLine()}
      </div>
    );
  }
}

export default TableWrapper;
