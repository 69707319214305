const fields = {};

fields.visits = item => item.value;

fields.status = item => item.response ? item.response.statusCode : 0;

fields.description = item => item.url;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.description;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
