import Access from '/services/Access';

import { link as createLink } from '/services/Components/methods';

import checkActive from '../../../../utils/checkActive';

const getProjects = (lang, current) => {
  const page = ['Projects', 'Root'];
  const name = 'Проекты';
  const icon = 'fal fa-books';
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsLemmer = (lang, current) => {
  const page = ['Tools', 'Lemmer'];
  const name = 'Лемматизатор';
  const icon = null;
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsServerResponse = (lang, current) => {
  const page = ['Tools', 'ServerResponse'];
  const name = 'Проверка ответа сервера';
  const icon = null;
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsSitemapper = (lang, current) => {
  const page = ['Tools', 'Sitemapper'];
  const name = 'Проверка карты сайта';
  const icon = null;
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsTitleDescriptionH1 = (lang, current) => {
  const page = ['Tools', 'TitleDescriptionH1'];
  const name = 'Анализ title, description и заголовков H1-H6 по списку страниц';
  const icon = null;
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsIndexChecker = (lang, current, user) => {
  const page = ['Tools', 'UrlIndexing'];
  const name = 'Проверка индексации страниц';
  const icon = null;

  const available = Access.check(user.role, page);

  if (!available) return null;

  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getToolsRobotsChecker = (lang, current, user) => {
  const page = ['Tools', 'RobotsChecker'];
  const name = 'Проверка robots.txt';
  const icon = null;

  const available = Access.check(user.role, page);

  if (!available) return null;

  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getTools = (lang, current, user) => {
  const page = ['Tools'];
  const name = 'Инструменты';
  const icon = 'fal fa-tools';
  const link = '#';

  const childs = [
    getToolsLemmer(lang, current, user),
    getToolsServerResponse(lang, current, user),
    getToolsSitemapper(lang, current, user),
    getToolsTitleDescriptionH1(lang, current, user),
    getToolsIndexChecker(lang, current, user),
    getToolsRobotsChecker(lang, current, user),
  ].filter(v => !!v);

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive, childs };
};

const getSettings = (lang, current) => {
  const page = ['Settings', 'Accesses'];
  const name = 'Настройки доступов';
  const icon = 'fal fa-cog';
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getSupport = (lang, current) => {
  const page = ['Support', 'UserTickets'];
  const name = 'Поддержка';
  const icon = 'fal fa-life-ring';
  const link = createLink(page, { lang });

  const isActive = checkActive(page, current);

  return { page, name, icon, link, isActive };
};

const getItems = (lang, current, user) => [
  getProjects(lang, current, user),
  getTools(lang, current, user),
  getSettings(lang, current, user),
  getSupport(lang, current, user),
];

export default getItems;
