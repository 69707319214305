import { isArray } from '/utils/types';

import { PADDING } from '../../constants';

const matchCapacity = items => {
  if ('items' in items) items = items.items;

  if (!isArray(items)) return items.capacity || 1;

  let total = 0;

  for (let i = 0, len = items.length; i < len; i++) {
    total += matchCapacity(items[i].items || items[i]);
  }

  return total;
};

const matchZeroLine = range => {
  const { min, max } = range;

  return max / (max - min);
};

const matchColumn = (idx, _boundary, _area, _center, item, antecedent, settings, deep) => {
  const a_capacity = matchCapacity(antecedent);
  const i_capacity = matchCapacity(item);

  const { label: name = '', border: brd = {} } = item;

  const boundary = {};

  boundary.top = _boundary.top;
  boundary.left = Math.round(_area.x[0] + _area.width * a_capacity / _area.capacity.size);
  boundary.right = Math.round(_area.x[0] + _area.width * (a_capacity + i_capacity) / _area.capacity.size);
  boundary.bottom = _boundary.bottom;

  const area = {};

  area.capacity = {
    size: i_capacity,
    from: _area.capacity.from + a_capacity,
    to: _area.capacity.from + a_capacity + i_capacity - 1,
  };

  area.x = [boundary.left, boundary.right];
  area.y = [boundary.top + PADDING.TOP, boundary.bottom - PADDING.BOTTOM];

  area.width = area.x[1] - area.x[0];
  area.height = area.y[1] - area.y[0];

  const center = {
    x: Math.round((area.x[0] + area.x[1]) / 2),
    y: _center.y,
  };

  const label = {
    name: name,
    top: boundary.bottom,
    left: Math.round((area.x[0] + area.x[1]) / 2),
    width: null,
  };

  const type_left = deep ? (deep === 1 ? 'x2' : 'x3') : (idx ? 'x' : 'x0');
  const type_right = deep ? (deep === 1 ? 'x2' : 'x3') : 'x';

  const border = {
    left: {
      line: [
        { x: boundary.left, y: boundary.top },
        { x: boundary.left, y: boundary.bottom - (deep ? PADDING.BOTTOM : 0) },
      ],
      isHighlight: !!brd.left || type_left === 'x0',
      color: brd.left && brd.left.color || settings.axis[type_left].color,
      width: brd.left && brd.left.width || settings.axis[type_left].width,
      dasharray: brd.left && brd.left.dasharray || settings.axis[type_left].dasharray,
    },
    right: {
      line: [
        { x: boundary.right, y: boundary.top },
        { x: boundary.right, y: boundary.bottom - (deep ? PADDING.BOTTOM : 0) },
      ],
      isHighlight: !!brd.right,
      color: brd.right && brd.right.color || settings.axis[type_right].color,
      width: brd.right && brd.right.width || settings.axis[type_right].width,
      dasharray: brd.right && brd.right.dasharray || settings.axis[type_right].dasharray,
    },
  };

  const items = item.items ? matchColumns(boundary, area, center, item.items, settings, deep + 1) : null;

  return { ...item, label, boundary, area, center, border, items };
};

const matchColumns = (boundary, area, center, items, settings, deep) => {
  const columns = [];

  for (let item, i = 0; item = items[i]; i++) {
    columns[i] = matchColumn(i, boundary, area, center, item, items.slice(0, i), settings, deep);
  }

  return columns;
};

export default function match(canvas, offset, range, labels, settings) {
  const capacity = matchCapacity(labels);

  const zero = matchZeroLine(range)

  const boundary = {};

  boundary.top = offset.top;
  boundary.left = offset.left;
  boundary.right = canvas.width - offset.right;
  boundary.bottom = canvas.height - offset.bottom;

  const area = {
    x: [offset.left, canvas.width - offset.right],
    y: [offset.top, canvas.height - offset.bottom],
    width: canvas.width - (offset.left + offset.right),
    height: canvas.height - (offset.top + offset.bottom),
    capacity: {
      size: capacity,
      from: 0,
      to: capacity,
    },
  };

  const center = {
    x: Math.round(area.width * 0.5 + offset.left),
    y: Math.round((canvas.height - (offset.top + offset.bottom + PADDING.TOP + PADDING.BOTTOM)) * zero + offset.top + PADDING.TOP),
  };

  return matchColumns(boundary, area, center, labels, settings, 0);
};
