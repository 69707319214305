const getStepSize = (min, max, steps) => {
  const value = Math.max(Math.abs(min), Math.abs(max));

  for (let item, i = 0; item = steps[i]; i++) {
    if (item.max < value) continue;

    return item.step;
  }
};

const match = (values, steps) => {
  const minVal = Math.min(0, ...values);
  const maxVal = Math.max(0, ...values);

  const minExp = Math.max(0, ('' + Math.abs(minVal)).length - 2);
  const maxExp = Math.max(0, ('' + Math.abs(maxVal)).length - 2);

  const exp = Math.max(minExp, maxExp);
  const step = getStepSize(minVal, maxVal, steps) * (10 ** exp);

  let min = Math.floor(minVal / step) * step;
  let max = Math.ceil(maxVal / step) * step;

  if (min === 0 && max === 0) {
    max = steps[0].max;
  }

  return { min, max, step };
};

const update = (range, counts) => {
  const higher = Math.max(Math.abs(range.min), Math.abs(range.max));
  const lower = Math.min(Math.abs(range.min), Math.abs(range.max));

  const m = {
    [Math.abs(range.min)]: range.min / Math.abs(range.min) || 0,
    [Math.abs(range.max)]: range.max / Math.abs(range.max) || 0,
  };

  const versions = [];

  for (let count = counts[0], last = counts.length - 1; count <= counts[last]; count++) {
    let value = count;

    while (value) {
      const step = Math.ceil(higher / value / range.step) * range.step;

      let max = Math.ceil(step) * value;
      let min = step * (count - value);

      if (min >= lower) {
        if (higher === lower) {
          min *= -1;
        } else {
          (max *= m[higher]) & (min *= m[lower]);
        }

        if (max < min) [max, min] = [min, max];

        versions.push({ min, max, step });
      }

      value -= 1;
    }
  }

  let items = versions;

  items = items.filter(item => item.max - item.min !== item.step);

  const biggest = Math.min(...items.map(item => item.max - item.min));

  items = items.filter(item => item.max - item.min === biggest);

  const lowest = Math.min(...items.map(item => item.step));

  return items.find(item => item.step === lowest) || range;
};

export default function matchRange(values, settings) {
  let range = null;

  range = match(values, settings.rows.steps);
  range = update(range, settings.rows.count);

  return range;
};
