import moment from 'moment';

import { capitalize } from '/utils/string';
import { createArray, deduplicate } from '/utils/array';

export default months => {
  let labels = months.map(month => {
    const date = moment(month.date.from);

    const number = +date.format('M');
    const year = +date.format('YYYY');

    return { number, year, date };
  });

  return labels.reduce((result, item, i) => {
    const isSeasonStart = !(item.number % 3);
    const season = Math.floor(item.number % 12 / 3);

    let box = isSeasonStart || !result.length ? {} : result[result.length];

    if (isSeasonStart || !result.length) {
      let { year } = item;

      if (season === 0 && item.number === 12 && i !== labels.length - 1) {
        year = year + '/' + (year - 1999);
      }

       box = result[result.length] = { label: ['Зима', 'Весна', 'Лето', 'Осень'][season] + ' ' + year, items: [] };
    } else {
      box = result[result.length - 1];
    }

    const capacity = item.date.daysInMonth();

    const column = {
      label: capitalize(item.date.format('MMMM YYYY')),
      items: createArray(capacity, i => ({
        label: (i + 1).toString(),
        popupLabel: moment(item.date).add(i, 'day').format('D MMMM YYYY (dd)'),
      })),
    };

    if (item.number === 1) {
      column.border = {
        left: { width: 5, color: '#4de5d5', dasharray: '0' },
      };
    }

    box.items.push(column);

    return result;
  }, []);
};
