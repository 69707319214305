import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { repack } from '/utils/object';
import { capitalize } from '/utils/string';
import { setAsyncTimeout } from '/utils/common';

import getPlainChartLabels from '../../../../../utils/getPlainChartLabels';

const collect = item => {
  const result = {};

  result.yearStart = 1 + item.stats.year[0].findIndex(month => month.date.from.indexOf('-01-01') !== -1);

  result.head = item.stats.year[0].map(month => moment(month.date.from).format('MMM')).map(capitalize);
  result.head.unshift(null);

  result.body = [];

  const overall = item.analytics.year.map(month => {
    return { visits: month.visits.variance };
  });

  const rows = [...item.stats.year, overall];

  rows.forEach((year, index) => {
    const item = year.map(month => month.visits);

    let label = '';

    if (index !== rows.length - 1) {
      const start = moment(year[0].date.from).format('YYYY');
      const finish = moment(year[11].date.from).format('YYYY');

      label = start === finish ? start : start + ' - ' + finish;
    } else {
      label = 'Разница';
    }

    item.unshift(label);

    result.body.push(item);
  });

  return result;
};

const collectChartData = (item, select) => {
  const labels = getPlainChartLabels(item.stats.year[0].map(month => month.date.from));

  const year_0 = {
    name: 'year_0',
    label: 'Год 1',
    pointColor: color.success._300,
    pointColorHover: color.success._500,
    color: color.success._300,
    fill: color.rgba(color.success._100, 0.3),
    values: item.stats.year[0].map(month => month.visits),
  };

  const year_1 = {
    name: 'year_1',
    label: 'Год 2',
    pointColor: color.primary._300,
    pointColorHover: color.primary._500,
    color: color.primary._300,
    fill: color.rgba(color.primary._100, 0.3),
    values: item.stats.year[1].map(month => month.visits),
  };

  const diff = {
    name: 'diff',
    label: 'Разница',
    color: color.danger._500,
    fill: color.danger._300,
    values: item.analytics.year.map(month => month.visits.variance),
  };

  return { labels, lines: [year_1, year_0], bars: [diff] };
};

class Child extends Component {
  renderDebugData = () => {
    const { item } = this.props;

    const { mediana } = item.analytics.overall.visits;

    return (
      <div className="mb-2">
        <p className="title color-inof-700 mb-2"><b>Медиана:</b> {mediana.variance} / {mediana.percent}%</p>
        <div className="divider" />
      </div>
    );
  };

  renderTitle = () => {
    return <div className="title text-nowrap color-primary-700 mb-2">Трафик</div>;
  };

  renderTableHead = (head, yearStart) => {
    if (!head) return null;

    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '7.5%' };
      } else {
        sets.style = { width: 80, minWidth: 80, maxWidth: 80, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <th { ...sets }>{key ? value : '\u00A0'}</th>;
    };

    return (
      <thead className="year-row">
        <tr>{head.map(cell)}</tr>
      </thead>
    );
  };

  renderTableBody = (body, yearStart) => {
    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '7.5%' };
      } else {
        sets.style = { width: 80, minWidth: 80, maxWidth: 80, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <td { ...sets }>{value}</td>;
    };

    const row = (data, key) => <tr key={key}>{data.map(cell)}</tr>;

    return <tbody>{body.map(row)}</tbody>;
  };

  renderTable = () => {
    const { item } = this.props;

    const { head, body, yearStart } = collect(item);

    return (
      <table className="table darken table-bordered table-layout-fixed table-sm text-center w-100 mb-3">
        {this.renderTableHead(head, yearStart)}
        {this.renderTableBody(body, yearStart)}
      </table>
    );
  };

  renderGraph = () => {
    const { item } = this.props;

    const chartData = collectChartData(item);

    return <UI.LineChart data={chartData} offset={{ left: 80 }} settings={{}} />;
  };

  render() {
    return (
      <Fragment>
        {this.renderDebugData()}

        {this.renderTitle()}
        {this.renderTable()}

        {this.renderGraph()}
      </Fragment>
    );
  }
}

export default (item, actions, settings) => <Child item={item} settings={settings} />;
