import { addReducer, addSaga } from '/services/Store';

import reducer from './store/reducer';
import saga from './store/saga';

import { packComponent } from '/services/Components/methods';

import { link } from '/services/Components/methods';

import actions from './actions';
import access from './access';

addReducer('user', reducer);
addSaga(saga);

const settings = {
  name: 'User',
  icon: 'expand',
};

export default packComponent({ settings, actions, access });
