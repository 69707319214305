import React, { Fragment } from 'react';

import { BL } from '/views';

import { Item as _Item, Block as _Block } from './Block';

import CONST from '../../constants';

const TYPE_CORRECT = 'correct';
const TYPE_INCORRECT = 'incorrect';

class Item extends _Item {
  hasExpand() {
    const { type, stats } = this.props;

    return !!stats.validity[type];
  }

  getHeadData() {
    const { lang, type, stats } = this.props;

    const { name, infoText, infoTextActive } = {
      [TYPE_CORRECT]: {
        name: lang('validity_item_correct'),
        infoText: lang('validity_item_correct_info'),
        infoTextActive: false,
      },
      [TYPE_INCORRECT]: {
        name: lang('validity_item_incorrect'),
        infoText: lang('validity_item_incorrect_info'),
        infoTextActive: false,
      },
    }[type];

    const value = stats.validity[type];

    return { name, infoText, infoTextActive, value };
  }

  renderSimpleMaps(maps) {
    const copyData = maps.map(item => item.url).join('\n');

    return (
      <Fragment>
        <BL.CopyButton className="ml-4 mb-2" value={copyData}/>
        <ol className="tools_sitemapper-stats-item-body-list">
          {maps.map(map => (
            <li key={map.url} className="tools_sitemapper-stats-item-body-list-item">
              <a href={map.url} target="_blank" className="font-weight-normal">{map.url}</a>
            </li>
          ))}
        </ol>
      </Fragment>
    );
  }

  renderIncorrectMaps(maps) {
    const { lang } = this.props;

    const ERRORS = {
      [CONST.DATA_ERRORS.UNKNOWN_MAP_FORMAT]: () => lang('validity_error_unknown_map_format', {}, { useParser: true }),
      [CONST.DATA_ERRORS.ERROR_FETCH]: item => <Fragment>{lang('validity_error_fetch')}<BL.StatusBadge code={item.status} /></Fragment>,
      [CONST.DATA_ERRORS.INCORRECT_XML]: () => lang('validity_error_incorrect_xml'),
      [CONST.DATA_ERRORS.EMPTY_MAP]: () => lang('validity_error_empty_map'),
      [CONST.DATA_ERRORS.CICLE_INDEX]: () => lang('validity_error_cicle_index'),
      [CONST.DATA_ERRORS.INVALID_HOST]: () => lang('validity_error_invalid_host'),
      [CONST.DATA_ERRORS.INVALID_MAP_FORMAT]: () => lang('validity_error_invalid_map_format'),
      [CONST.DATA_ERRORS.TOO_MUCH_LINKS]: () => lang('validity_error_too_much_links'),
      [CONST.DATA_ERRORS.RELATIVE_LINKS]: () => lang('validity_error_relative_links'),
      [CONST.DATA_ERRORS.MIXED_PROTOCOLS]: () => lang('validity_error_mixed_protocols'),
      [CONST.DATA_ERRORS.MAP_DISALLOWED]: () => lang('validity_error_map_disallowed'),
    };

    const renderItem = map => {
      return (
        <li key={map.url} className="tools_sitemapper-stats-item-body-list-item">
          <div>{map.url}</div>
          <ul className="tools_sitemapper-stats-item-body-error-list">
            {map.errors.map(error => <li key={error} className="tools_sitemapper-stats-item-body-error-item">{!!ERRORS[error] && ERRORS[error](map)}</li>)}
          </ul>
        </li>
      );
    };

    return <ol className="tools_sitemapper-stats-item-body-list">{maps.map(renderItem)}</ol>;
  }

  renderBody() {
    const { type, items } = this.props;

    const sholdBeValid = { [TYPE_CORRECT]: true, [TYPE_INCORRECT]: false }[type];

    const maps = items.filter(item => !item.errors.length === sholdBeValid);

    if (sholdBeValid) {
      return this.renderSimpleMaps(maps);
    } else {
      return this.renderIncorrectMaps(maps);
    }
  }
}

class Block extends _Block {
  constructor(props) {
    super(props);

    const { lang } = props;

    this.title = lang('validity_title');
    this.infoText = () => lang('validity_title_info');
    this.infoTextActive = false;
  }

  renderItems() {
    const { lang, result: { items, stats } } = this.props;

    const list = [TYPE_CORRECT, TYPE_INCORRECT];

    return list.map(type => <Item key={type} lang={lang} type={type} items={items} stats={stats} />);
  }
}

export default Block;
