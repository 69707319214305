import Provider from '/services/Provider';

class ProjectsProvider extends Provider {
  getProjectTypes() {
    const { api } = this;

    return api.projectTypes.getList();
  }

  getList() {
    const { api } = this;

    return api.projects.getList();
  }

  reloadProjects() {
    const { api } = this;

    return api.projects.reloadProjects();
  }

  getProject(project_id) {
    const { api } = this;

    return api.projects.getProject(project_id);
  }

  getProjectMirrors(project_id) {
    const { api } = this;

    return api.projects.getProjectMirrors(project_id);
  }

  getProjectSitemaps(project_id) {
    const { api } = this;

    return api.projects.getProjectSitemaps(project_id);
  }

  checkSitemaps(urls) {
    const { api } = this;

    return api.sitemap.checkSitemaps(urls);
  }

  decideSitemaps(host) {
    const { api } = this;

    return api.sitemap.decideSitemaps(host);
  }

  getSettings(project_id) {
    const { api } = this;

    return api.projects.getSettings(project_id);
  }

  updateSettings(project_id, site_type_id, sitemaps) {
    const { api } = this;

    return api.projects.updateSettings(project_id, { site_type_id, sitemaps });
  }

  checkSitemapUrl(url) {
    const { api } = this;

    return api.tools.serverResponse([{ url }], 'USER_BROWSER');
  }

  checkYandexCounter(counter) {
    const { api } = this;

    return api.tokens.checkYandexCounter(counter);
  }
}

export default ProjectsProvider;
