import Provider from '/services/Provider';

class SearcherUpdatesProvider extends Provider {
  getUpdates = () => this.api.searcerUpdates.getUpdates();
  addUpdate = data => this.api.searcerUpdates.addUpdate(data);
  updateUpdate = (id, data) => this.api.searcerUpdates.updateUpdate(id, data);
  deleteUpdate = id => this.api.searcerUpdates.deleteUpdate(id);
}

export default SearcherUpdatesProvider;
