import React from 'react';

import UI from '/views/ui';

import emitter from '/utils/emitter';

const union = function union() {
  let FORM_onSubmit = () => {};
  let FORM_onChange = () => {};

  const Form = function Form(props) {
    const { onChange, onInvalid, onSubmit, ...sets } = props;

    sets.onSubmit = FORM_onSubmit = async event => {
      event.preventDefault();

      const isValid = await Form.validate();

      if (isValid) {
        onSubmit && onSubmit(Form.data);
        Form.trigger('submit', Form.data);
      } else {
        onInvalid && onInvalid(Form.errors);
        Form.trigger('invalid', Form.errors);
      }
    };

    FORM_onChange = (name, value) => {
      onChange && onChange(name, value, Form.data);
      Form.trigger('change', name, value, Form.data);
    };

    return <UI.Form { ...sets } />;
  };

  Form.VALIDATION = UI.Form.VALIDATION;
  Form.ERROR = UI.Form.ERROR;

  Form.fields = {};

  Form.data = {};
  Form.errors = {};

  Form.validate = async function validate() {
    const waitings = Object.values(Form.fields).map(field => field.validate());

    await Promise.all(waitings);

    return Object.values(Form.errors).every(e => !e.length);
  };

  UI.Form.FIELDS.forEach(name => {
    Form[name] = props => {
      const Constructor = UI.Form[name];

      const sets = { ...props };

      sets.onCreate = (name, field) => Form.fields[name] = field;

      sets.onChange = (name, value) => {
        Form.data[name] = value;

        const { onChange } = props;

        onChange && onChange(name, value);
        FORM_onChange && FORM_onChange(name, value);
      };

      sets.onValidate = (name, errors) => {
        Form.errors[name] = errors;
        Form.trigger('error', name, errors);

        const { onValidate } = props;

        onValidate && onValidate(name, errors);
      };

      if (!['Submit'].includes(Constructor.name)) {
        sets.union = Form;
      }

      return <Constructor { ...sets } />
    };
  });

  UI.Form.VIEWS.forEach(name => {
    Form[name] = props => {
      const Constructor = UI.Form[name];

      const sets = { ...props };

      sets.onSubmit = FORM_onSubmit;

      return <Constructor { ...sets } />
    };
  });

  return emitter(Form, true);
};

union.VALIDATION = UI.Form.VALIDATION;
union.ERROR = UI.Form.ERROR;

export default union;
