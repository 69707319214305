import React, { Component } from 'react';
import Table from './components/Table';

import UI from '/views/ui';

class Yandex extends Component {
  componentWillUnmount() {
    this.popup && this.popup.destroy();
  }

  render() {
    const { items, onAdd, onRefresh, onRemove } = this.props;

    const actions = { onRefresh, onRemove };

    return (
      <div className="p-3">
        <UI.Notice className="mb-3">
          <p className="mb-1">Убедитесь, что вы залогинены в аккаунт Яндекс, который хотите прикрепить (например, <a href="https://passport.yandex.ru/auth" target="_blank">откройте профиль</a>).</p>
          <p>После этого нажмите кнопку "Добавить аккаунт".</p>
        </UI.Notice>
        <div className="mt-3 mb-3">
          <UI.Button icon="fal fa-plus" onClick={onAdd}>Добавить аккаунт</UI.Button>
        </div>
        <Table data={items} emptyText="Нет прикреплённых аккаунтов" actions={actions} />
      </div>
    );
  }
}

export default Yandex;
