import React, { Component } from 'react';

import { UI } from '/views';

class Closed extends Component {
  onOpen = () => {
    const { id, toggleConversationState } = this.props;

    toggleConversationState(id, false);
  };

  render() {
    return (
      <div className="support_chat-input_wrapper d-flex flex-column pb-2">
        <div className="pt-3 pb-2 text-center">
          <p>Данный тикет закрыт для общения.</p>
          <p>Вы можете переоткрыть его нажав на кнопку ниже.</p>
        </div>
        <div className="d-flex justify-content-center pb-3">
          <UI.Button.Info onClick={this.onOpen} text="Переоткрыть тикет" />
        </div>
      </div>
    );
  }
}

export default Closed;
