import { DEFAULT, SUPER_ADMIN, SUPPORT, USER, SUPER_USER } from '/static/data/roles';

const self = {};

self[DEFAULT] = {
  available: true,
  Root: {
    available: true,
  },
};

export default self;
