import React from 'react';

import { UI, BL, SH } from '/views';

const InsertBox = props => {
  const { main, ...sets } = props;

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title="Пользовательские ссылки" expand={false}>
          <SH.Insert.Links className="pt-2 pb-3 pl-3 pr-3" pattern={main} { ...sets } />
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default InsertBox;
