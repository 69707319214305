const render = (name, top, left, width, type) => {
  const style = [
    `top: ${top}px;`,
    `left: ${left}px;`,
  ];

  width && style.push(`width: ${width}px;`);

  const className = 'ui_line_chart-chart_label_text ui_line_chart-chart_label_text_' + type;

  return `<div class="${className}" style="${style.join(' ')}"><div>${name}</div></div>`;
};

const renderLabel = (label, type) => render(label.name, label.top, label.left, label.width, type);

const renderRows = rows => {
  const lines = [];

  for (let row, i = 0; row = rows[i]; i++) {
    const { top, bottom } = row.border;

    !i && (lines[lines.length] = renderLabel(top.label, 'y'));

    lines[lines.length] = renderLabel(bottom.label, 'y')
  }

  return lines.join('');
};

const renderColumns = columns => {
  const lines = [];

  for (let column, i = 0; column = columns[i]; i++) {
    lines[lines.length] = renderLabel(column.label, 'x');
  }

  return lines.join('');
};

export default function html_axis(rows, columns) {
  let html = '';

  html += renderRows(rows);
  html += renderColumns(columns);

  return html;
};
