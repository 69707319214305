import Text from './components/Text';
import Email from './components/Email';
import Password from './components/Password';
import Checkbox from './components/Checkbox';
import TextArea from './components/TextArea';
import Select from './components/Select';
import Submit from './components/Submit';

const self = { Text, Email, Password, Checkbox, TextArea, Select, Submit };

self.FIELDS = Object.keys(self);

export default self;
