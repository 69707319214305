import { deduplicateUsingMap } from '/utils/array';
import { getRoot } from '/utils/link';

import CONST from '../../constants';

const split = item => {
  const year = item.lastmod ? item.lastmod.slice(0, 4) : null;

  const root = getRoot(item.url) || '';
  const subs = item.url.slice(root.length).split('/').filter(v => !!v);

  if (item.url.slice(-1) === '/') {
    subs[subs.length - 1] += '/';
  }

  const chanks = [root, ...subs];

  return { chanks, year, link: item.url };
};

const createTreeDivarication = name => ({
  name: name,
  link: null,
  settings: {
    count: 0,
    years: [],
  },
  items: {},
});

const createTree = function createTree(items) {
  const result = {};

  for (let item, i = 0; item = items[i]; i++) {
    let context = result;

    for (let chank, j = 0; chank = item.chanks[j]; j++) {
      !(chank in context) && (context[chank] = createTreeDivarication(chank));

      const { settings } = context[chank];

      item.year && !settings.years.includes(item.year) && settings.years.push(item.year);

      if (item.chanks.length !== j + 1) {
        settings.count += 1;
      } else {
        context[chank].link = item.link;
      }

      context = context[chank].items;
    }
  }

  return result;
};

const structurize = function structurize(items) {
  const sitemaps = items.filter(item => item.type === CONST.SITE_TYPE.SITEMAP);
  const links = sitemaps.reduce((r, item) => (r.push(...item.links), r), []);

  const uniqs = deduplicateUsingMap(links, item => item.url);

  const splitted = uniqs.map(split);
  const tree = createTree(splitted);

  return tree;
};

export default structurize;
