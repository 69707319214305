import React from 'react';

import VIEW from '/views/view';

import { link } from '/services/Components/methods';

import './styles.scss';

const Logo = props => {
  const { lang } = props;

  return (
    <div className="page-logo">
      <a href={link('Home.Root', { lang })} className="page-logo-link d-flex align-items-center position-relative logo-32">
        <div className="logo-image" />
        <div className="logo-text" />
      </a>
      <span className="pin-sidebar" onClick={VIEW.SIDEBAR.toggle}>
        <i className="ni ni-pin" />
      </span>
    </div>
  );
};

export default Logo;
