import React, { Component } from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

import { LangConsumer } from '/contexts/Lang';

class Child extends Component {
  renderNGram(list) {
    const { lang, actions, selections } = this.props;
    const { onSelect, onDeselect } = actions;

    const row = (item, key) => {
      const exists = selections.includes(item.lemma);

      const Button = exists ? UI.Button.Danger : UI.Button;

      const icon = exists ? 'fal fa-times' : 'fal fa-chevron-double-right';
      const action = exists ? onDeselect : onSelect;

      return (
        <tr key={key}>
          <td className="text-center p-2">
            <Button icon={icon} size="xs" className="pl-2 pr-2 pt-1 pb-1" onClick={() => action(item.lemma)} />
          </td>
          <td className="text-left p-2">{item.lemma}</td>
          <td className="center p-2">{item.count}</td>
          <td className="center p-2">{item.percent}</td>
        </tr>
      );
    };

    return (
      <div className="mt-3">
        <div className="title color-inof-700 mb-2">
          <b>{lang('words_ngram')}</b>
        </div>
        <table className="table darken table-bordered table-sm text-center col-10 mt-2 mb-0" style={{ maxWidth: '640px' }}>
          <thead>
            <tr>
              <th style={{ width: '60px', maxWidth: '60px', minWidth: '60px' }} className="text-center p-2">{lang('words_ngram_col_add')}</th>
              <th style={{ width: '47%' }} className="text-left p-2">{lang('words_ngram_col_phrases')}</th>
              <th style={{ width: '20%' }} className="p-2">{lang('words_ngram_col_count')}</th>
              <th style={{ width: '20%' }} className="p-2">
                {lang('words_ngram_col_percent')}
                <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                  {lang('words_ngram_col_percent_info')}
                </BL.Info.Dark>
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map(row)}
          </tbody>
        </table>
      </div>
    );
  }

  renderNGrams() {
    const { lang, item } = this.props;

    if (!item.ngrams.length) {
      return <div className="pt-1">{lang('words_ngram_missing')}</div>;
    }

    const list = [].concat(...item.ngrams.map(v => v.items));

    list.sort((a, b) => b.count - a.count)

    return this.renderNGram(list);
  }

  render() {
    const { lang, item } = this.props;

    return (
      <div>
        <div className="title color-inof-700 mb-3">
          <b>{lang('words_forms')}:</b> {item.forms.join(', ')}
        </div>
        <div className="divider" />
        {this.renderNGrams()}
      </div>
    );
  }
}

const child = function child(item, actions, { ngrams, selections }) {
  return (
    <LangConsumer>
      {lang => <Child lang={lang} item={item} actions={actions} ngrams={ngrams} selections={selections} />}
    </LangConsumer>
  );
}

child.check = function check(item) {
  return item.ngrams.length;
};

export default child;
