import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL, UN } from '/views';

import { deferred } from '/utils/common';

class UserApprove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      error: null,
    };

    if (__CLIENT__) {
      const defer = deferred();

      defer.then(() => this.setState({ ready: true, error: false }));
      defer.catch(() => this.setState({ ready: true, error: true }));

      props.approveUserMail(defer);
    }
  }

  renderResult() {
    const { error } = this.state;

    const icon = error
      ? <i className="fal fa-times-circle text-danger" />
      : <i className="fal fa-check-circle text-success" />;

      const text = error ? 'Что-то пошло не так.' : 'Ваша почта подтверждена.';

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="text-center fs-35">
          {icon}
        </div>
        <div className="text-center mt-2">
          {text}
        </div>
      </div>
    );
  }

  render() {
    const { ready } = this.state;

    return (
      <div className="container">
        <h2 className="text-center" style={{ marginTop: 20 }}>Подтверждение адреса электронной почты</h2>

        <div style={{ marginTop: 40 }}>
          {ready ? this.renderResult() : <div className="text-center"><UI.Loader /></div>}
        </div>
      </div>
    );
  }
}

export default UserApprove;
