import React, { Fragment } from 'react';

import { Item as _Item, Block as _Block } from './Block';

import { BL } from '/views';

import CONST from '../../constants';

const TYPE_CORRECT = 'correct';
const TYPE_INCORRECT = 'incorrect';

class Item extends _Item {
  hasItems() {
    return true;
  }

  getHeadData() {
    const { lang, item } = this.props;

    const name = item.name === 'null' ? lang('switches_relative_pages') : item.name;
    const value = item.count;

    return { name, value, infoTextActive: false };
  }
}

class Block extends _Block {
  constructor(props) {
    super(props);

    const { lang } = props;

    this.title = lang('switches_title');
    this.emptyText = lang('switches_empty');
    this.infoText = () => lang('switches_title_info');
    this.infoTextActive = false;
  }

  hasItems() {
    const { lang } = this.props;
    const { root, pagesByRoots } = this.props.result.stats;

    const list = Object.keys(pagesByRoots).filter(name => name !== root);

    return !!list.length;
  }

  renderItems() {
    const { lang } = this.props;
    const { root, pagesByRoots } = this.props.result.stats;

    let list = Object.keys(pagesByRoots).filter(name => name !== root);

    const cleared = root.replace(/^https?:\/\/(www\.)?|\/$/g, '');

    let { subdomains, other, joined } = list.reduce((result, host) => {
      const isSubdomain = host.slice(-1 * cleared.length) === cleared;

      result[isSubdomain ? 'subdomains' : 'other'].push(host);

      return result;
    }, { subdomains: [], other: [], joined: [] });

    if (subdomains.length <= 10) {
      other = [...subdomains, ...other];

      other = other.map(name => ({ name, count: pagesByRoots[name] }));
    } else {
      joined = subdomains.reduce((result, name) => {
        result[0].count += pagesByRoots[name];

        return result;
      }, [{ name: lang('switches_different_pages'), count: 0 }]);
    }

    list = this.listData = [...joined, ...other];

    return list.map(item => <Item key={item.name} lang={lang} item={item} />);
  }

  renderContent() {
    const items = this.renderItems();
    const copyData = this.listData.map(item => item.name).join('\n');

    if (this.hasItems()) {
      return <ul className="tools_sitemapper-stats-list-colored">{items}</ul>;
    }

    return <div className="tools_sitemapper-stats-empty">{this.emptyText}</div>;
  }
}

export default Block;
