import React, { Fragment } from 'react';

import { BL } from '/views';

const fields = {};

fields.url = item => item.url;

fields.status = item => {
  if (item.waiting) return <i className="fal fa-ellipsis-h" />;
  if (!item.result) return <BL.StatusBadge loader={true} />;

  const items = item.result.map(val => ({ error: val.error, code: val.statusCode }));

  return items.map((item, i) => <BL.StatusBadge key={i} {...item} />);
};

fields.redirects = item => {
  if (!item.result) return <i className="fal fa-ellipsis-h" />;

  const last = item.result[item.result.length - 1];

  const items = item.result.filter(val => val.conclusion && val.conclusion.redirect);
  const isStopped = last.conclusion && last.conclusion.redirect && !last.isCycle;

  return items.length + (isStopped ? '+' : '');
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
