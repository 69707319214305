import React from 'react';

import { BL } from '/views';

const ModalErrorRefresh = props => {
  const { name, onCancel, onSubmit } = props;

  const sets = {
    title: 'Ошибка обновления токена',
    icon: 'fal fa-exclamation-circle',
  };

  return (
    <BL.Modal.Alert.Danger { ...sets }>
      <p>Не получилось обновить токен.</p>
      <p className="mt-3">Проверьте подключение к интернету и попробуйте позже.</p>
    </BL.Modal.Alert.Danger>
  );
};

export default ModalErrorRefresh;
