import view from './view';

import { createRoute } from '/services/Router/route';

import { DEFAULT } from '/static/data/roles';

const route = createRoute({
  path: '/tools/robots-txt',
  layout: user => user.role === DEFAULT ? 'Default' : 'Dashboard',
});

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

export default { route, view, settings };
