import React from 'react';

import { BL } from '/views';

const ModalClear = props => {
  const { lang, onCancel, onSubmit } = props;

  const sets = {
    title: lang('modal_clear_title'),
    icon: 'fal fa-exclamation-circle',
    actions: { onCancel, onSubmit },
    buttons: { cancel: lang('modal_clear_cancel'), submit: lang('modal_clear_confirm') },
  };

  return (
    <BL.Modal.Confirm.Danger { ...sets }>
      {lang('modal_clear_description', {}, { useParser: true })}
    </BL.Modal.Confirm.Danger>
  );
};

export default ModalClear;
