import getAuthCode from './getAuthCode';

const methods = {
  getAuthCode,
};

export default target => {
  Object.assign(target.prototype, methods);

  return target;
};
