const self  = {};

self.head_title = 'Keyword Density Checker Online: Fast &amp; Free SEO Tool';
self.head_keywords = '';
self.head_description = 'Keyword Density Checker by SERP.tools is a free tool to check keyword density percentage in text for one to five words, find LSI phrases';

self.name = 'Lemmer';

self.title = 'Keyword Density Checker';
self.description = 'Paste test you want to check, detect keyword stuffing, find competitor\'s LSI phrases';

self.field_title = 'Text';

self.count_symbolsTotal = 'Characters: <b><%= count %></b>';
self.count_symbolsCleared = 'Without spaces: <b><%= count %></b>';
self.count_words = 'Words: <b><%= count %></b>';

self.button_check = 'Check keyword density';

self.cleared_title = 'Processed text';

self.tab_words = 'Words';
self.tab_phrases = 'Phrases (2-4 words)';
self.tab_forms = 'Word forms';

self.export = 'Export';

self.mylist_title = 'My Keyword List';
self.mylist_button_clear = 'Clear';
self.mylist_button_copy = 'Copy';
self.mylist_empty = 'No added keywords';
self.mylist_button_add_all = 'Add All to My Keyword List';

self.modal_clear_title = 'Clear your Keword List?';
self.modal_clear_description = '<p>This action will clear all keywords added to your list</p>';
self.modal_clear_cancel = 'Cancel';
self.modal_clear_confirm = 'Ok, clear';

self.modal_transfer_title = 'Add All Keywords to My Keyword List';
self.modal_transfer_description = 'The action will add all words/phrases from the current table to My Keyword List';
self.modal_transfer_cancel = 'Cancel';
self.modal_transfer_confirm = 'Add all keywords';

self.words_col_add = 'Add to list';
self.words_col_words = 'Words';
self.words_col_count = 'Count';
self.words_col_percentText = 'Density %';
self.words_col_percentText_info = 'Shows keyword density among all words in the text, including stop words (prepositions, conjunctions, articles, etc.)';
self.words_col_percentCore = '% w/o stop words';
self.words_col_percentCore_info = 'Shows keyword density among all meaningful words (in the text cleared of stop words: prepositions, conjunctions, articles, etc.)';

self.words_forms = 'Word forms';

self.words_ngram = 'Word occurs in phrases:';
self.words_ngram_missing = 'This word does not appear in any phrase';

self.words_ngram_col_add = 'Add to list';
self.words_ngram_col_phrases = 'Phrases';
self.words_ngram_col_count = 'Count';
self.words_ngram_col_percent = '% for word';
self.words_ngram_col_percent_info = 'How often does this word appear in this particular phrase in the text';

self.phrases_col_add = 'Add to list';
self.phrases_col_phrases = 'Phrases';
self.phrases_col_count = 'Count';
self.phrases_col_percent = 'Density %';

self.phrases_filter = 'Words in a phrase';
self.phrases_filter_all = 'All (2-4)';

self.forms_col_words = 'Words';
self.forms_col_count = 'Count';
self.forms_col_forms = 'Word forms from the text';

export default self;
