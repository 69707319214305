import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Источник',
    field: 'source',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '200px', maxWidth: '200px', minWidth: '200px', textAlign: 'center' },
  },
  {
    name: 'Адрес страницы',
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: {},
  },
];
