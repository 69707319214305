const self = {};

self.all_traffic = {
  icon: 'fal fa-chart-bar',
  name: 'Изменение поискового трафика по страницам',
  menu: 'Изменение поискового трафика по страницам',
  component: 'Reports',
  action: 'AllTrafficResult',
};

self.lost_traffic = {
  icon: 'fal fa-chart-line-down',
  name: 'Страницы, потерявшие поисковый трафик',
  menu: 'Страницы, потерявшие трафик',
  component: 'Reports',
  action: 'LostTrafficResult',
};

self.zero_traffic = {
  icon: 'fal fa-file-times',
  name: 'Страницы без поискового трафика',
  menu: 'Страницы без поискового трафика',
  component: 'Reports',
  action: 'ZeroTraffic',
};

self.missing_in_sitemap = {
  icon: 'fal fa-book-dead',
  name: 'Страницы с трафиком, но не в XML-карте',
  menu: 'Страницы с трафиком, но не в XML-карте',
  component: 'Reports',
  action: 'MissingInSitemap',
};

self.yandex_metrika_query = {
  icon: 'fal fa-binoculars',
  name: 'Запросы, потерявшие поисковый трафик в Яндекс',
  menu: 'Запросы, потерявшие трафик',
  component: 'Reports',
  action: 'YandexMetrikaQueriesResult',
};

self.search_algo_update = {
  icon: 'fal fa-arrow-alt-square-up',
  name: 'Апдейты поисковых систем',
  menu: 'Апдейты поисковых систем',
  component: 'Reports',
  action: 'SearchAlgoUpdate',
};

self.search_keywords = {
  icon: 'fal fa-bath',
  name: 'Автоподбор запросов',
  menu: 'Автоподбор запросов',
  component: 'Reports',
  action: 'SearchKeywords',
};

self.https_transition = {
  icon: 'ni ni-lock',
  name: 'Переход на https',
  menu: 'Переход на https',
  component: 'Reports',
  action: 'HttpsTransition',
};

self.main_mirror = {
  icon: 'fal fa-chess-king-alt',
  name: 'Главное зеркало',
  menu: 'Главное зеркало',
  component: 'Reports',
  action: 'MainMirror',
};

self.metrika_domains = {
  icon: 'fal fa-ballot-check',
  name: 'Сайты со счетчиком Метрики',
  menu: 'Сайты со счетчиком Метрики',
  component: 'Reports',
  action: 'MetrikaDomains',
};

self.sitemaps = {
  icon: 'fal fa-sitemap',
  name: 'Анализ XML-карты сайта',
  menu: 'Анализ XML-карты сайта',
  component: 'Reports',
  action: 'Sitemaps',
};

export default self;
