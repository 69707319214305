import React from 'react';

import UserMenu from '/components/User/views/UserMenu';

import UI from '/views/ui';

const TopLine = props => {
  return (
    <header className="page-header">
      <div className="hidden-lg-up">
        <UI.Button.Primary href="#" icon="ni ni-menu" size="sm" />
      </div>

      <div className="ml-auto d-flex align-items-center">
        <UserMenu lang={props.lang} />
      </div>
    </header>
  );
};

export default TopLine;
