import React, { Component } from 'react';
import cn from 'classnames';

import { Lang } from '/contexts/Lang';

import Loader from '/views/ui/loader';

import TableRow from '../TableRow';

import unpackDeepChilds from '../../utils/unpackDeepChilds';

import { getPicker } from '/utils/object';

const Loading = props => {
  const { columns } = props;

  return (
    <tr>
      <td className="text-center" colSpan={columns.length}>
        <UI.Loader />
      </td>
    </tr>
  );
};

const Empty = props => {
  const { emptyText, columns } = props;

  return (
    <tr>
      <td className="text-center select-none" colSpan={columns.length}>
        {emptyText || <Lang text="empty" context="views.ui.Table" />}
      </td>
    </tr>
  );
};

class TableBody extends Component {
  renderRow = (item, idx, columns) => {
    const { itemId } = this.props;

    const key = itemId ? itemId(item) : idx;

    const picker = getPicker('data', 'getter', 'actions', 'decorator', 'settings', 'child');

    const sets = { key, columns, item, ...picker(this.props) };

    return <TableRow className={idx % 2 ? 'even' : 'odd'} { ...sets } />;
  };

  render() {
    const { data, loading, columns } = this.props;

    const deepColumns = unpackDeepChilds(columns);

    let childs = null;

    if (loading) childs = <Loading columns={deepColumns} />;
    else if (!data.visible.length) childs = <Empty columns={deepColumns} />;
    else childs = data.visible.map((item, idx) => this.renderRow(item, idx, deepColumns));

    return <tbody>{childs}</tbody>;
  }
}

export default TableBody;
