import React, { Component, Fragment } from 'react';
import { LangConsumer } from '/contexts/Lang';

import { UI } from '/views';

import { DATA_ERRORS } from '../../../../constants';

import CHECK_LIST from './checklist.js';

/*
5. Индексирование файла sitemap запрещено HTTP-заголовком X-Robots-Tag (X-Robots-Tag содержит nofollow)
*/

class ChildView extends Component {
  getCheckList = () => {
    const { lang, item, robots, stats } = this.props;

    const createBox = (src, item, status) => {
      let { title, body } = src;

      if (!title[status]) return null;

      const Icon = status === 'ok' ? UI.Icon.Good : UI.Icon.Danger;

      const name = (
        <Fragment>
          <Icon className="fs-11 mr-2" /> <span>{title[status](lang, item, stats)}</span>
        </Fragment>
      );

      body && (body = body[status] ? body[status](lang, item, robots) : null);

      return { name, body };
    };

    if (item.errors.includes(DATA_ERRORS.ERROR_FETCH)) {
      return [createBox(CHECK_LIST.ERROR_FETCH, item, 'error')];
    }

    if (item.redirects) {
      return [createBox(CHECK_LIST.REDIRECT, item, 'error')];
    }

    return [
      DATA_ERRORS.INCORRECT_XML,
      DATA_ERRORS.EMPTY_MAP,
      DATA_ERRORS.UNKNOWN_MAP_FORMAT,
      DATA_ERRORS.INVALID_MAP_FORMAT,
      DATA_ERRORS.INVALID_HOST,
      DATA_ERRORS.CICLE_INDEX,
      DATA_ERRORS.RELATIVE_LINKS,
      DATA_ERRORS.MAP_DISALLOWED,
      DATA_ERRORS.TOO_MUCH_LINKS,
      DATA_ERRORS.MIXED_PROTOCOLS,
    ].map(code => createBox(CHECK_LIST[code], item, item.errors.includes(code) ? 'error' : 'ok')).filter(v => !!v);
  };

  renderCheckList = () => {
    const { lang } = this.props;

    const list = this.getCheckList();

    return <UI.DetailList title={lang('title_checklist')} items={list} noValue={true} />;
  };

  render() {
    return (
      <Fragment>
        {this.renderCheckList()}
      </Fragment>
    );
  }
}

const child = (item, actions, settings) => {
  return (
    <LangConsumer>
      {lang => <ChildView lang={lang} item={item} {...settings} />}
    </LangConsumer>
  );
};

export default child;
