import React from "react";
import cn from 'classnames';
import logo from '/static/img/home-page/logo.svg';


const Logo = ({ className }) => {
  return (
    <div className={cn("d-flex align-items-center", className)}>
      <img src={logo} className="img-fluid" alt="SERP.tools"/>
    </div>
  );
};

export default Logo;
