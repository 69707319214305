import React, { Fragment } from 'react';

import { execChildren } from '/utils/react';

const Execute = props => {
  return (
    <Fragment>
      {execChildren(props.children)}
    </Fragment>
  );
};

export default Execute;
