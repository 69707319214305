import React, { Component, Fragment } from 'react';

import KeyControl from '/services/KeyControl';

import { BL } from '/views';

const ModalTransfer = props => {
  const { lang, onCancel, onSubmit } = props;

  const sets = {
    title: lang('modal_transfer_title'),
    icon: 'fal fa-question-circle',
    actions: { onCancel, onSubmit },
    buttons: { close: lang('modal_transfer_cancel'), submit: lang('modal_transfer_confirm') }
  };

  return (
    <BL.Modal.Confirm.Info { ...sets }>
      {lang('modal_transfer_description')}
    </BL.Modal.Confirm.Info>
  );
};

export default ModalTransfer;
