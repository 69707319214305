import parse from 'html-react-parser';

import languages from '/static/languages';

import { getter } from '/utils/object';
import { replace } from '/utils/string';

import { isFunction, isObject } from '/utils/types';

import settings from './settings';

const Lang = function Lang(lang, context = '') {
  const self = function lang(name, params, sets) {
    const { useContext = true, useParser = false } = sets || {};

    let path = self.cur + '.' + (useContext && self.context ? self.context + '.' + name : name);

    // console.log('## ->', path);

    let text = getter(languages, path);

    if (!text) return '';
    if (isObject(text)) return text;

    isFunction(text) && (text = text(params));
    isObject(params) && (text = replace(text, params));

    useParser && (text = parse(text));

    return text;
  };

  self.cur = lang;
  self.context = context;

  self.getLanguage = () => self.cur;

  self.setLanguage = name => {
    self.cur = name;

    return self;
  }

  self.setContext = context => self.context = context;

  self.toString = () => self.cur;

  self.child = context => {
    return new Lang(self.cur, context || self.context);
  };

  return self;
};

Object.assign(Lang, settings);

export default Lang;
