import React from 'react';
import cn from 'classnames';

import { BL } from '/views';

import config from './config';

const Table = props => {
  const { lang, className, ...sets } = props;
  const _config = { ...config };

  return (
    <div className={cn(className, 'pl-3 pr-3 select-text')}>
      <h5 className="mb-0 color-primary-500">{lang('sitemap_check_title')}</h5>
      <BL.Table className="darken" { ...sets } { ..._config } />
    </div>
  );
};

export default Table;
