import React, { Component } from 'react';
import cn from 'classnames';

import { deferred } from '/utils/common';

import { UN, VIEW } from '/views';

class Signin extends Component {
  state = {
    error: null,
    lock: false
  };

  Form = UN.Form();

  onSubmit = async values => {
    if (this.state.lock) return;

    this.setState({ lock: true });

    const { signin } = this.props;
    const { mail, password, remember } = values;

    const defer = deferred();

    defer.then(() => VIEW.MODAL.hide());
    defer.catch(() => this.setState({ lock: false, error: 'Не удалось найти пользователя' }));

    signin(mail, password, remember, defer);
  };

  render() {
    const { error, lock } = this.state;

    const { Form } = this;

    return (
      <Form className="d-flex flex-column justify-content-between col-12" onSubmit={this.onSubmit}>
        <div>
          <div className="form-group input-group-sm mb-2">
            <Form.Email className="w-100" name="mail" label="Адрес электронной почты" required />
          </div>
          <div className="form-group input-group-sm mb-3">
            <Form.Password className="w-100" name="password" label="Пароль" required />
          </div>
          <div className="custom-control custom-checkbox">
            <Form.Checkbox name="remember" label="Запомнить на этом устройстве" />
          </div>
        </div>
        <div>
          <div className={cn('h5 mb-2 select-none', !error && 'invisible')}>
            <small className="mb-0 color-danger-600">{error}.</small>
          </div>
          <div className="d-flex justify-content-center">
            <Form.Submit style={{ minWidth: 220 }} icon2="fal fa-angle-double-right ml-1" spin2={lock} text="Войти" />
          </div>
        </div>
      </Form>
    );
  }
};

export default Signin;
