import React, { Fragment } from 'react';

import Table from '../Table';

import { UI, BL } from '/views';

import { ROBOTS_FILE } from '../../constants';

const renderRobots = (lang, host, robots) => {
  const { status, data, error } = robots;

  const url = host + '/' + ROBOTS_FILE;

  const link = (
    <div>
      <a className="text-decoration-none" href={url} target="_blank">{url}</a>
    </div>
  );

  let content = null;

  if (error) {
    content = <div className="mt-3">{lang('error_missingRobots', { url, status }, { useParser: true })}</div>;
  } else {
    content = (
      <div className="mt-3">
        <UI.Code lines={data.split("\n")} />
      </div>
    );
  }

  return (
    <Fragment>
      {link}
      {content}
    </Fragment>
  );
};

const renderResults = (links, robots) => {
  return <Table data={links} settings={{ robots }} />
};

const renderTab = (lang, idx, host, robots, links) => {
  let content = null;

  idx === 0 && (content = renderResults(links, robots));
  idx === 1 && (content = renderRobots(lang, host, robots));

  return <div className="p-3">{content}</div>;
};

const Result = props => {
  const { lang, domain, host, robots, links } = props;

  const sets = {
    items: [
      lang('tab_links'),
      {
        name: (
          <Fragment>
            {lang('tab_robots')}
            {!!robots.error && <i className="fal fa-exclamation-circle color-danger-500 ml-1 fs-9 font-weight-bold" />}
          </Fragment>
        ),
      },
    ],
    child: idx => renderTab(lang, idx, host, robots, links),
  };

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title={lang('result_title', { domain })} expand={true}>
          <BL.Tabs { ...sets } />
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default Result;
