import React, { Fragment, useState } from 'react';

import TableForms from '../../../Lemmer/components/TableForms';

import { UI } from '/views';

import { LangProvider } from '/contexts/Lang';

const LemmsBox = (props) => {
  const { lang, lemms, className } = props;
  const [showLemms, setShowLemms] = useState(props.showLemms);

  const subLang = lang.child('components.Tools.Lemmer');

  return (
    <div className={className}>
      {showLemms
        ? <Fragment>
            <h3>{lang('title_lemms')}</h3>
            <LangProvider value={subLang}>
              <TableForms lang={subLang} data={lemms} />
            </LangProvider>
          </Fragment>
        : <UI.Button.Primary text={lang('button_countWords')} icon2="fal fa-angle-double-right ml-1" onClick={() => setShowLemms(true)}/>
      }
    </div>
  );
}

export default LemmsBox;
