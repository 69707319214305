import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';
import { createAudit } from '/components/Audit/store/actions';
import { fetchProjectTypes, saveProjectSettings, fetchProjectMirrors, fetchProjectSitemaps } from '../../store/actions';

const route = createRoute({ path: '/projects/:id/settings?step=:step&callback=:callback', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { router, projects } = state;

  const { id, step, callback } = router.params.url;

  const project = projects.items[id];
  const types = projects.types;

  return { project, types, step, callback };
};

const mapDispatchToProps = { fetchProjectTypes, saveProjectSettings, createAudit, fetchProjectMirrors, fetchProjectSitemaps };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
