import audits from './audits';
import conversations from './conversations';
import projects from './projects';
import projectTypes from './projectTypes';
import reports from './reports';
import searcerUpdates from './searcerUpdates';
import sitemap from './sitemap';
import statistics from './statistics';
import support from './support';
import tokens from './tokens';
import tools from './tools';
import users from './users';

const ext = {
  audits,
  conversations,
  projects,
  projectTypes,
  reports,
  searcerUpdates,
  sitemap,
  statistics,
  support,
  tokens,
  tools,
  users,
};

export default target => {
  for (let name in ext) {
    if (!ext.hasOwnProperty(name)) continue;

    target[name] = target[name] || {};

    for (let method in ext[name]) {
      if (!ext[name].hasOwnProperty(method)) continue;

      target[name][method] = ext[name][method].bind(target);
    }
  }

  return target;
};
