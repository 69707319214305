import React from 'react';

import getUniqLinks from '../utils/getUniqLinks';

const fields = {};

fields.query = item => item.query;

fields.links = item => getUniqLinks(item).length;

fields.state = (item, actions, settings) => {
  const { verdict } = item;

  const states = {
    TRAFFIC_NEW: 'Новый',
    TRAFFIC_GROW: 'Вырос',
    TRAFFIC_DROP: 'Упал',
    TRAFFIC_ZERO: 'Упал в 0',
    TRAFFIC_STABLE: 'На месте',
    TRAFFIC_UNMATCH: '-'
  };

  return <span className="d-block text-truncate w-100">{states[verdict]}</span>;
};

fields.year_0 = item => item.stats.year[0].total.visits.variance;
fields.year_1 = item => item.stats.year[1].total.visits.variance;

fields.diff_year = item => {
  const { variance, percent } = item.analytics.year.total.visits;

  return variance + (percent !== null ? ' (' + percent + '%)' : '');
};

fields.diff_3_month = (item, actions, settings) => {
  const { variance, percent } = item.analytics.last3month.total.visits;

  return variance + (percent !== null ? ' (' + percent + '%)' : '');
};

fields.diff_1_month = (item, actions, settings) => {
  const { variance, percent } = item.analytics.last1month.total.visits;

  return variance + (percent !== null ? ' (' + percent + '%)' : '');
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
