import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import KeyControl from '/services/KeyControl';

import UI from '/views/ui';
import VIEW from '/views/view';

import { isUndefined, isNull, isArray } from '/utils/types';
import { ref } from '/utils/react';

const getClosers = ({ closers }) => {
  if (isNull(closers)) return [];
  if (isArray(closers)) return closers;

  if (isUndefined(closers)) {
    return [Modal.CLOSE_OVERLAY, Modal.CLOSE_ESC, Modal.CLOSE_CROSS];
  }

  return closers.split(' ').map(v => v.toUpperCase());
};

class Modal extends Component {
  constructor(props) {
    super(props);

    this.closers = getClosers(props);
  }

  ref = ref;

  onClose = event => {
    event && event.preventDefault();

    const { onClose } = this.props;

    onClose && onClose();

    VIEW.MODAL.hide();
  };

  onSubmit = event => {
    event && event.preventDefault();

    const { onSubmit } = this.props;

    onSubmit && onSubmit();
  };

  onBgClick = event => {
    if (!this.closers.includes(Modal.CLOSE_OVERLAY)) return;

    event.target === this.ui.overlay && this.onClose(event);
  };

  componentDidMount() {
    if (this.closers.includes(Modal.CLOSE_OVERLAY)) {
      KeyControl.on('keyup:escape', this.onClose);
    }

    KeyControl.on('keyup:enter', this.onSubmit);
  }

  componentWillUnmount() {
    KeyControl.off('keyup:escape', this.onClose);
    KeyControl.off('keyup:enter', this.onSubmit);
  }

  renderCloseButton = () => {
    if (!this.closers.includes(Modal.CLOSE_CROSS)) {
      return null;
    }

    const style = {};

    return (
      <button className="close" type="button" onClick={this.onClose} style={style}>
        <i className="fal fa-times" />
      </button>
    );
  };

  render() {
    const {
      visible = true,
      position,
      size,
      stl,
      style = {},
    } = this.props;

    return (
      <Fragment>
        <div className={cn('modal', 'fade', 'show', visible && 'd-block')} ref={this.ref('overlay')} tabIndex={visible ? 1 : -1} onClick={this.onBgClick}>
          <div className={cn('modal-dialog', position === 'centered' && 'modal-dialog-centered', size && 'modal-' + size, stl && 'modal-' + stl)}>
            <div className="modal-content" style={style}>
              {this.props.children}
              {this.renderCloseButton()}
            </div>
          </div>
        </div>

        <div className={cn('modal-backdrop', 'fade', 'show', visible && 'd-block')} />
      </Fragment>
    );
  }
}

Modal.CLOSE_ESC = 'ESC';
Modal.CLOSE_CROSS = 'CROSS';
Modal.CLOSE_OVERLAY = 'OVERLAY';

export default Modal;
