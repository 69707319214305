import React, { Fragment } from 'react';
import moment from 'moment';

import ProjectActions from '../../ProjectActions';

import UI from '/views/ui';

import { link } from '/services/Components/methods';
import { capitalize } from '/utils/string';

import renderBadge from '../../../utils/renderBadge';

const fields = {};

fields.host = item => {
  return (
    <Fragment>
      <div className="projects_root-table_item_host"><UI.Favicon url={item.host} style={{ marginRight: 4 }} /><a href={'//' + item.host} target="_blank">{item.host}</a></div>
      <div className="projects_root-table_item_name fs-7p">{item.name}</div>
      <div className="projects_root-table_item_id fs-7p">ID: {item.yandexCounterId}</div>
    </Fragment>
  );
};

fields.stats = item => {
  if (!item.stats) return <b>Нет данных</b>;

  const sparklineLabel = (name, value) => {
    name = capitalize(name);

    return <span style={{ whiteSpace: 'nowrap', fontSize: '0.7rem'  }}>{name} - {value}</span>;
  };

  return (
    <Fragment>
      <div className="text-align-center mb-2">
        Данные на <b>{moment(item.stats.date).subtract(1, 'month').format('MMMM YYYY')}</b>
      </div>
      <div className="d-flex align-items-center">
        <div className="text-left pr-3" style={{ flexGrow: 1 }}>
          <div className="projects_root-table_item_stats">Поисковый трафик: <b>{item.stats.traffic}</b></div>
          <div className="projects_root-table_item_stats">Страницы входа: <b>{item.stats.pages}</b></div>
          <div className="projects_root-table_item_stats">Месяцев с трафиком: <b>{item.stats.monthlyTraffic.filter(item => item.value).length} / 24</b></div>
        </div>
        <UI.Sparkline items={item.stats.monthlyTraffic} width={119} itemWidth={4} popupWidth={126} height={45} label={sparklineLabel} style={{ backgroundColor: 'rgba(85, 76, 127, 0.08)'}} />
      </div>
    </Fragment>
  );
};

fields.audit = (item, actions, settings) => {
  const { lang } = settings;
  const audit = item.audits[0];

  const date = moment(audit.dateAdd).format('D MMMM YYYY HH:mm:ss');

  let move = null;

  if (audit.ready) {
    const url = link('Audit.Reports', { lang, auditId: audit.id });

    move = <UI.Button.Warning className="mt-1 py-1 px-2" icon2="fal fa-arrow-right" size="xs" href={url}>Просмотр</UI.Button.Warning>;
  }

  return (
    <Fragment>
      <div className="projects_root-table_item_stats">{date}</div>
      <div className="projects_root-table_item_stats">{audit.ready ? move : renderBadge(audit, { marginTop: 6 })}</div>
    </Fragment>
  );
};

fields.actions = (item, actions, settings) => {
  return <ProjectActions item={item} actions={actions} settings={settings} />;
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
