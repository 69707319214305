import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { repack } from '/utils/object';
import { capitalize } from '/utils/string';
import { setAsyncTimeout } from '/utils/common';

import getPlainChartLabels from '../../../../../../utils/getPlainChartLabels';

const collect = (isNew, item, select) => {
  const result = {};

  result.yearStart = 1 + item.stats.year[0].findIndex(month => month.date.from.indexOf('-01-01') !== -1);

  result.head = item.stats.year[0].map(month => moment(month.date.from).format('MMM')).map(capitalize);
  result.head.unshift(null);

  result.body = [];

  const overall = !isNew && item.analytics.year.map(month => {
    return { values: repack(month.values, v => v.variance) }
  });

  const rows = isNew ? [item.stats.year[1]] : [...item.stats.year, overall];

  rows.forEach((year, index) => {
    const item = year.map(month => month.values ? month.values[select] : '-');

    let label = '';

    if (isNew || index !== rows.length - 1) {
      const start = moment(year[0].date.from).format('YYYY');
      const finish = moment(year[11].date.from).format('YYYY');

      label = start === finish ? start : start + ' - ' + finish;
    } else {
      label = 'Разница';
    }

    item.unshift(label);

    result.body.push(item);
  });

  return result;
};

const collectChartData = (isNew, item, select) => {
  const labels = getPlainChartLabels(item.stats.year[0].map(month => month.date.from));

  const defaults = sets => ({
    type: 'line',
    pointBorderWidth: 1,
    borderWidth: 2,
    pointRadius: 4,
    pointHoverRadius: 5,
    ...sets
  });

  const lines = [];
  const bars = [];

  const year_1 = {
    name: 'year_1',
    label: moment(item.analytics.total.dates[1].from).format('MMM YYYY') + ' - ' + moment(item.analytics.total.dates[1].to).format('MMM YYYY'),
    pointColor: '#a38cc6',
    pointColorHover: '#54436f',
    color: '#a38cc6',
    fill: 'rgba(163, 140, 198, 0.3)',
    values: item.stats.year[1].map(month => month.values[select]),
  };

  lines.push(year_1);

  if (!isNew) {
    const year_0 = {
      name: 'year_0',
      label: moment(item.analytics.total.dates[0].from).format('MMM YYYY') + ' - ' + moment(item.analytics.total.dates[0].to).format('MMM YYYY'),
      pointColor: '#37e2d0',
      pointColorHover: '#1c978a',
      color: '#37e2d0',
      fill: 'rgba(55, 226, 208, 0.3)',
      values: item.stats.year[0].map(month => month.values[select]),
    };

    const diff = {
      name: 'diff',
      label: 'Разница',
      color: '#ea167a',
      fill: '#fe6bb0',
      values: item.analytics.year.map(month => month.values[select].variance),
    };

    lines.push(year_0);
    bars.push(diff);
  }

  return { labels, lines, bars };
};

class SearcherChild extends Component {
  constructor(props) {
    super(props);

    const { item, select } = props;

    this.state = { item, select };
  }

  static getDerivedStateFromProps(_props, state) {
    if (state === _props.select) {
      return state;
    }

    return _props;
  }

  isNewPage() {
    return this.props.item.verdict === 'TRAFFIC_NEW';
  }

  renderTitle = () => {
    const { select } = this.props;

    const names = { yandex: 'Яндексу', google: 'Google' };

    return <div className="title text-nowrap color-primary-700 mb-2">Трафик по {names[select]}</div>;
  };

  renderTableHead = (head, yearStart) => {
    if (!head) return null;

    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '7.5%' };
      } else {
        sets.style = { width: 80, minWidth: 80, maxWidth: 80, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <th { ...sets }>{key ? value : '\u00A0'}</th>;
    };

    return (
      <thead className="year-row">
        <tr>{head.map(cell)}</tr>
      </thead>
    );
  };

  renderTableBody = (body, yearStart) => {
    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '7.5%' };
      } else {
        sets.style = { width: 80, minWidth: 80, maxWidth: 80, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <td { ...sets }>{value}</td>;
    };

    const row = (data, key) => <tr key={key}>{data.map(cell)}</tr>;

    return <tbody>{body.map(row)}</tbody>;
  };

  renderTable = () => {
    const { item, select } = this.state;

    const isNew = this.isNewPage();

    const { head, body, yearStart } = collect(isNew, item, select);

    return (
      <table className="table darken table-bordered table-layout-fixed table-sm text-center w-100 mb-3">
        {this.renderTableHead(head, yearStart)}
        {this.renderTableBody(body, yearStart)}
      </table>
    );
  };

  renderGraph = () => {
    const { item, select } = this.state;

    const isNew = this.isNewPage();

    const chartData = collectChartData(isNew, item, select);

    return <UI.LineChart data={chartData} offset={{ left: 80 }} settings={{}} />;
  };

  render() {
    return (
      <Fragment>
        {this.renderTitle()}
        {this.renderTable()}
        {this.renderGraph()}
      </Fragment>
    );
  }
}

export default SearcherChild;
