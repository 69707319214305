import React, { Fragment } from 'react';

import { UI, BL } from '/views';

const Verdict = props => {
  const { main, google, yandex } = props.mirror;

  const items = [
    {
      name: (
        <Fragment>
          Главное зеркало
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
            Зеркало сайта, которое будет использоваться как главное в остальных отчетах аудита. Однозначно определяется, если в Яндекс и Google одинаковое главное зеркало.
          </BL.Info.Dark>
        </Fragment>
      ),
      value: main.from.host,
    },
    {
      name: (
        <Fragment>
          Главное зеркало Google
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
            Главное зеркало в поисковой системе Google, определенное нашим алгоритмом.
          </BL.Info.Dark>
      </Fragment>
      ),
      value: google.from.host,
    },
    {
      name: (
        <Fragment>
          Главное зеркало Яндекс
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
            Главное зеркало в поисковой системе Яндекс, определенное нашим алгоритмом.
          </BL.Info.Dark>
      </Fragment>
      ),
      value: yandex.from.host,
    },
  ];

  return <UI.DetailList title="Результат" items={items} />;
};

export default Verdict;
