import Provider from '/services/Provider';

class UserProvider extends Provider {
  signin = (mail, password) => this.api.users.signin(mail, password);
  signup = (lang, name, mail, password) => this.api.users.signup(lang, name, mail, password);
  refresh = () => this.api.users.refresh();
  approve = (id, token) => this.api.users.approve(id, token);
  checkRestoreToken = (id, token) => this.api.users.checkToken(id, 'restore', token);
  requestRestore = (lang, mail) => this.api.users.requestRestore(lang, mail);
  restore = (id, token, password) => this.api.users.restore(id, token, password);
}

export default UserProvider;
