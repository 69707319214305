const renderPoint = (point, idx) => {
  const type = idx ? 'L': 'M';

  return type + point.join(',');
};

const renderPath = item => {
  let points = [
    [item.box.x[0], item.box.y[0]],
    [item.box.x[0], item.box.y[1]],
    [item.box.x[1], item.box.y[1]],
    [item.box.x[1], item.box.y[0]],
  ];

  const width = item.box.x[1] - item.box.x[0];
  const height = item.box.y[1] - item.box.y[0];

  points = points.map(renderPoint).join(' ');

  return `<path d="${points}" fill="${item.fill}" stroke="${item.color || 'none'}" stroke-dasharray="${height},${width}" />`;
};

const renderSections = sections => {
  const items = [];

  for (let i = 0, len = sections.length; i < len; i++) {
    items[items.length] = sections[i].boxes.map(item => renderPath(item)).join('');
  }

  return items.join('');
};

export default function svg_sections(sections) {
  let svg = '';

  svg += '<g>' + renderSections(sections) + '</g>';

  return svg;
};
