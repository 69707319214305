import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL, UN } from '/views';

import { deferred } from '/utils/common';

class UserRestore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkStatus: null,
      checkError: null,
      updateLock: false,
      updateStatus: null,
      updateError: null,
    };

    if (__CLIENT__) {
      this.checkToken();
    }
  }

  Form = UN.Form();

  checkToken() {
    const defer = deferred();

    defer.then(() => this.setState({ checkStatus: true, checkError: false }));
    defer.catch(() => this.setState({ checkStatus: false, checkError: true }));

    this.props.checkRestoreToken(defer);
  }

  onSubmit = (values) => {
    const defer = deferred();

    defer.then(() => this.setState({ updateLock: false, updateStatus: true, updateError: false }));
    defer.catch(() => this.setState({ updateLock: false, updateStatus: false, updateError: true }));

    this.props.restoreUserAccaunt(values.password, defer);
  };

  renderForm() {
    const { updateError, updateLock } = this.state;

    const { Form } = this;

    return (
      <Form className="d-flex flex-column justify-content-between col-12" onSubmit={this.onSubmit}>
        <div className="text-center">
          <div className="d-flex flex-column align-items-center form-group input-group-sm mb-3">
            <Form.Password className="w-35" label="Пароль" name="password" equalTo="password-repeat" required />
            <Form.Password className="w-35" name="password-repeat" equalTo="password" required />
          </div>
        </div>
        <div className="text-center">
          <div className={cn('h5 mb-2 select-none', !updateError && 'invisible')}>
            <small className="mb-0 color-danger-600">Не удалось обновить пароль.</small>
          </div>
          <div>
            <Form.Submit size="sm" icon2="fal fa-angle-double-right ml-1" spin2={updateLock} text="Изменить пароль" />
          </div>
        </div>
      </Form>
    );
  }

  renderResult() {
    const { checkError } = this.state;

    const icon = checkError
      ? <i className="fal fa-times-circle text-danger" />
      : <i className="fal fa-check-circle text-success" />;

      const text = checkError ? 'Данный токен не найден или устарел.' : 'Ваш пароль изменён.';

    return (
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="text-center fs-35">
          {icon}
        </div>
        <div className="text-center mt-2">
          {text}
        </div>
      </div>
    );
  }

  render() {
    const { checkStatus, checkError, updateStatus } = this.state;

    return (
      <div className="container">
        <h2 className="text-center" style={{ marginTop: 20 }}>Подтверждение адреса электронной почты</h2>

        <div style={{ marginTop: 40 }}>
          {(checkStatus && !updateStatus) && this.renderForm()}
          {(checkError || updateStatus) && this.renderResult()}
          {(!checkStatus && !checkError) && <div className="text-center"><UI.Loader /></div>}
        </div>
      </div>
    );
  }
}

export default UserRestore;
