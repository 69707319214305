import React from 'react';

import { BL } from '/views';

const NoAudits = props => {
  return (
    <BL.Alert>
    <BL.Alert.Icon icon="fal fa-info-circle" />
      <BL.Alert.Content>
        <div>Запустите хотя бы один аудит на вкладке "Без аудитов". Все проекты, по которым есть хотя бы один готовый аудит, вы в будущем найдете здесь - на вкладке "С аудитами"</div>
      </BL.Alert.Content>
    </BL.Alert>
  );
};

export default NoAudits;
