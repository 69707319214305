import { SOURCE } from '../../../constants';

const fields = {};

fields.url = item => item.url;

fields.source = item => {
  const { sources } = item;

  if (sources.includes(SOURCE.METRICA)) return 0;
  if (sources.includes(SOURCE.SITEMAP)) return 1;
  if (sources.includes(SOURCE.USER)) return 2;

  return 3;
};

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.url;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
