import React, { Component } from 'react';

import { Meta, Date, Messages } from '../HistoryGroups';

import { UI } from '/views';

import {
  TYPE_META,
  TYPE_MESSAGE,
  TYPE_DATE,
} from '../../../../constants';

import processHistory from '../../../../utils/processHistory';
import splitHistory from '../../../../utils/splitHistory';

import { ref } from '/utils/react';

class ChatHistory extends Component {
  state = {
    hidden: false,
  };

  autoscroll = true;

  ref = ref;

  static getDerivedStateFromProps(props, state) {
    const result = { ...state };

    if (result._id !== props.id) {
      result._id = props.id;

      result.hidden = true;
    }

    return result;
  }

  onScroll = event => {
    const { list } = this.ui;

    const height = list.clientHeight;
    const scroll = list.scrollHeight;
    const offset = list.scrollTop;

    this.autoscroll = list.scrollHeight - list.clientHeight === list.scrollTop;
  };

  scrollDown = () => {
    const { list } = this.ui;

    this.autoscroll && (list.scrollTop = list.scrollHeight);

    if (this.state.hidden) {
      this.setState({ hidden: false });
    }
  };

  renderGroup = (group, key) => {
    const { id, user, editing, sendMessage, onEdit } = this.props;

    const View = {
      [TYPE_META]: Meta,
      [TYPE_DATE]: Date,
      [TYPE_MESSAGE]: Messages,
    }[group.type];

    return <View key={key} conversation_id={id} user={user} editing={editing} { ...group } sendMessage={sendMessage} onEdit={onEdit} />;
  };

  setupScrollTimeout() {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(this.scrollDown, 0);
  }

  componentWillUnmount() {
    clearTimeout(this.scrollTimeout);
  }

  render() {
    const { items, user } = this.props;
    const { hidden } = this.state;

    let groups = items;

    groups = processHistory(groups);
    groups = splitHistory(groups);

    this.setupScrollTimeout();

    const style = {};

    if (hidden) {
      style.visibility = 'hidden';
    }

    return (
      <div className="flex-1 h-100 position-relative">
        <div className="conversation_messanger-history_list custom-scrollbar" ref={this.ref('list')} onScroll={this.onScroll} style={style}>
          <div className="w-100 px-4">
            {groups.map(this.renderGroup)}
          </div>
        </div>
      </div>
    );
  }
}

export default ChatHistory;
