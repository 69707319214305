import React from 'react';

import { BL } from '/views';

import config from './config';

const VerdictTable = props => {
  return <BL.Table className="darken" pagination={false} { ...props } { ...config } />;
};

export default VerdictTable;
