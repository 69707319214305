import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { approveUserMail } from '../../store/actions';

const route = createRoute({ path: '/user/login', layout: 'Default' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = ({ user }) => ({ user });

export default { route, view: connect(mapStateToProps)(view), settings };
