import React, { Component, Fragment } from 'react';

import Stats from './components/Stats';
import Traffic from './components/Traffic';

import { UI, BL } from '/views';

import Provider from '../../provider';

import services from '/static/data/services';

class ReportsAllTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getAllTrafficResult(audit_id, id);
  }

  exportExcelLink() {
    const { id, audit_id, user_id } = this.props;

    return this.provider.getAllTrafficExcelLink(audit_id, id, user_id);
  }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  render() {
    const { stats, items } = this.state.report || {};

    return (
      <Fragment>
        <BL.Title icon={services.all_traffic.icon} name="Изменение поискового трафика по страницам" />

        <Stats data={stats} />
        <Traffic data={items} exportExcelLink={this.exportExcelLink()} />
      </Fragment>
    );
  }
}

export default ReportsAllTraffic;
