import React, { Component, Fragment } from 'react';

import { VIEW } from '/views';

class Modals extends Component {
  constructor(props) {
    super(props);

    VIEW.on('modal:show', this.showModal);
    VIEW.on('modal:hide', this.hideModal);
  }

  modals = null;

  showModal = modal => {
    this.modal = modal;

    this.forceUpdate();
  };

  hideModal = () => {
    this.modal = null;

    this.forceUpdate();
  };

  componentWillUnmount() {
    VIEW.off('modal:show', this.showModal);
    VIEW.off('modal:hide', this.hideModal);
  }

  render() {
    return (
      <Fragment>
        {this.modal}
      </Fragment>
    );
  }
};

export default Modals;
