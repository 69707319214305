const self = {};

self.search = 'Search';

self.show_by = 'Show by';
self.show_by_all = 'All';

self.empty = 'No records';

self.paging_total = 'Number of records: <%= total %>';
self.paging_showed = 'Showing <%= from %>-<%= to %> of <%= filtered %>';
self.paging_empty = 'No available records';

self.paging_picked_from = '(Picked out from <%= total %>)';

export default self;
