import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import ResultTable from '../ResultTable';

import { SOURCE } from '../../constants';

class Result extends Component {
  state = {
    filter: SOURCE.ALL,
  };

  getStats = type => {
    const { items } = this.props;

    const [good, bad] = items
      .filter(item => item.sources.includes(SOURCE.SITEMAP))
      .reduce((result, item) => {
        const empty = item.stats.year.values[type] === 0;

        result[+empty] += 1;

        return result;
      }, [0, 0]);

    return { good, bad };
  };

  renderExportToolbar = () => {
    const { id, audit_id, user_id, exportExcelLink } = this.props;

    return (
      <div className="mr-2">
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent href={exportExcelLink} className="btn-custom-excel" download />
      </div>
    );
  };

  renderToolbar = () => {
    const { filter } = this.state;

    const values = [
      { name: 'Все', value: SOURCE.ALL },
      { name: 'XML-карта сайта', value: SOURCE.SITEMAP },
      { name: 'Добавлена пользователем', value: SOURCE.USER },
    ];

    const onChange = (name, filter) => this.setState({ filter });

    return (
      <Fragment>
        <span className="mr-2">Источник страниц</span>
        <UI.Form.Select value={filter} values={values} onChange={onChange} />
      </Fragment>
    );
  };

  renderNoContent() {
    return (
      <BL.Alert.Warning className="mb-0">
        <BL.Alert.Icon icon="fal fa-exclamation-circle" />
        <BL.Alert.Content>К сожалению, при запуске аудита не была указана XML-карта сайта, поэтому у сервиса нет источника данных со списком страниц сайта.</BL.Alert.Content>
      </BL.Alert.Warning>
    );
  }

  renderPieChart = type => {
    const { good, bad } = this.getStats(type);

    const items = [
      { value: good, name: 'С трафиком', color: '#61dd78' },
      { value: bad, name: 'Без трафика', color: '#c93b3b' },
    ];

    const settings = {
      label: (item, total) => `${item.name} - ${item.value} <tspan dy="-6" font-size="10">${(item.value / total * 100).toFixed(1)}%</tspan>`,
    };

    const params = { items, settings, height: 275 };

    return (
      <Fragment>
        <h3 className="mb-3">Распределение страниц с трафиком и без</h3>
        <UI.PieChart { ...params } />
      </Fragment>
    );
  };

  renderAlert = type => {
    const { good, bad } = this.getStats(type);

    const total = good + bad;

    const Alert = bad ? BL.Alert.Warning : BL.Alert.Success;

    const icon = bad ? 'fal fa-exclamation-circle' : 'fal fa-check-circle';

    const text = bad
      ? (
          <Fragment>
            <p>{bad} страниц из {total} найденных ({(100 * bad / total).toFixed(1)}%) не приносят поисковый трафик.</p>
            <p className="mb-0">Проверьте страницы без поискового трафика: возможно, они не оптимизированы, либо их вовсе следует удалить.</p>
          </Fragment>
        )
      : `Все в порядке. У всех найденных на сайте страниц (${total} страниц) есть поисковый трафик.`;

    return (
      <Alert className="my-3">
        <BL.Alert.Icon icon={icon} />
        <BL.Alert.Content>{text}</BL.Alert.Content>
      </Alert>
    );
  };

  renderTable = type => {
    const { items } = this.props;
    const { filter } = this.state;

    const sources = filter === SOURCE.ALL ? [SOURCE.SITEMAP, SOURCE.USER] : [filter];

    let filtered = items.filter(item => item.stats.year.values[type] === 0);

    filtered = filtered.filter(item => item.sources.some(src => sources.includes(src)));

    return <ResultTable data={filtered} toolbar={this.renderToolbar} />;
  };

  renderTab = idx => {
    const type = ['overall', 'yandex', 'google'][idx];

    return (
      <div className="p-3">
        {this.renderPieChart(type)}
        {this.renderAlert(type)}
        {this.renderTable(type)}
      </div>
    );
  };

  renderTabs() {
    const sets = {
      items: ['Весь поисковый трафик', 'Яндекс', 'Google'],
      child: this.renderTab,
      toolbar: this.renderExportToolbar,
    };

    return (
      <BL.Panel>
        <BL.Panel.Content className="p-0">
          <BL.Tabs { ...sets } />
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { error } = this.props;

    return error ? this.renderNoContent() : this.renderTabs();
  }
}

export default Result;
