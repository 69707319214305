import React from 'react';
import cn from 'classnames';

import UI from '/views/ui';

const Alert = function (props) {
  const {
    className,
    stl = 'primary',
    children,
  } = props;

  return (
    <div className={cn('alert', 'alert-' + stl, className)}>
  		<div className="d-flex flex-start w-100">
        {children}
  		</div>
  	</div>
  );
};

Alert.Icon = props => {
  const { icon, ...sets } = props;

  sets.className = cn(sets.className, 'd-block', icon);

  return (
    <div className="mr-3 fs-14 d-flex align-items-center">
      <i { ...sets } />
    </div>
  );
};

Alert.Medal = props => {
  return (
    <div className="mr-2 hidden-md-down">
      <UI.Medal { ...props } />
    </div>
  );
};

Alert.Content = props => {
  const { children, ...sets } = props;

  return (
    <div className="d-flex flex-fill" { ...sets }>
      <div className="d-flex flex-column flex-fill justify-content-center">
        <div>
          {children}
        </div>
      </div>
    </div>
  );
};

Alert.Primary = props => <Alert stl="primary" { ...props } />;
Alert.Secondary = props => <Alert stl="secondary" { ...props } />;
Alert.Success = props => <Alert stl="success" { ...props } />;
Alert.Info = props => <Alert stl="info" { ...props } />;
Alert.Warning = props => <Alert stl="warning" { ...props } />;
Alert.Danger = props => <Alert stl="danger" { ...props } />;
Alert.Dark = props => <Alert stl="dark" { ...props } />;
Alert.Light = props => <Alert stl="light" { ...props } />;

export default Alert;
