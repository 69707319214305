import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Статус',
    field: 'verdict',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '50px', maxWidth: '50px', minWidth: '50px', textAlign: 'center' },
  },
  {
    name: 'Кол-во страниц',
    field: 'count_value',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '50px', maxWidth: '50px', minWidth: '50px', textAlign: 'center' },
  },
  {
    name: '% от общего числа страниц',
    field: 'count_percent',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '50px', maxWidth: '50px', minWidth: '50px', textAlign: 'center' },
  },
  {
    name: 'Общий трафик',
    style: { width: '160px', maxWidth: '160px', minWidth: '160px', textAlign: 'center' },
    childs: [
      {
        name: '1 Год',
        field: 'year_0',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '80px', textAlign: 'center' },
      },
      {
        name: '2 Год',
        field: 'year_1',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '80px', textAlign: 'center' },
      },
    ],
  },
  {
    name: 'Разница год к году',
    style: { width: '34%', maxWidth: '400px', minWidth: '300px', textAlign: 'center' },
    childs: [
      {
        name: 'Год',
        field: 'diff_year',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '25%', textAlign: 'center' },
      },
      {
        name: 'Год в расчёте на страницу',
        field: 'diff_year_per_page',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '25%', textAlign: 'center' },
      },
      {
        name: '3 месяца',
        field: 'diff_3_month',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '25%', textAlign: 'center' },
      },
      {
        name: '1 месяц',
        field: 'diff_1_month',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '25%', textAlign: 'center' },
      },
    ],
  },
];
