import Title from './Title';
import Alert from './Alert';
import CopyButton from './CopyButton';
import Modal from './Modal';
import Panel from './Panel';
import TableSync from './TableSync';
import Tabs from './Tabs';
import IconList from './IconList';
import Accordion from './Accordion';
import StatusBadge from './StatusBadge';
import Info from './Info';

export default {
  Title,
  Alert,
  CopyButton,
  Modal,
  Panel,
  Table: TableSync, // legacy
  TableSync,
  Tabs,
  IconList,
  Accordion,
  StatusBadge,
  Info,
};
