import React from 'react';
import cn from 'classnames';

import Field from '../Field';

const EMPTY = 'FORM_FIELD_SELECT_EMPTY';

class Select extends Field {
  FIELD_TYPE = 'select';

  onChange = event => {
    let { name, values, placeholder, onChange } = this.props;

    values = [].concat(...values.map(v => v.items || v));

    const idx = event.target.selectedIndex - +!!placeholder;
    const value = idx >= 0 ? values[idx].value : null;

    this.value = value;

    onChange && onChange(name, this.value);

    this.runValidator();
  };

  renderItem = item => {
    const label = item.name || item.label;

    if (item.type === 'group') {
      return (
        <optgroup key={label + '-group'} label={label}>
          {(item.items || []).map(this.renderItem)}
        </optgroup>
      );
    }

    return <option key={item.value + '-item'} value={item.value}>{label}</option>;
  };

  render () {
    const { errors } = this.state;
    const { label, name, value = null, values, placeholder, className, maxWidth } = this.props;

    const fieldSets = {
      name: name,
      defaultValue: value || EMPTY,
      className: 'form-control custom-select',
      onChange: this.onChange,
    };

    if (errors.length) {
      fieldSets.className += ' is-invalid';
    }

    if (maxWidth) {
      fieldSets.style = { maxWidth };
    }

    return (
      <label className={cn("d-block", className)}>
        {label && <span className="form-label select-none">{label}</span>}
        <div className="position-relative mt-2">
          <select {...fieldSets}>
            {placeholder && <option value={EMPTY} disabled>{placeholder}</option>}
            {values.map(this.renderItem)}
          </select>
        </div>
      </label>
    );
  }
}

export default Select;
