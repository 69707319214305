import React from 'react';

import './styles.scss';

const html = `
  <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7_dtp.css" rel="stylesheet" type="text/css" />

  <style type="text/css">
      #mc_embed_signup {background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:600px;}
  </style>

  <div id="mc_embed_signup">
    <form action="https://tools.us18.list-manage.com/subscribe/post?u=0c0437a30a8b22f6e0cef3b27&amp;id=4b8d5dbd1d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
      <div id="mc_embed_signup_scroll">
      <h2>Subscribe today for early access to Beta in early 2023</h2>
      <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
      <div class="mc-field-group">
          <label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
      </label>
          <input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
      </div>
      <div hidden="true"><input type="hidden" name="tags" value="2177883"></div>
      <div id="mce-responses" class="clear foot">
        <div class="response" id="mce-error-response" style="display:none"></div>
        <div class="response" id="mce-success-response" style="display:none"></div>
      </div>
      <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_0c0437a30a8b22f6e0cef3b27_4b8d5dbd1d" tabindex="-1" value=""></div>
        <div class="optionalParent">
          <div class="clear foot">
            <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
            <p class="brandingLogo"><a href="http://eepurl.com/h17raP" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
          </div>
        </div>
      </div>
    </form>
  </div>
`;

const Subscribe = props => {
  const { lang } = props;

  if (lang.getLanguage() !== 'en') return null;

  return (
    <section className="home_root-subscribe">
      <div className="home_root-subscribe_content">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </section>
  );
};

export default Subscribe;
