import React, { Fragment } from 'react';
import { Lang } from '/contexts/Lang';

import { UI } from '/views';

import { SITE_TYPE, DATA_ERRORS } from '../../../constants';

const fields = {};

fields.type = item => {
  const text = {
    [SITE_TYPE.INDEX_SITEMAP]: () => <Lang text="sitemap_type_index" />,
    [SITE_TYPE.SITEMAP]: () => <Lang text="sitemap_type_normal" />,
    [SITE_TYPE.REDIRECT]: () => <Lang text="sitemap_type_redirect" />,
    [SITE_TYPE.UNKNOWN]: () => <Lang text="sitemap_type_unknown" />,
  }[item.type];

  return text ? text() : '-';
};

fields.status = item => {
  let status = 0;

  if (item.errors.length) {
    status = 1;
  }

  const isCritical = [
    DATA_ERRORS.REDIRECT,
    DATA_ERRORS.ERROR_FETCH,
    DATA_ERRORS.INCORRECT_XML,
    DATA_ERRORS.INVALID_MAP_FORMAT,
  ].some(error => item.errors.includes(error));

  if (isCritical || !item.indexing.allowed) {
    status = 2;
  }

  const Icon = [UI.Icon.Good, UI.Icon.Warning, UI.Icon.Danger][status];

  const text = [
    () => <Lang text="status_ok" />,
    () => <Lang text="status_warning" />,
    () => <Lang text="status_warning" />,
  ][status]();

  const color = ['color-success-500', 'color-warning-500', 'color-danger-500'][status];

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Icon className="fs-11" /> <span className={'ml-2 ' + color}>{text}</span>
    </div>
  );
};

fields.url = item => {
  return <a href={item.url} target="_blank">{item.url}</a>
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
