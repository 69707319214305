import React, { Component, Fragment } from 'react';
import moment from 'moment';

import VerdictTable from '../VerdictTable';

import { UI, BL } from '/views';

import color from '/utils/color';

import getPlainChartLabels from '../../../../utils/getPlainChartLabels';

const sort = { field: 'verdict', dir: 'asc' };

const DATE_FORMAT = 'MMMM YYYY';

const getDates = function getDates(data) {
  const item = data[0];

  const dates = item.analytics.total.dates;

  return [
    {
      from: moment(dates[0].from).format(DATE_FORMAT),
      to: moment(dates[0].to).format(DATE_FORMAT),
    },
    {
      from: moment(dates[1].from).format(DATE_FORMAT),
      to: moment(dates[1].to).format(DATE_FORMAT),
    },
  ];
};

const pieChartItems = [
  { verdict: 'TRAFFIC_GROW', name: 'Вырос', color: '#61dd78' },        // #99f1a9
  { verdict: 'TRAFFIC_SUBSIDE', name: 'Падает', color: '#f4dc7c' },    // #ffeb9c
  { verdict: 'TRAFFIC_DROP', name: 'Упал', color: '#f27685' },         // #fd8c9a
  { verdict: 'TRAFFIC_ZERO', name: 'Упал в 0', color: '#c93b3b' },     // #ff3b3b
  { verdict: 'TRAFFIC_STABLE', name: 'На месте', color: '#dddddd' },   // #e9e9e9
  { verdict: 'TRAFFIC_NEW', name: 'Новая', color: '#70bfe9' },         // #a5cde2
  { verdict: 'TRAFFIC_UNMATCH', name: 'Отсутствует', color: '#eeeeee' },  // #fff
];

const getLabels = year => getPlainChartLabels(year.map(month => month.dates[0].from));

const collectUpDownChartData_1 = (items, select) => {
  const TRAFFIC_GROW = items.find(item => item.verdict === 'TRAFFIC_GROW');
  const TRAFFIC_DROP = items.find(item => item.verdict === 'TRAFFIC_DROP');
  const TRAFFIC_ZERO = items.find(item => item.verdict === 'TRAFFIC_ZERO');

  const labels = getLabels(TRAFFIC_GROW.analytics.year);

  const up = TRAFFIC_GROW.analytics.year.map(item => item.values[select].variance);
  const down = [];

  for (let i = 0, len = TRAFFIC_DROP.analytics.year.length; i < len; i++) {
    down[i] = TRAFFIC_DROP.analytics.year[i].values[select].variance + TRAFFIC_ZERO.analytics.year[i].values[select].variance;
  }

  const diff = [];

  for (let i = 0, len = up.length; i < len; i++) {
    diff[i] = up[i] + down[i];
  }

  const _up = {
    name: 'up',
    label: 'Вырос',
    pointColor: '#2ebd29',
    pointColorHover: '#17f146',
    color: '#2ebd29',
    fill: 'rgba(74, 195, 69, 0.2)',
    values: up,
  };

  const _down = {
    name: 'down',
    label: 'Упал + Упал в 0',
    pointColor: '#b90a1f',
    pointColorHover: '#d72266',
    color: '#e66272',
    fill: 'rgba(209, 39, 59, 0.1)',
    values: down,
  };

  const _diff = {
    name: 'diff',
    label: 'Разница',
    color: '#f9ce23',
    fill: '#ffdc4f',
    values: diff,
  };

  return { labels, lines: [_up, _down], bars: [_diff] };
};

const collectUpDownChartData_2 = (items, select) => {
  const TRAFFIC_GROW = items.find(item => item.verdict === 'TRAFFIC_GROW');
  const TRAFFIC_NEW = items.find(item => item.verdict === 'TRAFFIC_NEW');
  const TRAFFIC_DROP = items.find(item => item.verdict === 'TRAFFIC_DROP');
  const TRAFFIC_ZERO = items.find(item => item.verdict === 'TRAFFIC_ZERO');

  const labels = getLabels(TRAFFIC_GROW.analytics.year);

  const up = [];
  const down = [];

  for (let i = 0, len = TRAFFIC_GROW.analytics.year.length; i < len; i++) {
    up[i] = TRAFFIC_GROW.analytics.year[i].values[select].variance + TRAFFIC_NEW.analytics.year[i].values[select].variance;
    down[i] = TRAFFIC_DROP.analytics.year[i].values[select].variance + TRAFFIC_ZERO.analytics.year[i].values[select].variance;
  }

  const diff = [];

  for (let i = 0, len = up.length; i < len; i++) {
    diff[i] = up[i] + down[i];
  }

  const _up = {
    name: 'up',
    label: 'Вырос + Новая',
    pointColor: '#2ebd29',
    pointColorHover: '#17f146',
    color: '#2ebd29',
    fill: 'rgba(74, 195, 69, 0.2)',
    values: up,
  };

  const _down = {
    name: 'down',
    label: 'Упал + Упал в 0',
    pointColor: '#b90a1f',
    pointColorHover: '#d72266',
    color: '#e66272',
    fill: 'rgba(209, 39, 59, 0.1)',
    values: down,
  };

  const _diff = {
    name: 'diff',
    label: 'Разница',
    color: '#f9ce23',
    fill: '#ffdc4f',
    values: diff,
  };

  return { labels, lines: [_up, _down], bars: [_diff] };
};

class Stats extends Component {
  select = null;

  renderDescription = () => {
    const dates = getDates(this.props.data);

    return (
      <Fragment>
        <p>В анализе страниц вы получаете автоматический анализ изменения поискового трафика на страницы сайта как в целом, так и отдельно по поисковым системам Яндекс и Google. В отчет не попадают страницы, которые получили меньше 72 визитов из поиска за последние 24 месяца. Статус страницы определяется автоматически при сравнении данных за последние 24 месяца помесячно год к году, чтобы исключить сезонность.</p>
        <p className="mb-3">В этом аудите Год 1: <b>{dates[0].from}</b> - <b>{dates[0].to}</b>, Год 2: <b>{dates[1].from}</b> - <b>{dates[1].to}</b></p>
      </Fragment>
    );
  };

  renderVerdicts = () => <VerdictTable data={this.props.data} sort={sort} settings={{ select: this.select }} />;

  renderPieChart = () => {
    const items = pieChartItems.map(item => {
      const { verdict, name, color } = item;

      const src = this.props.data.find(i => i.verdict === verdict);

      if (!src) return;

      const { value } = src.count[this.select];

      return { value, name, color };
    });

    const settings = {
      label: (item, total) => `${item.name} - ${item.value} <tspan dy="-6" font-size="10">${(item.value / total * 100).toFixed(1)}%</tspan>`,
    };

    const params = { items, settings, height: 350 };

    return <UI.PieChart { ...params } />;
  };

  renderUpDownChart_1 = () => {
    const { data } = this.props;

    const chartData = collectUpDownChartData_1(data, this.select);

    return <UI.LineChart className="mt-3" data={chartData} offset={{ left: 60 }} settings={{}} />;
  };

  renderUpDownChart_2 = () => {
    const { data } = this.props;

    const chartData = collectUpDownChartData_2(data, this.select);

    return <UI.LineChart className="mt-3" data={chartData} offset={{ left: 60 }} settings={{}} />;
  };

  renderTab = idx => {
    const { data } = this.props;

    if (!data) return <div className="p-3 text-center"><UI.Loader /></div>;

    this.select = ['overall', 'yandex', 'google'][idx];

    return (
      <div className="p-3">
        <h3 className="mb-3">Сводка по изменению поискового трафика по страницам</h3>

        {this.renderDescription()}
        {this.renderVerdicts()}

        <h3 className="mt-3 mb-2 pt-2">Количество страниц по статусам</h3>

        {this.renderPieChart()}

        <h3 className="mt-3 pt-2">Сравнение изменения поискового трафика по группам страниц с разным статусом</h3>

        {this.renderUpDownChart_1()}
        {this.renderUpDownChart_2()}
      </div>
    );
  }

  render() {
    const sets = {
      items: ['Весь поисковый трафик', 'Яндекс', 'Google'],
      child: this.renderTab,
    };

    return (
      <BL.Panel>
        <BL.Panel.Content className="p-0">
          <UI.Collapse title="Сводка" expand={true}>
            <BL.Tabs { ...sets } />
          </UI.Collapse>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }
}

export default Stats;
