const self  = {};

self.head_title = 'Массовая проверка ответа сервера онлайн';
self.head_keywords = '';
self.head_description = 'Бесплатный онлайн-сервис для массовой проверки ответа сервера и HTTP-заголовков, цепочек редиректов для 100 URL за раз';

self.name = 'Проверки ответа сервера';

self.title = 'Проверки ответа сервера';
self.description = 'С помощью этого инструмента можно проверить коды ответа сервера по списку ссылок.';

self.field_links = 'Список ссылок';
self.field_userAgent_currentBrowser = 'Текущий браузер';
self.field_userAgent_empty = 'Не использовать';
self.field_userAgent_searchers = 'Поисковики';
self.field_userAgent_desktopBrowsers = 'Desktop Browsers';
self.field_userAgent_mobileBrowsers = 'Mobile Browsers';

self.count_links = 'Количество ссылок';

self.button_check = 'Проверить';

self.title_result = 'Заголовок блока Результат';
self.filter_code = 'Фильтр по коду';

self.count_redirects = 'Редиректов';

self.url = 'Адрес';
self.link = 'Ссылка';
self.statusCode = 'Код ответа';
self.redirectsChain = 'Цепочка редиректов';
self.result = 'Результат';
self.export = 'Экспорт';

self.backTo = 'Возврат на';
self.redirectTo = 'Направляет на';

export default self;
