import React, { Component } from 'react';

import { UI } from '/views';

const renderBadge = (item, style) => {
  const { percent, ready } = item;

  if (ready) return <UI.Badge.Success>Готов</UI.Badge.Success>;

  const sets = { style };

  return <UI.Badge.Warning { ...sets }>{percent}%</UI.Badge.Warning>;
};

export default renderBadge;
