import { fork, all } from 'redux-saga/effects';

const LIST = [];

export const addSaga = function addSaga(value) {
  LIST.push(value);
};

export default function createSaga() {
  return function* rootSaga() {
    // yield all(LIST);
    for (let i = 0, len = LIST.length; i < len; i++) {
      yield fork(LIST[i]);
    }
  }
}
