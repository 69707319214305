import React, { Component } from 'react';
import State from './components/State';
import cn from 'classnames';

import getCheckers from './getCheckers';

import { isAsyncFunction } from '/utils/types';

import { AGREED_TIMEOUT } from '../../settings';

class Field extends Component {
  static DEFAULT_VALUE = '';

  constructor(props) {
    super(props);

    this._timer = null;

    this.value = null;
    this.activated = false;

    this.ASYNC_CHECKER = !!props.checker && (!!props.asyncChecker || isAsyncFunction(props.checker));
    this.AGREED_TIMEOUT = this.ASYNC_CHECKER ? props.agreedTimeout || AGREED_TIMEOUT : 0;

    this.state = {
      waiting: false,
      errors: [],
    };

    const { name, onCreate } = props;

    onCreate && onCreate(name, this);

    this.setDefaultValue();
  }

  setDefaultValue() {
    const { name, value, onChange, onValidate } = this.props;

    this.value = value || this.constructor.DEFAULT_VALUE;

    onChange && onChange(name, this.value);
    onValidate && onValidate(name, []);
  }

  getCheckers = getCheckers;

  async collectErros(value) {
    const checkers = this.getCheckers();

    let errors = [];

    for (let i = 0, len = checkers.length; i < len; i++) {
      const { type, checker } = checkers[i];

      const error = checker(value, this);

      error && errors.push(error);
    }

    errors = await Promise.all(errors);

    return errors.filter(v => !!v);
  }

  async validate() {
    clearTimeout(this._timer);

    this.ASYNC_CHECKER && this.setState({ waiting: true });

    const errors = await this.collectErros(this.value);

    this.setState({ errors, waiting: false });

    const { name, onValidate } = this.props;

    onValidate && onValidate(name, errors);

    return !errors.length;
  }

  runValidator() {
    this._timer && clearTimeout(this._timer);

    if (this.AGREED_TIMEOUT) {
      this._timer = setTimeout(() => this.validate(), this.AGREED_TIMEOUT);
    } else {
      this.validate();
    }
  }

  onChange = event => {
    const { name, value } = event.target;
    const { onChange } = this.props;

    this.value = value.trim();

    onChange && onChange(name, this.value);

    this.runValidator();
  };

  componentWillUnmount() {
    this._timer && clearTimeout(this._timer);
  }

  renderState = props => {
    const show = this.props.state || 'partial';

    if (!this.activated || (this.value === '' && !this.errors.length)) return null;

    let state = State.CORRECT;

    if (this.waiting) state = State.WAITING;
    if (this.errors.length) state = State.ERROR;

    if (state === State.CORRECT && show !== 'fill') return null;

    return <State state={state} />;
  };
}

Field.Before = props => {
  const { className, children, ...sets } = props;

  return (
    <div className={cn('input-group-prepend', className)} { ...sets }>
      <span className="input-group-text">{children}</span>
    </div>
  );
};

Field.After = props => {
  const { className, children, ...sets } = props;

  return (
    <div className={cn('input-group-append', className)} { ...sets }>
      <span className="input-group-text">{children}</span>
    </div>
  );
};

export default Field;
