import React, { isValidElement, cloneElement } from 'react';

import { isArray, isFunction } from '/utils/types';

export const text = txt => ({ dangerouslySetInnerHTML: { __html: txt } });

export const lang = context => name => text(context[name]);

export const wrap = txt => typeof txt !== 'object' ? text(txt) : txt;

export const ref = function (name, isList = false) {
  if (!this.ui) this.ui = {};

  return element => {
    if (isList) {
      this.ui[name] = this.ui[name] || [];

      this.ui[name].push(element);
    }
    else {
      this.ui[name] = element;
    }
  };
};

export const extendElements = (children, extender) => {
  children = !children.length ? [children] : [...children];

  for (let i = 0, len = children.length; i < len; i++) {
    const item = children[i];

    if (!item || !isValidElement(item)) continue;

    const ext = extender.find(e => e.type === item.type);

    if (!ext || !ext.worker) continue;

    const props = ext.worker(item, i);

    if (props === null) {
      children[i] = null;
    } else {
      props.key = i;

      children[i] = cloneElement(item, props);
    }
  }

  return children;
};

export const execChildren = children => {
  const list = [];

  if (children.length === 1) {
    children = [children];
  }

  for (let i = 0, len = children.length; i < len; i++) {
    if (isFunction(children[i])) {
      let items = children[i](i);

      isArray(items) ? list.push(...items) : list.push(items);
    } else {
      list.push(children[i]);
    }
  }

  return list;
};
