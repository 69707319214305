import React, { Component, Fragment } from 'react';

import DarkPrompt from '../DarkPrompt';

import cn from 'classnames';

import CONST from '../../constants';

import { isFunction } from '/utils/types';

export class Item extends Component {
  state = {
    expanded: false,
  };

  hasExpand() {
    return false;
  }

  onToggle = () => {
    this.setState(state => ({ ...state, expanded: !state.expanded }));
  };

  renderToggler() {
    const hasExpand = this.hasExpand();
    const { expanded } = this.state;

    return (
      <div className="tools_sitemapper-stats-icon-toggle">
        {hasExpand && <i className={cn('fal', expanded ? 'fa-chevron-up' : 'fa-chevron-down')} />}
      </div>
    );
  }

  getHeadData() {
    const { name, infoText, infoTextActive, value } = this;

    return { name, infoText, infoTextActive, value };
  }

  _renderHead() {
    const hasExpand = this.hasExpand();
    const actions = {};

    if (hasExpand) {
      actions.onClick = this.onToggle;
    }

    const { name, infoText, infoTextActive, value } = this.getHeadData();

    return (
      <div className={cn('tools_sitemapper-stats-item-head', hasExpand && 'tools_sitemapper-stats-toggle-control')} { ...actions }>
        {this.renderToggler()}
        <div className="tools_sitemapper-stats-name">
          {name}
          {infoText && <DarkPrompt active={infoTextActive}>{isFunction(infoText) ? infoText() : infoText}</DarkPrompt>}
        </div>
        <div className="tools_sitemapper-stats-value">{value}</div>
      </div>
    );
  }

  renderBody() {
    return null;
  }

  _renderBody() {
    if (!this.state.expanded) return null;

    return (
      <div className="tools_sitemapper-stats-item-body">
        {this.renderBody()}
      </div>
    );
  }

  render() {

    return (
      <li className="tools_sitemapper-stats-item">
        {this._renderHead()}
        {this._renderBody()}
      </li>
    );
  }
}

export class Block extends Component {
  title = '';
  infoText = '';
  infoTextActive = false;
  emptyText = '';

  hasItems() {
    return true;
  }

  renderTitle() {
    if (this.infoText) {
      return (
        <Fragment>
          {this.title}
          <DarkPrompt active={this.infoTextActive}>{this.infoText()}</DarkPrompt>
        </Fragment>
      )
    }

    return this.title;
  }

  renderItems() {
    throw new Error('Method "renderItems" should be overriden');
  }

  renderContent() {
    if (this.hasItems()) {
      return <ul className="tools_sitemapper-stats-list-colored">{this.renderItems()}</ul>;
    }

    return <div className="tools_sitemapper-stats-empty">{this.emptyText}</div>;
  }

  renderPostContent() {
    return null;
  }

  render() {
    const title = this.renderTitle();

    return (
      <div className="select-text">
        {!!title && <h5 className="tools_sitemapper-stats-title">{title}</h5>}
        {this.renderContent()}
        {this.renderPostContent()}
      </div>
    );
  }
}
