import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { createAudit, deleteAudit, setCurrentAudit } from '/components/Audit/store/actions';
import { fetchProjects, reloadProjects, updateProjectStatus, fetchProjectSitemaps, blockProject } from '../../store/actions';

const route = createRoute({ path: '/projects', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = ({ audit, projects, settings }) => ({ audit, projects, lang: settings.language });

const mapDispatchToProps = { createAudit, deleteAudit, setCurrentAudit, fetchProjects, reloadProjects, updateProjectStatus, fetchProjectSitemaps, blockProject };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
