import { deduplicate } from '/utils/array';

export default function getResult(insert, result) {
  const links = deduplicate(insert.links);

  if (result.userAgent && insert.userAgent !== result.userAgent) return links.map(url => ({ url }));

  const finished = result.links.map(item => item.url);
  const values = result.links.filter(item => links.includes(item.url));

  links.forEach(url => !finished.includes(url) && values.push({ url }));

  return values;
};
