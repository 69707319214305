import React, { Fragment } from 'react';

import { BL } from '/views';

import { AGENTS } from '../../../constants';

const Status = props => {
  const { state } = props;

  const className = 'fal ' + (state ? 'fa-check-circle color-success-500 fs-11' : 'fa-ban color-danger-500 fs-10 font-weight-bold');

  return <i className={className} />;
};

const fields = {};

fields.all = item => <Status state={item.result[AGENTS.ALL].allowed} />;
fields.yandex = item => <Status state={item.result[AGENTS.YANDEX].allowed} />;
fields.google = item => <Status state={item.result[AGENTS.GOOGLE].allowed} />;
fields.url = item => item.url;

export default (name, item, actions, settings) => fields[name](item, actions, settings);
