import './config';

import React from 'react';
import ReactDOM from 'react-dom';

import global from '/utils/global';

import App from '/App';
import share from '/share';

const app = share.app = new App(global);

app.render();

if (__DEV__) {
  global.app = app;
  global.share = share;
}

export default () => app;
