const self  = {};

self.head_title = 'Бесплатная онлайн-проверка XML-карты сайта';
self.head_keywords = '';
self.head_description = 'Сервис проверки XML-карты онлайн позволяет протестировать нет ли ошибок в коде карты сайта, скачать список URL из карты, проверить структуру сайта';

self.name = 'Проверки карты сайта';

self.title = 'Проверка карты сайта';
self.description = '<p>С помощью этого инструмента можно проверить XML-карты сайта, получить список страниц в карте сайта и проанализировать структуру сайта, а также увидеть количество страниц в разбивке по году, если в карте используется тег &lt;lastmod&gt;.</p><p>Пожалуйста, вводите для анализа только карты сайта, которые относятся к <b>одному и тому же сайту</b>. Индексные карты сайта поддерживаются.</p>';
self.descriptionFull = '<p>С помощью этого инструмента можно проверить XML-карты сайта, получить список страниц в карте сайта и проанализировать структуру сайта, а также увидеть количество страниц в разбивке по году, если в карте используется тег &lt;lastmod&gt;.</p><p>Пожалуйста, вводите для анализа только карты сайта, которые относятся к <b>одному и тому же сайту</b>. Индексные карты сайта поддерживаются.</p><p>Также сервис проверяет ряд возможных ошибок карт сайта (но не заменяет валидаторы XML-карт):</p><ul class="mb-0"><li>Доступность и ответ сервера.</li><li>Содержит ли карта сайта ссылки на страницы (не пустая ли она).</li><li>Отсутствие обязательных XML-тегов <a href="https://www.sitemaps.org/ru/protocol.html" target="_blank">согласно  стандарту</a>.</li><li>Наличие в карте сайта страниц, относящихся к другому сайту.</li><li>Наличие в карте/картах сайта дубликатов страниц.</li></ul> ';

self.field_title = 'Список XML-карт сайта';
self.count_links = 'Количество ссылок на XML-карты сайта: <b><%= count %></b>';
self.error_incorrectUrl = 'В списке ссылок есть неправильный адрес. Адрес так же обязательно должен включать протокол.';
self.error_missmatchDomain = 'Все ссылки должны иметь одинаковый протокол и домен.';
self.button_check = 'Проверить';

self.title_result = 'Результат';

self.tab_stats = 'Статистика';
self.tab_stats_info = 'Данные по количеству карт, их валидности (корректности), количеству страниц в картах сайта.';
self.tab_validation = 'Валидация';
self.tab_pages = 'Страницы';
self.tab_pages_info = 'Список страниц, которые находятся в каждой карте сайта.';
self.tab_structure = 'Структура';
self.tab_structure_info = 'Древовидная структура сайта, построенная по структуре адресов страниц сайта. В [квадратных скобках] указано количество страниц в этой категории.';

self.export = 'Экспорт';

self.root_domain = 'Исходный домен';
self.root_domain_info = 'Адрес сайта, который мы используем для поиска страниц, которые не относятся к этому сайту. Сервис выделяет исходный домен из списка введенных карт сайта.';

self.main_mirror = 'Главное зеркало';
self.main_mirror_info = 'Адрес сайта, который мы используем для поиска страниц, все ли страницы в карте сайта относятся к вашему сайту. Сервис автоматически определяет главное зеркало, см. отчет Главное зеркало.';

self.site_type_title = 'Количество карт';
self.site_type_title_info = 'Ниже список всех найденных карт сайта.';
self.site_type_item_index = 'Индексные карты';
self.site_type_item_index_info = 'Файлы индекса Sitemap - в них содержатся ссылки на другие XML-карты сайта, а не на страницы сайта (<a href="https://www.sitemaps.org/ru/protocol.html#index" target="_blank">описание формата</a>). Из таких файлов сервис извлекает ссылки на XML-карты сайта и использует уже их для дальнейшего анализа.';
self.site_type_item_normal = 'Обычные карты';
self.site_type_item_normal_info = 'Обычные XML-карты.';
self.site_type_item_unknown = 'Неопознанные карты';
self.site_type_item_unknown_info = 'XML-карты, которые не соответствуют <a href="https://www.sitemaps.org/ru/protocol.html" target="_blank">стандарту</a>, поэтому не будут проанализированы.';

self.validity_title = 'Валидность карт';
self.validity_title_info = 'Мы автоматически проверяем отсутствие ошибок у карты сайта, например, отсутствие обязательных XML-тегов согласно стандарту. и используем в анализе только корректные (валидные) XML-карты.';
self.validity_item_correct = 'Корректные';
self.validity_item_correct_info = 'Валидные карты, которые содержат обязательные теги и прошли проверку сервиса.';
self.validity_item_incorrect = 'Некорректные';
self.validity_item_incorrect_info = 'XML-карты с ошибками: карта недоступна, пустая, не содержит обязательные теги и пр.';

self.validity_error_unknown_map_format = 'Карта сайта должна иметь в качестве начального элемента &lt;sitemapindex&gt; или &lt;urlset&gt;.';
self.validity_error_fetch = 'Не удалось загрузить карту сайта по этой ссылке. Код ответа сервера: ';
self.validity_error_incorrect_xml = 'Карта сайта имеет ошибку в синтаксисе xml.';
self.validity_error_empty_map = 'Карта сайта не содержит ни одной ссылки.';
self.validity_error_cicle_index = 'Индексная карта сайта циклически подключает себя напрямую, либо через другую карту.';
self.validity_error_invalid_host = 'Карта сайта расположена на другом протоколе или домене';
self.validity_error_invalid_map_format = 'XML-карта является индексной картой сайта, но не соответствует формату индексной карты';
self.validity_error_too_much_links = 'Карта сайта содержит более 50 000 ссылок';
self.validity_error_relative_links = 'Карта сайта содержит относительные ссылки';
self.validity_error_mixed_protocols = 'Ссылки внутри карты сайта имеют протоколы http и https';
self.validity_error_map_disallowed = 'Файл sitemap запрещен для сканирования в robots.txt';

self.count_title = 'Количество ссылок';
self.count_title_info = 'Данные по страницам сайта, обнаруженным в XML-картах.';
self.count_item_total = 'Всего';
self.count_item_total_info = 'Количество страниц сайта, обнаруженное в XML-картах, дубликаты страниц не удалены.';
self.count_item_uniq = 'Уникальных';
self.count_item_uniq_info = 'Количество страниц сайта, обнаруженное в XML-картах, без дубликатов страниц.';
self.count_item_duplicates = 'Повторов';
self.count_item_duplicates_info = 'Количество дубликатов страниц с указанием XML-карт сайта, в которых эти дубликаты встретились.';

self.switches_title = 'Количество ссылок ведущих на другие домены';
self.switches_title_info = 'Если в XML-картах сайта есть страницы другого домена, отличного от исходного, вы увидите список этих страниц.';
self.switches_empty = 'Все страницы принадлежат исходному домену.';
self.switches_different_pages = 'Страницы с поддоменов';
self.switches_relative_pages = 'Относительные ссылки';

self.years_title = 'Количество страниц по годам';
self.years_title_info = 'Если в XML-картах используется тег &lt;lastmod&gt;, то для указанной группировки по году используются значения дат этого тега. Как правило, это дата создания или изменения страницы.';
self.years_alert = '<p>На сайте <%= pages %> страниц (<%= percent %>% от всех страниц в XML-карте) старше 2 лет по данным карты сайта.</p><p>Обратите внимание на эти страницы, вы можете отфильтровать их по году в выгрузке.</p><p>Возможно, их контент устарел и его стоит обновить?</p><p class="mb-0">Дата изменения страницы в карте сайта может быть некорректной, поэтому проверьте на примере нескольких страниц сайта, можно ли доверять этим данным.</p>';
self.years_chart_pages = 'Страницы';
self.years_year_unknown = 'Не указан';

self.robots_title = 'Проверка robots.txt';

self.robots_status_sitemapOk = 'Ссылка на XML-карту присутствует в файле robots.txt, все в порядке.';
self.robots_status_sitemapMissing = 'Файл robots.txt не содержит ссылок на XML-карту сайта. Это не критично, но рекомендуем добавить в него ссылку на XML-карту (или карты) сайта. Как это сделать читайте в рекомендациях Яндекса и Google.';
self.robots_status_sitemapMirror = 'Robots.txt содержит карты сайта ведущие на неглавное зеркало.';
self.robots_status_fetchError = 'Не удалось загрузить файл robots.txt.';

self.sitemap_list = 'Список карт сайта:';

self.sitemap_check_title = 'Проверка карт сайта';

self.col_type = 'Тип';
self.col_state = 'Статус';
self.col_link = 'Ссылка';

self.status_ok = 'Ок';
self.status_warning = 'Ошибка';

self.sitemap_type_index = 'Индексная карта';
self.sitemap_type_normal = 'Обычная карта';
self.sitemap_type_redirect = 'Редирект';
self.sitemap_type_unknown = 'Неопределён';

self.title_checklist = 'Чеклист:';

self.sitemap_status_redirect = 'Перенаправление';
self.sitemap_status_fetchError = 'Не удалось загрузить карту сайта по этой ссылке. Код ответа сервера: ';
self.sitemap_status_correct = 'XML файл составлен правильно.';
self.sitemap_status_incorrect = 'Есть ошибка в синтаксисе XML.';
self.sitemap_status_empty = 'Карта сайта не содержит ссылок.';
self.sitemap_status_unknown = 'Неизвестный формат карты сайта.';
self.sitemap_status_indexIncorrect = 'XML-карта является индексной картой сайта, но не соответствует <a href="https://www.sitemaps.org/ru/protocol.html#index" target="_blank">формату индексной карты</a>.';
self.sitemap_status_invalidHost = 'Хост данной карты отличается от исходного <b><%= host %></b>';
self.sitemap_status_cycling = 'Карта сайта циклически возвращается на саму себя через другую xml-карту.';
self.sitemap_status_noRelatives = 'В файле нет относительных URL.';
self.sitemap_status_hasRelatives = 'В файле есть относительные URL (а надо указывать абсолютные).';
self.sitemap_status_allowed = 'Файл sitemap разрешён для сканирования в robots.txt';
self.sitemap_status_disallowed = 'Файл sitemap запрещен для сканирования в robots.txt';
self.sitemap_status_size = 'Файл карты сайта содержит <%= count %> URL.';
self.sitemap_status_tooBig = 'Файл карты сайта (любой отдельный) содержит 50 000 URL или более (а должно быть менее)';
self.sitemap_status_sameProtocol = 'В URL карте все ссылки используют одинаковый протокол';
self.sitemap_status_mixedProtocol = 'В URL карте используется протокол и http и https';

self.no_data = 'Нет данных';

self.col_lastmodify = 'Дата последнего изменения';
self.col_page = 'Страница';

self.total_pages = 'Всего страниц: <%= count %>';

export default self;
