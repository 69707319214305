const self = {};

self.search = 'Поиск';

self.show_by = 'Показывать по';
self.show_by_all = 'Все';

self.empty = 'Нет записей';

self.paging_total = 'Всего элементов: <%= total %>';
self.paging_showed = 'Показаны элементы с <%= from %> по <%= to %> из <%= filtered %>';
self.paging_empty = 'Нет доступных элементов';

self.paging_picked_from = '(Выбраны из <%= total %>)';

export default self;
