import Provider from '/services/Provider';

class AuditsProvider extends Provider {
  getReports = id => this.api.audits.getReports(id);
  getReport = id => this.api.reports.get(id);

  addAudit = project_id => this.api.audits.add(project_id);
  getAuditsByIds = ids => this.api.audits.getListByIds(ids);
  deleteAudit = id => this.api.audits.delete(id);
}

export default AuditsProvider;
