import * as KEYS from 'keycode-js';

import emitter from '/utils/emitter';
import global from '/utils/global';

const self = emitter({});

if (__CLIENT__) {
  const blacklist = 'INPUT TEXTAREA SELECT OPTION'.split(' ');

  self.KEYS = Object.keys(KEYS).reduce((result, key) => {
    const name = key.slice(4).toLowerCase();

    result[name] = KEYS[key];

    return result;
  }, {});

  self.CODES = Object.keys(KEYS).reduce((result, key) => {
    const name = key.slice(4).toLowerCase();

    result[KEYS[key]] = name;

    return result;
  }, {});

  // HACK: Override enter code from 14 to 13
  self.KEYS.enter = 13;
  self.CODES[13] = 'enter';

  self.states = {
    alt: false,
    ctrl: false,
    shift: false,
  };

  const switches = {
    keyup: false,
    keydown: true,
  };

  const roles = {
    [KEYS.KEY_ALT]: 'alt',
    [KEYS.KEY_CONTROL]: 'ctrl',
    [KEYS.KEY_SHIFT]: 'shift',
  };

  const avoid = event => blacklist.includes(event.target && event.target.tagName);

  const notify = function notify(event, type) {
    const { keyCode } = event;

    keyCode in roles && (self.states[roles[keyCode]] = switches[type]);

    if (avoid(event)) return;

    const key = self.CODES[keyCode];

    self.trigger(type + ':' + key, event, self.states);
  };

  self.onKeyUp = event => notify(event, 'keyup');
  self.onKeyDown = event => notify(event, 'keydown');

  global.addEventListener('keyup', self.onKeyUp);
  global.addEventListener('keydown', self.onKeyDown);
}

export default self;
