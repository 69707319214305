import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';

import SitemapResult from '../../views/SitemapResult';

import { check as checkLink } from '/utils/link';

import { SITE_TYPE } from '../../views/SitemapResult/constants';

class ToolsSitemapper extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
        items: [],
        links: [],
      },
      result: null,
    };
  }

  onLinksUpdate = (name, text) => {
    const items = text.split('\n').filter(v => !!v);
    const links = items.filter(link => checkLink(link));

    this.setState({ insert: { text, items, links } });
  };

  onProcess = async () => {
    const { lock, insert } = this.state;

    if (lock) return;

    this.setState({ lock: true });

    const { result } = await this.provider.sitemapper(insert.links);

    this.tree = null;

    this.setState({ result, lock: false });
  };

  renderInsertBox() {
    const { lang } = this.props;
    const { lock, insert, result } = this.state;

    const sets = { lang, lock, insert, result };

    sets.onLinksUpdate = this.onLinksUpdate;
    sets.onProcess = this.onProcess;

    return <InsertBox { ...sets } />;
  }

  renderToolbar = () => {
    const { lang } = this.props;
    const { lock, result } = this.state;

    if (lock) return null;

    let { main, items } = result;

    items = items.filter(item => [SITE_TYPE.SITEMAP, SITE_TYPE.REDIRECT].includes(item.type));

    return (
      <div className="mr-2">
        <span className="mr-2">{lang('export')}:</span>
        <UI.Button.Transparent onClick={() => this.provider.sitemapperExport({ main, items }, lang)} className="btn-custom-excel" download />
      </div>
    );
  };

  renderResultBox() {
    const { lang } = this.props;
    const { result } = this.state;

    if (!result) return null;

    return (
      <BL.Panel className="mb-0">
        <UI.Collapse title={lang('title_result')} expand={true}>
          <SitemapResult lang={lang} result={result} renderToolbar={this.renderToolbar} />
        </UI.Collapse>
      </BL.Panel>
    );
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title name={lang('title')}>
          <UI.Description short={lang('description', {}, { useParser: true })} full={lang('descriptionFull', {}, { useParser: true })} />
        </BL.Title>

        {this.renderInsertBox()}
        {this.renderResultBox()}
      </Fragment>
    );
  }
}


export default ToolsSitemapper;
