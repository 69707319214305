import React from 'react';
import cn from 'classnames';

import './styles.scss';

const Tag = props => {
  const { name, className } = props;

  return (
    <div className={cn('ui-tag', className)}>
      <i className="fal fa-angle-left ui-tag-icon" />
      <span className="ui-tag-name">{name} <span className="ui-tag-slash">/</span></span>
      <i className="fal fa-angle-right ui-tag-icon" />
    </div>
  );
};

export default Tag;
