const { isArray, isFunction, isNaN, isNill } = require('../types');

const _slice = Array.prototype.slice;

const self = {};

const DEFAULT_COMPARATOR = (a, b) => a === b || isNaN(a) && isNaN(b);

export const deduplicate = self.deduplicate = (target, comparator = DEFAULT_COMPARATOR) => {
  target = target.slice();

  for (let i = 0; i < target.length - 1; i++) {
    for (let j = i + 1; j < target.length; j++) {
      if (comparator(target[i], target[j])) {
        target.splice(j--, 1);
      }
    }
  }

  return target;
};

export const deduplicateUsingMap = self.deduplicateUsingMap = (target, getId = v => v) => {
  const map = {};

  for (let i = 0, len = target.length; i < len; i++) {
    const id = getId(target[i]);

    if (id in map) continue;

    map[id] = target[i];
  }

  return Object.values(map);
};

const SORTER_COMPARATOR = (a, b, m) => {
  if (a < b) return -1 * m;
  if (a > b) return  1 * m;

  return 0;
};

export const sorter = self.sorter = (cb = null, dirs = 'asc') => {
  const mult = dirs.split(',').map(dir => ({ asc: 1 , desc: -1 }[dir.trim()] || 1));

  if (cb === null) {
    return (a, b) => SORTER_COMPARATOR(a, b, mult[0]);
  }

  let fields = [];

  if (typeof cb === 'function') {
    fields = [cb];
  }

  if (typeof cb === 'string') {
    const items = cb.split(',').map(f => f.trim());

    fields = items.map(field => item => item[field]);
  }

  if (Array.isArray(cb)) {
    fields = cb.map(field => ({
      string: item => item[field],
      function: field,
    }[typeof field] || (() => 0)));
  }

  return (a, b) => {
    let result = 0;

    for (let field, i = 0; field = fields[i]; i++) {
      result = SORTER_COMPARATOR(field(a), field(b), mult[i] || mult[0]);

      if (result) break;
    }

    return result;
  };
};

export const createArray = self.createArray = (size, value = i => i) => {
  const result = [];
  const isFunc = isFunction(value);

  for (let i = 0; i < size; i++) {
    result[i] = isFunc ? value(i) : value;
  }

  return result;
};

export const diff = self.diff = (a1, a2) => a1.filter(v => !a2.includes(v));

export const getListArg = self.getListArg = (a, args) => isArray(a) ? a : _slice.call(args);

export const getLast = self.getLast = a => (isArray(a) ? a[a.length - 1] : null);

export const unzip = self.unzip = (array, name) => {
  if (isNill(name)) return [].concat(...array);

  return array.reduce((result, item) => {
    const adds = isArray(item[name]) ? item[name] : [item[name]];

    result.push(...adds);

    return result;
  });
};

export const mix = self.mix = (array, callback) => {
  const result = [];

  for (let i = 0, len = array.length; i < len; i++) {
    const item = array[i];

    if (i) result[result.length] = callback(i - 1, item, i);

    result.push(array[i]);
  }

  return result;
};

export const isMultiDim = self.isMultiDim = array => array.every(item => isArray(item));

export const suffle = self.suffle = (a, clone = false) => {
  if (clone) a = a.slice();

  for (let i = 0, c = a.length; i < c; i++) {
    const j = Math.floor(Math.random() * c);

    [a[j], a[i]] = [a[i], a[j]];
  }

  return a;
};

export const remove = self.remove = (arr, finder) => {
  const idx = arr[isFunction(finder) ? 'findIndex' : 'indexOf'](finder);

  if (idx === -1) return;

  arr.splice(idx, 1);
};

export const trimLeft = self.trimLeft = (arr, checker = v => !!v) => {
  arr = arr.slice();

  while (arr.length && !checker(arr[0])) arr.shift();

  return arr;
};

export const trimRight = self.trimRight = (arr, checker = v => !!v) => {
  arr = arr.slice();

  while (arr.length && !checker(arr[arr.length - 1])) arr.pop();

  return arr;
};

export const moveArrayItem = self.moveArrayItem = (arr, from, to) => {
  arr.splice(to, 0, arr.splice(from, 1)[0]);
};

export const split = self.split = (target, splitter) => {
  const _true = [];
  const _false = [];

  for (let i = 0, len = target.length; i < len; i++) {
    const res = splitter(target[i], i);

    (res ? _true : _false).push(target[i]);
  }

  return [_true, _false];
};

export const assign = self.assign = function assign(src, callback) {
  return src.reduce((result, item, i) => {
    const mixin = callback(item, i) || {};

    return Object.assign(result, mixin);
  }, {});
};

export default self;
