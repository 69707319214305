const _toString = Object.prototype.toString;

const getType = value => _toString.call(value).slice(8, -1);

export const isNull = value => value === null;
export const isUndefined = value => value === undefined;
export const isArray = value => Array.isArray(value);
export const isString = value => getType(value) === 'String';
export const isNumber = value => getType(value) === 'Number';
export const isBoolean = value => getType(value) === 'Boolean';
export const isFunction = value => value instanceof Function;
export const isAsyncFunction = value => value && value.constructor.name === 'AsyncFunction';
export const isRegExp = value => value instanceof RegExp;
export const isObject = value => getType(value) === 'Object';

export const isPlainObject = value => !!value && value.constructor === Object;
export const isNill = value => value == null;
export const isNaN = value => value !== value;
export const isNode = value => value.nodeType && getType.isFunction(value.cloneNode);
export const isDate = value => value instanceof Date;

export default getType;
