import { connect } from 'react-redux'
import { createRoute } from '/services/Router/route';

import view from './view';

import { fetchConversations, createConversation } from '/components/Conversations/store/actions';

const route = createRoute({ path: '/support/tickets', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { conversations } = state;

  return { conversations };
};

const mapDispatchToProps = { fetchConversations, createConversation };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
