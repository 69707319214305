const unpack = columns => {
  const result = [];

  if (!columns) return result;

  for (let i = 0, len = columns.length; i < len; i++) {
    const items = columns[i].childs ? unpack(columns[i].childs) : [columns[i]];

    result.push(...items);
  }

  return result;
};

export default unpack;
