const fields = {};

fields.accaunt = item => item.accaunt_name;
fields.date_add = item => item.date_add;
fields.date_expire = item => item.date_expire;

export default (field, dir) => {
  const getter = fields[field];
  const fallback = fields.id;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a);
    _b = getter(b);

    if (_a === _b) {
      _a = fallback(a);
      _b = fallback(b);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
