import React from 'react';

import BL from '/views/blocks';

import config from './config';

const TableWords = props => {
  return <BL.Table className="darken" { ...props } { ...config } />;
};

export default TableWords;
