import React, { Component } from 'react';
import cn from 'classnames';

import Chart from './chart';

import VIEW from '/views/view';

import { isEqual } from '/utils/object';
import { ref } from '/utils/react';

import './styles.scss';

class PieChart extends Component {
  ref = ref;

  shouldComponentUpdate(_props, _state) {
    return !isEqual(this._items, _props.items);
  }

  renderLegend() {
    const { items } = this.props;

    const renderItem = (item, idx) => {
      return (
        <li key={idx} className="d-flex align-content-center flex-wrap views_ui-pie_chart-legend_item">
          <div className="d-flex align-content-center flex-wrap views_ui-pie_chart-legend_item_color" style={{ backgroundColor: item.color }} />
          <div className="views_ui-pie_chart-legend_item_name">
            {item.name}
          </div>
        </li>
      );
    };

    return (
      <ul className="d-flex justify-content-center flex-wrap views_ui-pie_chart-legend">
        {items.map(renderItem)}
      </ul>
    );
  }

  componentDidMount() {
    this.renderChart();

    VIEW.on('viewport:change', this.renderChart);
    VIEW.on('sidebar:toggle', this.renderChart);
  }

  componentDidUpdate() {
    this.renderChart();
  }

  componentWillUnmount() {
    VIEW.off('viewport:change', this.renderChart);
    VIEW.off('sidebar:toggle', this.renderChart);
  }

  renderChart = () => {
    const { settings, items } = this.props;

    this._items = this.props.items;

    this.chart = new Chart({ ...settings, items });

    this.chart.render(this.ui.graph);
  }

  render() {
    const { className, items, width, height } = this.props;

    return (
      <div className={cn('d-flex flex-column views_ui-pie_chart', className)}>
        <div className="views_ui-pie_bg-white">
          <div className="d-flex justify-content-center w-100" style={{ width, height }}>
            <div className="views_ui-pie_chart-graph" ref={this.ref('graph')} />
          </div>
          {this.renderLegend()}
        </div>
      </div>
    );
  }
}

export default PieChart;
