import React, { Component, Fragment } from 'react';

import Provider from '../../provider';

import { UI, BL } from '/views';

// import DATA from './data_month';
import DATA from './data';

class SearchersUpdatesReports extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);
  }

  render() {
    return (
      <Fragment>
        <BL.Title icon="fal fa-ballot-check" name="Апдейты поисковых систем" />

        <BL.Panel>
          <BL.Panel.Content>
            <UI.LineChart data={DATA} settings={{ marker: { type: 'overall' }}} />
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
    // <UI.LineChart data={DATA} settings={{ marker: { type: 'single' }, popup: { label: labels => labels.pop() } }} />
  }
}

export default SearchersUpdatesReports;
