import React from 'react';
import cn from 'classnames';

import { isObject, isNill } from '/utils/types';

import './styles.scss';

const normalize = lines => {
  const result = [];

  let last = 0;

  for (let i = 0, len = lines.length; i < len; i++) {
    const line = lines[i];

    const num = isNill(line.num) ? last + 1 : line.num;
    const value = isNill(line.value) ? line : line.value;
    const className = isNill(line.className) ? null : line.className;

    last = num;

    result.push({ num, value, className });
  }

  return result;
};

const Line = props => {
  const { num, value, className } = props;

  return (
    <div className="ui_code-line">
      <div className={cn('ui_code-cell ui_code-line_number', className)}>{num}</div>
      <div className={cn('ui_code-cell ui_code-line_value', className)}>{value}</div>
    </div>
  );
};

const Code = props => {
  let { lines } = props;

  lines = normalize(lines);

  return (
    <div className="ui_code-root">
      {lines.map((line, i) => <Line key={i} {...line} />)}
    </div>
  );
};

export default Code;
