import React, { Component, Fragment } from 'react';
import moment from 'moment';

import Result from './components/Result';

import { UI, BL } from '/views';

import Provider from '../../provider';

import services from '/static/data/services';

class ReportsZeroTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
      },
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  async reload() {
    const { id, audit_id } = this.props;

    let report = await this.provider.getMissingInSitemapResult(audit_id, id);

    this.setState({ report });
  }

  renderContent() {
    const { lock, insert, report } = this.state;
    const { id, audit_id, user_id } = this.props;

    const sets = { lock, insert, main: report.main };

    sets.onChange = this.onInsertChange;
    sets.onSubmit = this.onProcess;

    const exportExcelLink = this.provider.getMissingInSitemapExcelLink(audit_id, id, user_id);

    return <Result error={report.error} items={report.items} exportExcelLink={exportExcelLink} />;
  }

  renderLoader() {
    return (
      <BL.Panel>
        <BL.Panel.Content className="py-3 text-center">
          <UI.Loader />
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { report } = this.state;

    const { dates } = report || {};

    return (
      <Fragment>
        <BL.Title name="Страницы с поисковым трафиком, но не в XML-карте сайта">
          <p>В этом отчете находится список страниц сайта, которые получали поисковый трафик за последние 3 месяца{dates ? ' (' + moment(dates.last3month.from).format('MMM YYYY') + ' - ' + moment(dates.last3month.to).format('MMM YYYY') +')' : ''}, но отсутствуют в XML-карте сайта.</p>
          <p>Кроме страниц, которые просто не добавлены в карту сайта, таким способом можно найти заброшенные разделы сайта, признаки взлома сайта.</p>
        </BL.Title>

        {report ? this.renderContent() : this.renderLoader()}
      </Fragment>
    );
  }
}

export default ReportsZeroTraffic;
