import React, { Component, Fragment } from 'react';

import Table from '../../views/TrafficTable';

import { link as createLink } from '/services/Components/methods';

import { UI, BL } from '/views';

import Provider from '../../provider';

import SitemapResult from '../../../Tools/views/SitemapResult';

import services from '/static/data/services';

class ReportsLostTraffic extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getSitemapsResult(audit_id, id);
  }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderToolbar = () => {
    const { id, audit_id, user_id } = this.props;

    const url = this.provider.getSitemapExcelLink(audit_id, id, user_id);

    return (
      <div className="mr-2">
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent href={url} className="btn-custom-excel" download />
      </div>
    );
  };

  renderResult() {
    const { lang, project_id } = this.props;
    const { report } = this.state;

    if (!report) return null;

    if (!report.items.length) {
      const link = project_id ? createLink('Projects.Settings', { lang, id: project_id, step: 0, callback: 'back-to-projects' }) : '#';

      return (
        <div className="pt-3 px-3">
          <BL.Alert.Warning>
            <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
            <BL.Alert.Content>
              <p>При запуске аудита не было указано ни одной XML-карты сайта, поэтому у сервиса нет данных для формирование этого и нескольких других отчетов.</p>
              <p>Поисковые системы используют XML-карты сайта для получения информации о страницах сайта и датах их изменения, SERP.tools использует XML-карту как источник данных о страницах сайта.</p>
              <p className="mb-0">Пожалуйста настройте на своем сайте корректное формирование XML-карты сайта и указывайте ее в <a href={link}>настройки проекта</a> при запуске аудита.</p>
            </BL.Alert.Content>
          </BL.Alert.Warning>
        </div>
      );
    }

    return <SitemapResult lang={lang.child('components.Tools.Sitemapper')} result={report} isAudit={true} renderToolbar={this.renderToolbar} />;
  }

  render() {
    const { report } = this.state;

    return (
      <Fragment>
        <BL.Title name="Анализ XML-карты сайта">
          <p>В рамках проверки XML-карты сайта сервис получает список страниц в карте сайта. Вы можете проанализировать структуру сайта, а также увидеть количество страниц в разбивке по году, если в карте используется тег {'<'}lastmod{'>'}.</p>
          <p>Сервис проверяет ряд возможных ошибок карт сайта (но не заменяет валидаторы XML-карт):</p>
          <ul className="mb-0">
            <li>Доступность и ответ сервера.</li>
            <li>Содержит ли карта сайта ссылки на страницы (не пустая ли она).</li>
            <li>Отсутствие обязательных XML-тегов согласно стандарту.</li>
            <li>Наличие в карте сайта страниц, относящихся к другому сайту.</li>
            <li>Наличие в карте/картах сайта дубликатов страниц.</li>
          </ul>
        </BL.Title>

        <BL.Panel className="mb-0">
          {!report && <div className="text-center pt-3 pb-3"><UI.Loader /></div>}
          {this.renderResult()}
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ReportsLostTraffic;
