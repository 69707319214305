import React from 'react';

import TableWords from '../TableWords';

import { BL } from '/views';

const ResultWords = props => {
  const { className, result, tableHeight, selections, onSelect, onDeselect } = props;

  return (
    <TableWords className={className} data={result.words} tableHeight={tableHeight} actions={{ onSelect, onDeselect }} settings={{ ngrams: result.ngrams, selections }} />
  );
};

export default ResultWords;
