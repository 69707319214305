const self = {};

self.checkSitemaps = function checkSitemaps(urls) {
  const url = '/api/v1/sitemap/check';
  const method = 'post';

  const data = { urls };

  return this.axios({ url, method, data });
};

self.decideSitemaps = function decideSitemaps(host) {
  const url = '/api/v1/sitemap/decide';
  const method = 'post';

  const data = { host };

  return this.axios({ url, method, data });
};

export default self;
