import React from 'react';
import moment from 'moment';

import { BL } from '/views';

const ModalRemove = props => {
  const { name, onCancel, onSubmit } = props;

  const date = moment(date).format('D MMMM YYYY HH:mm:ss');

  const sets = {
    title: 'Удалить аудит',
    icon: 'fal fa-exclamation-circle',
    actions: { onCancel, onSubmit },
    buttons: { cancel: 'Отмена', submit: 'Удалить' },
  };

  return (
    <BL.Modal.Confirm.Warning { ...sets }>
      <p>Вы действительно хотите удалить аудит по проекту <b>{name}</b> от <b>{date}</b>?</p>
    </BL.Modal.Confirm.Warning>
  );
};

export default ModalRemove;
