export default (capacity, columns) => {
  const find = list => list.find(item => item.area.capacity.from <= capacity && capacity <= item.area.capacity.to);

  const path = [];

  while (columns) {
    const column = find(columns);

    path.push(column);

    if (!column.items) return path;

    columns = column.items;
  }
};
