import { select, call, put, take, takeEvery, throttle, delay } from 'redux-saga/effects';
import moment from 'moment';

import { addAudit, addAudits, createAudit, deleteAudit, removeAudit, setCurrentAudit, setAuditReports, updateRunningAuditStates } from './actions';
import { startSession } from '../../User/store/actions';
import { routerUpdateState } from '/services/Router/store/actions';

import { link } from '/services/Components/methods';

import Provider from '../provider';

const repackReport = item => {
  const { id, percent, ready } = item;

  const result = { id, percent, ready };

  result.dateAdd = moment(item.date_add).format('D MMMM YYYY HH:mm:ss');
  result.dateStart = moment(item.date_start).format('D MMMM YYYY HH:mm:ss');
  result.dateFinish = moment(item.date_finish).format('D MMMM YYYY HH:mm:ss');

  result.serviceName = item.service_name;

  result.projectHost = item.project_host; // TODO: remove it!
  result.projectName = item.project_name; // TODO: remove it!

  return result;
};

function* _startSession() {
  if (__CLIENT__) {
    const { current } = yield select(state => state.audit);

    if (current) {
      yield put(setCurrentAudit(current));
    }
  }
}

function* _setCurrentAudit({ payload }) {
  if (__CLIENT__) {
    const { id } = payload;
    const { user, audit } = yield select();

    if (id === audit.current && audit[id] && audit[id].reports.length) return;

    const provider = new Provider(user.token);

    const { reports: list } = yield provider.getReports(id);

    const reports = [];

    for (let report, i = 0; report = list[i]; i++) {
      if (report.service_name === 'lost_traffic') {
        reports[reports.length] = repackReport({ ...report, service_name: 'all_traffic' });
      }

      if (report.service_name === 'collate_pages') {
        reports[reports.length] = repackReport({ ...report, service_name: 'zero_traffic' });
        reports[reports.length] = repackReport({ ...report, service_name: 'missing_in_sitemap' });
        continue;
      }

      if (report.service_name === 'daily_traffic') {
        reports[reports.length] = repackReport({ ...report, service_name: 'search_algo_update' });
        continue;
      }

      reports[reports.length] = repackReport(report);
    }

    yield put(setAuditReports(id, reports));
  }
}

function* _routerUpdateState({ payload }) {
  const { auditId } = payload.params.url || {};

  if (auditId) {
    yield put(setCurrentAudit(auditId));
  }
}

function* _createAudit({ payload }) {
  const { user } = yield select();

  const { projectId, defer } = payload;

  const provider = new Provider(user.token);

  const { audit, error } = yield provider.addAudit(projectId);

  if (error) return defer.reject(error);

  const { id, date_add } = audit;

  yield put(addAudit(id, projectId, 0, 0, date_add, null, null, []));

  defer.resolve(audit);
}

let UPDATE_CHECKER = false;

function* _onAuditsAdd({ payload }) {
  const { user } = yield select();

  const provider = new Provider(user.token);

  let retries = 0;

  if (!UPDATE_CHECKER) {
    UPDATE_CHECKER = true;

    while (true) {
      yield delay(5000);

      const items = yield select(state => state.audit.items);

      const ids = Object.values(items).map(item => !item.ready ? item.id : null).filter(v => !!v);

      if (!ids.length) {
        return UPDATE_CHECKER = false;
      }

      const { audits, error } = yield provider.getAuditsByIds(ids);

      if (error && (retries += 1)) {
        if (retries >= 5) return;

        continue;
      }

      retries = 0;

      const _audits = [];

      for (let audit, i = 0; audit = audits[i]; i++) {
        const item = {};

        item.id = audit.id;
        item.dateFinish = audit.date_finish;
        item.dateStart = audit.date_start;
        item.percent = audit.percent;
        item.ready = audit.ready;

        _audits.push(item);
      }

      yield put(updateRunningAuditStates(_audits));
    }
  }
}

function* _deleteAudit({ payload }) {
  const { id, defer } = payload;

  const { user } = yield select();

  const provider = new Provider(user.token);

  const { error } = yield provider.deleteAudit(id);

  if (error) return defer.reject(error);

  yield put(removeAudit(id));

  defer.resolve();
}

export default function* auditSage() {
  yield takeEvery(setCurrentAudit, _setCurrentAudit);
  yield takeEvery(routerUpdateState, _routerUpdateState);
  yield takeEvery(createAudit, _createAudit);
  yield takeEvery(deleteAudit, _deleteAudit);

  // Hack: wait while reducer will update state
  yield throttle(0, startSession, _startSession);
  yield throttle(0, addAudit, _onAuditsAdd);
  yield throttle(0, addAudits, _onAuditsAdd);
}
