import React, { Fragment } from 'react';

import { BL } from '/views';

const fields = {};

fields.visits = item => item.value;

fields.status = item => {
  if (!item.response) return '-';

  const sets = { error: item.response.error, code: item.response.statusCode };

  return <BL.StatusBadge {...sets} />;
};

const labelGood = () => {
  return (
    <div className="d-flex align-center mt-2 text-success">
      <i className="fal fa-check-circle fs-12 mr-2" /> Есть хорошо
    </div>
  );
};

const labelProblem = () => {
  return (
    <div className="d-flex align-center mt-2 text-danger">
      <i className="fal fa-exclamation-circle fs-12 mr-2" />Есть проблема
    </div>
  );
};

fields.description = item => {
  if (!item.response) return item.url;

  let html = null, label = null;

  if (item.response.statusCode === 404) {
    label = labelGood();
    html = `Страница удалена. Убедитесь, что с нее не должен быть настроен 301 редирект, если вы меняли адреса каких-то страниц. Так как страница недоступна, поисковые системы в скором времени должны исключить ее из индекса и страница должна перестать получать поисковый трафик.`;
  }

  if (item.response.statusCode === 302) {
    label = labelProblem();
    html = `http-версия страницы отдает редирект на https-версию, но не <b>301</b> редирект, а <b>302</b>.<br />Необходимо использовать <b>301</b> редирект.`;
  }

  if (item.response.statusCode === 301) {
    const { redirect } = item.response.conclusion;

    const url_1 = item.url.replace(/^https?:\/\//i, '');
    const url_2 = redirect.replace(/^https?:\/\//i, '');

    if (url_1 === url_2) {
      label = labelGood;
      html = `http-версия страницы отдает <b>301</b> редирект на https-версию этой же страницы.<br />Похоже, проблема уже была исправлена.`;
    } else {
      label = labelProblem();
      html = `http-версия страницы отдает <b>301</b> редирект на https-версию, но не на ту же самую страницу, а на ${redirect}.<br />Проверьте, все ли корректно.`;
    }
  }

  if (item.response.statusCode === 200) {
    label = labelProblem();
    html = `http-версия страницы не отдает <b>301</b> редирект на https-версию.<br />Настройте <b>301</b> редирект на https-версию.`
  }

  if (!html) return item.url;

  return (
    <Fragment>
      {item.url}
      {label}
      <div className="mt-2" dangerouslySetInnerHTML={{ __html: html }} />
    </Fragment>
  );
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
