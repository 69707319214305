const fields = {};

fields.url = item => item.url;

fields.state = (item, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return -2;

  const { verdict } = values[settings.select];

  const states = {
    TRAFFIC_GROW: 4,
    TRAFFIC_STABLE: 3,
    TRAFFIC_SUBSIDE: 2,
    TRAFFIC_DROP: 1,
    TRAFFIC_ZERO: 0,
    TRAFFIC_UNMATCH: -1
  };

  return states[verdict];
};

fields.year_0 = (item, settings) => item.stats.total[0].values[settings.select];
fields.year_1 = (item, settings) => item.stats.total[1].values[settings.select];

fields.diff_year = (item, settings) => {
  const { state, values } = item.analytics.total;

  if (state !== 'STATE_OK') return -Infinity;

  return values[settings.select].variance;
};

fields.diff_3_month = (item, settings) => {
  const { state, values } = item.analytics.last3month;

  if (state !== 'STATE_OK') return -Infinity;

  return values[settings.select].variance;
};

fields.diff_1_month = (item, settings) => {
  const { state, values } = item.analytics.year[11];

  if (state !== 'STATE_OK') return -Infinity;

  return values[settings.select].variance;
};

fields.downfall = (item, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return Infinity;

  return values[settings.select].downfall.variance;
};

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.url;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
