const self  = {};

self.head_title = 'Бесплатный онлайн сервис проверки Robots.txt';
self.head_keywords = '';
self.head_description = 'Массовая проверка страниц сайта (URL) открыты ли они для сканирования поисковыми системами в файле robots.txt и если закрыты, то каким правилом';

self.name = 'Проверка robots.txt';

self.title = 'Анализ robots.txt';
self.description = 'Сервис позволяет проверить, открыты или закрыты страницы от сканирования роботом поисковой системы Google или Яндекс в файле robots.txt. Пожалуйста, вводите адреса страниц по одному в строку с указанием http/https-протокола. Для адресов без протокола по умолчанию будет использоваться протокол https. Вы можете проверять сразу страницы разных сайтов, в результатах проверки они будут сгруппированы по сайтам. В результатах проверки вы увидите, закрыты или открыты страницы для сканирования каждой из поисковых систем и если да, каким правилом';

self.field_title = 'Список ссылок';
self.field_links_count = 'Количество ссылок: <b><%= count %></b>';

self.error_incorrectUrl = 'В списке ссылок присутствуют неправильные адреса.';
self.error_duplicateUrl = 'В списке ссылок присутствуют дублированные адреса.';

self.error_missingRobots = 'Файл по адресу <b><%= url %></b> отдает код ответа сервера <b><%= status %></b>, поэтому мы не можем его загрузить. Пожалуйста, проверьте, корректно ли вы указали адрес сайта. Возможно, файл robots.txt отсутствует на сайте.';

self.button_check = 'Проверить';

self.result_title = 'Проверка ссылок для домена <%= domain %>';
self.result_links_count = 'Количество ссылок';

self.tab_links = 'Ссылки';
self.tab_robots = 'Robots.txt';

self.col_yandex = 'Яндекс';
self.col_google = 'Google';
self.col_url = 'Адрес';

self.result_subtitle_all = 'Правило блокировки для всех поисковых систем';
self.result_subtitle_yandex = 'Правило блокировки для Яндекса';
self.result_subtitle_google = 'Правило блокировки для Google';

export default self;
