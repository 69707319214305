const self = {};

self.SUCCESS = 'report-result-good';
self.STATIC = 'report-result-static';
self.SUBSIDE = 'report-result-subside';
self.WARNING = 'report-result-warning';
self.DANGER = 'report-result-danger';
self.CRITICAL = 'report-result-critical';
self.UNKNOWN = 'report-result-unknown';

export default self;
