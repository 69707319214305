import { createActions } from 'redux-actions';

export const {
  dropSupport,
  createConversation,
  readConversation,
  fetchConversations,
  watchConversations,
  appendConversations,
  updateConversations,
  setCurrentConversation,
  toggleConversationState,
  sendConversationMessage,
  updateConversationMessage,
  removeConversationMessage,
  appendConversationMessages,
  finalizeConversationMessage,
} = createActions(
  {
    DROP_SUPPORT: () => ({}),
    CREATE_CONVERSATION: (title, message, defer) => ({ title, message, defer }),
    READ_CONVERSATION: (id, message_id, timestamp) => ({ id, message_id, timestamp }),
    FETCH_CONVERSATIONS: () => ({}),
    WATCH_CONVERSATIONS: () => ({}),
    APPEND_CONVERSATIONS: (items) => ({ items }),
    UPDATE_CONVERSATIONS: (updates) => ({ updates }),
    SET_CURRENT_CONVERSATION: (id) => ({ id }),
    TOGGLE_CONVERSATION_STATE: (id, closed) => ({ id, closed }),
    SEND_CONVERSATION_MESSAGE: (id, message_id, purpose, msg) => ({ id, message_id, purpose, msg }),
    UPDATE_CONVERSATION_MESSAGE: (id, message_id, msg) => ({ id, message_id, msg }),
    REMOVE_CONVERSATION_MESSAGE: (id, message_id) => ({ id, message_id }),
    APPEND_CONVERSATION_MESSAGES: (id, messages, remember) => ({ id, messages, remember }),
    FINALIZE_CONVERSATION_MESSAGE: (id, message, purpose) => ({ id, message, purpose }),
  }
);
