import { addReducer, addSaga } from '/services/Store';

// import reducer from './store/reducer';
// import saga from './store/saga';

import { packComponent } from '/services/Components/methods';

import actions from './actions';
import access from './access';

// addReducer('support', reducer);
// addSaga(saga);

const settings = {
  name: __FOLDER__,
};

export default packComponent({ settings, actions, access });
