import React from 'react';

import { BL } from '/views';

import config from './config';

const ResultTable = props => {
  return <BL.Table className="darken" pagination={true} { ...props } { ...config } />;
};

export default ResultTable;
