const self  = {};

self.head_title = 'Лемматизатор текста и проверка плотности слов онлайн';
self.head_keywords = '';
self.head_description = 'Онлайн лемматизатор текста, проверка плотности слов и фраз из 2-4 слов, определение переспама, поиск частых фраз в текстах конкурентов';

self.name = 'Лемматизатор';

self.title = 'Лемматизатор текста';
self.description = 'С помощью этого инструмента можно быстро лемматизировать текст (приводит к именительному падежу единственному числу)';

self.field_title = 'Исходный текст';

self.count_symbolsTotal = 'Количество символов: <b><%= count %></b>';
self.count_symbolsCleared = 'Без пробелов: <b><%= count %></b>';
self.count_words = 'Количество слов: <b><%= count %></b>';

self.button_check = 'Лемматизировать';

self.cleared_title = 'Лемматизированный текст';

self.tab_words = 'Слова (леммы)';
self.tab_phrases = 'Фразы (N-граммы)';
self.tab_forms = 'Формы';

self.export = 'Экспорт';

self.mylist_title = 'Мой список';
self.mylist_button_clear = 'Очистить';
self.mylist_button_copy = 'Копировать';
self.mylist_empty = 'Нет выбранных лемм';
self.mylist_button_add_all = 'Все в мой список';

self.modal_clear_title = 'Очистить список?';
self.modal_clear_description = '<p>После подтверждения это действие невозможно будет отменить.</p>';
self.modal_clear_cancel = 'Отмена';
self.modal_clear_confirm = 'Очистить';

self.modal_transfer_title = 'Добавить все из таблицы';
self.modal_transfer_description = 'Добавляем все слова/фразы из текущей таблицы в Мой список';
self.modal_transfer_cancel = 'Отмена';
self.modal_transfer_confirm = 'Добавить';

self.words_col_add = 'В список';
self.words_col_words = 'Слова (леммы)';
self.words_col_count = 'Кол-во';
self.words_col_percentText = '% в тексте';
self.words_col_percentText_info = 'Показывает какой процент составляет это слово среди всех слов, включая стоп-слова (предлоги, союзы, междометия и пр.)';
self.words_col_percentCore = '% в ядре';
self.words_col_percentCore_info = 'Показывает какой процент составляет это слово среди всех значимых слов (в тексте, очищенном от стоп-слов: предлогов, союзов, междометий и пр.)';

self.words_forms = 'Формы';

self.words_ngram = 'Встречается в N-граммах:';
self.words_ngram_missing = 'Данное слово не встречается ни в одной n-грамме';

self.words_ngram_col_add = 'В список';
self.words_ngram_col_phrases = 'Фразы (N-граммы)';
self.words_ngram_col_count = 'Повторений';
self.words_ngram_col_percent = '% для слова';
self.words_ngram_col_percent_info = 'Как часто это слово встречается именно в этой фразе в тексте';

self.phrases_col_add = 'В список';
self.phrases_col_phrases = 'Фразы (N-граммы)';
self.phrases_col_count = 'Кол-во';
self.phrases_col_percent = '% в тексте';

self.phrases_filter = 'Слов в N-грамме';
self.phrases_filter_all = 'Все';

self.forms_col_words = 'Слова (леммы)';
self.forms_col_count = 'Кол-во';
self.forms_col_forms = 'Словоформы из текста';

export default self;
