const self = {};

self.get = function get(type, period) {
  const url = '/api/v1/statistics/?type=' + type + '&period=' + period;
  const method = 'get';

  return this.axios({ url, method });
};

export default self;
