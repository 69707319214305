const createTreeDivarication = (type, level, value) => ({
  type, value,
  settings: {
    level,
    count: 0,
  },
  items: [],
});

const structurize = function structurize(items) {
  const result = [];

  for (let item, i = 0; item = items[i]; i++) {
    const level = +item.type.slice(1);
    const child = createTreeDivarication(item.type, level, item.value);

    let parent = null;
    let target = result;

    while (target.length) {
      const last = target[target.length - 1];

      if (last.settings.level >= level) break;

      parent = last;
      target = last.items;
    }

    parent && parent.settings.count++;

    target.push(child);
  }

  return result;
};

export default structurize;
