import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import Table from './components/Table';

import getResult from './utils/getResult';
import getNewLinks from './utils/getNewLinks';
import getWaitings from './utils/getWaitings';
import updateResult from './utils/updateResult';
import updateStatuses from './utils/updateStatuses';

import './styles.scss';

const PACKAGE_SIZE = 10;

class ToolsServerResponse extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        userAgent: 'USER_BROWSER',
        text: '',
        links: [],
      },
      result: {
        userAgent: '',
        links: [],
      },
      statuses: [],
    };
  }

  onToggle = (status, disabled) => {
    const statuses = [...this.state.statuses];

    const index = statuses.findIndex(v => v.status === status);

    statuses[index] = { status, disabled };

    this.setState({ statuses });
  };

  onStartTextChange = (name, text) => {
    const links = text.split('\n').map(v => v.trim()).filter(v => !!v);

    const insert = { ...this.state.insert, text, links };

    this.setState({ insert });
  };

  onUserAgentChange = userAgent => {
    const insert = { ...this.state.insert, userAgent };

    this.setState({ insert });
  };

  onProcess = () => {
    const { lock, insert } = this.state;

    if (lock || !insert.links.length) return;

    this.setState({ lock: true });

    this.process();
  };

  async process() {
    const { userAgent } = this.state.insert;

    let result = getResult(this.state.insert, this.state.result);
    const links = getNewLinks(this.state.insert, this.state.result);

    for (let i = 0, len = links.length; i < len; i += PACKAGE_SIZE) {
      const list = links.slice(i, i + PACKAGE_SIZE).map(url => ({ url }));

      this.setState({ result: { userAgent, links: getWaitings(list, result) } });

      const data = await this.provider.serverResponse(list, userAgent);

      this.setState(state => {
        const next = {};

        next.result = { userAgent, links: updateResult(result, data.result) };
        next.statuses = updateStatuses(state.statuses, next.result.links);

        return next;
      });
    }

    this.setState({ lock: false });
  }

  renderInsertBox() {
    const { lock, insert, result } = this.state;

    const sets = { lock, insert, result };

    sets.onStartTextChange = this.onStartTextChange;
    sets.onUserAgentChange = this.onUserAgentChange;
    sets.onProcess = this.onProcess;

    return <InsertBox { ...sets } />;
  }

  renderToolbar = () => {
    const { id, lang } = this.props;
    const { result, lock } = this.state;

    return (
      <Fragment>
        <span className="mr-2">{lang('export')}:</span>
        <UI.Button.Transparent disabled={lock} onClick={() => !lock && this.provider.serverResponseExport(result.links, lang)} className="btn-custom-excel" download />
      </Fragment>
    );
  };

  renderResult() {
    const { lang } = this.props;
    const { result, statuses } = this.state;

    if (!result.links.length) return null;

    const filtered = result.links.filter(item => {
      if (!item.result) return true;

      const code = item.result[0].statusCode;

      return !statuses.find(s => s.status === code).disabled;
    });

    return (
      <BL.Panel className="mb-0">
        <BL.Panel.Header>
          <h2>{lang('result')}</h2>
          <div>{this.renderToolbar()}</div>
        </BL.Panel.Header>
        <BL.Panel.Content className="pb-0">
          <Table data={filtered} actions={{ onToggle: this.onToggle }} settings={{ items: result.links, statuses }} />
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title icon="fal fa-heartbeat" name={lang('title')} text={lang('description')} />

        {this.renderInsertBox()}
        {this.renderResult()}
      </Fragment>
    );
  }
}


export default ToolsServerResponse;
