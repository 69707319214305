import React from 'react';

import { BL } from '/views';

import config from './config';

const PagesTable = props => {
  const sort = { field: 'visits', dir: 'desc' };

  return <BL.Table className="darken" sort={sort} { ...props } { ...config } />;
};

export default PagesTable;
