import COLORS from '../colors';

export default statistic => ({
  STATISTIC_GOOD: COLORS.SUCCESS,
  STATISTIC_STATIC: COLORS.STATIC,
  STATISTIC_SUBSIDE: COLORS.SUBSIDE,
  STATISTIC_WARNING: COLORS.WARNING,
  STATISTIC_DANGER: COLORS.DANGER,
  STATISTIC_UNKNOWN: COLORS.UNKNOWN,
  STATISTIC_CRITICAL: COLORS.CRITICAL,
}[statistic]);
