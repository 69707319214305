import React, { Fragment, useState } from 'react';
import cn from 'classnames';

import NoContent from '../NoContent'
import LemmsBox from '../LemmsBox'

import { UI, BL } from '/views';
import ContentValue from '../ContentValue';

const Item = props => {
  const { lang, item, index } = props;
  const { content } = item || {};

  let value = null;

  if (content) {
    const h1Tags = content.structurized_tags_h.filter(item => item.type === 'h1');

    if (h1Tags.length > 0) {
      value = h1Tags.map((item, key) => {
        return (
          <div key={key + item.type}><ContentValue lang={lang} value={item.value} /></div>
        );
      });
    } else {
      value = <NoContent text={lang('state_notFound')} />;
    }
  } else {
    value = <NoContent text={lang('state_cantCheck')} />;
  }

  return (
    <tr>
      <td>{index + 1}</td>
      <td className="url">
        <BL.StatusBadge code={item.statusCode} error={item.error} style={{ marginLeft: 0 }} /> <a href={item.url} target="_blank">{item.croppedUrl}</a>
      </td>
      <td>{value}</td>
    </tr>
  );
};

const Table = props => {
  const { lang, list, lemms, showLemms } = props;

  return (
    <Fragment>
      <table className="table table-responsive-md select-default tools_tdh-table-results">
        <thead>
          <tr>
            <th className="tools_tdh-table-number">#</th>
            <th>{lang('col_url')}</th>
            <th>{lang('col_h1')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, key) => <Item key={key} lang={lang} item={item} index={key} />)}
        </tbody>
        <tfoot>
          <tr>
            <th>#</th>
            <th>{lang('col_url')}</th>
            <th>{lang('col_h1')}</th>
          </tr>
        </tfoot>
      </table>

      <LemmsBox className="mt-5" lang={lang} lemms={lemms} showLemms={showLemms} />
    </Fragment>
  );
};

export default Table;
