import React, { Component } from 'react';

import { LangProvider } from '/contexts/Lang';

import Api from '/services/Api';
import Lang from '/services/Lang';
import Access from '/services/Access';

import { getView, getSettings } from '/services/Components/methods';

import Modals from './components/Modals';
import LayoutLocker from './components/LayoutLocker';

import LAYOUTS from '../layouts';

import './styles.scss';

class Root extends Component {
  constructor(props) {
    super(props);

    const { user, lang } = props;

    this.api = new Api(user.token);
    this.lang = new Lang(lang);
    this.access = new Access(user.role);
  }

  shouldComponentUpdate(_props, _state) {
    let equal = true;

    equal = equal && this.props.lang === _props.lang;
    equal = equal && this.props.component === _props.component;
    equal = equal && this.props.action === _props.action;
    equal = equal && this.props.layout === _props.layout;

    equal = equal && this.props.user.role === _props.user.role;

    return !equal;
  }

  componentDidUpdate(_props, _state) {
    const { props } = this;

    props.user.role !== this.access.getRole() && this.access.setRole(props.user.role);
    props.user.token !== this.api.getToken() && this.api.setToken(props.user.token);
  }

  updateLang() {
    const { lang, component, action } = this.props;

    const context = ['components', component, action].join('.');

    this.lang = new Lang(lang, context);
  }

  updateMeta(settings) {
    if (__CLIENT__) try {
      const { component, action } = this.props;

      const settings = getSettings(component, action);

      const { head } = document;

      const title = settings.title(this.lang) || 'Serp.tools';
      const description = settings.description(this.lang) || 'Serp.tools';

      head.querySelector('title').textContent = title;
      head.querySelector('meta[name="description"]').setAttribute('content', description);
    } catch(e) {}
  }

  render() {
    const { path, component, action, layout } = this.props;

    const Layout = LAYOUTS[layout + 'Layout'];
    const View = getView(component, action);

    this.updateLang(); // incorrect to it here, but componentDidUpdate call after render
    this.updateMeta();

    return (
      <LangProvider value={this.lang}>
        <Layout api={this.api} access={this.access} lang={this.lang} View={View} path={path} />
        <Modals />
        <LayoutLocker />
      </LangProvider>
    );
  }
}

export default Root;
