import React, { Component } from 'react';

import { UI, BL } from '/views';

import { deduplicate } from '/utils/array';
import { check as checkLink, getRoot } from '/utils/link';

const ERROR_INVALID_ITEMS = 'INVALID_ITEMS';
const ERROR_DIFFERENT_ROOTS = 'DIFFERENT_ROOTS';

class InsertBox extends Component {
  state = {
    errors: [],
  };

  onSubmit = () => {
    const { insert, onProcess } = this.props;
    const { items, links } = insert;

    const errors = [];

    let roots = links.map(link => getRoot(link));

    items.length !== links.length && errors.push(ERROR_INVALID_ITEMS);

    this.setState({ errors });

    !errors.length && onProcess();
  }

  renderError() {
    const { lang } = this.props;
    const { errors } = this.state;

    if (!errors.length) return null;

    const text = [];

    if (errors.includes(ERROR_INVALID_ITEMS)) {
      text[text.length] = lang('error_incorrectUrl');
    }

    if (errors.includes(ERROR_DIFFERENT_ROOTS)) {
      text[text.length] = lang('error_missmatchUrl');
    }

    return (
      <div className="w-65 color-danger-500">
        {text.map((text, i) => <div key={i}>{text}</div>)}
      </div>
    );
  }

  render() {
    const { lang, lock, insert, hasResult, showLemms, onLinksUpdate, onToggleLemms, linksLimit } = this.props;

    const Button = insert.links.length ? UI.Button.Primary : UI.Button.Secondary;

    const linksCount = deduplicate(insert.links).length;
    const isExcess = linksCount > linksLimit;
    const styleCount = isExcess ? 'text-danger' : '';

    return (
      <BL.Panel>
        <BL.Panel.Content className="p-0">
          <UI.Collapse title={lang('field_title')} expand={!hasResult}>
            <div className="pt-2 pb-3 pl-3 pr-3">
              <UI.Form.TextArea value={insert.text} onChange={onLinksUpdate} style={{ height: '120px' }} />
              {this.renderError()}
              <UI.Form.Checkbox className="custom-switch mt-3" label={lang('field_matchWords')} onChange={onToggleLemms} checked={showLemms}/>
              <div className="mt-2 select-none">
                <span className="mr-2">{lang('field_linksCount', { styleCount, linksCount, linksLimit }, { useParser: true })}</span>
                {isExcess && <b className="text-danger">{lang('notice_tooMuchPages')}.</b>}
              </div>
              <Button className="mt-3" icon2="fal fa-angle-double-right ml-1" spin2={lock} text={lang('button_check')} onClick={this.onSubmit} />
            </div>
          </UI.Collapse>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }
}

export default InsertBox;
