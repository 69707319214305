import React from 'react';
import cn from 'classnames';

import { capitalize } from '/utils/string';

const createJSLink = props => <span {...props} />;
const createLink = props => <a {...props} />;
const createSubmit = props => <button type="submit" {...props} />;

// stl: .btn-primary, .btn-secondary, .btn-success, .btn-info, .btn-warning, .btn-danger, .btn-dark, .btn-light
// size: .btn-lg, .btn-sm, .btn-xs

const iconize = icon => {
  const isFull = icon.split(' ').length >= 2;

  return isFull ? icon : 'fal ' + icon;
};

const reverse = side => ({ left: 'right', right: 'left' }[side]);

const renderIcon = (icon, spin, size, margin) => {
  const side = margin && 'm' + margin.slice(0, 1);
  const offset = margin && (icon || '').indexOf(side + '-') === -1 && `${side}-1`;

  const items = [];

  if (icon) {
    items[items.length] = <span key="icon" className={cn(iconize(icon), offset, spin && 'invisible')} />;
  }

  if (spin) {
    const style = {
      width: size === 'xs' ? '0.65rem' : '0.8rem',
      height: size === 'xs' ? '0.65rem' : '0.8rem',
      top: '50%',
      marginTop: '-0.35rem',
    };

    if (margin) {
      style[reverse(margin)] = {
        sm: '0.6rem',
        xs: '0.5rem',
      }[size] || '0.4rem';
    }

    items[items.length] = <span key="spin" className={cn('spinner-border spinner-border-sm position-absolute', offset)} style={style} />;
  }

  return items;
};

const Button = function Button(props) {
  const {
    stl = 'primary',
    size = 'md',
    icon,
    icon2,
    spin,
    spin2,
    text,
    children,
    ...sets
  } = props;

  let Tag = createJSLink;

  props.type === 'submit' && (Tag = createSubmit);
  props.href && (Tag = createLink);

  sets.className = cn(
    props.className,
    'btn btn-' + stl + ' btn-' + size,
    'position-relative select-none with-action',
    Tag !== createSubmit && sets.disabled && 'disabled'
  );

  Tag !== createSubmit && (delete sets.disabled);

  return (
    <Tag {...sets}>
      {renderIcon(icon, spin, size, (children || text) && 'right')}
      {text || children}
      {renderIcon(icon2, spin2, size, (children || text) && 'left')}
    </Tag>
  );
};

const styles = {
  Primary: { stl: 'primary' },
  Secondary: { stl: 'secondary' },
  Success: { stl: 'success' },
  Info: { stl: 'info' },
  Warning: { stl: 'warning' },
  Danger: { stl: 'danger' },
  Dark: { stl: 'dark' },
  DarkGray: { stl: 'darkgray' },
  Light: { stl: 'light' },
  Link: { stl: 'link' },
  Default: { stl: 'default' },
  Transparent: { stl: 'transparent' },
};

Button.stylize = Target => {
  for (let name in styles) {
    if (!styles.hasOwnProperty(name)) continue;

    Target[name] = props => <Target { ...styles[name] } { ...props } />;
  }

  return Target;
};

export default Button.stylize(Button);
