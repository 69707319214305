import React, { Fragment } from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';

import time2day from '../../utils/time2day';
import getLabelsBySeasons from '../../utils/getLabelsBySeasons';

const displayDate = date => moment(date).format('D MMM YYYY');

const names = {
  yandex: 'Яндекс',
  google: 'Google',
};

const types = {
  yandex: 'info',
  google: 'primary',
};

const updates = {
  yandex: 'warning',
  google: 'info',
};

const collectChartData = items => {
  const { months } = items[0];

  const labels = getLabelsBySeasons(months);

  const lines = items.map(item => {
    const type = types[item.searcher];

    return {
      name: item.searcher,
      label: 'Дневной трафик ' + capitalize(item.searcher),
      pointColor: color[type]._300,
      pointColorHover: color[type]._500,
      color: color[type]._300,
      fill: color.rgba(color[type]._50, 0.4),
      values: [].concat(...item.months.map(month => month.values)),
    };
  });

  return { labels, lines };
};

const settings = {
  legend: {
    hidden: true,
  },
  selection: {
    enabled: true,
    deep: 1,
  },
  marker: {
    type: 'none',
  },
  rows: {
    count: [1, 2],
  },
};

const TrafficGraph = props => {
  const { data, onSelectionChange } = props;

  const chartData = collectChartData(data);

  const events = { onSelectionChange };

  return <UI.LineChart className="pt-3" height={80} data={chartData} settings={settings} events={events} />;
};

export default TrafficGraph;
