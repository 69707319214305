import React, { Component } from 'react';
import cn from 'classnames';

import { BL } from '/views';

import { isObject, isUndefined } from '/utils/types';

class TabItem extends Component {
  onClick = event => {
    event.preventDefault();

    const { idx, onClick } = this.props;

    onClick(idx);
  };

  render() {
    const { name, enabled, active, infoText } = this.props;

    let inner;

    if (enabled) {
      inner = <a href="#" className={cn('nav-link', active && 'active')} onClick={this.onClick}>
        {name}
        {infoText &&
          (<BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
            { infoText }
          </BL.Info.Dark>)
        }
      </a>;
    } else {
      inner = <span className="nav-text">{name}</span>;
    }

    return (
      <li className="nav-item">
        {inner}
      </li>
    );
  }
}

class Tabs extends Component {
  state = {
    active: null
  };

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._active !== _props.active) {
      result._active = _props.active;
      result.active = _props.active;
    }

    return result;
  }

  onClick = idx => {
    const { active } = this.state;
    const { items, onChange } = this.props;

    if (idx === active) return;

    this.setState({ active: idx });

    onChange(idx, items[idx]);
  };

  renderItem = (item, idx) => {
    const { active } = this.state;

    const name = isObject(item) ? item.name : item;
    const infoText = isObject(item) ? item.infoText : null;
    const enabled = isObject(item) && !isUndefined(item) ? item.enabled || true : true;

    const sets = {
      idx,
      key: idx,
      enabled,
      name,
      infoText
    };

    sets.active = idx === active;

    return <TabItem { ...sets } onClick={this.onClick} />;
  };

  render() {
    const { items, style, className } = this.props;

    return (
      <ul className={cn('nav nav-tabs nav-tabs-clean select-none', className)} style={style}>
        {items.map(this.renderItem)}
      </ul>
    );
  }
};

export default Tabs;
