import React from 'react';

import BL from '/views/blocks';

import config from './config';

const Table = props => {
  const { className, ...sets } = props;

  return (
    <div className={className}>
      <BL.Table className="darken" { ...sets } { ...config } />
    </div>
  );
};

export default Table;
