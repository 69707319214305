const renderPoint = (item, idx) => {
  const { bezier, point } = item;

  const type = bezier ? 'C' : (idx ? 'L': 'M');

  let coords = point.join(',');

  if (bezier) {
    coords = bezier.map(v => v.join(',')).join(' ') + ' ' + coords;
  }

  return type + coords;
};

const renderLine = (points, color, width) => {
  points = points.map(renderPoint).join(' ');

  return `<path d="${points}" fill="none" stroke="${color}" stroke-width="${width}" />`;
};

const renderLines = lines => {
  const items = [];

  for (let i = 0, len = lines.length; i < len; i++) {
    const line = lines[i];

    items[items.length] = renderLine(line.points.slice(1, -1), line.color, line.width || 2);
  }

  return items.join('');
};

const renderPath = (points, fill) => {
  points = points.map(renderPoint).join(' ');

  return `<path d="${points}" fill="${fill}" stroke="none" />`;
};

const renderPathes = lines => {
  const items = [];

  for (let i = 0, len = lines.length; i < len; i++) {
    const line = lines[i];

    items[items.length] = renderPath(line.points, line.fill);
  }

  return items.join('');
};

export default function svg_line(lines, renderLine, renderFill) {
  let svg = '';

  svg += '<g>' + (renderFill ? renderPathes(lines) : '') + (renderLine ? renderLines(lines) : '') + '</g>';

  return svg;
};
