import React, { Component, PureComponent } from 'react';
import cn from 'classnames';

import { isFunction } from '/utils/types';

import { extendElements } from '/utils/react';

import './styles.scss';

class Panel extends Component {
  state = {
    showContent: true,
  };

  onMinimize = () => this.setState(state => ({ showContent: !state.showContent }));

  render() {
    const { className } = this.props;

    const children = extendElements(this.props.children, [
      { type: Header, worker: () => ({ onMinimize: this.onMinimize }) },
      { type: Content, worker: () => this.state.showContent ? {} : null },
    ]);

    return <div className={cn('panel', className)}>{children}</div>;
  }
}

class Header extends Component {
  onMinimize = event => {
    event.preventDefault();

    this.props.onMinimize();
  }

  renderMinimaze() {
    return (
      <div className="panel-toolbar">
        <button className="btn btn-panel waves-effect waves-themed" onClick={this.onMinimize} />
      </div>
    );
  }

  renderToolbar() {
    const { minimize } = this.props;

    if (!minimize) return null;

    return (
      <div className="panel-toolbar">
        {minimize && <button className="btn btn-panel waves-effect waves-themed" data-action="panel-collapse" onClick={this.onMinimize} />}
      </div>
    );
  }

  render() {
    const { children, className, minimize, onMinimize, ...sets } = this.props;

    return (
      <div className={cn('panel-hdr', className)} { ...sets }>
        {children}
        {this.renderToolbar()}
      </div>
    );
  }
};

class Content extends PureComponent {
  render() {
    const { children, className, ...sets } = this.props;

    return (
      <div className="panel-container">
        <div className={cn('panel-content', className)}>
          {children}
        </div>
      </div>
    );
  }
};

Panel.Header = Header;
Panel.Content = Content;

export default Panel;
