import React from 'react';

import { BL } from '/views';

const DarkPrompt = ({ children, className = 'ml-1', active = false }) => {
  return (
    <BL.Info.Dark
      className={className}
      side={BL.Info.SIDE_TOP}
      width={320}
      boundingBox=".panel"
      passive={!active}
    >
      {children}
    </BL.Info.Dark>
  );
};

export default DarkPrompt;
