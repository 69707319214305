import { createActions } from 'redux-actions';

export const {
  initUser,
  setUser,
  startSession,
  signin,
  signup,
  restore,
  signout,
  approveUserMail,
  checkRestoreToken,
  restoreUserAccaunt,
} = createActions(
  {
    INIT_USER: (token, remember, defer) => ({ token, remember, defer }),
    SET_USER: ({ id, name, mail, role }) => ({ id, name, mail, role }),
    START_SESSION: ({ token, remember }) => ({ token, remember }),
    SIGNIN: (mail, password, remember, defer) => ({ mail, password, remember, defer }),
    SIGNUP: (name, mail, password, remember, defer) => ({ name, mail, password, remember, defer }),
    RESTORE: (mail, defer) => ({ mail, defer }),
    SIGNOUT: () => ({}),
    APPROVE_USER_MAIL: defer => ({ defer }),
    CHECK_RESTORE_TOKEN: defer => ({ defer }),
    RESTORE_USER_ACCAUNT: (password, defer) => ({ password, defer }),
  }
);
