import React from 'react';

import NoContent from "../../components/NoContent";

const ContentValue = props => {
  const { lang, value } = props;

  if (value === undefined) {
    return <NoContent text={lang('state_cantCheck')} />;
  } else if (value === null) {
    return <NoContent text={lang('state_notFound')} />;
  } else if (value.trim() === '') {
    return <NoContent />;
  }

  return value;
};

export default ContentValue
