import getCellStyle from '../../../../../utils/getCellStyle';
import getVerdictStyle from '../../../../../utils/getVerdictStyle';

const fields = {};

fields.state = item => {
  const { verdict } = item.analytics.overall.visits;

  return getVerdictStyle(verdict);
};

fields.diff_year = item => {
  const { statistic } = item.analytics.total.visits;

  return getCellStyle(statistic);
};

fields.diff_3_month = item => {
  const { statistic } = item.analytics.last3month.visits;

  return getCellStyle(statistic);
};

fields.diff_1_month = item => {
  const { statistic } = item.analytics.year[11].visits;

  return getCellStyle(statistic);
};

fields.downfall = item => {
  const { downfall } = item.analytics.overall.visits;

  return getCellStyle(downfall.statistic);
};

export default (name, item, settings) => !!fields[name] && fields[name](item, settings);
