import moment from 'moment';
import { handleActions } from 'redux-actions';

import {
  addProjects,
  dropProjects,
  changeProjectsStatus,
  addAuditToProject,
  setProjectTypes,
  removeProjectAudit,
  updateProjectStatus,
  updateProjectMirrors,
  updateProjectSitemaps,
  blockProject,
} from './actions';

import initial from './initial';

export default handleActions({
    [addProjects]: _addProjects,
    [dropProjects]: _dropProjects,
    [changeProjectsStatus]: _changeProjectsStatus,
    [addAuditToProject]: _addAuditToProject,
    [setProjectTypes]: _setProjectTypes,
    [removeProjectAudit]: _removeProjectAudit,
    [updateProjectStatus]: _updateProjectStatus,
    [updateProjectMirrors]: _updateProjectMirrors,
    [updateProjectSitemaps]: _updateProjectSitemaps,
    [blockProject]: _blockProject,
}, initial());

function _addProjects (state, { payload }) {
  const items = { ...state.items };

  for (let project, i = 0; project = payload.list[i]; i++) {
    const item = {};

    item.id = project.id;
    item.name = project.name;
    item.host = project.host;
    item.removed = project.removed;
    item.status = project.status;

    item.yandexCounterId = project.yandex_counter_id;
    item.accauntName = project.accaunt_name;

    item.blocked = (project.yandex_blocked && moment(project.yandex_blocked).isAfter(new Date()));

    if (project.last_month_pages && project.last_month_traffic && project.monthly_traffic) {
      const date = project.last_month_date && project.last_month_date.replace(/z$/i, '');

      item.stats = {};

      item.stats.date = date ? new Date(date) : null;
      item.stats.pages = project.last_month_pages;
      item.stats.traffic = project.last_month_traffic;

      const values = (project.monthly_traffic || '').split(',').map(v => +v);

      item.stats.monthlyTraffic = values.map((value, idx) => {
        const name = moment(date).add(-24 + idx, 'month').format('MMM YYYY');

        return { name, value };
      });
    } else {
      item.stats = null;
    }

    item.mirrors = {
      main: project.mirror_main,
      google: project.mirror_google,
      yandex: project.mirror_yandex,
    };

    item.sitemaps = items[item.id] ? items[item.id].sitemaps : null;

    item.settings = {
      siteTypeId: project.site_type_id,
    };

    item.audits = project.audits.map(audit => audit.id);

    items[project.id] = item;
  }

  return { ...state, items };
}

function _dropProjects () {
  return initial();
}

function _changeProjectsStatus (state, { payload }) {
  return { ...state, status: payload.status };
}

function _addAuditToProject(state, { payload }) {
  const { id, audit_id } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id] };

  items[id].audits = [].concat(audit_id, items[id].audits);

  return { ...state, items };
}

function _setProjectTypes(state, { payload }) {
  const { types } = payload;

  return { ...state, types };
}

function _removeProjectAudit(state, { payload }) {
  const { id, auditId } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id] };

  items[id].audits = items[id].audits.filter(aid => aid !== auditId);

  return { ...state, items };
}

function _updateProjectStatus(state, { payload }) {
  const { id, status } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id], status };

  return { ...state, items };
}

function _updateProjectMirrors(state, { payload }) {
  const { id, mirrors } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id], mirrors };

  return { ...state, items };
}

function _updateProjectSitemaps(state, { payload }) {
  let { id, sitemaps } = payload;

  const items = { ...state.items };

  const list = (items[id].sitemaps || []).slice();

  (sitemaps.add || []).forEach(item => {
    const sitemap = list.find(v => v.id === item.id);

    !sitemap && list.push(item);
  });

  (sitemaps.remove || []).forEach(item => {
    const idx = list.findIndex(v => v.id === item.id);

    idx !== -1 && list.splice(idx, 1);
  });

  items[id] = { ...items[id], sitemaps: list };

  return { ...state, items };
}

function _blockProject(state, { payload }) {
  let { id, blocked } = payload;

  const items = { ...state.items };

  items[id] = { ...items[id], blocked: !!blocked };

  return { ...state, items };
}
