export default function updateResult(result, update) {
  update.forEach((item, i) => {
    const idx = result.findIndex(v => v.url === item.url);

    result[idx] = item;

    result[idx].waiting = false;
  });

  return [...result];
};
