const fields = {};

fields.status = item => item.result && item.result[0].statusCode || Infinity;
fields.url = item => item.url;

fields.redirects = item => {
  if (!item.result) return -1;

  const items = item.result.filter(val => val.conclusion && val.conclusion.redirect);

  return items.length;
};

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.url;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
