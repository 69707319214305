import React, { Fragment } from 'react';

import { UI } from '/views';

const fields = {};

fields.name = item => item.name_ru;

fields.state = item => {
  if (item.disabled) {
    return <i className="fal fa-exclamation-circle text-danger fs-12" />;
  }

  return <i className="fal fa-check-circle text-success fs-12" />;
};

fields.date_start = (item) => item.date_start;
fields.date_finish = (item) => item.date_finish;

fields.actions = (item, actions, settings) => {
  const classes = 'pt-1 pb-1 pl-2 pr-2 m-1';

  return (
    <Fragment>
      <UI.Button.Info className={classes} size="xs" icon="fal fa-pen-nib" text="Изменить" onClick={() => actions.onEdit(item.id)} />
      <UI.Button.Danger className={classes} size="xs" icon="fal fa-times mr-1" text="Удалить" onClick={() => actions.onDelete(item.id)} />
    </Fragment>
  );
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
