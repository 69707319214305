export const ERROR = {};

ERROR.ERROR_DIFFERENT_ROOTS = new Error('ERROR_DIFFERENT_ROOTS');

export const PROVIDER_TYPE = {};

PROVIDER_TYPE.MANUAL = 'MANUAL';
PROVIDER_TYPE.INDEX_SITEMAP = 'INDEX_SITEMAP';

export const SITE_TYPE = {};

SITE_TYPE.INDEX_SITEMAP = 'INDEX_SITEMAP';
SITE_TYPE.SITEMAP = 'SITEMAP';
SITE_TYPE.REDIRECT = 'REDIRECT';
SITE_TYPE.UNKNOWN = 'UNKNOWN';

export const DATA_ERRORS = {};

DATA_ERRORS.REDIRECT = 'REDIRECT';
DATA_ERRORS.ERROR_FETCH = 'ERROR_FETCH';
DATA_ERRORS.INCORRECT_XML = 'INCORRECT_XML';
DATA_ERRORS.EMPTY_MAP = 'EMPTY_MAP';
DATA_ERRORS.UNKNOWN_MAP_FORMAT = 'UNKNOWN_MAP_FORMAT';
DATA_ERRORS.INVALID_HOST = 'INVALID_HOST';
DATA_ERRORS.CICLE_INDEX = 'CICLE_INDEX';
DATA_ERRORS.INVALID_MAP_FORMAT = 'INVALID_MAP_FORMAT';
DATA_ERRORS.TOO_MUCH_LINKS = 'TOO_MUCH_LINKS';
DATA_ERRORS.RELATIVE_LINK = 'RELATIVE_LINK';
DATA_ERRORS.RELATIVE_LINKS = 'RELATIVE_LINKS';
DATA_ERRORS.MIXED_PROTOCOLS = 'MIXED_PROTOCOLS';
DATA_ERRORS.MAP_DISALLOWED = 'MAP_DISALLOWED';

DATA_ERRORS.ROBOTS_EMPTY_MAPS = 'ROBOTS_EMPTY_MAPS';
DATA_ERRORS.ROBOTS_WRONG_MIRROR = 'ROBOTS_WRONG_MIRROR';

export default { ERROR, PROVIDER_TYPE, SITE_TYPE, DATA_ERRORS };
