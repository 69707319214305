import React, { Component, Fragment } from 'react';

// import UpdatesTable from './components/UpdatesTable';

import { UI, BL } from '/views';

import Provider from '../../provider';

import services from '/static/data/services';

class SearchKeywords extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null,
    };

    if (__CLIENT__) {
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getSearchKeywordsResult(audit_id, id);
  }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  renderLoader = () => {
    return <div className="p-3 text-center"><UI.Loader /></div>;
  };

  renderContent = () => {
    const sets = {
      items: ['Яндекс', 'Google'],
      child: this.renderTab
    };

    // return <BL.Tabs { ...sets } />;
  };

  render() {
    const { report } = this.state;

    const short = (
      <Fragment>
        <p>В этом отчете собраны запросы по данным Яндекс.Метрики:</p>
        <ul>
          <li>Запросы, которые дали не менее 72 визитов из поиска Яндекс за 2 года.</li>
          <li>Запросы, которые дали не менее 72 визитов из поисковой рекламы в Яндекс.Директ</li>
        </ul>

        <p>Используя эти данные, вы можете дополнить ваше семантическое ядро запросами, которые <b>уже</b> дают поисковый трафик из поиска или рекламы Яндекса. После этого оптимизировать страницы под новые запросы, либо создать недостающие страницы. </p>
      </Fragment>
    );

    const full = (
      <Fragment>
        {short}
        <p>Для удобного анализа запросов вы можете загрузить запросы, которые уже находятся в вашем семантическом ядре, чтобы сервис не предлагал вам запросы, которые вы уже продвигаете.</p>
        <p>Также рекомендуем:</p>
        <ul>
          <li>Указать брендовые запросы (содержащие название вашей компании или сайта). Это поможет сервису в перспективе определять объем брендового трафика и многое другое.</li>
          <li>Указать не интересующие вас запросы. Эта информация сохранится и в последующих аудитах и в подобранных сервисом запросах вы будете видеть <b>только неразобранные запросы</b>.</li>
        </ul>
      </Fragment>
    );

    return (
      <Fragment>
        <BL.Title icon={services.search_keywords.icon} name="Автоподбор запросов">
          <UI.Description short={short} full={full} />
        </BL.Title>

        <BL.Panel>
          <BL.Panel.Content className="p-0">
            {report ? this.renderContent() : this.renderLoader()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default SearchKeywords;
