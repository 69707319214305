import React, { Component } from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

import { deduplicate } from '/utils/array';
import { check as checkLink, getRoot } from '/utils/link';

import { SOURCE } from '../../constants';

const ERROR_WRONG_MIRROR = 'WRONG_MIRROR';
const ERROR_INVALID_LINKS = 'INVALID_LINKS';

class InsertBox extends Component {
  state = {
    message: false,
    found: null,
    added: null,
  };

  collectErrors = () => {
    const { insert, project } = this.props;

    const errors = [];

    const main = project.mirrors.main && project.mirrors.main.slice(0, -1);

    insert.items.length !== insert.links.length && errors.push(ERROR_INVALID_LINKS);

    if (main) {
      !insert.links.every(link => getRoot(link) === main) && errors.push(ERROR_WRONG_MIRROR);
    }

    return errors;
  };

  onSubmit = () => {
    const { lock, onProcess } = this.props;

    !lock && !this.collectErrors().length && onProcess();
  };

  onDecide = () => {
    const { lock, onDecide } = this.props;

    const callback = (found, added) => this.setState({ message: true, found, added });

    !lock && onDecide(callback);
  };

  renderErrors(errors) {
    if (!errors.length) return null;

    const { insert, project } = this.props;

    const text = [];

    if (errors.includes(ERROR_INVALID_LINKS)) {
      text[text.length] = 'В списке ссылок есть неправильный адрес. Адрес так же обязательно должен включать протокол.';
    }

    if (errors.includes(ERROR_WRONG_MIRROR)) {
      text[text.length] = `Все ссылки должны начинаться с главного зеркала - ${project.mirrors.main}`;
    }

    return (
      <div className="color-danger-500">
        {text.map((text, i) => <div key={i}>{text}</div>)}
      </div>
    );
  }

  renderMessage = () => {
    const { message, found, added } = this.state;

    if (!message) return null;

    const Alert = found ? BL.Alert.Success : BL.Alert.Warning;

    const icon = found ? 'fal fa-check-circle' : 'fal fa-exclamation-triangle';
    const text = found
      ? `Найдено карт сайта - ${found}, из них добавлено новых - ${added}.`
      : 'Увы, автоматический алгоритм не смог найти на сайте XML-карты. Пожалуйста, добавьте XML-карты вручную.';

    return (
      <Alert className="mt-2 mb-0">
        <BL.Alert.Icon icon={icon} />
        <BL.Alert.Content>
          {text}
        </BL.Alert.Content>
      </Alert>
    );
  };

  render() {
    const { lock, insert, result, project, onLinksUpdate } = this.props;

    const errors = this.collectErrors();

    const Button = insert.links.length ? UI.Button.Success : UI.Button.Secondary;

    return (
      <div className="mt-3">
        <div>
          Добавить XML-карты сайта
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
            Введите в поле ниже XML-карту/карты вашего сайта по одной в строку и нажмите "Добавить"
          </BL.Info.Dark>
          {
            !!project.mirrors.main && <UI.Button.Success className="ml-3" size="sm" spin={lock === SOURCE.AUTOMATICALY} icon={cn({ 'mr-3': lock === SOURCE.AUTOMATICALY })} onClick={this.onDecide}>
              Найти XML-карты автоматически
              <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
                Нажмите на эту кнопку и мы постараемся автоматически найти XML-карты на вашем сайте
              </BL.Info.Dark>
            </UI.Button.Success>
          }
        </div>

        {this.renderMessage()}

        <div className="mt-2">
          <UI.Form.TextArea value={insert.text} onChange={onLinksUpdate} style={{ height: '120px' }} />

          {this.renderErrors(errors)}

          <div className="mt-2 select-none">
            Количество ссылок на XML-карты сайта: <b>{deduplicate(insert.links).length}</b>.
          </div>

          <Button className="mt-2" size="sm" spin={lock === SOURCE.MANUAL} icon={cn({ 'mr-3': lock === SOURCE.MANUAL })} text="Добавить" onClick={this.onSubmit} />
        </div>
      </div>
    );
  }
}

export default InsertBox;
