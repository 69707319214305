import React, { Component } from 'react';

import Logo from './components/Logo';
import MainMenu from './components/MainMenu';
import AuditMenu from './components/AuditMenu';

const Sidebar = props => {
  const { access, lang, audit, projects, router, user } = props;

  const current = [router.component, router.action];

  return (
    <aside className="page-sidebar nav-function-fixed select-none">
      <Logo lang={lang} />

      <nav className="primary-nav">
        <ul className="nav-menu">
          <AuditMenu access={access} lang={lang} current={current} audit={audit} projects={projects} user={user} />
          <MainMenu access={access} lang={lang} current={current} user={user} />
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
