import React, { Component, Fragment } from 'react';

import Header from './components/Header';
import Footer from '../../common/Footer';

import VIEW from '/views/view';

import './styles.scss';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    VIEW.SIDEBAR.disable();
  }

  render() {
    const { View, api, lang, token, access } = this.props;

    return (
      <div className="layout_default">
        <Header isAuthorized={!!token} lang={lang} access={access} />
        <div className="layout_default-content_wrapper">
          <div className="layout_default-center">
            <View api={api} lang={lang} />
          </div>
        </div>
        <Footer lang={lang} />
      </div>
    );
  }
}

export default DefaultLayout;
