import React from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';

const collectChartData = (stats) => {
  const months_http = [].concat(...stats.http.year);
  const months_https = [].concat(...stats.https.year);

  let labels = months_http.map(month => {
    const date = moment(month.date.from);

    const number = +date.format('M');
    const year = +date.format('YYYY');

    return { number, year, date };
  })

  labels = labels.reduce((result, item, i) => {
    const isSeasonStart = !(item.number % 3);
    const season = Math.floor(item.number % 12 / 3);

    let box = isSeasonStart || !result.length ? {} : result[result.length];

    if (isSeasonStart || !result.length) {
      let { year } = item;

      if (season === 0 && item.number === 12 && i !== labels.length - 1) {
        year = year + '/' + (year - 1999);
      }

       box = result[result.length] = { label: ['Зима', 'Весна', 'Лето', 'Осень'][season] + ' ' + year, items: [] };
    } else {
      box = result[result.length - 1];
    }

    const column = {
      label: capitalize(item.date.format('MMMM YYYY')),
    };

    if (item.number === 1) {
      column.border = {
        left: { width: 5, color: '#4de5d5', dasharray: '0' },
      };
    }

    box.items.push(column);

    return result;
  }, []);

  const https = {
    name: 'https',
    label: 'https',
    pointColor: color.info._300,
    pointColorHover: color.info._500,
    color: color.info._300,
    fill: color.rgba(color.info._50, 0.4),
    values: months_https.map(month => month.value),
  };

  const http = {
    name: 'http',
    label: 'http',
    pointColor: color.danger._300,
    pointColorHover: color.danger._500,
    color: color.danger._300,
    fill: color.rgba(color.danger._50, 0.4),
    values: months_http.map(month => month.value),
  };

  const lines = [https, http];

  return { labels, lines };
};

const settings = {
  marker: {
    type: 'overall',
  },
  popup: {
    label: labels => labels.pop(),
  },
};

const TrafficGraph = props => {
  const { stats } = props;

  const chartData = collectChartData(stats);

  return <UI.LineChart data={chartData} settings={settings} />;
};

export default TrafficGraph;
