import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

import { checkRestoreToken, restoreUserAccaunt } from '../../store/actions';

const route = createRoute({ path: '/user/restore?id=:id&token=:token', layout: 'Default' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapDispatchToProps = { checkRestoreToken, restoreUserAccaunt };

export default { route, view: connect(null, mapDispatchToProps)(view), settings };
