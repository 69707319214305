import React from 'react';

import { BL } from '/views';

import { link } from '/services/Components/methods';

const AlertHttpTraffic = props => {
  const { lang, auditId, reports } = props;

  const report = reports.find(item => item.serviceName === 'https_transition');

  const url = link('Reports.HttpsTransition', { lang, auditId, reportId: report.id });

  return (
    <BL.Alert.Danger className="mt-3">
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        Главное зеркало - https-версия сайта, но поисковый трафик все еще попадает и на http-версию сайта.
        <br />
        Рекомендуем изучить отчет <a href={url}>Переход на https</a> и завершить переход на https.
      </BL.Alert.Content>
    </BL.Alert.Danger>
  );
};

export default AlertHttpTraffic;
