import React, { Fragment } from 'react';
import { DATA_ERRORS } from '../../../../constants';

import { BL } from '/views';

import RobotsDisallowRules from '../../../../../RobotsDisallowRules';

const self = {};

/*
5. Индексирование файла sitemap запрещено HTTP-заголовком X-Robots-Tag (X-Robots-Tag содержит nofollow)
*/

self.REDIRECT = {
  title: {
    error: lang => lang('sitemap_status_redirect'),
  },
  body: {
    error: (lang, item) => {
      const { redirects } = item;

      return (
        <ul className="list m-0" style={{ fontFamily: 'Inter' }}>
          {redirects.map((v, i) => <li className="my-2" key={i}><BL.StatusBadge code={v.statusCode} /> {v.url}</li>)}
        </ul>
      );
    },
  },
};

self.ERROR_FETCH = {
  title: {
    error: (lang, item) => <Fragment>{lang('sitemap_status_fetchError')}<BL.StatusBadge code={item.status} /></Fragment>,
  },
};

self.INCORRECT_XML = {
  title: {
    ok: lang => lang('sitemap_status_correct'),
    error: lang => lang('sitemap_status_incorrect'),
  },
};

self.EMPTY_MAP = {
  title: {
    error: lang => lang('sitemap_status_empty'),
  },
};

self.UNKNOWN_MAP_FORMAT = {
  title: {
    error: lang => lang('sitemap_status_unknown'),
  },
};

self.INVALID_MAP_FORMAT = {
  title: {
    error: lang => lang('sitemap_status_indexIncorrect', {}, { useParser: true }),
  },
};

self.INVALID_HOST = {
  title: {
    error: (lang, item, stats) => lang('sitemap_status_invalidHost', { host: stats.root }, { useParser: true }),
  },
};

self.CICLE_INDEX = {
  title: {
    error: lang => lang('sitemap_status_cycling'),
  },
};

self.RELATIVE_LINKS = {
  title: {
    ok: lang => lang('sitemap_status_noRelatives'),
    error: lang => lang('sitemap_status_hasRelatives'),
  },
  body: {
    error: (lang, item) => {
      const urls = item.links.filter(v => !v.url.startsWith('http://') && !v.url.startsWith('https://'));

      return (
        <ul>
          {urls.map((v, i) => <li key={i}>{v.url}</li>)}
        </ul>
      );
    },
  },
};

self.MAP_DISALLOWED = {
  title: {
    ok: lang => lang('sitemap_status_allowed'),
    error: lang => lang('sitemap_status_disallowed'),
  },
  body: {
    error: (lang, item, robots) => <RobotsDisallowRules items={item.indexing.robots} robots={robots.robots} />,
  },
};

self.TOO_MUCH_LINKS = {
  title: {
    ok: (lang, item) => lang('sitemap_status_size', { count: item.links.length }),
    error: lang => lang('sitemap_status_tooBig'),
  },
};

self.MIXED_PROTOCOLS = {
  title: {
    ok: lang => lang('sitemap_status_sameProtocol'),
    error: lang => lang('sitemap_status_mixedProtocol'),
  },
};

export default self;
