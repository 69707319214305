import React from 'react';
import cn from 'classnames';

const Notice = props => {
  const { className, ...sets } = props;

  return <div className={cn('panel-tag', className)} {...sets} />;
};

export default Notice;
