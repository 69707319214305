import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import ResultTable from '../ResultTable';

import { FILTER } from '../../constants';

class Result extends Component {
  state = {
    filter: FILTER.ALL,
  };

  renderExportToolbar = () => {
    const { id, audit_id, user_id, exportExcelLink } = this.props;

    return (
      <Fragment>
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent href={exportExcelLink} className="btn-custom-excel" download />
      </Fragment>
    );
  };

  renderToolbar = () => {
    const { filter } = this.state;

    const values = [
      { name: 'Все', value: FILTER.ALL },
      { name: 'Только без параметров', value: FILTER.SIMPLE },
      { name: 'Только с параметрами', value: FILTER.PARAMS },
    ];

    const onChange = (name, filter) => this.setState({ filter });

    return (
      <Fragment>
        <span className="mr-2">Источник страниц</span>
        <UI.Form.Select value={filter} values={values} onChange={onChange} />
      </Fragment>
    );
  };

  renderNoContent() {
    return (
      <BL.Panel>
        <BL.Panel.Content className="p-3">
          <BL.Alert.Warning className="mb-0">
            <BL.Alert.Icon icon="fal fa-exclamation-circle" />
            <BL.Alert.Content>К сожалению, при запуске аудита не была указана XML-карта сайта, поэтому у сервиса нет источника данных со списком страниц сайта.</BL.Alert.Content>
          </BL.Alert.Warning>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  renderAlert = () => {
    const { items } = this.props;

    const Alert = items.length ? BL.Alert.Warning : BL.Alert.Success;

    const icon = items.length ? 'fal fa-exclamation-circle' : 'fal fa-check-circle';

    const text = items.length
      ? (
          <Fragment>
            <p>Обнаружено {items.length} страниц с поисковым трафиком, отсутствующих в XML-карте сайта.</p>
            <p className="mb-0">Это не ошибка, просто проверьте список страниц, нет ли там ненужных, неактуальных, заброшенных страниц.</p>
          </Fragment>
        )
      : 'Все страницы с трафиком находятся в XML-карте сайта или в списке добавленных страниц, все в порядке.';

    return (
      <Alert className="mb-3">
        <BL.Alert.Icon icon={icon} />
        <BL.Alert.Content>{text}</BL.Alert.Content>
      </Alert>
    );
  };

  renderTable = () => {
    let { items } = this.props;

    const filter = {
      [FILTER.ALL]: () => true,
      [FILTER.SIMPLE]: item => item.url.indexOf('?') === -1,
      [FILTER.PARAMS]: item => item.url.indexOf('?') !== -1,
    }[this.state.filter];

    items = items.filter(filter);

    return <ResultTable data={items} toolbar={this.renderToolbar} />;
  };

  renderContent() {
    return (
      <BL.Panel>
        <BL.Panel.Header className="d-flex justify-content-between">
          <h6 className="my-0" style={{ fontWeight: 500 }}>Поисковый трафик</h6>
          <div className="my-0">{this.renderExportToolbar()}</div>
        </BL.Panel.Header>
        <BL.Panel.Content className="p-3">
          {this.renderAlert()}
          {this.renderTable()}
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    const { error } = this.props;

    return error ? this.renderNoContent() : this.renderContent();
  }
}

export default Result;
