export const SOURCE = {};

SOURCE.AUTOMATICALY = 'AUTOMATICALY';
SOURCE.ROBOTS = 'ROBOTS';
SOURCE.MANUAL = 'MANUAL';

export const TYPE = {};

TYPE.INDEX_SITEMAP = 'INDEX_SITEMAP';
TYPE.SITEMAP = 'SITEMAP';
TYPE.UNKNOWN = 'UNKNOWN';

export default { SOURCE, TYPE };
