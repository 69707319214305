import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="phrases_col_add" />,
    field: 'action',
    sortable: false,
    style: { width: '60px', maxWidth: '60px', minWidth: '60px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="phrases_col_phrases" />,
    field: 'lemma',
    sortable: true,
    sortFirst: 'asc',
    style: { textAlign: 'left' },
  },
  {
    name: () => <Lang text="phrases_col_count" />,
    field: 'count',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '18%', maxWidth: '18%', minWidth: '18%', textAlign: 'center' },
  },
  {
    name: () => <Lang text="phrases_col_percent" />,
    field: 'core',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '18%', maxWidth: '18%', minWidth: '18%', textAlign: 'center' },
  },
];
