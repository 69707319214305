class Popup {
  constructor(url, name, settings) {
    this.url = url;
    this.name = name;
    this.settings = settings || {};

    this.callbacks = {};
  }

  open() {
    this.target = window.open(this.url, this.name, this.getParams());

    this.target.addEventListener('close', this.onClose);

    return this;
  }

  onClose = () => this.destroy();

  getParams() {
    const { screen } = window;
    const { width, height, status, menubar, toolbar, resizable, scrollbars } = this.settings;

    const params = {};

    params.width = width ? Math.min(width, screen.width) : ~~(screen.width / 3);
    params.height = height ? Math.min(height, screen.height) : ~~(screen.height * 2 / 3);

    params.left = ~~((screen.width - params.width) / 2);
    params.top = ~~((screen.height - params.height) / 3);

    if (!status) params.status = 'no';
    if (!menubar) params.menubar = 'no';
    if (!toolbar) params.toolbar = 'no';
    if (!resizable) params.resizable = 'no';
    if (!scrollbars) params.scrollbars = 'no';

    return Object.entries(params).map(e => e.join('=')).join(',');
  }

  addCallback(name, callback, close = true) {
    this.callbacks[name] = window[name] = (...args) => {
      this.removeCallback(name);

      close && this.destroy();

      callback(...args);
    }

    return this;
  }

  removeCallback(name) {
    delete this.callbacks[name];
    delete window[name];

    return this;
  }

  destroy() {
    for (let name in this.callbacks) {
      if (!this.callbacks.hasOwnProperty(name)) continue;

      this.removeCallback(name);
    }

    this.target && this.target.close();
  }
}

export default Popup;
