const render = item => {
  const [x1, x2, y1, y2, color, width, dasharray] = [item.line[0].x, item.line[1].x, item.line[0].y, item.line[1].y, item.color, item.width, item.dasharray];

  if (!color || !width) return '';

  let svg = '';

  svg += `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" stroke="${color}" stroke-width="${width}"`

  if (dasharray) {
    svg += ` stroke-dasharray="${dasharray}"`;
  }

  svg += ` />`;

  return svg;
};

const renderRows = (rows, isHighlight) => {
  const lines = [];

  for (let row, i = 0; row = rows[i]; i++) {
    const { top, bottom } = row.border;

    !i && top.isHighlight === isHighlight && lines.push(render(top));

    bottom.isHighlight === isHighlight && lines.push(render(bottom));
  }

  return lines.join('');
};

const renderColumns = (columns, deep, isHighlight) => {
  const lines = [];

  for (let column, i = 0; column = columns[i]; i++) {
    const { left, right } = column.border;

    (!i && !deep || left.isHighlight === isHighlight) && lines.push(render(left));

    column.items && lines.push(renderColumns(column.items, deep + 1, isHighlight));

    (!deep || i !== columns.length - 1) && right.isHighlight === isHighlight && lines.push(render(right));
  }

  return lines.join('');
};

export default function svg_axis(rows, columns, isHighlight) {
  let svg = '';

  svg += '<g>' + renderColumns(columns, 0, isHighlight) + '</g>';
  svg += '<g>' + renderRows(rows, isHighlight) + '</g>';

  return svg;
};
