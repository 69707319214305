import React, { Component } from 'react';

import ErrorBoundary from './components/ErrorBoundary';
import Sidebar from './components/Sidebar';
import TopLine from './components/TopLine';

import VIEW from '/views/view';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);

    VIEW.SIDEBAR.enable();
  }

  render() {
    const { View, api, access, lang, path } = this.props;

    return (
      <div className="page-wrapper">
        <div className="page-inner">
          <Sidebar access={access} lang={lang} />

          <div className="page-content-wrapper">
            <TopLine access={access} lang={lang} />

            <main className="page-content">
              <ErrorBoundary path={path} lang={lang}>
                <View api={api} access={access} lang={lang} />
              </ErrorBoundary>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardLayout;
