import React, { Component } from 'react';
import cn from 'classnames';

import { link } from '/services/Components/methods';

import image_user from '/static/images/user/64.png';

class UserMenu extends Component {
  state = {
    isActive: false
  };

  onShow = () => {
    this.setState({ isActive: true });

    document.body.addEventListener('click', this.onHide, false);
  };

  onHide = () => {
    const { isActive } = this.state;

    if (!isActive) return;

    this.setState({ isActive: false });
  };

  onSignout = () => this.props.signout();

  componentWillUnmount() {
    document.body.removeEventListener('click', this.onHide, false);
  }

  render() {
    const { isActive } = this.state;
    const { lang } = this.props;

    const style = { top: '100%', right: 0, left: 'auto' };

    return (
      <div className={cn('position-relative select-none', isActive && 'show')}>
				<a href="#" className="header-icon d-flex align-items-center justify-content-center ml-2" onClick={this.onShow}>
					<img src={image_user} className="profile-image rounded-circle" />
				</a>
				<div className={cn('dropdown-menu dropdown-menu-animated dropdown-lg position-absolute', isActive && 'show')} style={style}>
					<a href={link('User.Settings', { lang })} className="dropdown-item">
						<span>Настройки аккаунта</span>
					</a>

          <div className="dropdown-divider m-0" />

          <a href={link('Home.Root', { lang })} className="dropdown-item fw-500 pt-3 pb-3" onMouseUp={this.onSignout}>
						<span>Выход</span>
					</a>
				</div>
			</div>
    );
  }
}

export default UserMenu;
