import React from 'react';
import cn from 'classnames';

import BL from '/views/blocks';

import config from './config';

const Table = props => {
  const { className, ...sets } = props;
  const _config = { ...config };

  sets.toolbar = () => {
    const copyData = sets.data.map(item => item.url).join('\n');

    return <BL.CopyButton value={copyData} />
  };

  return (
    <div className={cn(className, 'pl-3 pr-3 select-text')}>
      <BL.Table className="darken" { ...sets } { ..._config } />
    </div>
  );
};

export default Table;
