import React, { Component, Fragment } from 'react';
import moment from 'moment';

import { UI } from '/views';

import color from '/utils/color';
import { capitalize } from '/utils/string';

import getPlainChartLabels from '../../../../../../utils/getPlainChartLabels';

const collectYear = year => {
  const result = {};

  result.yearStart = 1 + year.findIndex(month => month.date.from.indexOf('-01-01') !== -1);

  result.head = year.map(month => moment(month.date.from).format('MMM YYYY')).map(capitalize);
  result.head.unshift('');

  result.body = [];

  result.body[0] = year.map(month => month.values.overall);
  result.body[0].unshift('Все');

  result.body[1] = year.map(month => month.values.yandex);
  result.body[1].unshift('Я');

  result.body[2] = year.map(month => month.values.google);
  result.body[2].unshift('G');

  return result;
};

const collectOverall = year => {
  const result = {};

  result.yearStart = 1 + year.findIndex(month => month.dates[0].from.indexOf('-01-01') !== -1);

  result.body = [];

  result.body[0] = year.map(month => month.values.overall.variance);
  result.body[0].unshift('Все');

  result.body[1] = year.map(month => month.values.yandex.variance);
  result.body[1].unshift('Я');

  result.body[2] = year.map(month => month.values.google.variance);
  result.body[2].unshift('G');

  return result;
};

const collectChartData = (item) => {
  const year = item.analytics.year;

  const getter = name => month => month.values[name].variance;

  const labels = getPlainChartLabels(item.analytics.year.map(month => month.dates[0].from));

  const overall = {
    name: 'up',
    label: 'Вырос',
    pointColor: '#2b98ef',
    pointColorHover: '#00b4ff',
    color: '#2b98ef',
    fill: 'rgba(43, 152, 239, 0.2)',
    values: year.map(getter('overall')),
  };

  const yandex = {
    name: 'yandex',
    label: 'Яндекс',
    color: '#ffbe35',
    fill: '#ffd274',
    values: year.map(getter('yandex')),
  };

  const google = {
    name: 'google',
    label: 'Google',
    color: '#ea167a',
    fill: '#fe6bb0',
    values: year.map(getter('google')),
  };

  const datasets = [overall, yandex, google];

  return { labels, lines: [overall], bars: [yandex, google] };
};

class OverallChild extends Component {
  renderTitle = name => <div className="title text-nowrap color-primary-700 mb-2">{name}</div>;

  renderTableHead = (head, yearStart) => {
    if (!head) return null;

    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '8%' };
      } else {
        sets.style = { width: 60, minWidth: 60, maxWidth: 60, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <th { ...sets }>{key ? value : '\u00A0'}</th>;
    };

    return (
      <thead className="year-row">
        <tr>{head.map(cell)}</tr>
      </thead>
    );
  };

  renderTableBody = (body, yearStart) => {
    const cell = (value, key) => {
      const sets = { key };

      if (key) {
        sets.style = { width: '8%' };
      } else {
        sets.style = { width: 60, minWidth: 60, maxWidth: 60, boxSizing: 'border-box', fontSize: '0.7rem' };
      }

      if (key === yearStart) {
        sets.className = 'table-divide-left';
      }

      return <td { ...sets }>{value}</td>;
    };

    const row = (data, key) => <tr key={key}>{data.map(cell)}</tr>;

    return <tbody>{body.map(row)}</tbody>;
  };

  renderTable = data => {
    const { head, body, yearStart } = data;

    return (
      <table className="table darken table-bordered table-layout-fixed table-sm text-center w-100 mb-3">
        {this.renderTableHead(head, yearStart)}
        {this.renderTableBody(body, yearStart)}
      </table>
    );
  };

  renderGraph = () => {
    const { item } = this.props;

    const chartData = collectChartData(item);

    return <UI.LineChart data={chartData} offset={{ left: 60 }} settings={{}} />;
  };

  render() {
    const { item } = this.props;

    const isNew = item.verdict === 'TRAFFIC_NEW';

    const year_0 = collectYear(item.stats.year[0]);
    const year_1 = collectYear(item.stats.year[1]);

    const overall = collectOverall(item.analytics.year);

    return (
      <Fragment>
        {!isNew && this.renderTitle('Предыдущий год')}
        {!isNew && this.renderTable(year_0)}

        {this.renderTitle('Текущий год')}
        {this.renderTable(year_1)}

        {!isNew && this.renderTitle('Разница трафика по месяцам год к году')}
        {!isNew && this.renderTable(overall)}

        {this.renderGraph()}
      </Fragment>
    );
  }
}

export default OverallChild;
