import React, { Component, Fragment } from 'react';
import moment from 'moment';

import NavigationGraph from './components/NavigationGraph';
import TrafficGraph from './components/TrafficGraph';
import UpdatesTable from './components/UpdatesTable';
import UpdatesToggler from './components/UpdatesToggler';

import { UI, BL } from '/views';

import Provider from '../../provider';

import { SEARCER_TYPE, UPDATE_TYPE } from '/components/SearcherUpdates/constants';

import services from '/static/data/services';

import time2day from './utils/time2day';

class SearchAlgoUpdate extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      report: null,
      blacklist: [],
      selection: null,
    };

    if (__CLIENT__) {
      props.reloadUpdates();
      this.reload();
    }
  }

  fetch() {
    const { id, audit_id } = this.props;

    return this.provider.getSearchAlgoUpdateResult(audit_id, id);
  }

  async reload() {
    const report = await this.fetch();

    this.setState({ report });
  }

  onSelectionChange = selection => this.setState({ selection });

  onToggle = (type, state) => {
    let { blacklist } = this.state;

    if (state) {
      blacklist = blacklist.filter(v => v !== type);
    } else {
      blacklist = [...blacklist, type];
    }

    this.setState({ blacklist });
  };

  getData(searcher, selection) {
    const { blacklist } = this.state;

    const data = [searcher.toLowerCase()];

    const items = this.state.report.stats.filter(item => data.includes(item.searcher));

    const from = selection ? time2day(items[0].date.from) + selection[0] : time2day(items[0].date.from);
    const to = selection ? time2day(items[0].date.from) + selection[1] : time2day(items[0].date.to);

    const updates = data.map(name => {
      let items = this.props.updates[name];

      items = items.filter(upd => {
        const _from = time2day(upd.date_start);
        const _to = time2day(upd.date_finish);

        const dc = Math.abs((to + from) / 2 - (_to + _from) / 2);
        const vc = Math.abs(to - from) / 2 + Math.abs(_to - _from) / 2;

        return dc <= vc;
      });

      if (searcher === SEARCER_TYPE.GOOGLE) {
        items = items.filter(item => !blacklist.includes(item.update_type));
      }

      return { name, items };
    });

    return { items, updates };
  }

  renderTab = idx => {
    const { blacklist, selection } = this.state;

    const searcher = [SEARCER_TYPE.YANDEX, SEARCER_TYPE.GOOGLE][idx];

    const { items, updates } = this.getData(searcher, selection);

    return (
      <div className="p-3">
        <TrafficGraph data={items} updates={updates} selection={selection} />
        <NavigationGraph data={items} onSelectionChange={this.onSelectionChange} />
        <UpdatesToggler className="mt-3" searcher={searcher} states={blacklist} onToggle={this.onToggle} />
        <UpdatesTable className="mt-3" data={updates[0].items} />
      </div>
    );
  };

  renderLoader = () => {
    return <div className="p-3 text-center"><UI.Loader /></div>;
  };

  renderContent = () => {
    const sets = {
      items: ['Яндекс', 'Google'],
      child: this.renderTab
    };

    return <BL.Tabs { ...sets } />;
  };

  render() {
    const { updatesReady } = this.props;
    const { report } = this.state;

    return (
      <Fragment>
        <BL.Title icon={services.all_traffic.icon} name="Апдейты поисковых систем" />

        <BL.Panel>
          <BL.Panel.Content className="p-0">
            {report && updatesReady ? this.renderContent() : this.renderLoader()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default SearchAlgoUpdate;
