import React, { Component, Fragment } from 'react';

import Popup from '../../../Popup';

import { ref } from '/utils/react';

import columnFinder from '../../../../utils/columnFinder';
import columnPath from '../../../../utils/columnPath';
import matchLabel from '../../../../utils/matchLabel';

import { MARKER } from '../../../../constants';

const getTotal = columns => columns[columns.length - 1].area.capacity.to + 1;

class Overlay extends Component {
  ref = ref;

  mouseDown = false;

  start = null;
  end = null;

  constructor(props) {
    super(props);

    this.state = {
      hover: null,
      total: getTotal(props.columns),
      selection: null,
    };
  }

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    result.total = getTotal(_props.columns);

    return result;
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  coord2capacity = coord_x => {
    const rect = this.ui.root.getBoundingClientRect();
    const x = Math.max(0, coord_x - rect.x);

    return Math.floor(this.state.total * x / rect.width);
  }

  onMouseDown = event => {
    const { columns, settings, events } = this.props;

    if (!settings.selection.enabled) return;

    this.mouseDown = true;

    const capacity = this.coord2capacity(event.clientX);

    const column = columnFinder(capacity, columns, settings.selection.deep);

    this.start = { ...column.area.capacity };
    this.end = { ...column.area.capacity };

    const { from, to } = this.start;

    this.setState({ selection: [from, to] });

    events.onSelectionChange && events.onSelectionChange([from, to]);

    window.addEventListener('mouseup', this.onMouseUp, false);
  };

  onMouseUp = () => {
    this.mouseDown = false;

    window.removeEventListener('mouseup', this.onMouseUp);
  };

  onLeave = () => {
    this.setState({ hover: null });
  };

  onMove = event => {
    const { total } = this.state;
    const { columns, settings, events } = this.props;

    const { marker, selection } = settings;

    const update = {};

    const capacity = this.coord2capacity(event.clientX);

    if (marker.type === MARKER.OVERALL) {
      if (this.state.hover !== capacity) {
        update.hover = capacity;
      }
    }

    if (selection.enabled && this.mouseDown) {
      if (capacity < this.end.from || this.end.to < capacity) {
        const column = columnFinder(capacity, columns, settings.selection.deep);

        this.end = { ...column.area.capacity };

        const from = Math.min(this.start.from, this.end.from);
        const to = Math.max(this.start.to, this.end.to);

        update.selection = [from, to];
      }
    }

    this.setState(update);

    if (update.selection) {
      events.onSelectionChange && events.onSelectionChange(update.selection);
    }
  };

  onClearSelection = () => {
    const { events } = this.props;

    this.setState({ selection: null });

    events.onSelectionChange && events.onSelectionChange(null);
  };

  renderPopup = () => {
    const { hover } = this.state;
    const { columns, data, settings } = this.props;

    if (hover === null) return null;

    // wrong to match it here but it's the most simple way
    const label = settings.popup.label(matchLabel(hover, columns), columnPath(hover, columns));
    const { boundary, area } = columnFinder(hover, columns);

    const top = boundary.top;
    const left = Math.round(area.x[0] + area.width / area.capacity.size * (hover + 0.5 - area.capacity.from));

    const lines = data.lines.map(item => ({ type: 'lines', name: item.label, value: item.points[hover + 2].value, color: item.color, fill: item.fill }));
    const bars = data.bars.map(item => ({ type: 'bars', name: item.label, value: item.boxes[hover].value, color: item.color }));

    const values = [...lines, ...bars];

    return (
      <div style={{ position: 'absolute', top, left, zIndex: 4 }}>
        <Popup label={label} values={values} />
      </div>
    );
  };

  renderColumn = () => {
    const { hover } = this.state;
    const { columns, settings } = this.props;

    if (hover === null) return null;

    const current = columnFinder(hover, columns);

    const style = {
      top: 0,
      left: current.boundary.left,
      width: current.area.width,
      height: current.area.y[1],
      backgroundColor: settings.marker.column.color,
    };

    return <div className="ui_line_chart-chart_marker_column" style={style} />;
  };

  renderSelection = () => {
    const { selection } = this.state;
    const { columns, settings } = this.props;

    if (!selection) return null;

    const from = columnFinder(selection[0], columns, settings.selection.deep);
    const to = columnFinder(selection[1], columns, settings.selection.deep);

    const areaStyle = {
      top: 0,
      left: from.boundary.left,
      width: to.boundary.right - from.boundary.left,
      height: from.area.y[1],
      borderLeft: settings.selection.border + ' 1px solid',
      borderRight: settings.selection.border + ' 1px solid',
      backgroundColor: settings.selection.color,
    };

    const removeStyle = {
      left: from.boundary.left + (to.boundary.right - from.boundary.left) / 2,
    };

    return (
      <Fragment>
        <div className="ui_line_chart-chart_selection" style={areaStyle} />
        <div className="ui_line_chart-chart_selection_clear fal fa-times" style={removeStyle} onClick={this.onClearSelection} />
      </Fragment>
    );
  };

  render() {
    const { columns, settings } = this.props;

    const { marker, selection } = settings;

    const first = columns[0];
    const last = columns[columns.length - 1];

    const style = {
      top: first.area.y[0],
      left: first.area.x[0],
      width: last.area.x[1] - first.area.x[0],
      height: last.area.y[1] - first.area.y[0],
    };

    return (
      <Fragment>
        {this.renderPopup()}
        {this.renderColumn()}
        {this.renderSelection()}
        <div
          ref={this.ref('root')}
          className="ui_line_chart-chart_overlay"
          style={style}
          onMouseDown={this.onMouseDown}
          onMouseLeave={this.onLeave}
          onMouseMove={this.onMove}
        />
      </Fragment>
    );
  }
}

export default (columns, lines, bars, settings, events) => <Overlay columns={columns} data={{ lines, bars }} settings={settings} events={events} />;
