import React from 'react';

import { link } from '/services/Components/methods';

import { BL } from '/views';

const NoContent = props => {
  const { lang } = props;

  const url = link('Settings.Accesses', { lang });

  return (
    <BL.Alert>
      <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
      <BL.Alert.Content>
        <div className="fs-10">Нет подключеных проектов</div>
        <div>Для того чтобы подключить проекты перейдите на <a href={url}>страницу доступов</a> и добавьте Яндекс аккаунт с подключенными сайтами.</div>
      </BL.Alert.Content>
    </BL.Alert>
  );
};

export default NoContent;
