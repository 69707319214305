import React, { Fragment, useState } from 'react';
import cn from 'classnames';

import Headers from '../Headers';
import NoContent from '../NoContent';
import LemmsBox from '../LemmsBox';

import { UI, BL } from '/views';

const Item = props => {
  const { lang, item, expanded } = props;
  const { content } = item || {};

  return (
    <div className="col-sm-6 pb-3 mb-3 border-bottom">
      <div className="url- mb-3">
        <BL.StatusBadge className="ml-0" code={item.statusCode} error={item.error}/> <a href={item.url} target="_blank">{item.croppedUrl}</a>
      </div>
      {content ? <Headers lang={lang} tree={content.structurized_tags_h} expanded={expanded} /> : <NoContent text={lang('state_cantCheck')} />}
    </div>
  );
};

const Table = props => {
  const { lang, list, lemms, showLemms } = props;
  const [expanded, setExpanded] = useState(true);

  const toggleExpand = () => setExpanded(!expanded);

  return (
    <Fragment>
      <table className="table table-responsive-md select-default tools_tdh-table-results">
        <thead>
          <tr>
            <th>{lang('col_url')} <UI.Button.Link className="p-0 ml-2" onClick={toggleExpand}>{lang('button_toggleAll')}</UI.Button.Link></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="row">
              {list.map((item, key) => <Item key={key} lang={lang} item={item} index={key} expanded={expanded}/>)}
            </td>
          </tr>
        </tbody>
      </table>

      <LemmsBox className="mt-5" lang={lang} lemms={lemms} showLemms={showLemms}/>
    </Fragment>
  );
};

export default Table;
