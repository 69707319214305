const fields = {};

fields.word = item => item.word;
fields.queries = item => item.count;

fields.state = item => {
  const { verdict } = item.analytics.overall.visits;

  const states = {
    TRAFFIC_GROW: 3,
    TRAFFIC_DROP: 1,
    TRAFFIC_ZERO: 0,
    TRAFFIC_STABLE: 2,
    TRAFFIC_UNMATCH: -1
  };

  return states[verdict];
};

fields.year_0 = item => item.stats.total[0].visits;
fields.year_1 = item => item.stats.total[1].visits;

fields.diff_year = item => item.analytics.total.visits.variance;
fields.diff_3_month = item => item.analytics.last3month.visits.variance;
fields.diff_1_month = item => item.analytics.year[11].visits.variance;

fields.downfall = (item, settings) => item.analytics.overall.visits.downfall.variance;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.word;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
