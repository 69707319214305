import React, { Component, Fragment } from 'react';

import Messanger from '/components/Conversations/views/Messanger';

import { UI, BL, VIEW } from '/views';

import './styles.scss';

class UserMessanger extends Component {
  render() {
    const { id } = this.props;

    // <BL.Title icon="fal fa-user-headset" name="Служба поддержки" />

    return (
      <Fragment>
        <div className="support_chat-root border-faded shadow-4">
          <Messanger id={+id} />
        </div>
      </Fragment>
    );
  }
}

export default UserMessanger;
