import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, UN } from '/views';

import { SEARCER_TYPE, UPDATE_TYPE, UPDATE_NAME } from '/components/SearcherUpdates/constants';

import { isEmpty } from '/utils/object';

class EditForm extends Component {
  constructor(props) {
    super(props);

    this.Form = UN.Form();

    this.state = {
      lock: false,
    };
  }

  onChange = (name, value) => {
    name === 'searcher' && this.forceUpdate();
  };

  onSubmit = values => this.props.onSubmit(values);

  render() {
    const { Form } = this;
    const { lock } = this.state;
    const { id, data: income, onCancel } = this.props;

    const data = isEmpty(Form.data) ? income : Form.data;

    const searchers = [
      { name: '', value: null },
      { name: 'Яндекс', value: SEARCER_TYPE.YANDEX },
      { name: 'Google', value: SEARCER_TYPE.GOOGLE },
    ];

    const types = [
      { name: '', value: null },
      { name: UPDATE_NAME[UPDATE_TYPE.CORE_UPDATE], value: UPDATE_TYPE.CORE_UPDATE },
      { name: UPDATE_NAME[UPDATE_TYPE.SPAM_UPDATE], value: UPDATE_TYPE.SPAM_UPDATE },
      { name: UPDATE_NAME[UPDATE_TYPE.LINK_SPAM_UPDATE], value: UPDATE_TYPE.LINK_SPAM_UPDATE },
      { name: UPDATE_NAME[UPDATE_TYPE.PRODUCT_REVIEWS_UPDATE], value: UPDATE_TYPE.PRODUCT_REVIEWS_UPDATE },
      { name: UPDATE_NAME[UPDATE_TYPE.LOCAL_SEARCH_UPDATE], value: UPDATE_TYPE.LOCAL_SEARCH_UPDATE },
      { name: UPDATE_NAME[UPDATE_TYPE.OTHER_UPDATE], value: UPDATE_TYPE.OTHER_UPDATE },
    ];

    return (
      <Fragment>
        <Form className="d-flex flex-column justify-content-between" onChange={this.onChange} onSubmit={this.onSubmit}>
          <div>
            <div className="form-group input-group-sm mb-3">
              <Form.Select className="w-65" name="searcher" value={data.searcher} values={searchers} label="Поисковая система" required />
            </div>

            {
              data.searcher === SEARCER_TYPE.GOOGLE &&
              <div className="form-group input-group-sm my-3">
                <Form.Select className="w-65" name="update_type" value={data.update_type} values={types} label="Тип апдейта" />
              </div>
            }

            <div className="form-group input-group-sm my-3">
              <Form.Text className="w-65" name="name_ru" value={data.name_ru} prepend="RU" label="Название" required />
              <Form.Text className="w-65" name="name_en" value={data.name_en} prepend="EN" />
            </div>

            <div className="form-group input-group-sm my-3">
              <Form.Text className="w-65" name="date_start" value={data.date_start} prepend="Начала" label="Дата" required />
              <Form.Text className="w-65" name="date_finish" value={data.date_finish} prepend="Окончания" required />
            </div>

            <div className="form-group input-group-sm my-3">
              <Form.Text className="w-65" name="confirm_date_start" value={data.confirm_date_start} prepend="Начала" label="Ссылка на подтверждение" />
              <Form.Text className="w-65" name="confirm_date_finish" value={data.confirm_date_finish} prepend="Окончания" />
            </div>

            <div className="form-group input-group-sm my-3">
              <Form.Text className="w-65" name="comment_ru" value={data.comment_ru} prepend="RU" label="Комментарий" />
              <Form.Text className="w-65" name="comment_en" value={data.comment_en} prepend="RU" />
            </div>

            <div className="form-group input-group-sm my-3">
              <Form.Text className="w-65" name="description_link_ru_1" value={data.description_link_ru_1} prepend="RU 1" label="Ссылка на пояснение RU" />
              <Form.Text className="w-65" name="description_link_ru_2" value={data.description_link_ru_2} prepend="RU 2" />
            </div>

            <div className="form-group input-group-sm mb-3">
              <Form.Text className="w-65" name="description_link_en_1" value={data.description_link_en_1} prepend="EN 1" label="Ссылка на пояснение EN" />
              <Form.Text className="w-65" name="description_link_en_2" value={data.description_link_en_2} prepend="EN 2" />
            </div>

            <div className="custom-control custom-checkbox mb-2">
              <Form.Checkbox name="confirmed" value={data.confirmed} label="Подтвержденный" />
            </div>

            <div className="custom-control custom-checkbox mb-2">
              <Form.Checkbox name="disabled" value={data.disabled} label="Скрыть" />
            </div>
          </div>
          <div>
            <div>
              <Form.Submit size="sm" spin2={lock} text="Сохранить" />
              <UI.Button.Light className="ml-2" size="sm" text="Отмена" onClick={onCancel} />
            </div>
          </div>
        </Form>
      </Fragment>
    );
  }
}

export default EditForm;
