import React from 'react';
import cn from 'classnames';

import BL from '/views/blocks';

import config from './config';

const UpdatesTable = props => {
  const { className, ...sets } = props;

  return <BL.Table className={cn('darken', className)} { ...sets } { ...config } />;
};

export default UpdatesTable;
