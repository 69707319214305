import { getPicker } from '/utils/object';

class Provider {
  constructor(api) {
    this.api = api;
  }

  lemmatize(text) {
    const { api } = this;

    return api.tools.lemmer(text);
  }

  lemmerExport(result, lang) {
    const { api } = this;

    const picker = getPicker('lemma', 'count', 'percent');

    const words = result.words.map(picker);
    const ngrams = Object.values(result.ngrams).reduce((r, v) => [].concat(r, v), []).map(picker);

    ngrams.sort((a, b) => {
      let _a = a.count;
      let _b = b.count;

      if (_a === _b) {
        _a = a.lemma.split(' ').length;
        _b = b.lemma.split(' ').length;
      }

      if (_a === _b) {
        _a = a.lemma;
        _b = b.lemma;
      }

      if (_a < _b) return 1;
      if (_a > _b) return -1;

      return 0;
    });

    return api.tools.lemmerExport(words, ngrams, lang);
  }

  serverResponse(links, ua) {
    const { api } = this;

    return api.tools.serverResponse(links, ua);
  }

  serverResponseExport(result, lang) {
    const { api } = this;

    const links = result.map(item => item.result.map(block => {
      const result = {};

      result.url = block.url;
      result.code = block.statusCode;
      result.redirect = block.conclusion.redirect;
      result.isRedirect = !!block.conclusion.redirect;
      result.isCycle = !!block.isCycle;
      result.isError = !!block.error;

      return result;
    }));

    return api.tools.serverResponseExport(links, lang);
  }

  sitemapper(links) {
    const { api } = this;

    return api.tools.sitemapper(links);
  }

  sitemapperExport(result, lang) {
    const { api } = this;

    let { main, items } = result;

    items = items.map(({ url, type, links }) => ({ url, type, links }));

    return api.tools.sitemapperExport(main, items, lang);
  }

  titleDescriptionH1(links) {
    const { api } = this;

    return api.tools.titleDescriptionH1(links);
  }

  titleDescriptionH1Export(items, lang) {
    const { api } = this;

    items = items.map(item => {
      let { url, statusCode, content } = item;
      let { title = '', description = '', tags_h = [] } = content || {};

      content = { title, description, tags_h };

      return { url, statusCode, content };
    });

    return api.tools.titleDescriptionH1Export(items, lang);
  }

  indexChecker(links, searchers) {
    const { api } = this;

    return api.tools.indexChecker(links, searchers);
  }

  indexCheckerExport(items, searchers, lang) {
    const { api } = this;

    return api.tools.indexCheckerExport(items, searchers, lang);
  }

  robotsChecker(links) {
    const { api } = this;

    return api.tools.robotsChecker(links);
  }
}

export default Provider;
