import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import Chart from './components/Chart';
import Table from './components/Table';

import { deduplicate } from '/utils/array';
import { check } from '/utils/link';

import { CHECK_YANDEX, CHECK_GOOGLE } from './constants';

import './styles.scss';

class ToolsUrlIndexing extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      insert: {
        text: '',
        links: {
          all: [],
          valid: [],
          invalid: [],
        },
        searchers: [CHECK_YANDEX, CHECK_GOOGLE],
      },
      result: {
        items: [],
        searchers: [],
      },
    };
  }

  onSearcherChange = (name, value) => {
    this.setState(state => {
      const insert = { ...state.insert };

      insert.searchers = [...insert.searchers];

      if (value) {
        !insert.searchers.includes(name) && (insert.searchers = [...insert.searchers, name]);
      } else {
        insert.searchers = insert.searchers.filter(v => v !== name);
      }

      return { ...state, insert };
    });
  };

  onStartTextChange = (name, text) => {
    let all = text.split('\n').map(v => v.trim()).filter(v => !!v);

    all = deduplicate(all);

    const links = all.reduce((result, link) => {
      const target = check(link) ? 'valid' : 'invalid';

      result[target].push(link);

      return result;
    }, { all, valid: [], invalid: [] });

    const insert = { ...this.state.insert, text, links };

    this.setState({ insert });
  };

  onProcess = () => {
    const { lock, insert } = this.state;

    if (lock || !insert.links.valid.length || !insert.searchers.length) return;

    this.setState({ lock: true });

    this.process();
  };

  async process() {
    const links = this.state.insert.links.all;
    const searchers = this.state.insert.searchers;

    const { result } = await this.provider.indexChecker(links, searchers);

    this.setState({ result: { searchers, items: result }, lock: false });
  }

  renderInsertBox() {
    const { lock, insert, result } = this.state;

    const sets = { lock, insert, result };

    sets.onStartTextChange = this.onStartTextChange;
    sets.onSearcherChange = this.onSearcherChange;
    sets.onProcess = this.onProcess;

    return <InsertBox { ...sets } />;
  }

  renderToolbar = () => {
    const { id } = this.props;
    const { result, lock } = this.state;

    const { searchers, items } = result;

    return (
      <div className="mr-2">
        <span className="mr-2">Экспорт:</span>
        <UI.Button.Transparent disabled={lock} onClick={() => !lock && this.provider.indexCheckerExport(items, searchers)} className="btn-custom-excel" download />
      </div>
    );
  };

  collectChartData = searcher => {
    const good = { name: 'Есть', value: 0, color: '#1dc9b7' };
    const warning = { name: 'Зеркало', value: 0, color: '#ffc241' };
    const danger = { name: 'Нет', value: 0, color: '#fd3995' };

    const { items } = this.state.result;

    for (let item, i = 0; item = items[i]; i++) {
      const data = item.result[searcher];

      if (!data) continue;

      if (!data.exists) {
        danger.value += 1;
      } else if (data.errors.length) {
        warning.value += 1;
      } else {
        good.value += 1;
      }
    }

    return [good, warning, danger];
  };

  renderYandexChart = () => {
    const { result, statuses } = this.state;

    if (!result.items.length || !result.searchers.includes(CHECK_YANDEX)) return null;

    const items = this.collectChartData('yandex');

    const margin = result.searchers.includes(CHECK_GOOGLE) ? '0.5rem' : 0;

    return (
      <div style={{ flexGrow: 1, marginRight: margin }}>
        <h5 className="mb-2 pt-2 text-center font-weight-bold">Яндекс</h5>
        <Chart items={items} />
      </div>
    );
  };

  renderGoogleChart = () => {
    const { result, statuses } = this.state;

    if (!result.items.length || !result.searchers.includes(CHECK_GOOGLE)) return null;

    const items = this.collectChartData('google');

    const margin = result.searchers.includes(CHECK_YANDEX) ? '0.5rem' : 0;

    return (
      <div style={{ flexGrow: 1, marginLeft: margin }}>
        <h5 className="mb-2 pt-2 text-center font-weight-bold">Google</h5>
        <Chart items={items} />
      </div>
    );
  };

  renderChart() {
    const { result, statuses } = this.state;

    if (!result.items.length) return null;

    return (
      <BL.Panel className="mb-3">
        <BL.Panel.Header>
          <h2>Статистика индексации страниц в поисковых системах</h2>
        </BL.Panel.Header>
        <BL.Panel.Content>
          <div className="d-flex">
            {this.renderYandexChart()}
            {this.renderGoogleChart()}
          </div>
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  renderResult() {
    const { result, statuses } = this.state;

    if (!result.items.length) return null;

    const { items, searchers } = result;

    return (
      <BL.Panel className="mb-0">
        <BL.Panel.Header className="d-flex justify-content-between">
          <h2>Результат</h2>
          {this.renderToolbar()}
        </BL.Panel.Header>
        <BL.Panel.Content>
          <Table data={items} searchers={searchers} />
        </BL.Panel.Content>
      </BL.Panel>
    );
  }

  render() {
    return (
      <Fragment>
        <BL.Title icon="fal fa-clipboard-list-check" name="Проверка индексации страниц в Google и Яндекс">
          С помощью этого инструмента можно массово проверить наличие страниц сайта в индексе Google и/или Яндекс.
        </BL.Title>

        {this.renderInsertBox()}
        {this.renderChart()}
        {this.renderResult()}
      </Fragment>
    );
  }
}


export default ToolsUrlIndexing;
