import { handleActions } from 'redux-actions';

import { addAudit, addAudits, setCurrentAudit, createAudit, removeAudit, clearAudits, setAuditReports, updateRunningAuditStates } from './actions';
import initial from './initial';

export default handleActions({
    [addAudit]: _addAudit,
    [addAudits]: _addAudits,
    [setCurrentAudit]: _setCurrentAudit,
    [removeAudit]: _removeAudit,
    [clearAudits]: _clearAudits,
    [setAuditReports]: _setAuditReports,
    [updateRunningAuditStates]: _updateRunningAuditStates,
}, initial());

function _addAudits (state, { payload }) {
  const items = { ...state.items };

  for (let audit, i = 0; audit = payload.list[i]; i++) {
    const current = items[audit.id] || {};

    current.id = audit.id;
    current.projectId = audit.project_id;
    current.percent = audit.percent;
    current.ready = audit.ready;
    current.dateAdd = audit.date_add;
    current.dateStart = audit.date_start;
    current.dateFinish = audit.date_finish;

    const currentReports = current.reports || [];

    const filteredReports = (audit.reports || []).filter(item => {
      return !currentReports.find(r => r.serviceName === item.serviceName);
    });

    current.reports = [...currentReports, ...filteredReports];

    items[audit.id] = current;
  }

  return { ...state, items };
}

function _setCurrentAudit (state, { payload }) {
  return { ...state, current: payload.id };
}

function _addAudit (state, { payload }) {
  const items = { ...state.items };

  const { id } = payload;

  items[id] = payload;

  return { ...state, items };
}

function _removeAudit (state, { payload }) {
  const { id } = payload;

  const current = state.current !== id ? state.current : null;
  const items = { ...state.items };

  delete items[id];

  return { ...state, current, items };
}

function _clearAudits () {
  return initial();
}

function _setAuditReports(state, { payload }) {
  const { id, reports } = payload;

  const items = { ...state.items };

  items[id] = { ...(items[id] || {}), reports };

  return { ...state, items };
}

function _updateRunningAuditStates(state, { payload }) {
  const items = { ...state.items };

  for (let item, i = 0; item = payload.items[i]; i++) {
    items[item.id] = { ...items[item.id], ...item };
  }

  return { ...state, items };
}
