import React, { Fragment } from 'react';

const fields = {};

fields.lemm = item => {
  const lemms = item.forms.join(', ');

  return (
    <Fragment>
      {item.lemm} <i>({lemms})</i>
    </Fragment>
  );
};

fields.queries = item => item.count + '(' + item.percent + '%)';

fields.state = item => {
  const { verdict } = item.analytics.overall.visits;

  const states = {
    TRAFFIC_GROW: 'Вырос',
    TRAFFIC_DROP: 'Упал',
    TRAFFIC_ZERO: 'Упал в 0',
    TRAFFIC_STABLE: 'На месте',
    TRAFFIC_UNMATCH: 'Отсутствует'
  };

  return <span className="d-block text-truncate w-100">{states[verdict]}</span>;
};

fields.year_0 = item => item.stats.total[0].visits;
fields.year_1 = item => item.stats.total[1].visits;

fields.diff_year = item => {
  const { variance, percent } = item.analytics.total.visits;

  return variance + ' (' + percent + '%)';
};

fields.diff_3_month = item => {
  const { variance, percent } = item.analytics.last3month.visits;

  return variance + ' (' + percent + '%)';
};

fields.diff_1_month = item => {
  const { variance, percent } = item.analytics.year[11].visits;

  return variance + ' (' + percent + '%)';
};

fields.downfall = item => {
  const { downfall } = item.analytics.overall.visits;

  return downfall.variance;
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
