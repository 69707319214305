import React, { Component } from 'react';
import cn from 'classnames';
import moment from 'moment';

import { link } from '/services/Components/methods';

import { UI } from '/views';

import renderBadge from '../../../utils/renderBadge';

const renderActions = (lang, project, audit, actions) => {
  if (!audit.ready) return null;

  const items = [];

  const classes = 'pt-1 pb-1 pl-2 pr-2 m-1';

  const url = link('Audit.Reports', { lang, auditId: audit.id });

  items[items.length] = <UI.Button.Info key="res" className={classes} size="xs" icon="fal fa-ballot-check mr-1" text="Просмотр" href={url} />;
  items[items.length] = <UI.Button.Danger key="del" className={classes} size="xs" icon="fal fa-times mr-1" text="Удалить" onClick={() => actions.onAuditRemove(project.id, audit.id)} />;

  return items;
};

class Child extends Component {
  renderItem = audit => {
    const { item, actions, settings } = this.props;

    const { id, ready, percent } = audit;
    const { lang } = settings;

    const dateAdd = moment(audit.dateAdd).format('D MMMM YYYY HH:mm:ss');
    const dateFinished = ready ? moment(audit.dateFinish).format('D MMMM YYYY HH:mm:ss') : '-';

    return (
      <tr key={audit.id} style={{ height: '43px' }}>
        <td>{dateAdd}</td>
        <td>{dateFinished}</td>
        <td>{renderBadge(audit)}</td>
        <td>{renderActions(lang, item, audit, actions)}</td>
      </tr>
    );
  };

  render() {
    const { audits } = this.props.item;

    return (
      <div>
        <div className="title color-inof-700 mb-3 fs-9">
          История аудитов:
        </div>
        <table className="table darken table-bordered table-sm text-center col-10" style={{ maxWidth: 730 }}>
          <thead>
            <tr>
              <th className="p-2" style={{ width: '185px' }}>Дата добавления</th>
              <th className="p-2" style={{ width: '185px' }}>Дата завершения</th>
              <th className="p-2" style={{ width: '60px' }}>Статус</th>
              <th className="p-2">Действия</th>
            </tr>
          </thead>
          <tbody>
            {audits.map(this.renderItem)}
          </tbody>
        </table>
      </div>
    );
  }
}

const child = function child(item, actions, settings) {
  return <Child item={item} actions={actions} settings={settings} />;
}

child.check = function check(item) {
  return item.audits && item.audits.length >= 1;
};

export default child;
