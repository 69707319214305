export const SEARCER_TYPE = {
  YANDEX: 'YANDEX',
  GOOGLE: 'GOOGLE',
};

export const UPDATE_TYPE = {
  CORE_UPDATE: 'CORE_UPDATE',
  SPAM_UPDATE: 'SPAM_UPDATE',
  LINK_SPAM_UPDATE: 'LINK_SPAM_UPDATE',
  PRODUCT_REVIEWS_UPDATE: 'PRODUCT_REVIEWS_UPDATE',
  LOCAL_SEARCH_UPDATE: 'LOCAL_SEARCH_UPDATE',
  OTHER_UPDATE: 'OTHER_UPDATE',
};

export const UPDATE_NAME = {
  [UPDATE_TYPE.CORE_UPDATE]: 'Core Update',
  [UPDATE_TYPE.SPAM_UPDATE]: 'Spam Update',
  [UPDATE_TYPE.LINK_SPAM_UPDATE]: 'Link Spam Update',
  [UPDATE_TYPE.PRODUCT_REVIEWS_UPDATE]: 'Product Reviews Update',
  [UPDATE_TYPE.LOCAL_SEARCH_UPDATE]: 'Local Search Update',
  [UPDATE_TYPE.OTHER_UPDATE]: 'Other update',
};

export default { SEARCER_TYPE, UPDATE_TYPE, UPDATE_NAME };
