const self = {};

self.head_title = 'SEO Tools & Web Analytics Based SEO Audit - SERP.tools';
self.head_keywords = '';
self.head_description = 'SERP.tools ® syncs with Google Analytics and Search Console to give you a web analytics based audit & plan to improve your SEO + 5 free Premium SEO Tools';

self.hero_start_soon = 'Coming in 2023';
self.hero_title = 'Get web analytics based growth plan to improve your SEO';

self.hero_item_1 = 'Unique web analytics based SEO audit.';
self.hero_item_2 = 'Takes only 60 seconds to start.';
self.hero_item_3 = 'Find organic traffic problems and get growth plan for your website.';
self.hero_item_4 = 'You save 8-10 hours of manual analysis.';

export default self;
