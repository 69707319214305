import React, { Fragment } from 'react';

import { BL } from '/views';

import refreshImage from './yandex_refresh.png';

const content = {};

content.SERVICE_UNAVAILABLE = {
  title: () => 'Сервис недоступен',
  message: () => <p>К сожалению, мы не можем запустить аудит. В данный момент нам не удалось проверить доступность данных счётчика. Попробуйте запустить аудит позже.</p>,
};

content.REFRESH_ACCESS = {
  title: () => 'Обновите доступы',
  message: props => <p>К сожалению, мы не можем запустить аудит. Закончился срок действия доступа к аккаунту <b>{props.accauntName}</b>, обновите доступ в настройках доступов.</p>,
};

content.ACCESS_DENIED = {
  title: () => 'Проект не найден',
  message: () => <p>К сожалению, мы не можем запустить аудит. Счетчик не найден в привязанных аккаунтах Метрики. Верните доступ для одного из привязанных аккаунтов и обновите список проектов снова.</p>,
};

content.COUNTER_ERROR = {
  title: () => 'Ошибка установки счётчика',
  message: () => <p>К сожалению, мы не можем запустить аудит. У сайта нет трафика за последний месяц, возможно, проблема с установкой счетчика Метрики. Пожалуйста, проверьте счетчик <a href="https://yandex.ru/support/metrica/general/check-counter.html" target="_blank">по инструкции от Яндекс.Метрики</a>. Если с ним все в порядке, а аудит не запускается, напишите в Поддержку.</p>,
};

content.QUOTAS_EXCEEDED = {
  title: () => 'Превышена квота',
  message: () => (
    <Fragment>
      <p>К сожалению, мы не можем запустить аудит. Яндекс.Метрика дает квоту в 5000 запросов к одному счетчику и для вашего счетчика они уже потрачены. Квота могла быть превышена:</p>
      <ol>
        <li>Если вы уже делали сегодня аудит этого проекта.</li>
        <li>Если какой-то еще сервис обращается по API к счетчику этого сайта и тратит лимиты, выделенные Яндексом.</li>
      </ol>

      <p>Пожалуйста, попробуйте запустить аудит завтра, предварительно обновив список проектов, чтобы кнопка запуска аудита стала активной.</p>
    </Fragment>
  ),
};

const ModalAuditAbort = props => {
  const { isOk, isNoAccess, isUnavailable, isUnauthorized, isQuotasExceeded } = props;

  const type = (() => {
    if (!isOk) return 'COUNTER_ERROR';
    if (isNoAccess) return 'ACCESS_DENIED';
    if (isUnavailable) return 'SERVICE_UNAVAILABLE';
    if (isUnauthorized) return 'REFRESH_ACCESS';
    if (isQuotasExceeded) return 'QUOTAS_EXCEEDED';
  })();

  const sets = {
    title: content[type].title(),
    icon: 'fal fa-exclamation-circle',
  };

  return (
    <BL.Modal.Alert.Danger { ...sets }>
      {content[type].message(props)}
    </BL.Modal.Alert.Danger>
  );};

export default ModalAuditAbort;
