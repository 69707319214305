import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL, UN, VIEW } from '/views';

import Step_1 from './components/Step_1';
import Step_2 from './components/Step_2';

import ModalAuditError from '../../views/ModalAuditError';

import { link } from '/services/Components/methods';
import { navigate } from '/services/Router/methods';

import { deferred } from '/utils/common';
import { isEqual } from '/utils/object';

import Provider from '../../provider';
import { moveArrayItem } from '../../../../utils/array';

import transformSitemap from './utils/transformSitemap';
import mergeSitemaps from './utils/mergeSitemaps';

class ProjectsSettings extends Component {
  constructor(props) {
    super(props);

    this.Form_1 = UN.Form();

    this.provider = new Provider(props.api);

    this.state = {
      step_1: {
        error: null,
        lock: null,
      },
      step_2: {
        loading: false,
        lock: false,
        insert: {
          text: '',
          items: [],
          links: [],
        },
        result: [],
      },
      submit: {
        lock: false,
        error: false,
      },
    };

    if (__CLIENT__) {
      props.fetchProjectTypes();

      setTimeout(() => this.fetchMirrors() & this.fetchSitemaps(), 0); // wait for render
    }
  }

  componentDidUpdate(_props) {
    if (!isEqual(this.props.project, _props.project)) {
      this.fetchMirrors();
      this.fetchSitemaps();
    }
  }

  fetchMirrors = async () => {
    if (this.state.step_1.lock) return;

    const { project } = this.props;

    if (!project || !!project.mirrors.main) return;

    this.setState({
      step_1: {
        ...this.state.step_1,
        lock: true,
      }
    });

    const defer = deferred();

    await this.props.fetchProjectMirrors(project.id, defer);

    defer.catch(e => console.log(e));

    defer.finally(() => this.setState({
        step_1: {
          ...this.state.step_1,
          lock: false,
        }
      })
    );
  };

  fetchSitemaps = async () => {
    if (this.state.step_2.loading || this.state.step_2.result.length) return;

    const { project } = this.props;

    if (!project) return;

    this.setState({
      step_2: {
        ...this.state.step_2,
        loading: true,
      }
    });

    const defer = deferred();

    await this.props.fetchProjectSitemaps(project.id, defer);

    const update = { ...this.state.step_2, loading: false };

    defer.then(items => {
      update.result = mergeSitemaps(items.map(item => transformSitemap(item, false)), update.result);
    });

    defer.catch(e => console.log(e));

    defer.finally(() => this.setState({ step_2: update }));
  };

  createAudit = () => {
    const { lang, project, createAudit } = this.props;

    const defer = deferred();

    defer.then(() => navigate(link('Projects.Root', { lang })));
    defer.catch((e) => VIEW.MODAL.show(() => <ModalAuditError />));

    createAudit(project.id, defer);
  };

  backToProjects = () => {
    const { lang } = this.props;

    navigate(link('Projects.Root', { lang }));
  };

  applyCallback = () => {
    const { callback } = this.props;

    const worker = {
      'create-audit': this.createAudit,
      'back-to-projects': this.backToProjects,
    }[callback];

    worker && setTimeout(() => worker(), 0);
  };

  step_2_updateState = step_2 => {
    this.setState({ step_2 });
  };

  step_1 = () => {
    const { project, types } = this.props;
    const { lock } = this.state.step_1;

    const sets = { types };

    sets.loading = !(project && types && types.length);

    sets.Form = this.Form_1;
    sets.project = project;
    sets.lock = lock;

    return <Step_1 { ...sets } />;
  };

  step_2 = () => {
    const { project } = this.props;
    const { step_2 } = this.state;

    const sets = { step_2, project };

    sets.provider = this.provider;
    sets.checkSitemaps = this.checkSitemaps;

    sets.updateState = this.step_2_updateState;

    return <Step_2 { ...sets } />;
  };

  isCompleted = async idx => {
    let valid = true;

    if (idx === 0) {
      valid = valid && !this.state.step_1.lock;
      valid = valid && await this.Form_1.validate();
    }

    if (idx === 1) {
      valid = valid && !this.state.step_2.lock;
    }

    return valid;
  };

  onSubmit = async () => {
    if (this.state.submit.lock) return;

    const { submit } = this.state;
    const { project, saveProjectSettings } = this.props;

    this.setState({ submit: { ...submit, lock: true } });

    const settings = {};

    settings.site_type_id = this.Form_1.data.site_type_id;

    const sitemaps = this.state.step_2.result;

    settings.sitemaps = {
      add: sitemaps.filter(item => !item.id && item.isAdded),
      remove: sitemaps.filter(item => !!item.id && item.isRemoved),
    };

    const defer = deferred();

    defer.then(this.applyCallback);

    defer.catch(() => {
      this.setState({ submit: { lock: false, error: true } });
    });

    saveProjectSettings(project.id, settings, defer);
  };

  renderError() {
    if (!this.state.submit.error) return null;

    return (
      <BL.Alert.Danger className="mt-3 mb-0">
        <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
        <BL.Alert.Content>
          Не удалось обновить настройки проекта. Проверьте подключение к интернету и попробуйте ещё раз.
        </BL.Alert.Content>
      </BL.Alert.Danger>
    );
  }

  render() {
    const { project, step, callback } = this.props;

    const steps = [this.step_1, this.step_2];

    const buttons = {
      submit: {
        text: callback === 'create-audit' ? 'Сохранить и запустить аудит' : 'Сохранить',
        stl: 'info',
      },
    };

    return (
      <Fragment>
        <BL.Title
          icon="fal fa-books"
          name={'Настройки проекта' + (project ? ' ' + project.host : '')}
        />
        <BL.Panel>
          <BL.Panel.Content>
            <UI.Form.Steps steps={steps} current={step} buttons={buttons} isCompleted={this.isCompleted} onSubmit={this.onSubmit}/>

            {this.renderError()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default ProjectsSettings;
