export const VALIDATION = {};

VALIDATION.REQUIRED = 'REQURED';
VALIDATION.INVALID = 'INVALID';
VALIDATION.CUSTOM = 'CUSTOM';
VALIDATION.EQUAL = 'EQUAL';

export const ERROR = {};

ERROR.REQUIRED = 'REQUIRED';
ERROR.INVALID = 'INVALID';
ERROR.CUSTOM = 'CUSTOM';

ERROR.NOT_UNIQ = 'NOT_UNIQ';
ERROR.NOT_EQUAL = 'NOT_EQUAL';

export default { VALIDATION, ERROR };
