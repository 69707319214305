import array from '/utils/array';
import link from '/utils/link';

const validate = (items, pattern, allow) => {
  allow = allow || { www: true, subdomain: true };

  const [correct, incorrect] = array.split(items, item => {
    if (!link.check(item.url)) return false;

    const parts = link.split(item.url);

    if (pattern.protocol && parts.protocol !== pattern.protocol) {
      return false;
    }

    if (pattern.domain && parts.domain !== pattern.domain) {
      if (!parts.domain.endsWith(pattern.domain)) return false;

      const sub = parts.domain.slice(0, -1 * (pattern.domain.length + 1));

      if (!allow.www && sub === 'www') return false;
      if (!allow.subdomain && sub !== 'www') return false;
    }

    return true;
  });

  return { correct, incorrect };
};

const deduplicate = (items) => {
  let uniq = {};
  let duples = {};

  for (let item, i = 0; item = items[i]; i++) {
    const isUniq = !(item.url in uniq);

    if (isUniq) {
      uniq[item.url] = item;
    } else {
      duples[item.url] = duples[item.url] || Object.assign({}, item, { count: 0 });

      duples[item.url].count += 1;
    }
  }

  uniq = Object.values(uniq);
  duples = Object.values(duples);

  return { uniq, duples };
};

export default (text, pattern, allow) => {
  const items = text.split('\n').filter(v => !!v).map(url => ({ url }));

  const { correct, incorrect } = validate(items, pattern, allow);
  const { uniq, duples } = deduplicate(correct);

  return { correct, incorrect, uniq, duples };
};
