const self = {};

self.getList = function getList() {
  const url = '/api/v1/tokens/list';
  const method = 'get';

  return this.axios({ url, method });
};

self.addYandexAccaunt = function addYandexAccaunt(data) {
  const url = '/api/v1/tokens/yandex/';
  const method = 'post';

  return this.axios({ url, method, data });
};

self.refreshYandexAccaunt = function refreshYandexAccaunt(id) {
  const url = '/api/v1/tokens/yandex/' + id + '/refresh';
  const method = 'post';

  return this.axios({ url, method });
};

self.removeYandexAccaunt = function removeYandex(id) {
  const url = '/api/v1/tokens/yandex/' + id;
  const method = 'delete';

  return this.axios({ url, method });
};

self.checkYandexCounter = function (counter) {
  const url = '/api/v1/tokens/yandex/check/' + counter;
  const method = 'get';

  return this.axios({ url, method });
};

export default self;
