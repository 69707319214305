import React, { Component } from 'react';

import { LangProvider } from '/contexts/Lang';

import { UI, BL } from '/views';

import Stats from './components/Stats';
import Links from './components/Links';
import Maps from './components/Maps';
import Structure from './components/Structure';

import { check as checkLink } from '/utils/link';

import structurize from './utils/structurize';

import './styles.scss';

class SitemapResult extends Component {
  componentDidUpdate() {
    this.tree = null;
  }

  renderResultTab = idx => {
    const { lang, result, isAudit = false } = this.props;

    if (idx === 3) {
      this.tree = this.tree || structurize(result.items);

      return <Structure lang={lang} tree={this.tree} />;
    }

    const View = [Stats, Maps, Links][idx];

    return <View lang={lang} result={result} isAudit={isAudit} />;
  };

  render() {
    const { lang, result, renderToolbar } = this.props;

    if (!result) return null;

    const sets = {
      items: [
        {
          name: lang('tab_stats'),
          infoText: lang('tab_stats_info'),
        },
        {
          name: lang('tab_validation'),
        },
        {
          name: lang('tab_pages'),
          infoText: lang('tab_pages_info'),
        },
        {
          name: lang('tab_structure'),
          infoText: lang('tab_structure_info'),
        },
      ],
      child: this.renderResultTab,
      toolbar: renderToolbar,
    };

    return (
      <LangProvider value={lang}>
        <BL.Tabs className="pl-3 pr-3" { ...sets } />
      </LangProvider>
    );
  }
}

export default SitemapResult;
