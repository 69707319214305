import React from 'react';

import { getDomain } from '/utils/link';

import './styles.scss';

const Favicon = props => {
  const { url, style } = props;

  const domain = getDomain(url);

  const sets = {
    className: 'ui-favicon-img',
    src: 'https://favicon.yandex.net/favicon/' + domain + '?size=16',
    // src: 'https://www.google.com/s2/favicons?domain=' + domain,
  };

  style && (sets.style = style);

  return <img { ...sets } />;
};

export default Favicon;
