import React from 'react'
import { UI, BL } from '/views';

import { Lang } from '/contexts/Lang';

const NoContent = props => {
  const { text } = props;

  return <UI.Badge.Danger className="bg-danger-400 text-light">{text || <Lang text="state_empty" />}</UI.Badge.Danger>;
};

export default NoContent;
