import React, { Component } from 'react';

import OverallChild from './child/overall';
import SearcherChild from './child/searcher';

const child = (item, actions, { select }) => {
  if (select === 'overall') {
    return <OverallChild item={item} />;
  } else {
    return <SearcherChild item={item} select={select} />;
  }
}

export default child;
