import { getInitial } from '/services/Store';

import { DEFAULT } from '/static/data/roles';

const defaults = {
  id: null,
  name: 'Anonymous',
  mail: '',
  role: DEFAULT,
  token: null,
  remember: false,
};

export default () => getInitial('user', defaults || {});
