import { unzip as unzipArray } from '../array';
import { isUndefined, isNaN, isNill, isArray, isObject, isNode, isDate, isPlainObject } from '../types';

const _hasOwnProperty = Object.prototype.hasOwnProperty;

const TYPES = [Number, String, Boolean];

export const cloneDeep = function cloneDeep(item) {
  if (!item) return item; // null, undefined, false, 0, '', NaN values check

  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  for (let i = 0, len = TYPES.length; i < len; i++) {
    if (item instanceof TYPES[i]) return TYPES[i](item);
  }

  if (isArray(item)) return item.map(value => cloneDeep(value));

  if (!isObject(item)) return item;

  // if (isNode(item)) return item.cloneNode(true);

  if (isDate(item)) return new Date(item);

  if (isPlainObject(item)) {
    const result = {};

    for (let name in item) {
      if (!_hasOwnProperty.call(item, name)) continue;

      result[name] = cloneDeep(item[name]);
    }

    return result;
  }

  if (isPlainObject(item)) {
    return Object.values(item).reduce((r, n) => (r[n] = item[n], r), {});
  }

  return item;
};

export const isEqual = function isEqual(a, b) {
  let p;

  if ((!isArray(a) && !isObject(a)) || (!isArray(b) && !isObject(b))) {
    return a === b;
  }

  for (p in a) {
    if (!_hasOwnProperty.call(a, p)) continue;

    if (isNaN(a[p]) && isNaN(b[p])) continue;

    if (isArray(a[p]) || isPlainObject(a[p])) {
      if (!isEqual(a[p], b[p])) return false;
    }
    else if (a[p] !== b[p]) {
      return false;
    }
  }

  for (p in b) {
    if (!_hasOwnProperty.call(b, p)) continue;

    if (isUndefined(a[p]) && !isUndefined(b[p])) {
      return false;
    }
  }

  return true;
};

export const merge = function merge(dest, ...items) {
  for (let i = 0, len = items.length; i < len; i++) {
    const src = items[i];

    for (let name in src) {
      if (!src.hasOwnProperty(name)) continue;

      const value = src[name];

      const _src = isObject(src[name]);
      const _dest = isObject(dest[name]);

      if (_src && _dest) {
        dest[name] = merge({ ...dest[name] }, src[name]);
      } else {
        dest[name] = _src ? { ...src[name] } : src[name];
      }
    }
  }

  return dest;
};

export const getter = function getter(data, path) {
  let name;

  path = path.split(/]\[|]\.|\.|]|\[/).filter(v => v);

  while (name = path.shift()) {
    if (isNill(data)) return undefined;

    data = data[name];
  }

  return data;
};

export const setter = function setter(data, path, value) {
  let name, context = data;

  path = path.split(/]\[|]\.|\.|]|\[/).filter(v => v);

  while (name = path.shift()) {
    if (!path.length) break;

    context[name] = context[name] || {};
    context = context[name];
  }

  return context[name] = value;
};

export const getPicker = function getPicker(...args) {
  return function picker(src) {
    const processor = (res, name) => (res[name] = src[name], res);

    return args.reduce(processor, {});
  };
};

export const simplify = function simplify(object) {
  const result = {};

  for (let name in object) {
    if (_hasOwnProperty.call(object, name)) continue;

    result[name] = object[name];
  }

  return result;
};

export const collect = function collect(object, params = []) {
  return params.reduce((result, name) => {
    result[name] = object[name];

    return result;
  }, {});
};

export const unpack = function unpack(src, name) {
  const keys = Object.keys(src);

  return keys.reduce((result, key) => {
    result[key] = src[key][name];

    return result;
  }, {});
};

export const unzip = (src, name) => {
  src = Object.values(src);

  return unzipArray(src, name);
};

export const repack = (src, worker) => {
  return Object.keys(src).reduce((result, key) => {
    result[key] = worker(src[key], key);

    return result;
  }, {});
};

export const isEmpty = object => !Object.keys(object).length;
