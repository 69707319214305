import { updateRoute, createLink } from '../Router/route';
import { addRoute, addFallbackRoute } from '../Router/methods';

import { getListArg } from '/utils/array';
import { isString, isArray } from '/utils/types';

const components = [];

export const addComponent = function addComponent(items) {
  const list = getListArg(items, arguments);

  for (let i = 0, len = list.length; i < len; i++) {
    const component = list[i];

    components.push(component);

    if (!component.actions) continue;

    for (let action in component.actions) {
      if (!component.actions.hasOwnProperty(action)) continue;

      const { is404, route } = component.actions[action];

      (is404 ? addFallbackRoute : addRoute)(route);
    }
  }
};

export const getComponent = function getComponent(name) {
  return components.find(c => c.settings.name === name) || null;
};

export const getAction = function getAction(name, action) {
  const component = getComponent(name);

  return component.actions[action];
};

export const getView = function getView(name, action) {
  console.log('~>>', name, action);
  const component = getComponent(name);

  return component.actions[action].view;
};

export const getSettings = function getSettings(name, action) {
  const component = getComponent(name);

  return component.actions[action].settings;
};

export const packComponent = function packComponent(component) {
  const { settings, actions, access } = component;

  for (let act in actions) {
    if (!actions.hasOwnProperty(act)) continue;

    const { route } = actions[act];

    updateRoute(route, settings.name, act);
  }

  return { settings, actions, access };
};

export const getComponents = () => components.slice();

export const link = function link(target, values) {
  if (isString(target) || isArray(target)) {
    const [component, action] = isString(target) ? target.split('.') : target;

    target = { component, action };
  }

  const { component, action } = target;

  const comp = getComponent(component);
  const act = comp && comp.actions[action];

  if (!comp || !act) return '#';

  return createLink(act.route, values);
};
