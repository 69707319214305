import React, { Component, Fragment } from 'react';

import { UI, BL } from '/views';

import { link } from '/services/Components/methods';

import services from '/static/data/services';

class AuditReports extends Component {
  renderReports = () => {
    const { lang, auditId, audit } = this.props;

    const renderItem = item => {
      const data = services[item.serviceName];

      const url = link([data.component, data.action], { lang, auditId, reportId: item.id });

      return (
        <UI.List.Item key={item.id + ':' + item.serviceName} icon={data.icon + ' fs-16'} href={url}>
          <div className="title fs-9">{data.name}</div>
          <div className="mt-2 fs-6">Дата добавления: {item.dateAdd}</div>
          <div className="mt-1 fs-6">
            {item.ready ? 'Дата завершения:' + item.dateFinish : 'Готовность: ' + item.percent + '%'}
          </div>
        </UI.List.Item>
      );
    };

    return (
      <UI.List>
        {audit.reports.map(renderItem)}
      </UI.List>
    );
  };

  renderLoader = () => <div className="text-center"><UI.Loader /></div>;

  render() {
    const { audit } = this.props;

    const isLoaded = audit && !!audit.reports.length;

    return (
      <Fragment>
        <BL.Title icon="fal fa-ballot-check" name={'Аудит по проекту ' + (isLoaded ? audit.reports[0].projectName : '')} />

        <BL.Panel>
          <BL.Panel.Header>
            <div className="title ml-1 mr-1 fs-9">
              <b>Отчёты</b>
            </div>
          </BL.Panel.Header>

          <BL.Panel.Content>
            {isLoaded ? this.renderReports() : this.renderLoader()}
          </BL.Panel.Content>
        </BL.Panel>
      </Fragment>
    );
  }
}

export default AuditReports;
