import React, { Component } from 'react';
import cn from 'classnames';

import Lang from '/services/Lang';

import { LangConsumer } from '/contexts/Lang';

import { link } from '/services/Components/methods';

import UI from '/views/ui';

import './styles.scss';

class LanguagesMenu extends Component {
  state = {
    show: false,
  };

  show = () => this.setState({ show: true });
  hide = () => this.setState({ show: false });

  onClick = event => {
    if (this.state.show) return;

    this.show();

    window.addEventListener('click', this.onWindowClick, true);
  };

  onWindowClick = event => {
    this.hide();
  };

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  renderItem(name, lang) {
    const { component, action, params } = this.props;

    const cur = lang.getLanguage();

    return (
      <li key={name} className={cn('common_languages-item', cur === name && 'common_languages-item_active')}>
        <a href={link([component, action], { ...params.url, lang: name })}>
          {lang.child().setLanguage(name)('name_full')}
        </a>
      </li>
    );
  }

  renderPopup(lang) {
    return (
      <UI.Popup.Light
        className="common_languages-popup"
        width={180}
        side={UI.Popup.SIDE_BOTTOM}
        shown={this.state.show}
        boundingBox=".popup-bounder"
      >
        <ul className="common_languages-list">
          {Lang.AVAILABLE.map(name => this.renderItem(name, lang))}
        </ul>
      </UI.Popup.Light>
    );
  }

  render() {
    const { size = 'sm' } = this.props;

    return (
      <LangConsumer context="global">
        {lang => (
          <div className="common_languages">
            <UI.Button.Warning className="fs-8" size={size} onClick={this.onClick}>
              <i className="fal fa-globe-europe" /> {lang('name_full')}
            </UI.Button.Warning>
            {this.renderPopup(lang)}
          </div>
        )}
      </LangConsumer>
    );
  }
}

export default LanguagesMenu;
