import React from 'react';
import cn from 'classnames';

import Search from '../Search';
import Pagination from '../Pagination';

const style = { padding: '0 0.75rem' };

const Toolbar = props => {
  const { data, actions, settings, toolbar } = props;

  return (
    <div className="ml-auto d-flex align-items-center col justify-content-end">
      {toolbar(data, actions, settings)}
    </div>
  );
};

const showSearch = (onSearch, style) => {
  return (
    <div className="d-flex align-items-center" style={style}>
      <Search onSearch={onSearch} />
    </div>
  );
};

const TopLine = props => {
  const { data, actions, settings, toolbar, pagination, paging, searcher, onSearch, onPaginate } = props;

  const renderPaging = () => {
    return (
      <div className={cn("d-flex align-items-center", !toolbar && 'ml-auto')} style={style}>
        <Pagination.Limiter paging={paging} onPaginate={onPaginate} />
    	</div>
    );
  };

  return (
    <div className="row mb-3">
      {!!searcher && showSearch(onSearch, style)}

      {toolbar && <Toolbar toolbar={toolbar} data={data} actions={actions} settings={settings} />}

      {!!pagination && renderPaging()}
    </div>
  );
};

export default TopLine;
