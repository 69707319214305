import React from 'react';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: '*',
    field: 'all',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '76px', maxWidth: '76px', minWidth: '76px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_yandex" />,
    field: 'yandex',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '60px', maxWidth: '60px', minWidth: '60px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_google" />,
    field: 'google',
    sortable: true,
    sortFirst: 'asc',
    style: { width: '60px', maxWidth: '60px', minWidth: '60px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="col_url" />,
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: { textAlign: 'left' },
  },
];
