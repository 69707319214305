import React, { Fragment } from 'react';

import { BL } from '/views';

import { Lang } from '/contexts/Lang';

export default [
  {
    name: () => <Lang text="words_col_add" />,
    field: 'action',
    sortable: false,
    style: { width: '60px', maxWidth: '60px', minWidth: '60px', textAlign: 'center' },
  },
  {
    name: () => <Lang text="words_col_words" />,
    field: 'lemma',
    sortable: true,
    sortFirst: 'asc',
    style: { textAlign: 'left' },
  },
  {
    name: () => <Lang text="words_col_count" />,
    field: 'count',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '18%', maxWidth: '18%', minWidth: '18%', textAlign: 'center' },
  },
  {
    name: () => (
      <Fragment>
        <Lang text="words_col_percentText" />
        <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
          <Lang text="words_col_percentText_info" />
        </BL.Info.Dark>
      </Fragment>
    ),
    field: 'overall',
    sortable: false,
    style: { width: '18%', maxWidth: '18%', minWidth: '18%', textAlign: 'center' },
  },
  {
    name: () => (
      <Fragment>
        <Lang text="words_col_percentCore" />
        <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} boundingBox=".panel" passive={true}>
          <Lang text="words_col_percentCore_info" />
        </BL.Info.Dark>
      </Fragment>
    ),
    field: 'core',
    sortable: false,
    style: { width: '18%', maxWidth: '18%', minWidth: '18%', textAlign: 'center' },
  },
];
