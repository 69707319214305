import COLORS from '../../../../../utils/colors';

import getCellStyle from '../../../../../utils/getCellStyle';
import getVerdictStyle from '../../../../../utils/getVerdictStyle';

import getUniqLinks from '../utils/getUniqLinks';

const fields = {};

fields.query = item => 'yandex-queries-query';

fields.links = item => {
  const links = getUniqLinks(item);

  return links.length === 1 ? COLORS.SUCCESS : COLORS.DANGER;
};

fields.state = item => getVerdictStyle(item.verdict);

fields.diff_year = item => {
  const { statistic } = item.analytics.year.total.visits;

  return getCellStyle(statistic);
};

fields.diff_3_month = (item, actions, settings) => {
  const { statistic } = item.analytics.last3month.total.visits;

  return getCellStyle(statistic);
};

fields.diff_1_month = (item, actions, settings) => {
  const { statistic } = item.analytics.last1month.total.visits;

  return getCellStyle(statistic);
};

export default (name, item, settings) => !!fields[name] && fields[name](item, settings);
