import React, { Component, Fragment } from 'react';

import { UI, BL, VIEW } from '/views';

import Provider from '../../provider';

import InsertBox from './components/InsertBox';
import AllResult from './components/AllResult';
import TitleResult from './components/TitleResult';
import DescriptionResult from './components/DescriptionResult';
import TagsResult from './components/TagsResult';
import H1Result from './components/H1Result';

import { check as checkLink } from '/utils/link';

import structurize from './utils/structurize';

import './styles.scss';

class TitleDescriptionH1 extends Component {
  constructor(props) {
    super(props);

    this.provider = new Provider(props.api);

    this.state = {
      lock: false,
      showLemms: false,
      linksLimit: 100,
      insert: {
        text: '',
        items: [],
        links: [],
      },
      result: {
        items: null,
        lemms: null,
      },
    };
  }

  onLinksUpdate = (name, text) => {
    const items = text.split('\n').filter(v => !!v);
    const links = items.filter(link => checkLink(link));

    this.setState({ insert: { text, items, links } });
  };

  onProcess = async () => {
    const { insert, linksLimit } = this.state;
    const links = insert.links.slice(0, linksLimit);

    this.setState({ lock: true });

    const { items, lemms } = await this.provider.titleDescriptionH1(links);

    for (let item, i = 0; item = items[i]; i++) {
      const { tags_h } = item.content || {};

      item.croppedUrl = item.url.length > 60 ? `${item.url.slice(0, 60)}...` : item.url;

      tags_h && (item.content.structurized_tags_h = structurize(tags_h));
    }

    const result = { items, lemms };

    this.setState({ result, lock: false });
  };

  onToggleLemms = (name, value) => {
    this.setState(state => ({ ...state, showLemms: value }));
  }

  renderInsertBox() {
    const { lang } = this.props;

    const { lock, insert, result, showLemms, linksLimit } = this.state;
    const sets = { lock, insert, hasResult: result.items, showLemms, linksLimit };

    sets.onLinksUpdate = this.onLinksUpdate;
    sets.onProcess = this.onProcess;
    sets.onToggleLemms = this.onToggleLemms;

    return <InsertBox lang={lang} { ...sets } />;
  }

  renderToolbar = () => {
    const { id, lang } = this.props;
    const { result, lock } = this.state;

    return (
      <div className="mr-2">
        <span className="mr-2">{lang('export')}:</span>
        <UI.Button.Transparent disabled={lock} onClick={() => !lock && this.provider.titleDescriptionH1Export(result.items, lang)} className="btn-custom-excel" download />
      </div>
    );
  };

  renderResultTab = idx => {
    const { lang } = this.props;
    const { result, showLemms } = this.state;
    const { items: list, lemms } = result;

    let content = null;

    if (idx === 0) {
      content = <AllResult lang={lang} list={list} />;
    } else {
      const pick = ['title', 'description', 'tags_h', 'tags_h1'][idx - 1]
      const sets = { lang, list, showLemms, lemms: lemms[pick].words };
      const Component = [TitleResult, DescriptionResult, TagsResult, H1Result][idx - 1];

      content = <Component { ...sets }/>;
    }

    return <div className="p-3">{content}</div>;
  };

  renderResult() {
    const { lang } = this.props;
    const { result } = this.state;

    if (!result.items) return null;

    const sets = {
      items: [
        lang('tab_allRestuls'),
        lang('tab_title'),
        lang('tab_description'),
        lang('tab_h1h6'),
        lang('tab_h1'),
      ],
      child: this.renderResultTab,
      toolbar: this.renderToolbar,
    };

    return (
      <BL.Panel className="mb-0">
        <UI.Collapse className="p-0 select-default" title={lang('title_result')} expand={true}>
          <BL.Tabs { ...sets } />
        </UI.Collapse>
      </BL.Panel>
    );
  }

  render() {
    const { lang } = this.props;

    return (
      <Fragment>
        <BL.Title icon="fal fa-clipboard-list" name={lang('title')} text={lang('description')} />

        {this.renderInsertBox()}
        {this.renderResult()}
      </Fragment>
    );
  }
}


export default TitleDescriptionH1;
