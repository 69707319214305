import itemId from './_config/itemId';
import columns from './_config/columns';
import getter from './_config/getter';
import sorter from './_config/sorter';
import child from './_config/child';

const pagination = false;

export default {
  itemId,
  columns,
  getter,
  sorter,
  pagination,
  child,
};
