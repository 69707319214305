const self  = {};

self.head_title = 'XML Sitemap Checker &amp; URL Extractor';
self.head_keywords = '';
self.head_description = 'XML Sitemap Checker allows you to test if XML sitemaps are formatted correctly, extract &amp; export URLs list form sitemaps, analyze website structure';

self.name = 'Sitemap checker';

self.title = 'XML Sitemap Checker, Validator & URL Extractor';
self.description = '<p>This free online tool allows you to check XML sitemaps, extract URLs list from sitemaps, analyze website\'s structure, analyze number of pages by year if the &lt;lastmod&gt; tag is used in sitemaps.</p><p>Please, enter only those sitemaps for analysis, that belong to <b>the same website</b>. XML sitemap index files are supported.</p>';
self.descriptionFull = '<p>This free online tool allows you to check XML sitemaps, extract URLs list from sitemaps, analyze website\'s structure, analyze number of pages by year if the &lt;lastmod&gt; tag is used in sitemaps.</p><p>Please, enter only those sitemaps for analysis, that belong to <b>the same website</b>. XML sitemap index files are supported.</p><p>SERP.tools checks a number of possible XML sitemap errors, such as:</p><ul class="mb-0"><li>Broken sitemaps with non 200 OK server response.</li><li>Empty sitemaps without links.</li><li>Errors in the XML tag format in <a href="https://www.sitemaps.org/protocol.html" target="_blank">accordance with the standard</a>.</li><li>URLs not in the same host as the Sitemap location.</li><li>Duplicate pages in sitemaps.</li></ul>';

self.field_title = 'XML sitemaps list';
self.count_links = 'XML Sitemaps count: <b><%= count %></b>';
self.error_incorrectUrl = 'URL list contains invalid URL. Please make sure that your URLs begin with \'https://\' or \'http://\' and include your domain name.';
self.error_missmatchDomain = 'All sitemaps must have the same protocol and domain name.';
self.button_check = 'Check XML sitemaps';

self.title_result = 'Result';

self.tab_stats = 'Statistics';
self.tab_stats_info = 'Data on number of XML sitemaps, their validity, number of URLs in each sitemap.';
self.tab_validation = 'Validation';
self.tab_pages = 'URLs';
self.tab_pages_info = 'The number of URLs in each sitemap.';
self.tab_structure = 'Website structure';
self.tab_structure_info = 'The website\'s tree structure, built according to URL structure. A number of pages in category is indicated in [square brackets].';

self.export = 'Export';

self.root_domain = 'Host';
self.root_domain_info = 'Website\'s address that is used to search for pages that do not belong to this website. SERP.tools allocates the host from the list of entered sitemaps.';

self.main_mirror = 'Main mirror';
self.main_mirror_info = 'The site address we use to check if all pages in the sitemap refer to your site. The tool automatically detects the main mirror, see Main Mirror report. (http/https, www./ without the www prefix)';

self.site_type_title = 'XML Sitemaps count';
self.site_type_title_info = 'You can find a list of all found XML sitemaps below.';
self.site_type_item_index = 'XML Sitemap Index Files';
self.site_type_item_index_info = 'XML sitemap index files contain links to other XML sitemaps, not website\'s URLs (<a href="https://www.sitemaps.org/protocol.html#index" target="_blank">format description</a>). XML Sitemap Checker extracts XML sitemaps and uses them for further analysis.';
self.site_type_item_normal = 'XML Sitemaps';
self.site_type_item_normal_info = 'XML Sitemaps count excluding XML Sitemap Index Files';
self.site_type_item_unknown = 'Unidentified maps';
self.site_type_item_unknown_info = 'XML sitemaps that do not comply with the <a href="https://www.sitemaps.org/protocol.html" target="_blank">XML standard</a>, so they are not analyzed.';

self.validity_title = 'XML Sitemap Validation';
self.validity_title_info = 'XML Sitemap Checker automatically checks sitemaps for errors, for example, missing required XML tags according to the XML standard. Only correct (valid) XML sitemaps are used in the analysis.';
self.validity_item_correct = 'Valid';
self.validity_item_correct_info = 'Valid sitemaps that contain mandatory tags and passed all the tests.';
self.validity_item_incorrect = 'Invalid';
self.validity_item_incorrect_info = 'XML cards with errors: sitemap is not available, empty or does not contain required tags, etc.';

self.validity_error_unknown_map_format = 'The sitemap must have &lt;sitemapindex&gt; or &lt;urlset&gt; as the initial element.';
self.validity_error_fetch = 'Failed to load the sitemap via this link. Server response code: ';
self.validity_error_incorrect_xml = 'The sitemap has an error in xml syntax.';
self.validity_error_empty_map = 'The sitemap does not contain any links.';
self.validity_error_cicle_index = 'XML Sitemap Index File cyclically connects itself directly, or through another map.';
self.validity_error_invalid_host = 'The sitemap uses a different protocol or host';
self.validity_error_invalid_map_format = 'XML Sitemap Index File does not follow the XML sitemap index file syntax';
self.validity_error_too_much_links = 'XML sitemap contains more than 50,000 URLs';
self.validity_error_relative_links = 'XML sitemap contains relative URLs';
self.validity_error_mixed_protocols = 'Links within the sitemap have http and https protocols';
self.validity_error_map_disallowed = 'XML sitemap is blocked by robots.txt';

self.count_title = 'Number of URLs';
self.count_title_info = 'The number of URLs found in XML sitemaps.';
self.count_item_total = 'Total';
self.count_item_total_info = 'The number of URLs found in XML sitemaps, duplicate pages are not deleted.';
self.count_item_uniq = 'Unique URLs';
self.count_item_uniq_info = 'The number of unique URLs found in XML sitemaps (without duplicates).';
self.count_item_duplicates = 'Duplicate URLs';
self.count_item_duplicates_info = 'The number of duplicate URLs indicating source XML sitemaps.';

self.switches_title = 'The number of external links';
self.switches_title_info = 'List of URLs belonging to a host other than the one the sitemap belongs to.';
self.switches_empty = 'All URLs belong to the host.';
self.switches_different_pages = 'Subdomain URLs';
self.switches_relative_pages = 'Relative URLs';

self.years_title = 'Number of URLs by year';
self.years_title_info = 'If &lt;lastmod&gt; tag is used in XML sitemaps, then the values ​​of this tag are used for URLs as dates of creation or update of this pages.';
self.years_alert = '<p>The website has <%= pages %> URLs (<%= percent %>% of all URLs found in XML sitemaps) older than 2 years old according to the XML sitemaps.</p><p>Pay attention to these URLs. You can filter them by year in export file.</p><p>Perhaps their content is outdated and needs to be updated?</p><p class="mb-0">The date of URL update in XML sitemap may be incorrect, so we recommend you to verify a few URLs manually to determine if you can trust the data from XML sitemaps.</p>';
self.years_chart_pages = 'Pages';
self.years_year_unknown = 'Unknown';

self.robots_title = 'Verifying the robots.txt file';

self.robots_status_sitemapOk = 'OK. The link to XML sitemap was found in the robots.txt file.';
self.robots_status_sitemapMissing = 'The robots.txt file does not contain links to XML sitemap. This is not critical, but we recommend adding a link to an XML sitemap (or sitemaps) to it. You can use <a href="https://developers.google.com/search/docs/advanced/sitemaps/build-sitemap" target="_blank">Google\'s sitemap.xml instructions</a>.';
self.robots_status_sitemapMirror = 'Robots.txt contains sitemaps that don\'t belong to the main mirror (the primary domain).';
self.robots_status_fetchError = 'The robots.txt file could not be loaded.';

self.sitemap_list = 'XML Sitemaps count:';

self.sitemap_check_title = 'XML Sitemap validation';

self.col_type = 'Type';
self.col_state = 'Status';
self.col_link = 'Sitemap URL';

self.status_ok = 'Ок';
self.status_warning = 'Warning';

self.sitemap_type_index = 'XML Sitemap Index';
self.sitemap_type_normal = 'XML Sitemap';
self.sitemap_type_redirect = 'Redirect';
self.sitemap_type_unknown = 'Not defined';

self.title_checklist = 'Tests:';

self.sitemap_status_redirect = 'Redirect';
self.sitemap_status_fetchError = 'Failed to load the sitemap via this link. Server response code: ';
self.sitemap_status_correct = 'XML sitemap contains the required tags.';
self.sitemap_status_incorrect = 'The sitemap has an error in xml syntax.';
self.sitemap_status_empty = 'The sitemap does not contain any links.';
self.sitemap_status_unknown = 'Unknown XML sitemap format.';
self.sitemap_status_indexIncorrect = 'XML Sitemap Index File does not follow <a href="https://www.sitemaps.org/protocol.html#index" target="_blank">the XML sitemap index file syntax</a>.';
self.sitemap_status_invalidHost = 'The XML sitemap doesn\'t belong to the host <b><%= host %></b>';
self.sitemap_status_cycling = 'XML Sitemap Index File cyclically connects through another map.';
self.sitemap_status_noRelatives = 'All URLs contain protocol and host.';
self.sitemap_status_hasRelatives = 'The sitemap contains relative URLs (you should specify absolute ones).';
self.sitemap_status_allowed = 'XML sitemap crawling is allowed in robots.txt';
self.sitemap_status_disallowed = 'XML sitemap is blocked by robots.txt';
self.sitemap_status_size = 'The XML sitemap contains  <%= count %> URLs.';
self.sitemap_status_tooBig = 'One of the XML sitemaps contains more than 50,000 URLs (should be less)';
self.sitemap_status_sameProtocol = 'All URLs have the same protocol.';
self.sitemap_status_mixedProtocol = 'Links within the sitemap have http and https protocols';

self.no_data = 'No data';

self.col_lastmodify = 'Date of last modification';
self.col_page = 'URL';

self.total_pages = 'Number of URLs: <%= count %>';

export default self;
