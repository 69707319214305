const getStatuses = function getStatuses(result) {
  let states = result.filter(v => v.result).map(v => v.result[0].statusCode);

  states = states.reduce((result, base) => {
    result.includes(base) || result.push(base);

    return result;
  }, []);

  states.sort((a, b) => (a || 0) - (b || 0));

  return states;
};

export default function updateStatuses(states, result) {
  const current = getStatuses(result);

  const isDisabled = status => !!(states.find(v => v.status) || {}).disabled;

  return current.map(status => ({ status, disabled: isDisabled(status) }));
};
