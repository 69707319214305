import React, { PureComponent } from 'react';

import { UI, BL } from '/views';

import { deduplicate } from '/utils/array';

const renderError = (lang, insert) => {
  const { items, links } = insert;

  const text = [];

  if (items.length !== links.length) {
    text[text.length] = lang('error_incorrectUrl');
  }

  if (links.length !== deduplicate(links).length) {
    text[text.length] = lang('error_duplicateUrl');
  }

  return (
    <div className="w-65 color-danger-500">
      {text.map((text, i) => <div key={i}>{text}</div>)}
    </div>
  );
};

const InsertBox = props => {
  const { lang, lock, insert, result, onStartTextChange, onProcess } = props;

  const Button = insert.links.length ? UI.Button.Primary : UI.Button.Secondary;

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title={lang('field_title')} expand={!result.links.length}>
          <div className="pt-2 pb-3 pl-3 pr-3">
            <UI.Form.TextArea value={insert.text} onChange={onStartTextChange} style={{ height: '260px' }} />
            {renderError(lang, insert)}
            <div className="mt-2 select-none">
              {lang('field_links_count', { count: insert.links.length }, { useParser: true })}
            </div>
            <Button className="mt-2" size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} text={lang('button_check')} onClick={onProcess} />
          </div>
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default InsertBox;
