import link from '/utils/link';

const self = {};

export const searchYandexLink = self.searchYandexLink = query => {
  query = encodeURIComponent(query);

  return 'https://yandex.by/search/?text=' + query;
};

export const indexYandexLink = self.indexYandexLink = url => {
  const { domain } = link.split(url);

  return searchYandexLink(`host:${domain}`);
};

export const searchGoogleLink = self.searchGoogleLink = query => {
  query = encodeURIComponent(query).split('%20').join('+');

  return 'https://www.google.com/search?q=' + query;
};

export const indexGoogleLink = self.indexGoogleLink = url => {
  const { protocol, domain } = link.split(url);

  return searchGoogleLink(`site:${domain} inurl:${protocol}://${domain}`);
};

export default self;
