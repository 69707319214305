import grabLinks from './grabLinks';

import { deduplicate } from '/utils/array';

export default function getUniqLinks(item) {
  let links = grabLinks(item);

  links = links.map(link => link.replace(/^https?:|#.*$/g, ''));

  return deduplicate(links);
};
