import React from 'react';
import cn from 'classnames';

import { ref } from '/utils/react';

import Field from '../Field';

class TextArea extends Field {
  FIELD_TYPE = 'textarea';

  ref = ref;

  padding = 0;

  componentDidMount() {
    const { field } = this.ui;

    if (!this.props.extandable) return;

    const styles = window.getComputedStyle(field);

    const bt = parseInt(styles['border-top-width'], 10);
    const bb = parseInt(styles['border-bottom-width'], 10);
    // const pt = parseInt(styles['padding-top'], 10);
    // const pb = parseInt(styles['padding-bottom'], 10);

    this.padding = bt + bb; // + pt + pb;

    this.resize();
  }

  onChangeValue = event => {
    this.onChange(event);

    this.props.extandable && this.resize();
  }

  resize = () => {
    const { wrapper, field } = this.ui;

    wrapper.style.height = field.clientHeight + 'px';
    field.style.height = 'auto';

    field.style.height = (field.scrollHeight + this.padding) + 'px';
    wrapper.style.height = 'auto';
  }

  render () {
    const { errors } = this.state;
    const { label, name, value, className, style, extandable } = this.props;

    const fieldSets = {
      name: name,
      style: style,
      type: this.FIELD_TYPE,
      defaultValue: value,
      className: 'form-control custom-scrollbar',
      onChange: this.onChangeValue,
    };

    if (errors.length) {
      fieldSets.className += ' is-invalid';
    }

    return (
      <label className={cn("d-block", className)}>
        {label && <span className="form-label select-none">{label}</span>}
        <div className={cn('position-relative mt-2', extandable && 'extandable-area-wrapper')} ref={this.ref('wrapper')}>
          <textarea { ...fieldSets } ref={this.ref('field')} />
        </div>
      </label>
    );
  }
}

export default TextArea;
