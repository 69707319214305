export const STATUSES = {};

STATUSES.OK = 'CS_OK';
STATUSES.NO_ACCESS = 'CS_NO_ACCESS';
STATUSES.LOST_COUNTER = 'CS_LOST_COUNTER';
STATUSES.ACCAUNT_REMOVED = 'CS_ACCAUNT_REMOVED';
STATUSES.NOT_FOUND = 'CS_NOT_FOUND';
STATUSES.ERR_UNKNOWN = 'CS_ERR_UNKNOWN';
STATUSES.ERR_TIMEOUT = 'CS_ERR_TIMEOUT';
STATUSES.ERR_INFECTED = 'CS_ERR_INFECTED';
STATUSES.ERR_CONNECT = 'CS_ERR_CONNECT';
STATUSES.ERR_OTHER_HTML_CODE = 'CS_ERR_OTHER_HTML_CODE';
