import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { LangConsumer } from '/contexts/Lang';

import { UI, BL } from '/views';

class Child extends Component {
  state = {
    expands: [],
  };

  renderRedirectIcon(item) {
    if (item.isCycle) {
      return <i className="fal fa-repeat tools_server_response-cycle fs-9p" />;
    }

    if (item.conclusion.redirect) {
      return <i className="fal fa-level-down tools_server_response-redirect fs-9p" />;
    }

    return null;
  }

  renderCycleText(item) {
    if (!item.isCycle) return null;

    const { lang } = this.props;

    return (
      <div className="fs-6 mt-1">
        {lang('backTo')} <a href={item.url} target="_blank">{item.conclusion.redirect}</a>
      </div>
    );
  }

  renderBreakText(item) {
    if (!item.isBreak || !item.conclusion.redirect) return null;

    const { lang } = this.props;

    return (
      <div className="fs-6 mt-1">
        {lang('redirectTo')} <a href={item.url} target="_blank">{item.conclusion.redirect}</a>
      </div>
    );
  }

  onToggle = idx => {
    let expands = this.state.expands.slice();
    const index = expands.indexOf(idx);

    index === -1 ? expands.push(idx) : expands.splice(index, 1);

    this.setState({ expands });
  };

  renderHeadersList = item => {
    const headers = Object.entries(item.headers).map(([name, value]) => {
      name = name.replace(/(^|-)(\w)/g, (m, $1, $2) => $1 + $2.toUpperCase());

      return { name, value };
    });

    return (
      <ul className="list text-left p-2 mb-0">
        {headers.map((item, idx) => <li key={idx} style={{ wordBreak: 'break-all' }}><b>{item.name}:</b> {item.value}</li>)}
      </ul>
    );
  }

  renderRow = (item, idx) => {
    const expand = this.state.expands.includes(idx);

    const sets = {
      loader: !item,
      error: item.error,
      code: item.statusCode,
    };

    const showExpandButton = () => {
      if (!item.headers) return null;

      const icon = expand ? 'fal fa-chevron-circle-down' : 'fal fa-chevron-circle-right';

      return (
        <i className={cn('fs-12', icon)} />
      );
    };

    const showHeaders = () => {
      return (
        <tr key={item.url + ':headers'}>
          <td colSpan="3">
            {this.renderHeadersList(item)}
          </td>
        </tr>
      );
    };

    return (
      <Fragment key={item.url + ':fragment'}>
        <tr key={item.url + ':url'} onClick={() => item.headers && this.onToggle(idx)}>
          <td className="text-center p-2">
            {item.headers && showExpandButton()}
          </td>
          <td className="text-center p-2">
            <span className="d-inline-block position-relative pr-1">
              <BL.StatusBadge {...sets} />
              {this.renderRedirectIcon(item)}
            </span>
          </td>
          <td className="text-left p-2">
            <div style={{ padding: '1px 0' }}><a href={item.url} target="_blank">{item.url}</a></div>
            {this.renderCycleText(item)}
            {this.renderBreakText(item)}
          </td>
        </tr>
        {expand && showHeaders()}
      </Fragment>
    );
  }

  renderChain() {
    const { lang, item: { result } } = this.props;

    return (
      <div>
        <div className="title color-inof-700 mb-2">
          <b>{lang('redirectsChain')}:</b>
        </div>
        <table className="table darken table-bordered table-sm text-center col-10 mt-2 mb-0" style={{ maxWidth: '740px' }}>
          <thead>
            <tr>
              <th style={{ width: '30px', maxWidth: '30px', minWidth: '30px' }} className="text-center p-2" />
              <th style={{ width: '80px', maxWidth: '80px', minWidth: '80px' }} className="text-center p-2">{lang('statusCode')}</th>
              <th className="text-left p-2">{lang('link')}</th>
            </tr>
          </thead>
          <tbody>
            {result.map(this.renderRow)}
          </tbody>
        </table>
      </div>
    );
  }

  renderSingleResponse() {
    const { lang, item: { result } } = this.props;

    const sets = {
      loader: !result[0],
      error: result[0].error,
      code: result[0].statusCode,
    };

    return (
      <div>
        <table className="table darken table-bordered table-sm text-center col-10 mt-2 mb-0" style={{ maxWidth: '740px' }}>
          <thead>
            <tr>
              <th style={{ width: '80px', maxWidth: '80px', minWidth: '80px' }} className="text-center p-2">{lang('statusCode')}</th>
              <th className="text-left p-2">{lang('link')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><BL.StatusBadge {...sets} /></td>
              <td className="text-left p-2">{result[0].url}</td>
            </tr>
            <tr>
              <td colSpan="2">
                {this.renderHeadersList(result[0])}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  render() {
    const { result } = this.props.item;

    return result.length > 1 ? this.renderChain() : this.renderSingleResponse();
  }
}

const child = function child(item) {
  return <LangConsumer>{lang => <Child item={item} lang={lang} />}</LangConsumer>;
}

child.check = function check(item) {
  return item.result && (item.result.length > 1 || !item.result[0].error);
};

export default child;
