import React, { Fragment } from 'react';

import { BL } from '/views';

const fields = {};

fields.lastmod = item => item.lastmod || '-';
fields.url = item => item.url;

export default (name, item, actions, settings) => fields[name](item, actions, settings);
