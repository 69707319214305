import React from 'react';

import { SOURCE } from '../../../constants';

const fields = {};

fields.url = item => <a href={item.url} target="_blank">{item.url}</a>;

fields.source = item => {
  const { sources } = item;

  if (sources.includes(SOURCE.SITEMAP)) return 'XML-карта сайта';
  if (sources.includes(SOURCE.USER)) return 'Добавлена пользователем';
  if (sources.includes(SOURCE.METRICA)) return 'Яндекс метрика';

  return '-';
};

export default (name, item, actions, settings) => fields[name](item, actions, settings);
