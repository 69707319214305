import { handleActions } from 'redux-actions';

import { routerUpdateState } from './actions';
import initial from './initial';

export default handleActions({
    [routerUpdateState]: _routerUpdateState,
}, initial());

function _routerUpdateState (state, { payload }) {
  const { path, method, component, action, layout, params } = payload;

  const data = {
    get: params.get || {},
    post: params.post || {},
    url: params.url || {}
  };

  return { path, method, component, action, layout, params: data };
}
