import { SITE_TYPE, DATA_ERRORS } from '../../../constants';

const fields = {};

fields.type = item => {
  return {
    [SITE_TYPE.INDEX_SITEMAP]: 0,
    [SITE_TYPE.SITEMAP]: 1,
    [SITE_TYPE.REDIRECT]: 2,
    [SITE_TYPE.UNKNOWN]: 3,
  }[item.type] || 4;
};

fields.status = item => {
  let status = 0;

  if (item.errors.length) {
    status = 1;
  }

  const isCritical = [
    DATA_ERRORS.ERROR_FETCH,
    DATA_ERRORS.INCORRECT_XML,
    DATA_ERRORS.INVALID_MAP_FORMAT
  ].some(error => item.errors.includes(error));

  if (isCritical || !item.indexing.allowed) {
    status = 2;
  }

  return status;
};

fields.url = item => item.url;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.url;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
