import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import UN from '/views/unions';

import link from '/utils/link';
import { isFunction } from '/utils/types';

import extract from './utils/extract';

class InsertLinks extends Component {
  constructor(props) {
    super(props);

    this.Form = UN.Form();

    this.Form.data = Object.assign({}, props.insert || {});

    const insert = props.insert || {};
    const pattern = link.split(props.pattern || '');
    const links = extract(insert.text || '', pattern, props.allow);

    this.state = { insert, pattern, links };
  }

  static getDerivedStateFromProps(_props, state) {
    const result = { ...state };

    if (result._pattern !== _props.pattern) {
      result._pattern !== _props.pattern;
      result.pattern = link.split(_props.pattern);
    }

    return result;
  }

  renderAdditionalFields() {
    const { Form } = this;
    const { links } = this.state;
    const { additionalFields } = this.props;

    if (!additionalFields) return null;

    const result = additionalFields(Form, links);

    if (!result) return null;

    return <div>{result}</div>;
  }

  renderErrors() {
    const { errors } = this.props;
    const { links } = this.state;

    const result = [];

    if (links.incorrect.length) {
      result[result.length] = <p key="incorrect">Непарвильных ссылок: <b>{links.incorrect.length}</b></p>;
    }

    isFunction(errors) && result.push(...(errors(links) || []));

    if (!result.length) return null;

    return <div className="color-danger-500">{result}</div>;
  }

  onLinksChange = (name, text) => {
    const { allow } = this.props;
    const { pattern } = this.state;

    const links = extract(text, pattern, allow);
    const insert = { ...this.Form.data };

    this.setState({ insert, links });
  };

  onChange = (name, value, values) => {
    const { onChange } = this.props;

    onChange && onChange(name, value, values);
  };

  onSubmit = values => {
    const { links } = this.state;
    const { onSubmit } = this.props;

    onSubmit && onSubmit(links, values);
  };

  isCompleted() {
    const { Form } = this;

    const { links } = this.state;
    const { isCompleted } = this.props;

    if (isFunction(isCompleted)) return isCompleted(links, this.Form.values);

    return links.correct.length && !links.incorrect.length;
  }

  render() {
    const { Form } = this;

    const { insert, links } = this.state;
    const { className, lock, submitText = 'Отправить' } = this.props;

    const stl = this.isCompleted() ? 'primary' : 'secondary';

    return (
      <Form className={className} onChange={this.onChange} onSubmit={this.onSubmit}>
        <Form.TextArea name="text" value={insert.text} onChange={this.onLinksChange} style={{ height: 260 }} />

        {this.renderAdditionalFields()}

        <div className="mt-2 select-none">
          Количество ссылок: <b>{links.uniq.length}</b>.
        </div>

        {this.renderErrors()}

        <div>
          <Form.Submit className="mt-2" size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} stl={stl} text={submitText} />
        </div>
      </Form>
    );
  }
}

export default InsertLinks;
