const self = {};

self.create = function create(title, message) {
  const url = '/api/v1/support/conversations/';
  const method = 'post';

  const data = { title, message };

  return this.axios({ url, method, data });
};

self.fetch = function fetch() {
  const url = '/api/v1/support/conversations/list';
  const method = 'get';

  return this.axios({ url, method });
};

self.getHistory = function getHistory(id, message_id, timestamp) {
  let url = '/api/v1/support/conversations/' + id;
  const method = 'get';

  if (message_id && timestamp) {
    url += '?id=' + message_id + '&timestamp=' + timestamp;
  }

  return this.axios({ url, method });
};

self.getUpdates = function getUpdates(ids) {
  const url = '/api/v1/support/conversations/updates?ids=[' + ids.join(',') + ']';
  const method = 'get';

  return this.axios({ url, method });
};

self.toggleState = function toggleState(id, closed) {
  const url = '/api/v1/support/conversations/' + id + '/toggle';
  const method = 'put';

  const data = { closed };

  return this.axios({ url, method, data });
};

self.sendMessage = function sendMessage(id, message_id, message) {
  const url = '/api/v1/support/conversations/' + id;
  const method = 'put';
  const data = { type: 'add', message_id, message };

  return this.axios({ url, method, data });
};

self.updateMessage = function updateMessage(id, message_id, message) {
  const url = '/api/v1/support/conversations/' + id;
  const method = 'put';
  const data = { type: 'update', message_id, message };

  return this.axios({ url, method, data });
};

self.removeMessage = function updateMessage(id, message_id) {
  const url = '/api/v1/support/conversations/' + id;
  const method = 'put';
  const data = { type: 'remove', message_id };

  return this.axios({ url, method, data });
};

export default self;
