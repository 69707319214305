import { handleActions } from 'redux-actions';

import { startSession, setUser, signout } from './actions';
import initial from './initial';

export default handleActions({
    [startSession]: _startSession,
    [setUser]: _setUser,
    [signout]: _signout,
}, initial());

function _startSession (state, { payload }) {
  return { ...state, ...payload };
}

function _setUser (state, { payload }) {
  return { ...state, ...payload };
}

function _signout() {
  return initial();
}
