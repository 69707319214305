import React, { Component } from 'react';

import Popup from '/views/ui/Popup';

import { SECTION_LINE_TYPE } from '../../constants';

class Section extends Component {
  state = {
    hover: false,
  };

  showPopup = () => this.setState({ hover: true });
  hidePopup = () => this.setState({ hover: false });

  renderPopup = () => {
    const { hover } = this.state;
    const { description } = this.props;

    if (!hover || !description) return null;

    return (
      <Popup.Dark width={280} shown={true} style={{ padding: '12px 5px' }} boundingBox=".panel">
        <div className="fs-7">
          {description()}
        </div>
      </Popup.Dark>
    );
  };

  render() {
    const { color, line } = this.props;

    const style = {
      left: line.x[0],
      width: line.width,
      backgroundColor: color,
    };

    const className = {
      [SECTION_LINE_TYPE.LINE]: 'ui_line_chart-sections_item',
      [SECTION_LINE_TYPE.POINT]: 'ui_line_chart-sections_point',
    }[line.type] || '';

    return (
      <div
        className={className}
        style={style}
        onMouseEnter={this.showPopup}
        onMouseLeave={this.hidePopup}
      >
        {this.renderPopup()}
      </div>
    );
  }
}

const Line = props => {
  const { items } = props;

  return (
    <div className="ui_line_chart-sections_line">
      {items.map((section, i) => <Section key={i} { ...props } { ...section } />)}
    </div>
  );
};

const Item = props => {
  let { label, area, lines } = props;

  !lines.length && (lines = [[]]);

  const style = {
    width: area.x[1] - area.x[0],
  };

  return (
    <div className="ui_line_chart-sections_row">
      <div className="ui_line_chart-sections_label">
        {label}
      </div>
      <div className="ui_line_chart-sections_lines" style={style}>
        {lines.map((items, i) => <Line key={i} items={items} { ...props } />)}
      </div>
    </div>
  );
};

const Sections = props => {
  const { data, sections, exclude, settings, onLegendClick } = props;

  return (
    <div className="ui_line_chart-sections_wrapper">
      {sections.map(item => <Item key={item.name} { ...item } />)}
    </div>
  );
};

export default Sections;
