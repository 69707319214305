import moment from 'moment';

import { capitalize } from '/utils/string';

export default year => year.map(month => {
  const date = moment(month);

  const number = +date.format('M');
  const label = date.format('MMMM');

  const result = { label: capitalize(label) };

  if (number === 1) {
    result.border = {
      left: { width: 5, color: '#4de5d5' },
    };
  }

  return result;
});
