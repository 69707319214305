import React from 'react';

import { UI } from '/views';

const fields = {};

fields.action = (item, actions, settings) => {
  const { lemma } = item;
  const { selections } = settings;

  const { onSelect, onDeselect } = actions;

  const exists = selections.includes(lemma);

  const Button = exists ? UI.Button.Danger : UI.Button;

  const icon = exists ? 'fal fa-times' : 'fal fa-chevron-double-right';
  const action = exists ? onDeselect : onSelect;

  return <Button icon={icon} size="xs" className="pl-2 pr-2 pt-1 pb-1" onClick={() => action(lemma)} />;
};

fields.lemma = item => item.lemma;
fields.count = item => item.count;
fields.overall = item => item.percent.overall;
fields.core = item => item.percent.core;

export default (name, item, actions, settings) => fields[name](item, actions, settings);
