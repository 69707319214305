import React, { Component } from 'react';
import cn from 'classnames';

import svg from './svg';
import html from './html';
import react from './react';

import { MARKER } from '../../constants';

const Chart = props => {
  let { data, settings, events, className } = props;

  let _svg = '', _html = '', _react = '';

  _svg = `
    <svg style="width: 100%; height: ${data.area.height}px;">
      ${svg.axis(data.rows, data.columns, false)}
      ${svg.sections(data.sections)}
      ${svg.axis(data.rows, data.columns, true)}
      ${svg.lines(data.lines, false, true)}
      ${svg.bars(data.bars)}
      ${svg.lines(data.lines, true, false)}
    </svg>
  `;

  _html = `
    ${html.axis(data.rows, data.columns)}
  `;

  const { marker, selection } = data.settings;

  _react = (
    <div className="ui_line_chart-chart_react">
      {(marker.type === MARKER.OVERALL || selection.enabled) && react.overlay(data.columns, data.lines, data.bars, data.settings, events)}
      {react.bars(data.bars, data.settings)}
      {react.lines(data.lines, data.settings)}
    </div>
  );

  return (
    <div className={cn('ui_line_chart-chart_wrapper', className)}>
      <div className="ui_line_chart-chart">
        <div className="ui_line_chart-chart_svg" dangerouslySetInnerHTML={{ __html: _svg }} />
        <div className="ui_line_chart-chart_html" dangerouslySetInnerHTML={{ __html: _html }} />
        {_react}
      </div>
    </div>
  );
};

export default Chart;
