import React, { Component, Fragment } from 'react';
import cn from 'classnames';

import { UI, BL } from '/views';

import SitemapInsertBox from '../SitemapInsertBox';
import SitemapTable from '../SitemapTable';

import { SOURCE } from '../../constants';

import { deduplicate } from '/utils/array';
import { check as checkLink, getRoot } from '/utils/link';

import transformSitemap from '../../utils/transformSitemap';
import mergeSitemaps from '../../utils/mergeSitemaps';

class Step_2 extends Component {
  onLinksUpdate = (name, text) => {
    const { step_2, updateState } = this.props;

    const items = text.split('\n').filter(v => !!v);
    const links = items.filter(link => checkLink(link));

    updateState({ ...step_2, insert: { text, items, links } });
  };

  onCheckSitemaps = async () => {
    const { step_2, provider, updateState } = this.props;

    const urls = deduplicate(step_2.insert.links).filter(url => !step_2.result.find(v => v.link === url));

    if (!urls.length) return;

    updateState({ ...step_2, lock: SOURCE.MANUAL });

    const { items } = await provider.checkSitemaps(urls);

    const result = mergeSitemaps(step_2.result, items.map(item => transformSitemap(item, item.valid)));

    updateState({ ...step_2, lock: false, result });
  };

  onDecide = async callback => {
    const { step_2, provider, updateState, project } = this.props;

    updateState({ ...step_2, lock: SOURCE.AUTOMATICALY });

    const { items } = await provider.decideSitemaps(project.mirrors.main);

    const result = mergeSitemaps(step_2.result, items.map(item => transformSitemap(item, item.valid)));

    const found = items.length;
    const added = result.length - step_2.result.length;

    callback(found, added);

    updateState({ ...step_2, lock: false, result });
  };

  onSitemapAdd = url => {
    const { step_2, updateState } = this.props;

    const idx = step_2.result.findIndex(v => v.link === url);

    if (idx === -1) return;

    const item = { ...step_2.result[idx] };

    if (item.id) item.isRemoved = false;
    if (!item.id) item.isAdded = true;

    const result = step_2.result.slice();

    result[idx] = item;

    updateState({ ...step_2, lock: false, result });
  };

  onSitemapRemove = url => {
    const { step_2, updateState } = this.props;

    const idx = step_2.result.findIndex(v => v.link === url);

    if (idx === -1) return;

    const item = { ...step_2.result[idx] };

    if (item.id) item.isRemoved = true;
    if (!item.id) item.isAdded = false;

    const result = step_2.result.slice();

    result[idx] = item;

    updateState({ ...step_2, lock: false, result });
  };

  render() {
    const { project, step_2 } = this.props;
    const { loading, lock, insert, result } = step_2;

    if (loading) return <div className="py-3 text-center"><UI.Loader /></div>;

    const { onSitemapAdd, onSitemapRemove } = this;

    const actions = { onSitemapAdd, onSitemapRemove };

    return (
      <Fragment>
        <h4 className="py-2 font-weight-semibold">Шаг 2: Настройки XML-карты сайта</h4>

        <div className="panel-tag my-2">
          <p>XML-карта используется в аудите как источник информации о списке страниц сайта и крайне важна для ряда отчетов, но не обязательна для запуска аудита.</p>
          <p>Если вы не знаете адрес XML-карты сайта, нажмите кнопку "Найти XML-карты автоматически" и мы постараемся найти их автоматически.</p>
        </div>

        <SitemapInsertBox lock={lock} insert={insert} project={project} onLinksUpdate={this.onLinksUpdate} onProcess={this.onCheckSitemaps} onDecide={this.onDecide} />

        {
          result.length
          ? <SitemapTable data={result} actions={actions} />
          : (
            <BL.Alert.Warning className="mt-3 mb-0">
              <BL.Alert.Icon icon="fal fa-exclamation-triangle" />
              <BL.Alert.Content>
                Не добавлено ни одной карты сайта.
              </BL.Alert.Content>
            </BL.Alert.Warning>
          )
        }
      </Fragment>
    );
  }
}

export default Step_2;
