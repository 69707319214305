import itemId from './_config/itemId';
import columns from './_config/columns';
import getter from './_config/getter';
import searcher from './_config/searcher';
import decorator from './_config/decorator';
import sorter from './_config/sorter';
import child from './_config/child';

const pagination = true;

export default {
  itemId,
  columns,
  getter,
  searcher,
  decorator,
  sorter,
  pagination,
  child
};
