import { connect } from 'react-redux'
import view from './view';

import { createRoute } from '/services/Router/route';

const route = createRoute({ path: '/audit/:auditId/reports/traffic/pages/:reportId', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { router, user } = state;

  const { auditId, reportId } = router.params.url;

  return { id: +reportId, audit_id: +auditId, user_id: user.id };
};

export default { route, view: connect(mapStateToProps)(view), settings };
