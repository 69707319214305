import React, { Component } from 'react';
import Modal from '../Base';

import cn from 'classnames';

import KeyControl from '/services/KeyControl';

import UI from '/views/ui';

const getEventTagName = event => event.target ? event.target.tagName.toLowerCase() : null;

class Confirm extends Component {
  onKeyEnter = event => {
    const tag = getEventTagName(event);

    if (['textarea'].includes(tag)) return;

    this.props.actions.onSubmit();
  };

  onKeyEscape = event => {
    const tag = getEventTagName(event);

    if (['input', 'textarea'].includes(tag)) return;

    this.props.actions.onCancel();
  };

  componentDidMount() {
    KeyControl.on('keyup:enter', this.onKeyEnter);
    KeyControl.on('keyup:escape', this.onKeyEscape);
  }

  componentWillUnmount() {
    KeyControl.off('keyup:enter', this.onKeyEnter);
    KeyControl.off('keyup:escape', this.onKeyEscape);
  }

  render() {
    const { icon, title, subtitle, actions, children, buttons, ...sets } = this.props;

    return (
      <Modal { ...sets } position="top" onClose={actions.onCancel}>
        <div className="modal-header pt-3 pb-3">
          <h4 className="modal-title d-flex align-items-center">
            {icon && <i className={cn(icon, 'mr-3')} style={{ fontSize: '1.9rem' }} />}
            <span>
              {title}
              {subtitle && <small className="m-0 text-muted">{subtitle}</small>}
            </span>
          </h4>
        </div>
        <div className="modal-devider" />
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer pt-2 pb-2">
          <UI.Button stl="secondary" onClick={actions.onCancel}>{buttons.cancel || 'Отмена'}</UI.Button>
          <UI.Button stl={sets.stl} onClick={actions.onSubmit}>{buttons.submit || 'Подтвердить'}</UI.Button>
        </div>
      </Modal>
    );
  }
};

Confirm.Danger = props => <Confirm stl="danger" { ...props } />;
Confirm.Warning = props => <Confirm stl="warning" { ...props } />;
Confirm.Success = props => <Confirm stl="success" { ...props } />;
Confirm.Info = props => <Confirm stl="info" { ...props } />;
Confirm.Primary = props => <Confirm stl="primary" { ...props } />;

export default Confirm;
