import React, { Component, Fragment } from 'react';
import moment from 'moment';
import cn from 'classnames';

import { BL } from '/views';

import getCellStyle from '../../../../../utils/getCellStyle';

import { deduplicate } from '/utils/array';
import { sec2dur } from '/utils/time';

const empty = {
  visits: { variance: 0 },
  deny: { variance: 0 },
  deep: { variance: 0 },
  time: { variance: 0 }
};

const sortLinks = (item, links) => {
  return links.sort((a, b) => {
    let src_a, src_b, _a, _b;

    src_a = item.stats.year[1].links[a] || empty;
    src_b = item.stats.year[1].links[b] || empty;

    [_a, _b] = [src_a.visits, src_b.visits];

    if (_a < _b) return  1;
    if (_a > _b) return -1;

    return 0;
  });
};

const grabLinks = item => {
  const links = Object.keys(item.stats.year[1].links);

  return links.filter(link => item.stats.year[1].links[link].visits);
};

const grabSortedLinks = item => {
  const links = grabLinks(item);

  return sortLinks(item, links);
};

const getUniqLinks = item => {
  let links = grabLinks(item);

  links = links.map(link => link.replace(/^https?:|#.*$/g, ''));

  return deduplicate(links);
};

class QueryChild extends Component {
  hasVisits() {
    const { item } = this.props;

    return item.stats.year[1].total.visits.variance > 0;
  }

  renderDescription = () => {
    const { item } = this.props;

    const links = grabLinks(item);
    const uniq = getUniqLinks(item);

    const from = moment(item.stats.year[1].date.from).format('MMM YYYY');
    const to = moment(item.stats.year[1].date.to).format('MMM YYYY');

    const elements = [
      <p key="1" className="select-none">Ниже указаны данные по поведению пользователей в целом по запросу и по страницам, на которые шел трафик по запросу.</p>,
      <p key="2">Данные за период <b>{from}</b> - <b>{to}</b>.</p>,
    ];

    if (uniq.length > 1) {
      elements[elements.length] = (
        <BL.Alert.Danger key="3" className="mb-3 select-none">
          <BL.Alert.Content>
            Трафик по запросу идет на разные страницы, убедитесь, что вы не оптимизируете их под один и тот же запрос и что это не дубликаты одной и той же страницы.
          </BL.Alert.Content>
        </BL.Alert.Danger>
      );
    } else if (uniq.length === 1 && links > 1) {
      elements[elements.length] = (
        <BL.Alert.Warning key="3" className="mb-3 select-none">
          <BL.Alert.Content>
            Трафик по запросу за год шел на одну и ту же страницу, но с разными протоколами: адрес страницы различается только http и https.
          </BL.Alert.Content>
        </BL.Alert.Warning>
      );
    }

    return elements;
  };

  renderTotal = () => {
    const { item } = this.props;

    const year = item.stats.year[1].total || empty;

    return (
      <tr>
        <td className="text-left p-2 table-divide-bottom">Общие данные по запросу</td>
        <td className="p-2 table-divide-bottom">{year.visits.variance}</td>
        <td className="p-2 table-divide-bottom">{year.deny.variance}%</td>
        <td className="p-2 table-divide-bottom">{year.deep.variance}</td>
        <td className="p-2 table-divide-bottom">{sec2dur(year.time.variance)}</td>
      </tr>
    );
  };

  renderLink = link => {
    const { item } = this.props;

    const year = item.stats.year[1].links[link] || empty;
    const total = item.stats.year[1].total || empty;

    return (
      <tr key={link}>
        <td className="text-left p-2">
          <a href={link} target="_blank">{link}</a> ({Math.round(year.visits / total.visits.variance * 100)}%)
        </td>
        <td className="p-2">{year.visits}</td>
        <td className="p-2">{year.deny}%</td>
        <td className="p-2">{year.deep}</td>
        <td className="p-2">{sec2dur(year.time)}</td>
      </tr>
    );
  };

  renderLinksTable = () => {
    const { item } = this.props;

    const links = grabSortedLinks(item);

    return (
      <div>
        <table className="table darken table-bordered table-sm text-center col-10 m-0">
          <thead>
            <tr>
              <th style={{ width: '50%' }} className="text-left p-2">Страница</th>
              <th style={{ width: '12.5%' }} className="p-2">Визиты</th>
              <th style={{ width: '12.5%' }} className="p-2">Отказы</th>
              <th style={{ width: '12.5%' }} className="p-2">Глубина</th>
              <th style={{ width: '12.5%' }} className="p-2">Время</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTotal()}
            {links.map(this.renderLink)}
          </tbody>
        </table>
      </div>
    );
  };

  renderDenyDescription = () => {
    return (
      <div className="mt-3">
        Ниже данные по показателю отказов по запросу за разные периоды и среднее значение показателя отказов по сайту за период. Если показатель отказов заметно растет и тем более выше среднего по сайту, возможно, страница все хуже отвечает требованиям пользователей и это может быть причиной снижения трафика в Яндекс.
      </div>
    );
  };

  renderDenyTable = () => {
    const { item, settings } = this.props;

    const year_0 = {
      from: moment(item.stats.year[0].date.from).format('MMM YYYY'),
      to: moment(item.stats.year[0].date.to).format('MMM YYYY'),
    };

    const year_1 = {
      from: moment(item.stats.year[1].date.from).format('MMM YYYY'),
      to: moment(item.stats.year[1].date.to).format('MMM YYYY'),
    };

    return (
      <table className="table darken table-bordered table-sm text-center col-10 mt-3 mb-0">
        <thead>
          <tr>
            <th style={{ width: '25%' }} className="text-left p-2">Период</th>
            <th style={{ width: '18.75%' }} className="p-2">{year_0.from} - {year_0.to}</th>
            <th style={{ width: '18.75%' }} className="p-2">Среднее за период</th>
            <th style={{ width: '18.75%' }} className="p-2">{year_1.from} - {year_1.to}</th>
            <th style={{ width: '18.75%' }} className="p-2">Среднее за период</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: '25%' }} className="text-left p-2">
              12 месяцев
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.year[0].total.deny.statistic))}>
              {item.stats.year[0].total.visits.variance ? item.stats.year[0].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.year[0].average.deny}%
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.year[1].total.deny.statistic))}>
              {item.stats.year[1].total.visits.variance ? item.stats.year[1].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.year[1].average.deny}%
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }} className="text-left p-2">
              3 месяца ({moment(item.stats.last3month[1].date.from).format('MMM')} - {moment(item.stats.last3month[1].date.to).format('MMM')})
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.last3month[0].total.deny.statistic))}>
              {item.stats.last3month[0].total.visits.variance ? item.stats.last3month[0].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.last3month[0].average.deny}%
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.last3month[1].total.deny.statistic))}>
              {item.stats.last3month[1].total.visits.variance ? item.stats.last3month[1].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.last3month[1].average.deny}%
            </td>
          </tr>
          <tr>
            <td style={{ width: '25%' }} className="text-left p-2">
              1 месяц ({moment(item.stats.last1month[1].date.from).format('MMM')})
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.last1month[0].total.deny.statistic))}>
              {item.stats.last1month[0].total.visits.variance ? item.stats.last1month[0].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.last1month[0].average.deny}%
            </td>
            <td style={{ width: '18.75%' }} className={cn('p-2', getCellStyle(item.stats.last1month[1].total.deny.statistic))}>
              {item.stats.last1month[1].total.visits.variance ? item.stats.last1month[1].total.deny.variance + '%' : '-'}
            </td>
            <td style={{ width: '18.75%' }} className="p-2">
              {settings.average.last1month[1].average.deny}%
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  render() {
    const hasVisits = this.hasVisits();

    return (
      <Fragment>
        {hasVisits && this.renderDescription()}
        {hasVisits && this.renderLinksTable()}
        {this.renderDenyDescription()}
        {this.renderDenyTable()}
      </Fragment>
    );
  }
}

export default (item, actions, settings) => <QueryChild item={item} settings={settings} />;
