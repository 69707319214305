const fields = {};

const color_success = 'report-result-good';
const color_static = 'report-result-static';
const color_subside = 'report-result-subside';
const color_warning = 'report-result-warning';
const color_danger = 'report-result-danger';
const color_critical = 'report-result-critical';
const color_unknown = 'report-result-unknown';

const getColor = percent => {
  if (percent > 10) return color_success;
  if (percent > -10) return color_static;
  if (percent > -40) return color_warning;

  return color_danger;
};

fields.url = () => 'text-break';

fields.state = (item, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return color_static;

  const { verdict } = values[settings.select];

  const states = {
    TRAFFIC_GROW: color_success,
    TRAFFIC_DROP: color_danger,
    TRAFFIC_ZERO: color_critical,
    TRAFFIC_STABLE: color_static,
    TRAFFIC_SUBSIDE: color_subside,
    TRAFFIC_UNMATCH: color_unknown
  };

  return states[verdict];
};

fields.diff_year = (item, settings) => {
  const { state, values } = item.analytics.total;

  if (state !== 'STATE_OK') return null;

  const { variance, percent, tone } = values[settings.select];

  return getColor(percent);
};

fields.diff_3_month = (item, settings) => {
  const { state, values } = item.analytics.last3month;

  if (state !== 'STATE_OK') return null;

  const { variance, percent, tone } = values[settings.select];

  return getColor(percent);
};

fields.diff_1_month = (item, settings) => {
  const { state, values } = item.analytics.year[11];

  if (state !== 'STATE_OK') return null;

  const { variance, percent, tone } = values[settings.select];

  return getColor(percent);
};

fields.downfall = (item, settings) => {
  const { state, values } = item.analytics.overall;

  if (state !== 'STATE_OK') return null;

  const { overall, yandex, google } = values;

  const downfall = values[settings.select].downfall.variance;

  if (downfall === 0) return color_success;
  if (downfall === 1) return color_static;
  if (downfall < 4) return color_warning;

  return color_danger;
};

export default (name, item, settings) => !!fields[name] && fields[name](item, settings);
