import { createActions } from 'redux-actions';

export const {
  fetchProjects,
  reloadProjects,
  addProjects,
  dropProjects,
  changeProjectsStatus,
  addAuditToProject,
  fetchProjectTypes,
  setProjectTypes,
  saveProjectSettings,
  removeProjectAudit,
  updateProjectStatus,
  fetchProjectMirrors,
  updateProjectMirrors,
  fetchProjectSitemaps,
  updateProjectSitemaps,
  blockProject,
} = createActions(
  {
    FETCH_PROJECTS: (attempt = 0) => ({ attempt }),
    RELOAD_PROJECTS: (defer) => ({ defer }),
    ADD_PROJECTS: list => ({ list }),
    DROP_PROJECTS: () => ({}),
    CHANGE_PROJECTS_STATUS: status => ({ status }),
    ADD_AUDIT_TO_PROJECT: (id, audit_id) => ({ id, audit_id }),
    FETCH_PROJECT_TYPES: (attempt = 0) => ({ attempt }),
    SET_PROJECT_TYPES: types => ({ types }),
    SAVE_PROJECT_SETTINGS: (id, settings, defer) => ({ id, settings, defer }),
    REMOVE_PROJECT_AUDIT: (id, auditId) => ({ id, auditId }),
    UPDATE_PROJECT_STATUS: (id, status) => ({ id, status }),
    FETCH_PROJECT_MIRRORS: (id, defer) => ({ id, defer }),
    UPDATE_PROJECT_MIRRORS: (id, mirrors) => ({ id, mirrors }),
    FETCH_PROJECT_SITEMAPS: (id, defer) => ({ id, defer }),
    UPDATE_PROJECT_SITEMAPS: (id, sitemaps) => ({ id, sitemaps }),
    BLOCK_PROJECT: (id, blocked) => ({ id, blocked }),
  }
);
