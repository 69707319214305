import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Адрес страницы',
    field: 'url',
    sortable: true,
    sortFirst: 'asc',
    style: {},
  },
  {
    name: 'Поисковый трафик за 3 месяца',
    field: 'traffic',
    style: { width: '390px', maxWidth: '390px', minWidth: '390px', textAlign: 'center' },
    childs: [
      {
        name: 'Весь поисковый трафик',
        field: 'all',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '130px', maxWidth: '130px', minWidth: '130px', textAlign: 'center' },
      },
      {
        name: 'Яндекс',
        field: 'yandex',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '130px', maxWidth: '130px', minWidth: '130px', textAlign: 'center' },
      },
      {
        name: 'Google',
        field: 'google',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '130px', maxWidth: '130px', minWidth: '130px', textAlign: 'center' },
      },
    ],
  },
];
