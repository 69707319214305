import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Сайт',
    field: 'host',
    sortable: true,
    sortFirst: 'asc',
  },
  {
    name: settings => {
      return (
        <Fragment>
          Статистика за месяц
          <BL.Info.Dark className="ml-1" side={BL.Info.SIDE_TOP} width={420} boundingBox=".panel" style={{ pointerEvents: 'none' }}>
            Поисковый трафик - визиты из поиска. Страницы входа - количество страниц сайта с поисковым трафиком. Месяцев с трафиком - количество месяцев с ненулевым поисковым трафиком за последние 24 месяца.
          </BL.Info.Dark>
        </Fragment>
      );
    },
    field: 'stats',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '35%', minWidth: '120px', maxWidth: '220px', textAlign: 'center' },
  },
  {
    name: 'Действия',
    field: 'actions',
    sortable: false,
    style: { width: '23%', minWidth: '160px', textAlign: 'center' },
  },
];
