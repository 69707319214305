const INITIAL_STATE = {};

if (__CLIENT__) {
  Object.assign(INITIAL_STATE, window.INITIAL_STATE || {});

  if (!__DEV__) {
    delete window.INITIAL_STATE;
  }
}

export const getInitial = function getInitial(name, value) {
  return name in INITIAL_STATE ? INITIAL_STATE[name] : value;
};

export default function createInitial() {
  return INITIAL_STATE;
}
