import React, { Component } from 'react';
import cn from 'classnames';

import { UI } from '/views';

import { sorter } from '/utils/array';

const MAX_WORDS = 6;
const MAX_LETTERS = 70;

const cropTitle = title => {
  const words = title.split(' ').filter(word => word.length > 3);

  let isOverWords, isOverLetters;

  (isOverWords = words.length > MAX_WORDS) && (title = title.split(words[MAX_WORDS - 1])[0] + ' ' + words[MAX_WORDS - 1]);
  (isOverLetters = title.length > MAX_LETTERS) && (title = title.slice(0, MAX_LETTERS));

  return title + (isOverWords || isOverLetters ? '...' : '');
};

class ChatSidebar extends Component {
  renderAddTicketButton() {
    const { onTicketAdd } = this.props;

    return (
      <div className="d-flex align-items-center justify-content-center" style={{ height: 58 }}>
        <UI.Button.Primary icon="fal fa-plus" text="Добавить тикет" onClick={onTicketAdd} style={{ width: 220 }} />
      </div>
    );
  }

  renderTickets() {
    let { selected, conversations, onTicketSelect } = this.props;

    conversations = Object.values(conversations).sort(sorter('id', 'desc'));

    const renderItem = item => {
      const title = cropTitle(item.title);

      const classes = [];

      classes[classes.length] = 'support_chat-ticket_button px-3 py-2';
      classes[classes.length] = !!item.updates && !item.unreaded && 'support_chat-ticket_button_update';
      classes[classes.length] = !!item.unreaded && 'support_chat-ticket_button_message';
      classes[classes.length] = item.id === selected && 'support_chat-ticket_button-selected';

      return (
        <li key={item.id} className="support_chat-ticket_item">
          <div className={cn(...classes)} onClick={() => onTicketSelect(item.id)}>
            <div className="pr-2 flex-1">
              {title}
            </div>
          </div>
        </li>
      );
    };

    return (
      <ul className="list-unstyled m-0">
        {conversations.map(renderItem)}
      </ul>
    );
  }

  render() {
    return (
      <div className="support_chat-sidebar border-faded select-none">
        <div className="support_chat-sidebar_wrapper bg-faded">
          <div className="support_chat-sidebar_wrapper_inner custom-scrollbar">
            <div className="w-100">
              {this.renderAddTicketButton()}
              <div className="nav-title m-0 px-3 text-muted">Тикеты</div>
              {this.renderTickets()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChatSidebar;
