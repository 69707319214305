import React from 'react';

import { BL } from '/views';

const ModalError = props => {
  const sets = {
    title: props.title,
    icon: 'fal fa-exclamation-circle',
  };

  return (
    <BL.Modal.Alert.Danger { ...sets }>
      <p>{props.description}</p>
      <p className="mt-3">Проверьте подключение к интернету и попробуйте позже.</p>
    </BL.Modal.Alert.Danger>
  );
};

export default ModalError;
