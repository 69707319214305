import React, { Component } from 'react';
import cn from 'classnames';

const IconBar = props => {
  const { color, className } = props;

  return (
    <div className={cn('ui_line_chart-legend_bar_icon', className)}>
      <div style={{ backgroundColor: color }} />
      <div style={{ backgroundColor: color }} />
      <div style={{ backgroundColor: color }} />
    </div>
  );
};

export default IconBar;
