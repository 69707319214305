export default function getLabelWidth(text) {
  const element = document.createElement('span');

  element.className = 'ui_line_chart-chart_label_text ui_line_chart-chart_label_text_y ui_line_chart-chart_level_text_hidden';
  element.textContent = text;

  document.body.appendChild(element);

  const width = element.offsetWidth;

  // document.body.removeChild(element);

  return width;
};
