import React from 'react';
import cn from 'classnames';

import TableHead from '../TableHead';
import TableBody from '../TableBody';

import { getPicker } from '/utils/object';

const Head = props => {
  const picker = getPicker('columns', 'settings', 'sort', 'onSort');

  const sets = picker(props);

  return <TableHead { ...sets } />;
};

const Body = props => {
  const picker = getPicker('loading', 'emptyText', 'itemId', 'data', 'columns', 'getter', 'actions', 'decorator', 'settings', 'child');

  const sets = picker(props);

  return <TableBody { ...sets } />
};

const Table = props => {
  const { className, child, wrapperStyle, useScrollbar } = props;

  const classList = [
    'table table-bordered table-hover table-striped table-layout-fixed',
    'dataTable position-relative w-100 valign-middle dtr-inline',
    'block_table-root',
    className,
  ];

  child && classList.push('collapsed');

  return (
    <div className={cn('dataTables_inner-wrapper', useScrollbar && 'custom-scrollbar')} style={wrapperStyle}>
      <table className={cn(classList)}>
        <Head { ...props } />
        <Body { ...props } />
      </table>
    </div>
  );
};

export default Table;
