export const PADDING = {
  TOP: 5,
  LEFT: -8,
  RIGHT: 0,
  BOTTOM: 12,
};

export const MARKER = {
  NONE: 'none',
  SINGLE: 'single',
  OVERALL: 'overall',
};

export const SECTION_LINE_TYPE = {
  LINE: 'LINE',
  POINT: 'POINT',
};

export default { PADDING, MARKER, SECTION_LINE_TYPE };
