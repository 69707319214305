import React, { Component } from 'react';

import cn from 'classnames';

import Popup from '/views/ui/Popup';

class Info extends Component {
  state = {
    shown: false
  };

  toggle = shown => this.setState({ shown });

  show = () => this.toggle(true);
  hide = () => this.toggle(false);

  onMouseEnter = () => this.show();
  onMouseLeave = () => this.hide();

  onClick = event => {
    event.preventDefault();

    this.show();

    document.addEventListener('click', this.onDocumentClick, false);
  };

  onDocumentClick = () => {
    this.hide();

    document.removeEventListener('click', this.onDocumentClick, false);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick, false);
  }

  render() {
    const { shown } = this.state;

    const {
      className,
      title,
      text,
      children,
      style = {},
      width,
      showOn = Info.SHOW_ON_HOVER,
      side = Popup.SIDE_TOP,
      stl = 'primary',
      icon = 'fal fa-question-circle',
      bait,
      boundingBox,
      passive = false,
    } = this.props;

    const sets = { className: cn('info-box', className) };

    showOn === Info.SHOW_ON_CLICK && (sets.onClick = this.onClick);
    showOn === Info.SHOW_ON_HOVER && (sets.onMouseEnter = this.onMouseEnter) && (sets.onMouseLeave = this.onMouseLeave);

    if (passive) {
      style.pointerEvents = 'none';
    }

    const popupSets = { stl, title, text, side, shown, children, style };

    width && (popupSets.width = width);
    boundingBox && (popupSets.boundingBox = boundingBox);

    return (
      <span { ...sets }>
        {bait ? bait : <i className={icon} />}
        <Popup { ...popupSets } />
      </span>
    );
  }
}

Info.SHOW_ON_CLICK = 'SHOW_ON_CLICK';
Info.SHOW_ON_HOVER = 'SHOW_ON_HOVER';

Info.SIDE_TOP = Popup.SIDE_TOP;
Info.SIDE_BOTTOM = Popup.SIDE_BOTTOM;

Info.SIDE_LEFT_MIDDLE = Popup.SIDE_LEFT_MIDDLE;
Info.SIDE_RIGHT_MIDDLE = Popup.SIDE_RIGHT_MIDDLE;

Info.Primary = props => <Info stl="primary" { ...props } />;
Info.Secondary = props => <Info stl="secondary" { ...props } />;
Info.Success = props => <Info stl="success" { ...props } />;
Info.Info = props => <Info stl="info" { ...props } />;
Info.Warning = props => <Info stl="warning" { ...props } />;
Info.Danger = props => <Info stl="danger" { ...props } />;
Info.Dark = props => <Info stl="fusion" { ...props } />;
Info.Light = props => <Info stl="light" { ...props } />;

export default Info;
