import React from 'react';

import Pagination from '../Pagination';

const BottomLine = props => {
  const { count, pagination, page, limit, onPaginate } = props;

  return (
    <div className="row" style={{ height: '34px' }}>
    	<div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-start">
        <Pagination.Statistics pagination={pagination} count={count} page={page} limit={limit} />
      </div>
    	<div className="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
        {pagination && <Pagination count={count} page={page} limit={limit} onPaginate={onPaginate} />}
    	</div>
    </div>
  );
};

export default BottomLine;
