import render from './render';

const PIE_RADIUS = 0.70;
const OFFSET_RADIUS = 0.85;

const LINE_HEIGHT = 24;

const CIRCLE_OFFSET = 50;
const TEXT_OFFSET = 8;

const DEG = Math.PI / 180;

const POSITION_LEFT = 'left';
const POSITION_RIGHT = 'right';

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians)),
  };
}

const matchSectorPath = (x, y, radius, startAngle, endAngle) => {
  if (startAngle === 0 && endAngle === 360) {
    endAngle = 359.99;
  }

  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const arcSweep = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M', start.x, start.y,
    'A', radius, radius, 0, arcSweep, 0, end.x, end.y,
    'L', x, y,
    'L', start.x, start.y,
  ].join(' ');

  return d;
};

class PieChart {
  constructor(data) {
    const { items, ...settings } = data;

    this.settings = settings;

    this.items = items.map(meta => ({ meta }));
    this.sides = { [POSITION_RIGHT]: [], [POSITION_LEFT]: [] };
    this.total = items.reduce((r, item) => r + item.value, 0);

    this.debug = [];
  }

  getViewportSize(target) {
    const width = target.clientWidth;
    const height = target.clientHeight;

    return { width, height };
  }

  matchCenter() {
    const { width, height } = this.viewport;

    return {
      x: Math.floor(width / 2),
      y: Math.floor(height / 2),
    };
  }

  matchRaduises() {
    const { width, height } = this.viewport;

    const text = Math.floor(Math.min(width, height) / 2 * OFFSET_RADIUS);
    const pie = Math.floor(Math.min(width, height) / 2 * PIE_RADIUS);
    const bg = pie - 1;

    return { text, pie, bg };
  }

  matchOffset = i => this.items.slice(0, i).reduce((r, item) => r + item.meta.value, 0);

  updateItems() {
    this.matchItemsSnaps();
    this.matchLabelsVisabilities();
    this.resolveLabelOverlaps();
    this.displaceSnaps();

    this.matchSectors();
    this.matchLines();
    this.matchLabels();
  }

  matchItemsSnaps() {
    const { items, total, center, radiuses } = this;

    for (let item, i = 0; item = items[i]; i++) {
      const angle_center = 360 * (this.matchOffset(i) + item.meta.value / 2) / total;

      const x = center.x + Math.sin(angle_center * DEG) * radiuses.text;
      const y = center.y - Math.cos(angle_center * DEG) * radiuses.text;

      const side = angle_center < 180 ? POSITION_RIGHT : POSITION_LEFT;
      const method = angle_center < 180 ? 'push' : 'unshift';

      this.sides[side][method](item);

      item.snap = { x, y, side };

      // this.debug.push({ x, y, color: '#87c' });
    }
  }

  matchLabelsVisabilities() {
    const { radiuses } = this;

    const capacity = Math.floor((2 * radiuses.text + LINE_HEIGHT) / LINE_HEIGHT);

    for (let side in this.sides) {
      if (!this.sides.hasOwnProperty(side)) continue;

      const list = this.sides[side].slice();

      list.sort((a, b) => b.meta.value - a.meta.value);

      list.forEach((item, i) => item.label = { show: !!item.meta.value && i < capacity });
    }
  }

  resolveLabelOverlaps() {
    const { center, radiuses } = this;

    for (let side in this.sides) {
      if (!this.sides.hasOwnProperty(side)) continue;

      let top, vector;

      const LH2 = LINE_HEIGHT / 2;

      top = center.y - radiuses.text - LINE_HEIGHT;
      vector = LH2;

      for (let item, i = 0; item = this.sides[side][i]; i++) {
        if (!item.label.show) continue;

        const delta = Math.abs(top - item.snap.y);
        const distance = vector + LH2;

        if (delta < distance) {
          item.snap.y = top + distance;

          top += LH2;
          vector += LH2;
        } else {
          top = item.snap.y;
          vector = LH2;
        }

        // this.debug.push({ x: item.snap.x, y: item.snap.y, color: '#8c7' });
      }

      top = center.y + radiuses.text + LINE_HEIGHT;
      vector = LH2;

      const max_y = top;

      for (let item, i = this.sides[side].length - 1; item = this.sides[side][i]; i--) {
        if (!item.label.show) continue;

        const y = Math.min(max_y, item.snap.y);

        const delta = top - y;
        const distance = vector + LH2;

        if (delta < distance) {
          item.snap.y = top - LINE_HEIGHT;

          top -= LINE_HEIGHT;
          vector += LH2;
        } else {
          top = item.snap.y;
          vector = LH2;
        }

        // this.debug.push({ x: item.snap.x, y: item.snap.y, color: '#d2167c' });
      }
    }
  }

  displaceSnaps() {
    const { center, radiuses } = this;

    for (let side in this.sides) {
      if (!this.sides.hasOwnProperty(side)) continue;

      const mult = { [POSITION_RIGHT]: 1, [POSITION_LEFT]: -1 }[side];

      for (let item, i = 0; item = this.sides[side][i]; i++) {
        item.snap.x = center.x + mult * Math.sqrt(radiuses.text ** 2 - (item.snap.y - center.y) ** 2);

        // this.debug.push({ x: item.snap.x, y: item.snap.y, color: '#0711d3' });
      }
    }
  }

  matchSectors() {
    const { items, total, center, radiuses } = this;

    for (let item, i = 0; item = items[i]; i++) {
      const start = 360 * this.matchOffset(i) / total;
      const end = 360 * this.matchOffset(i + 1) / total;

      item.sector = matchSectorPath(center.x, center.y, radiuses.pie, start, end)
    }
  }

  matchLines() {
    const { items, total, center, radiuses } = this;

    for (let item, i = 0; item = items[i]; i++) {
      const angle = 360 * (this.matchOffset(i) + item.meta.value / 2) / total;

      const points = [];

      const mult = { [POSITION_RIGHT]: 1, [POSITION_LEFT]: -1 }[item.snap.side];

      const alpha = angle * DEG;

      const sin =  1 * Math.sin(alpha);
      const cos = -1 * Math.cos(alpha);

      points[0] = [center.x + radiuses.pie * sin, center.y + radiuses.pie * cos];
      points[1] = [item.snap.x, item.snap.y];
      points[2] = [item.snap.x + mult * CIRCLE_OFFSET, item.snap.y];

      item.line = points.map(p => p.join(',')).join(' ');
    }
  }

  matchLabels() {
    const { items, total, settings } = this;

    const label = settings.label || (meta => meta.name);

    for (let item, i = 0; item = items[i]; i++) {
      const mult = { [POSITION_RIGHT]: 1, [POSITION_LEFT]: -1 }[item.snap.side];
      const anchor = { [POSITION_RIGHT]: 'start', [POSITION_LEFT]: 'end' }[item.snap.side];

      const x = item.snap.x + mult * (CIRCLE_OFFSET + TEXT_OFFSET);
      const y = item.snap.y + 3;

      const text = label(item.meta, total, item, this);

      item.label = { ...item.label, text, x, y, anchor };
    }
  }

  render(target) {
    this.viewport = this.getViewportSize(target);

    this.center = this.matchCenter();
    this.radiuses = this.matchRaduises();

    this.updateItems();

    const { items, center, radiuses, debug } = this;

    target.innerHTML = render({ items, center, radiuses, debug });
  }
}

export default PieChart;
