import React from 'react';
import { Provider } from 'react-redux';
import moment from 'moment';

import { initUser } from '/components/User/store/actions';
import { routerUpdatePath } from '/services/Router/store/actions';

import store from '/services/Store';

import { deferred } from '/utils/common';
import Cookies from '/utils/cookies';

import './services';
import './components';

import Root from './views/root';

moment.locale('ru');

let render = function () {};

class App {
  constructor(context, request) {
    this.context = context;
    this.request = request;

    this.store = store();

    if (__SERVER__) {
      this.headers = context.request.headers;
    }

    this.defer = this.initialize();
  }

  async initialize() {
    if (__CLIENT__) {
      return deferred((resolve, reject, defer) => {
        const token = Cookies.get('token');
        const remember = Cookies.get('remember');

        this.store.dispatch(initUser(token, remember, defer));
      });
    }

    if (__SERVER__) {
      await deferred((resolve, reject, defer) => {
        const { token } = this.context.cookie || {};

        this.store.dispatch(initUser(token, false, defer));
      });

      return deferred((resolve, reject, defer) => {
        const { path, method, params } = this.request;

        this.store.dispatch(routerUpdatePath(path, method, params, false, defer));
      });
    }
  }

  async render() {
    await this.defer;

    return render.call(this, () => <Provider store={this.store}><Root /></Provider>);
  }
}

export const setRender = func => render = func;

export default App;
