import axios from 'axios';
import config from '/utils/config';

import extend from './extend';

import { parse } from '/utils/common';

const SERVER_HOST = config('DATA_SERVER_HOST');

class Api {
  constructor(token) {
    this._token = token;

    extend(this);
  }

  HOST = SERVER_HOST;

  setToken = token => this._token = token;
  getToken = () => this._token;

  async file(params) {
    params.url = SERVER_HOST + params.url;

    const headers = {};

    if (this._token) {
      headers.Authorization = 'Bearer ' + this._token;
    }

    try {
      const { data } = await axios({ ...params, headers });

      return parse(data); // TODO: Do it correct!
    } catch (e) {
      return this.createError(e);
    }
  }

  async download(params, filename = 'noname') {
    params = {
      method: 'get',
      responseType: 'blob',
      ...params,
      url: SERVER_HOST + params.url,
    };

    const { data: file, headers } = await axios(params);

    filename = (/filename="([^"]+)"/.exec(headers['content-disposition']) || [])[1] || filename;

    const link = document.createElement('a');

    link.setAttribute('download', filename);
    link.setAttribute('href', URL.createObjectURL(file));

    link.click();
    link.remove();
  }

  async axios(params) {
    params.url = SERVER_HOST + params.url;

    const headers = {};

    if (this._token) {
      headers.Authorization = 'Bearer ' + this._token;
    }

    try {
      const { data: jsend } = await axios({ ...params, headers });

      return jsend.data || {};
    } catch (e) {
      return this.createError(e);
    }
  }

  createError(e) {
    console.log(e);

    return { error: true };
  }
}

export default Api;
