import React, { Fragment } from 'react';

import { BL } from '/views';

export default [
  {
    name: 'Название',
    field: 'name',
    sortable: true,
    sortFirst: 'asc',
  },
  {
    name: 'Статус',
    field: 'state',
    sortable: true,
    sortFirst: 'desc',
    style: { width: '15%', maxWidth: '90px', minWidth: '40px', textAlign: 'center' },
  },
  {
    name: 'Дата',
    style: { width: '30%', maxWidth: '30%', minWidth: '30%', textAlign: 'center' },
    childs: [
      {
        name: 'Начала',
        field: 'date_start',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '15%', maxWidth: '15%', minWidth: '15%', textAlign: 'center' },
      },
      {
        name: 'Окончания',
        field: 'date_finish',
        sortable: true,
        sortFirst: 'desc',
        style: { width: '15%', maxWidth: '15%', minWidth: '15%', textAlign: 'center' },
      },
    ],
  },
  {
    name: 'Действия',
    field: 'actions',
    style: { width: '240px', maxWidth: '240px', minWidth: '240px', textAlign: 'center' },
  },
];
