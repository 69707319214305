import { deduplicate } from '/utils/array';
import { getDomain } from '/utils/link';

export default function matchDomainsDiff(current, newest) {
  current = current.map(link => getDomain(link));
  newest = newest.map(link => getDomain(link));

  current = deduplicate(current);
  newest = deduplicate(newest);

  const added = newest.filter(domain => !current.includes(domain));
  const removed = current.filter(domain => !newest.includes(domain));

  return { added, removed };
};
