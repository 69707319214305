import Yandex from '/services/Yandex';

class Provider {
  constructor(api) {
    this.api = api;

    this.YA = new Yandex();
  }

  getList() {
    const { api } = this;

    return api.tokens.getList();
  }

  getYandexAuthCode() {
    const { YA } = this;

    return YA.getAuthCode();
  }

  addYandexAccaunt(code) {
    const { api } = this;

    return api.tokens.addYandexAccaunt({ code });
  }

  refreshYandexAccaunt(id) {
    const { api } = this;

    return api.tokens.refreshYandexAccaunt(id);
  }

  removeYandexAccaunt(id) {
    const { api } = this;

    return api.tokens.removeYandexAccaunt(id);
  }
}

export default Provider;
