const self  = {};

self.head_title = 'Парсер title, description и заголовков H1-H6 онлай';
self.head_keywords = '';
self.head_description = 'Бесплатный парсинг тегов title, мета-тегов description, H1 и других заголовков до 100 URL. Анализ структуры H-тегов, плотности слов d title, h1';

self.name = 'Анализ title, description и H1-H6';

self.title = 'Анализ title, description и заголовков H1-H6 по списку страниц';
self.description = 'С помощью этого инструмента можно быстро проанализировать содержимое тега title, мета-тега description, тегов заголовков h1-h6 по списку страниц.';

self.field_title = 'Список ссылок';
self.field_matchWords = 'Посчитать часто встречающиеся слова';
self.field_linksCount = 'Количество ссылок: <b class="<%= styleCount %>"><%= linksCount %></b> из <b><%= linksLimit %></b>';

self.button_check = 'Проверить';

self.notice_tooMuchPages = 'Страницы, превышающие лимит, не будут проверены';

self.error_incorrectUrl = 'В списке ссылок есть неправильный адрес. Адрес так же обязательно должен включать протокол.';
self.error_missmatchUrl = 'Все ссылки должны иметь одинаковый протокол и домен.';

self.title_result = 'Результат';

self.tab_allRestuls = 'Все результаты';
self.tab_title = 'Title';
self.tab_description = 'Description';
self.tab_h1h6 = 'H1-H6';
self.tab_h1 = 'H1';

self.export = 'Экспорт';

self.col_url = 'Страница';
self.col_title = 'Title';
self.col_description = 'Description';
self.col_h1h6 = 'Теги H1-H6';
self.col_h1 = 'Тег H1';

self.button_toggleAll = 'Показать все / Скрыть все';
self.button_toggleTags = 'Развернуть теги / Свернуть теги';

self.state_notFound = 'Не найдено';
self.state_cantCheck = 'Не удалось проверить';
self.state_noH1H6Tags = 'На странице нет H-тегов';
self.state_empty = 'Пустой';

self.button_countWords = 'Посчитать часто встречающиеся слова';

self.title_lemms = 'Часто встречающиеся слова';

self.col_lems = 'Слова (леммы)';
self.col_count = 'Кол-во';
self.col_forms = 'Словоформы из текста';

export default self;
