import itemId from './_config/itemId';
import columns from './_config/columns';
import getter from './_config/getter';
import searcher from './_config/searcher';
import sorter from './_config/sorter';

export default {
  itemId,
  columns,
  getter,
  searcher,
  sorter,
};
