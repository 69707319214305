import React from 'react';

import Message from './Message';

const Messages = props => {
  const { conversation_id, items, user, user_id, sendMessage, editing, onEdit } = props;

  const side = user.id === user_id;

  return items.map((item, i) => {
    const isFirst = items.length > 1 && !i;
    const isMiddle = items.length > 2 && !!i && i !== items.length - 1;
    const isLast = items.length > 1 && i === items.length - 1;

    const showTime = i === items.length - 1;

    const sets = { conversation_id, isFirst, isMiddle, isLast, showTime, side, sendMessage, editing, onEdit };

    return <Message key={i} { ...item } { ...sets } />
  });
};

export default Messages;
