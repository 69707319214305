import React, { Fragment } from 'react';

import { UI, BL } from '/views';

import { CHECK_YANDEX, CHECK_GOOGLE } from '../../constants';

import iconYandex from '/static/images/icons/yandex.png';
import iconGoogle from '/static/images/icons/google.png';

const InsertBox = props => {
  const { lock, insert, result, userAgent, onStartTextChange, onSearcherChange, onProcess } = props;

  const Button = insert.links.valid.length && insert.searchers.length ? UI.Button.Primary : UI.Button.Secondary;

  const textYandex = (
    <Fragment>
      <img src={iconYandex} style={{ width: 16, height: 16 }} /> Проверить Яндекс
    </Fragment>
  );

  const textGoogle = (
    <Fragment>
      <img src={iconGoogle} style={{ width: 16, height: 16 }} /> Проверить Google
    </Fragment>
  );

  return (
    <BL.Panel>
      <BL.Panel.Content className="p-0">
        <UI.Collapse title="Список ссылок" expand={!result.items.length}>
          <div className="pt-2 pb-3 pl-3 pr-3">
            <UI.Form.TextArea value={insert.text} onChange={onStartTextChange} style={{ height: '260px' }} />
            <h5 className="mt-2">Выберите поисковые системы для проверки индексации</h5>
            <div className="mt-2 custom-control custom-checkbox">
              <UI.Form.Checkbox name={CHECK_YANDEX} label={textYandex} checked={insert.searchers.includes(CHECK_YANDEX)} onChange={onSearcherChange} />
            </div>
            <div className="mt-2 custom-control custom-checkbox">
              <UI.Form.Checkbox name={CHECK_GOOGLE} label={textGoogle} checked={insert.searchers.includes(CHECK_GOOGLE)} onChange={onSearcherChange} />
            </div>
            <div className="mt-2 select-none">
              Количество правильных ссылок: <b>{insert.links.valid.length}</b>.
              <br />
              Количество неправильных ссылок: <b>{insert.links.invalid.length}</b>.
            </div>
            <div className="mt-2 select-none">
              <Button className="mt-2" size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} text="Проверить" onClick={onProcess} />
            </div>
          </div>
        </UI.Collapse>
      </BL.Panel.Content>
    </BL.Panel>
  );
};

export default InsertBox;
