import React from 'react';

import { BL } from '/views';

import config from './config';

const Table = props => {
  return <BL.Table className="darken select-default" { ...props } { ...config } />;
};

export default Table;
