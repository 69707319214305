import Link from './classes/Link';

import * as Methods from './constants/methods';

import { isFunction } from '/utils/types';
import { warn } from '/utils/logger';

import Lang from '/services/Lang';

const PARAM_REGEXP = /:([^\/&]+)/g;

const LANG_PREFIXES = Lang.AVAILABLE.filter(name => name !== Lang.DEFAULT);

const createChecker = function createChecker(path) {
  const params = [];

  let matches;

  while (matches = PARAM_REGEXP.exec(path)) {
    params.push(matches[1]);
  }

  const condition = path.replace(/\?/, '\\?').replace(PARAM_REGEXP, '([^/&]+)');
  const regexp = new RegExp(`^${condition}$`);

  return { params, regexp };
};

export const updateRoute = function updateRoute(route, component, action) {
  route.component = component;
  route.action = action;

  return route;
};

export const createRoute = function createRoute(sets) {
  const { path, method = Methods.GET, dataChecker, normalize = {}, layout = 'Default' } = sets;
  const { params, regexp } = createChecker(path);

  const result = { path, method, params, normalize, regexp, dataChecker };

  result.layout = layout;

  return result;
};

export const testRoute = function testRoute(path, method, route) {
  method = method.toUpperCase();

  return (route.method === method || route.method === Methods.ALL) && route.regexp.test(path);
};

export const matchRoute = function matchRoute(path, method, route) {
  const values = {};

  const matches = route.regexp.exec(path);

  for (let i = 0, len = route.params.length; i < len; i++) {
    const param = route.params[i];
    const normalizer = route.normalize[param];

    values[param] = matches[i + 1];

    if (isFunction(normalizer)) {
      values[param] = normalizer(values[param]);
    }
  }

  return values;
};

export const createLink = function createLink(router, values = {}) {
  if (!values.lang) {
    console.warn('Router: Function createLink should always receive lang in values');

    return '#';
  }

  let path = router.path.replace(PARAM_REGEXP, (match, $1) => {
    if (values[$1] === undefined) {
      warn(`<#>Router::createLink</> Can't find param <$>${$1}</> for path <$>${router.path}</>`);
    }

    return values[$1];
  });

  if (values.lang.toString() !== Lang.DEFAULT) {
    path = '/' + values.lang + path;
  }

  return new Link(path, router.method);
};
