import React, { PureComponent } from 'react';

import { LangConsumer } from '/contexts/Lang';

import { UI, BL } from '/views';

class UserAgentSelect extends PureComponent {
  onChange = event => {
    const value = event.target.value;
    const { onChange } = this.props;

    onChange(value);
  };

  render() {
    const { selected } = this.props;

    return (
      <LangConsumer>
        {lang => (
          <select className="form-control custom-select ml-1 mr-1" defaultValue={selected} onChange={this.onChange} style={{ width: 'auto' }}>
            <option value="USER_BROWSER">{lang('field_userAgent_currentBrowser')}</option>
            <option value="NONE">{lang('field_userAgent_empty')}</option>

            <optgroup label={lang('field_userAgent_searchers')}>
              <option value="CRAWLER_GOOGLE_BOT">Google Bot 2.1</option>
              <option value="CRAWLER_YANDEX_BOT">Yandex Bot 3.0</option>
            </optgroup>

            <optgroup label={lang('field_userAgent_desktopBrowsers')}>
              <option value="BROWSER_DESKTOP_IE">Internet Explorer 11</option>
              <option value="BROWSER_DESKTOP_EDGE">Microsoft Edge</option>
              <option value="BROWSER_DESKTOP_CHROME">Google Chrome</option>
              <option value="BROWSER_DESKTOP_SAFARI">Apple Safari</option>
              <option value="BROWSER_DESKTOP_FIREFOX">Mozilla FireFox</option>
            </optgroup>

            <optgroup label={lang('field_userAgent_mobileBrowsers')}>
              <option value="BROWSER_MOBILE_CHROME">Android Chrome</option>
              <option value="BROWSER_MOBILE_SAFARI">iPhone Safari</option>
              <option value="BROWSER_TABLET_SAFARI">iPad Safari</option>
            </optgroup>
          </select>
        )}
      </LangConsumer>
    );
  }
}

const InsertBox = props => {
  const { lock, insert, result, userAgent, onStartTextChange, onUserAgentChange, onProcess } = props;

  const Button = insert.links.length ? UI.Button.Primary : UI.Button.Secondary;

  return (
    <LangConsumer>
      {lang => (
        <BL.Panel>
          <BL.Panel.Content className="p-0">
            <UI.Collapse title={lang('field_links')} expand={!result.length}>
              <div className="pt-2 pb-3 pl-3 pr-3">
                <UI.Form.TextArea value={insert.text} onChange={onStartTextChange} style={{ height: '260px' }} />
                <label className="mt-2 select-none">
                  User agent: <UserAgentSelect selected={insert.userAgent} onChange={onUserAgentChange} />
                </label>
                <div className="mt-2 select-none">
                  {lang('count_links')}: <b>{insert.links.length}</b>.
                </div>
                <Button className="mt-2" size="sm" icon2="fal fa-angle-double-right ml-1" spin2={lock} text={lang('button_check')} onClick={onProcess} />
              </div>
            </UI.Collapse>
          </BL.Panel.Content>
        </BL.Panel>
      )}
    </LangConsumer>
  );
};

export default InsertBox;
