import { connect } from 'react-redux'
import { createRoute } from '/services/Router/route';

import view from './view';

import { setCurrentConversation, sendConversationMessage, toggleConversationState } from '/components/Conversations/store/actions';

const route = createRoute({ path: '/support/tickets/:id', layout: 'Dashboard' });

const settings = {
  name: __FOLDER__,

  title: lang => lang('head_title'),
  keywords: lang => lang('head_keywords'),
  description: lang => lang('head_description'),
};

const mapStateToProps = state => {
  const { router } = state;

  const { id } = router.params.url;

  return { id };
};

const mapDispatchToProps = { setCurrentConversation, sendConversationMessage, toggleConversationState };

export default { route, view: connect(mapStateToProps, mapDispatchToProps)(view), settings };
