const fields = {};

fields.name = item => item.name_ru;

fields.state = item => item.disabled;

fields.date_start = item => item.date_start;
fields.date_finish = item => item.date_finish;

export default (field, dir, settings) => {
  const getter = fields[field];
  const fallback = fields.date_start;

  dir = { asc: 1, desc: -1 }[dir];

  return (a, b) => {
    let _a, _b, m = dir;

    _a = getter(a, settings);
    _b = getter(b, settings);

    if (_a === _b) {
      _a = fallback(a, settings);
      _b = fallback(b, settings);

      m = 1;
    }

    if (_a > _b) return 1 * m;
    if (_a < _b) return -1 * m;

    return 0;
  };
};
