import { connect } from 'react-redux'

import View from './view';

const mapStateToProps = state => {
  const { audit, projects, router, user } = state;

  return { audit, projects, router, user };
};

export default connect(mapStateToProps)(View);
