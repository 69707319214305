const renderLabels = columns => {
  const items = columns.map(item => {
    const style = [
      `top: ${item.center.y}px`,
      `left: ${item.center.x}px`,
    ];

    return `<div class="ui_line_chart-chart_point" style="${style.join('; ')}"></div>`;
  });

  return items.join('');
};

const renderBoxes = columns => {
  const items = columns.map(item => {
    const style = [
      `position: absolute`,
      `top: ${item.bounder.top}px`,
      `left: ${item.bounder.left + 4}px`,
      `width: ${item.bounder.right - item.bounder.left - 8}px`,
      `height: ${item.bounder.bottom - item.bounder.top}px`,
      `background-color: #08ede26e`,
    ];

    return `<div style="${style.join('; ')}"></div>`;
  });

  return items.join('');
};

export default function html_axis(axis) {
  let html = '';

  html += renderBoxes(axis.x.columns);
  html += renderLabels(axis.x.columns);

  return html;
};
