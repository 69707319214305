import { GET } from '../constants/methods';

import { getNearest } from '/utils/dom';

export const createClickHandler = callback => function onClick(event) {
  const target = getNearest('A', event.target);

  if (!target || event.defaultPrevented) return true;

  const trg = target.getAttribute('target');
  const download = target.getAttribute('download') !== null;

  if (trg === '_blank' || trg === '_outer' || download) return true;

  const link = target.getAttribute('href');

  event.preventDefault();

  if (link === '#' || link === '') return true;

  callback(link, GET, {}, true);

  return true;
};

export const createPopStateHandler = callback => function onPopState(event) {
  event.preventDefault();

  callback(location.pathname + location.search, GET, {}, false);
};
